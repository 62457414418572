import {styled} from '@mui/system'

export const PrimaryButtonStyled = styled('div')(({theme}) => ({
  '.MuiButtonBase-root': {
    color: 'white',
    borderRadius: theme.spacing(21),
    height: theme.spacing(21),
    minWidth: theme.spacing(18),
    padding: `0 ${theme.spacing(15)}`,
    fontSize: 14,
    fontWeight: '600',
    textTransform: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary[100]
    }
  }
}))
