import {styled} from '@mui/system'

export const PatientDigitalEnrollmentStyled = styled('div')(({theme}) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingTop: '50px',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#F0F3F4',
  a: {
    color: '#000000de'
  },

  '.header': {
    width: '100%',
    top: 0
  },

  '.img-container': {
    margin: 'auto',
    height: '200px'
  },

  '.img-style': {
    width: '100%',
    height: '200px',
    padding: '16px'
  },

  '.message-container': {
    margin: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '50px',
    justifyContent: 'center',
    alignItems: 'center'
  },

  '.span-link': {
    cursor: 'pointer'
  },

  '.MuiPaper-root': {
    paddingBottom: theme.spacing(8)
  },

  '.ant-picker': {
    width: '100%'
  },

  '.submit-button': {
    color: '#fff',
    borderRadius: theme.spacing(21),
    fontWeight: 500,
    textTransform: 'none !important',
    marginBottom: theme.spacing(8)
  },

  '.button-container': {
    display: 'flex',
    justifyContent: 'space-between'
  },

  '.card-container': {
    boxShadow: '5px 5px 10px rgb(0 0 0 / 5%)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: '0 32px 32px 32px',
    padding: theme.spacing(8),

    '#patient-digital-enrollment-form': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '10px',
      '.consent': {
        gridColumn: '1 /  3',
        ' .ant-form-vertical .ant-form-item-row': {
          flexDirection: 'column-reverse'
        }
      }
    }
  }
}))
