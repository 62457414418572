import {Tab, TabProps} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import {ReactNode, useState} from 'react'
import {TabsLayoutStyled} from './TabsLayoutStyled'

type TabsLayoutProps = {
  tabs: TabProps[],
  contents: ReactNode[]
}

const TabsLayout: React.FC<TabsLayoutProps> = ({
  tabs,
  contents
}) => {
  const [value, setValue] = useState('0')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(`${newValue}`)
  }

  return (
    <TabsLayoutStyled>
      <TabContext value={value}>
        <TabList onChange={handleChange} scrollButtons value={value} variant='scrollable'>
          {tabs.map((tab, index) => <Tab key={index} value={`${index}`} {...tab} />)}
        </TabList>
        {
          contents.map((content, index) => (
            <TabPanel key={index} value={`${index}`}>
              {content}
            </TabPanel>
          ))
        }
      </TabContext>
    </TabsLayoutStyled>
  )
}

export default TabsLayout
