import {FormInstance} from 'antd'
import moment from 'moment'

export const setFormPatient = (form: FormInstance<any>, patient: Record<string, any>) => {
  form.setFieldValue('title', patient?.title)
  form.setFieldValue('lastName', patient?.lastName)
  form.setFieldValue('firstName', patient?.firstName)
  form.setFieldValue('sex', (patient?.sex && patient.sex.length > 0) ? patient?.sex : undefined)
  form.setFieldValue('fiscalCode', patient?.fiscalCode)
  form.setFieldValue('nationality', patient?.nationality)
  form.setFieldValue('birthDate', patient?.birthDate ? moment(patient?.birthDate) : '')
  form.setFieldValue('prescribingDoctor', patient?.prescribingDoctor)
  form.setFieldValue('phoneNumber', patient?.phoneNumber)
  form.setFieldValue('cellPhoneNumber', patient?.cellPhoneNumber)
  form.setFieldValue('faxNumber', patient?.faxNumber)
  form.setFieldValue('email', patient?.email)
  form.setFieldValue('country', 'IT')
  form.setFieldValue('region', (patient?.address?.region && patient.address?.region.length > 0) ? patient?.address?.region : undefined)
  form.setFieldValue('province', (patient?.address?.province && patient.address?.province.length > 0) ? patient?.address?.province : undefined)
  form.setFieldValue('city', (patient?.address?.city && patient.address?.city.length > 0) ? patient?.address?.city : undefined)
  form.setFieldValue('address', patient?.address?.address)
  form.setFieldValue('streetNumber', patient?.address?.streetNumber)
  form.setFieldValue('postalCode', patient?.address?.postalCode)
  form.setFieldValue('locality', patient?.address?.locality)
  form.setFieldValue('EMAIL', patient?.preferredContacts ? patient?.preferredContacts?.includes('EMAIL') : false)
  form.setFieldValue('SMS', patient?.preferredContacts ? patient?.preferredContacts?.includes('SMS') : false)
  form.setFieldValue('PUSH', patient?.preferredContacts ? patient?.preferredContacts?.includes('PUSH') : false)
  form.setFieldValue('delegateId_1', patient?.delegateId_1)
  form.setFieldValue('delegateId_2', patient?.delegateId_2)
  form.setFieldValue('caregiverId', patient?.caregiverId)
  form.setFieldValue('preferredContactMethods', patient?.preferredContactMethods)
  form.setFieldValue('receiveModuleDate', patient?.receiveModuleDate ? moment(patient?.receiveModuleDate) : '')
  form.setFieldValue('remindTherapyExpiryTypes', patient?.remindTherapyExpiryTypes)
  form.setFieldValue('ENROLLMENT', patient?.privacyConsents ? patient?.privacyConsents?.includes('ENROLLMENT') : false)
  form.setFieldValue('PRIVACY_PHONE_SURVEY', patient?.privacyConsents ? patient?.privacyConsents?.includes('PRIVACY_PHONE_SURVEY') : false)
  form.setFieldValue('PRIVACY_ENROLLMENT_APP_WEB', patient?.privacyConsents ? patient?.privacyConsents?.includes('PRIVACY_ENROLLMENT_APP_WEB') : false)
  form.setFieldValue('mds', patient?.mds?.toString())
  form.setFieldValue('testDBSService', patient?.testDBSService?.toString())
  form.setFieldValue('serviceConf', patient?.serviceConf?.toString())
  form.setFieldValue('drugDeliveryService', patient?.drugDeliveryService?.toString())
}

export const setFormUser = (form: FormInstance<any>, patient: Record<string, any>) => {
  form.setFieldValue('title', patient?.title)
  form.setFieldValue('lastName', patient?.lastName)
  form.setFieldValue('firstName', patient?.firstName)
  form.setFieldValue('consuntiveCode', patient?.consuntiveCode)
  form.setFieldValue('fiscalCode', patient?.fiscalCode)
  form.setFieldValue('sex', (patient?.sex && patient.sex.length > 0) ? patient?.sex : undefined)
  form.setFieldValue('nationality', patient?.nationality)
  form.setFieldValue('birthDate', patient?.birthDate ? moment(patient?.birthDate) : '')
  form.setFieldValue('prescribingDoctor', patient?.prescribingDoctor)
  form.setFieldValue('createdAt', patient?.createdAt ? moment(patient?.createdAt) : '')
  form.setFieldValue('specialization', patient?.specialization)
  form.setFieldValue('medicalCenterId', patient?.medicalCenterId)
  form.setFieldValue('medicalCenter', patient?.medicalCenter)
  form.setFieldValue('phoneNumber', patient?.phoneNumber)
  form.setFieldValue('cellPhoneNumber', patient?.cellPhoneNumber)
  form.setFieldValue('faxNumber', patient?.faxNumber)
  form.setFieldValue('email', patient?.email)
  form.setFieldValue('skypeId', patient?.skypeId)
  form.setFieldValue('country', 'IT')
  form.setFieldValue('region', (patient?.address?.region && patient.address?.region.length > 0) ? patient?.address?.region : undefined)
  form.setFieldValue('province', (patient?.address?.province && patient.address?.province.length > 0) ? patient?.address?.province : undefined)
  form.setFieldValue('city', (patient?.address?.city && patient.address?.city.length > 0) ? patient?.address?.city : undefined)
  form.setFieldValue('address', patient?.address?.address)
  form.setFieldValue('streetNumber', patient?.address?.streetNumber)
  form.setFieldValue('postalCode', patient?.address?.postalCode)
  form.setFieldValue('locality', patient?.address?.locality)
  form.setFieldValue('EMAIL', patient?.preferredContacts ? patient?.preferredContacts?.includes('EMAIL') : false)
  form.setFieldValue('SMS', patient?.preferredContacts ? patient?.preferredContacts?.includes('SMS') : false)
  form.setFieldValue('PUSH', patient?.preferredContacts ? patient?.preferredContacts?.includes('PUSH') : false)
  form.setFieldValue('delegateId_1', patient?.delegateId_1)
  form.setFieldValue('delegateId_2', patient?.delegateId_2)
  form.setFieldValue('caregiverId', patient?.caregiverId)
  form.setFieldValue('remindTherapyExpiryTypes', patient?.remindTherapyExpiryTypes)
  form.setFieldValue('preferredContactMethods', patient?.preferredContactMethods)
  form.setFieldValue('receiveModuleDate', patient?.receiveModuleDate ? moment(patient?.receiveModuleDate) : '')
  form.setFieldValue('enrollmentDate', patient?.enrollmentDate ? moment(patient?.enrollmentDate) : '')
  form.setFieldValue('ENROLLMENT', patient?.privacyConsents ? patient?.privacyConsents?.includes('ENROLLMENT') : false)
  form.setFieldValue('PRIVACY_PHONE_SURVEY', patient?.privacyConsents ? patient?.privacyConsents?.includes('PRIVACY_PHONE_SURVEY') : false)
  form.setFieldValue('PRIVACY_ENROLLMENT_APP_WEB', patient?.privacyConsents ? patient?.privacyConsents?.includes('PRIVACY_ENROLLMENT_APP_WEB') : false)
  form.setFieldValue('serviceConf', patient?.serviceConf?.toString())
  form.setFieldValue('drugDeliveryService', patient?.drugDeliveryService?.toString())
}
