import {styled} from '@mui/material'

export const DepartmentDialogStyle = styled('div')(({theme}) => ({
  '.ant-form': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(10.5)
  },
  '.MuiCardContent-root': {
    '.card': {
      '&-start': {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: theme.spacing(15)
      },
      '&-end': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: theme.spacing(15)
      }
    }
  },
  '.MuiPaper-root': {
    borderRadius: 0
  }
}))
