import _, {pickBy} from 'lodash'
import {fetchPspConfig} from 'src/slices/pspConfigSlice'
import {fetchUserInfo, patchUserData} from 'src/slices/userSlice'
import apiClientRequest from './apiClientRequest'
import createApiClient from './createApiClient'
import {PharmacovigilanceEvent} from 'src/components/templates/PatientDetailsComponent/Pharmacovigilance/Pharmacovigilance'
import {AdherenceType} from 'src/pages/home/Home'
import {PaginationType} from 'src/components/elements/TableCustom/TableCustom'

const apiClient = _.once(createApiClient)

async function login ({identifier, password}: {identifier: string; password: string}, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: '/psp-backend/oauth/token',
    method: 'post',
    data: {username: identifier, password}
  },
  setStatus)
}

async function logout (setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/logout',
    method: 'post'
  }, setStatus)
}

async function changePassword ({
  identifier,
  email
}: {
  identifier: string;
  email: string;
}, setStatus: any) {
  return apiClientRequest(apiClient(), undefined, {
    url: '/psp-backend/change-password',
    method: 'post',
    data: {username: identifier, email}
  },
  setStatus)
}

async function getProfile () {
  return apiClientRequest(apiClient(), fetchUserInfo, {
    url: 'psp-backend/userinfo',
    method: 'get'
  })
}

async function loginConfiguration () {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/login-configuration',
    method: 'get'
  })
  return response
}

async function pspConfig () {
  await apiClientRequest(apiClient(), fetchPspConfig, {
    url: 'psp-backend/psp-config',
    method: 'get'
  })
}

async function postPatientsSearch (filters: any, setStatus: any, paginationFilters: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/patients/search/',
    method: 'post',
    data: {
      filters,
      pagination: {
        size: paginationFilters?.rowsPerPage,
        page: paginationFilters?.page,
        sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
      }
    }
  }, setStatus)

  return response
}

async function postPatientsSearchCount (filters: any, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/patients/count',
    method: 'post',
    data: {
      filters
    }
  }, setStatus)

  return response
}

async function postPatientEnrollment (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/patient-enrollment',
    method: 'post',
    data: body,
    headers: {'Content-Type': 'multipart/form-data'}
  }, setStatus)
}

async function getTestDbs (filters: any, setStatus: any, paginationFilters?: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/test-dbs/',
    method: 'get',
    params: {
      patientId: filters.find((filter: any) => filter.property === 'patient')?.value._id,
      status: filters.find((filter: any) => filter.property === 'status')?.value,
      size: paginationFilters?.rowsPerPage,
      page: paginationFilters?.page,
      sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
    }
  }, setStatus)

  return response
}

async function getTestDbsCount (filters: any, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/test-dbs/count',
    method: 'get',
    params: {
      patientId: filters.find((filter: any) => filter.property === 'patient')?.value._id,
      status: filters.find((filter: any) => filter.property === 'status')?.value
    }
  }, setStatus)

  return response
}

async function postTestDbs (patientId: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/test-dbs/${patientId}`,
    method: 'post'
  }, setStatus)
}

async function postAppointment (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/appointments/',
    method: 'post',
    data: body
  }, setStatus)
}

async function patchAppointment (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/appointments/${body.id}`,
    method: 'patch',
    data: body.event
  }, setStatus)
}

async function getDocuments (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    description: filters.find((filter: any) => filter.property === 'description')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    fileName: filters.find((filter: any) => filter.property === 'fileName')?.value,
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/documents',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getDocumentsCount (filters: any, setStatus: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    description: filters.find((filter: any) => filter.property === 'description')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    fileName: filters.find((filter: any) => filter.property === 'fileName')?.value,
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/documents/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function postDocuments (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/documents',
    method: 'post',
    data: body,
    headers: {'Content-Type': 'multipart/form-data'}
  }, setStatus)
}

async function deleteDocument (documentId: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/documents/${documentId}`,
    method: 'delete'
  }, setStatus)
}

async function download (fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/download/${fileId}`,
    method: 'get',
    responseType: 'arraybuffer'
  }, setStatus, true)
  return response
}

async function getMedicalCenters (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    region: filters.find((filter: any) => filter.property === 'region')?.value,
    province: filters.find((filter: any) => filter.property === 'province')?.value,
    doctor: filters.find((filter: any) => filter.property === 'doctor')?.value,
    city: filters.find((filter: any) => filter.property === 'city')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/medical-centers/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getMedicalCentersCount (filters: any, setStatus: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    region: filters.find((filter: any) => filter.property === 'region')?.value,
    province: filters.find((filter: any) => filter.property === 'province')?.value,
    doctor: filters.find((filter: any) => filter.property === 'doctor')?.value,
    city: filters.find((filter: any) => filter.property === 'city')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/medical-centers/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getMedicalCenterDetail (id: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/medical-centers/${id}`,
    method: 'get'
  }, setStatus)
  return response
}

async function getCaregivers (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    firstName: filters?.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters?.find((filter: any) => filter.property === 'lastName')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/caregivers/',
    method: 'get',
    params
  }, setStatus)

  return response
}

async function getCaregiversCount (filters: any, setStatus: any) {
  const params = {
    firstName: filters?.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters?.find((filter: any) => filter.property === 'lastName')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/caregivers/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientDiary (filters: Record<string, any>, setStatus: any) {
  const url = 'psp-backend/diary/'
  const params = {
    therapy: filters?.therapy,
    size: filters?.rowsPerPage,
    page: filters?.page
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientDiaryCount (filters: Record<string, any>, setStatus: any) {
  const url = 'psp-backend/diary/count'
  const params = {
    therapy: filters?.therapy
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientDiaryExport () {
  const url = 'psp-backend/diary/export/'

  const response = await apiClientRequest(apiClient(), undefined, {
    url,
    method: 'get'
  }, () => { })
  return response
}

async function getTherapies (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/therapies/',
    method: 'get'
  }, setStatus)
  return response
}

async function getExports (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    filename: filters.find((filter: any) => filter.property === 'filename')?.value,
    status: filters.find((filter: any) => filter.property === 'status')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/exports',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getExportCount (filters: Record<string, any>, setStatus: any) {
  const params = {
    filename: filters.find((filter: any) => filter.property === 'filename')?.value,
    status: filters.find((filter: any) => filter.property === 'status')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/exports/count',
    method: 'get',
    params
  }, setStatus)
  return response
}
async function patchDocuments (id: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/documents/${id}`,
    method: 'patch',
    data: body
  }, setStatus)
}

async function getInfoMaterials (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    description: filters.find((filter: any) => filter.property === 'description')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    fileName: filters.find((filter: any) => filter.property === 'fileName')?.value,
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    drugId: filters.find((filter: any) => filter.property === 'drugId')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/materials/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getInfoMaterialsCount (filters: any, setStatus: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    description: filters.find((filter: any) => filter.property === 'description')?.value,
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    fileName: filters.find((filter: any) => filter.property === 'fileName')?.value,
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    drugId: filters.find((filter: any) => filter.property === 'drugId')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/materials/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function postExport (params: Record<string, any>, setStatus: any) {
  const paramsToUpload = {
    filters: {
      dateFrom: params?.startDate,
      dateTo: params?.endDate,
      drugId: params?.drug,
      hcpId: params?.doctor,
      medicalCenterId: params?.medicalClinic,
      patientId: params?.patient?._id ?? params?.patientCode
    },
    exportType: params?.exportType
  }
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/exports',
    method: 'post',
    data: paramsToUpload
  }, setStatus)
  return response
}

async function downloadExport (id: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/exports/download/${id}`,
    method: 'get',
    responseType: 'blob'
  }, setStatus)
  return response
}
async function getLinks (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/links/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getLinksCount (filters: any, setStatus: any) {
  const params = {
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/links/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getVirtualMeetings (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    appointmentStatus: filters.find((filter: any) => filter.property === 'appointmentStatus')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    patientAuthUserId: filters.find((filter: any) => filter.property === 'patient')?.value.authUserId,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/virtual-meetings/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getNotifications (filters: Record<string, any>, setStatus: any) {
  const validFilters = pickBy(filters, filter => filter !== undefined && filter !== '')

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/notifications-messages/',
    method: 'get',
    params: validFilters
  }, setStatus)
  return response
}

async function getVirtualMeetingsCount (filters: any, setStatus: any) {
  const params = {
    appointmentStatus: filters.find((filter: any) => filter.property === 'appointmentStatus')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    patientAuthUserId: filters.find((filter: any) => filter.property === 'patient')?.value.authUserId
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/virtual-meetings/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function patchVirtualMeetings (id: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/virtual-meetings/${id}`,
    method: 'patch',
    data: body
  }, setStatus)
}

async function deleteVirtualMeetings (id: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/virtual-meetings/${id}`,
    method: 'delete'
  }, setStatus)
}

async function postVirtualMeetings (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/virtual-meetings',
    method: 'post',
    data: body
  }, setStatus)
}

async function postRequestVirtualMeetings (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/request-virtual-meetings',
    method: 'post',
    data: body
  }, setStatus)
}

async function getNotificationsCount (filters: Record<string, any>) {
  const validFilters = pickBy(filters, filter => filter !== undefined && filter !== '')

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/notifications-messages/count',
    method: 'get',
    params: validFilters
  })
  return response
}

async function patchNotificationMessage (notificationId: string, messageIndex: string, body: Record<string, any>) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/notifications/${notificationId}/messages/${messageIndex}`,
    method: 'patch',
    data: body
  })
  return response
}

async function getAvatars () {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/avatars/',
    method: 'get'
  })
  return response
}

async function getByIdAvatars (id: string) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/avatars/${id}`,
    method: 'get'
  })
  return response
}

async function getGraphs (filters: Record<string, any>, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/graphs/summaries/',
    method: 'get',
    params: filters
  }, setStatus)
  return response
}

async function getPrograms (setStatus: any = () => {}) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/programs',
    method: 'get'
  }, setStatus)
  return response
}

async function getClients (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters.find((filter: any) => filter.property === 'lastName')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/clients/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getClientsCount (filters: any, setStatus: any) {
  const params = {
    firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters.find((filter: any) => filter.property === 'lastName')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/clients/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function patchClients (id: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/clients/${id}`,
    method: 'patch',
    data: body
  }, setStatus)
}

async function getPatientsCaregivers (patientId: string, setStatus: any, paginationFilters?: any) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/caregivers/`,
    method: 'get',
    params
  }, setStatus)

  return response
}

async function getPatientsCaregiversCount (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/caregivers/count`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsDocuments (patientId: string, filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/documents/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsDocumentsCount (patientId: string, filters: any, setStatus: any) {
  const params = {
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/documents/count`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function deletePatientsDocument (patientId: string, documentId: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/documents/${documentId}`,
    method: 'delete'
  }, setStatus)
}

async function patientsDownload (patientId: string, fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/download/${fileId}`,
    method: 'get',
    responseType: 'arraybuffer'
  }, setStatus)
  return response
}

async function postPatientsDocuments (patientId: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/documents/`,
    method: 'post',
    data: body,
    headers: {'Content-Type': 'multipart/form-data'}
  }, setStatus)
}

async function patchPatientsDocuments (patientId: string, id: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/documents/${id}`,
    method: 'patch',
    data: body
  }, setStatus)
}

async function getPatientsActivities (filters: any, startDate: Date, endDate: Date, setStatus: any) {
  const params = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    beneficiaryFirstName: filters.find((filter: any) => filter.property === 'beneficiaryFirstName')?.value,
    beneficiaryLastName: filters.find((filter: any) => filter.property === 'beneficiaryLastName')?.value,
    drugId: filters.find((filter: any) => filter.property === 'drugId')?.value,
    activityType: filters.find((filter: any) => filter.property === 'activityType')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/patients-activities/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function activitiesDocumentDownload (activityId: string, fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/activities/${activityId}/download/${fileId}`,
    method: 'get',
    responseType: 'arraybuffer'
  }, setStatus)
  return response
}

async function patchActivitiesDocuments (activityId: string, id: string, body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/activities/${activityId}/documents/${id}`,
    method: 'patch',
    data: body
  }, setStatus)
}

async function getActivitiesDocuments (activityId: string, beneficiaryId: string, filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    beneficiaryId,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/activities/${activityId}/documents/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getActivitiesDocumentsCount (activityId: string, filters: any, setStatus: any) {
  const params = {
    category: filters.find((filter: any) => filter.property === 'category')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/activities/${activityId}/documents/count`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function deleteActivitiesDocument (activityId: string, documentId: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/activities/${activityId}/documents/${documentId}`,
    method: 'delete'
  }, setStatus)
}

async function getPatientsUserEvents (patientId: string, filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    startDate: filters.find((filter: any) => filter.property === 'date')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'date')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/user-events/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getUserEvents (startDate: any, endDate: any, setStatus: any) {
  const params = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/user-events/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getUserEventsTypes (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/personal-events-types/',
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsUserEventsCount (patientId: string, filters: any, setStatus: any) {
  const params = {
    type: filters.find((filter: any) => filter.property === 'type')?.value,
    startDate: filters.find((filter: any) => filter.property === 'date')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'date')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/user-events/count`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPersonalEvents (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/personal-events/',
    method: 'get'
  }, setStatus)

  return response
}

async function postContactRequest (body: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/contact-request/',
    method: 'post'
  }, setStatus)
}

async function getPatientsTherapies (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/therapies/`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsAssumptions (patientId: string, setStatus: any, paginationFilters?: any) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/assumptions/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsAssumptionsCount (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/assumptions/count`,
    method: 'get'
  }, setStatus)
  return response
}

async function getDoctorsSearch (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters.find((filter: any) => filter.property === 'lastName')?.value,
    state: filters.find((filter: any) => filter.property === 'state')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/doctors/search/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getActivities (filters: any, setStatus: any, paginationFilters?: any) {
  const params = {
    code: filters.find((filter: any) => filter.property === 'code')?.value,
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    // activityType: filters.find((filter: any) => filter.property === 'activityType')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    state: filters.find((filter: any) => filter.property === 'state')?.value,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined

  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/activities/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getActivitiesCount (filters: any, setStatus: any) {
  const params = {
    code: filters.find((filter: any) => filter.property === 'code')?.value,
    name: filters.find((filter: any) => filter.property === 'name')?.value,
    // activityType: filters.find((filter: any) => filter.property === 'activityType')?.value,
    startDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'rangeDates')?.value[1],
    state: filters.find((filter: any) => filter.property === 'state')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/activities/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getDoctorsCount (filters: any, setStatus: any) {
  const params = {
    firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
    lastName: filters.find((filter: any) => filter.property === 'lastName')?.value,
    state: filters.find((filter: any) => filter.property === 'state')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/doctors/search/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getSurveys (setStatus: any, paginationFilters?: any) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: paginationFilters?.sort
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/surveys/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getSurveysCount (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/surveys/count',
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsSurveys (patientId: string, setStatus: any, paginationFilters?: any) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: paginationFilters?.sort
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/surveys/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsSurveysCount (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/surveys/count`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsPharmacies (patientId: string, setStatus: any, paginationFilters?: any) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/pharmacies/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsPharmaciesCount (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/pharmacies/count`,
    method: 'get'
  }, setStatus)
  return response
}

async function patchUserDataHttp (body: any) {
  return apiClientRequest(apiClient(), patchUserData, {
    url: 'psp-backend/user-data',
    method: 'patch',
    data: body
  })
}

async function getProgramImage (fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/program-image/${fileId}`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPharmacovigilanceEvents (patientId: string, filters: any, setStatus: any, paginationFilters?: any): Promise<PharmacovigilanceEvent[]> {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined,

    eventStartDate: filters.find((filter: any) => filter.property === 'eventDate')?.value[0],
    eventEndDate: filters.find((filter: any) => filter.property === 'eventDate')?.value[1],
    eventType: filters.find((filter: any) => filter.property === 'eventType')?.value,
    signallingStartDate: filters.find((filter: any) => filter.property === 'signallingDate')?.value[0],
    signallingEndDate: filters.find((filter: any) => filter.property === 'signallingDate')?.value[1],
    state: filters.find((filter: any) => filter.property === 'state')?.value,
    notificationStartDate: filters.find((filter: any) => filter.property === 'notificationDate')?.value[0],
    notificationEndDate: filters.find((filter: any) => filter.property === 'notificationDate')?.value[1],
    code: filters.find((filter: any) => filter.property === 'code')?.value,
    reportType: filters.find((filter: any) => filter.property === 'reportType')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/pharmacovigilance/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPharmacovigilanceEventsCount (patientId: string, filters: any, setStatus: any) {
  const params = {
    eventStartDate: filters.find((filter: any) => filter.property === 'eventDate')?.value[0],
    eventEndDate: filters.find((filter: any) => filter.property === 'eventDate')?.value[1],
    eventType: filters.find((filter: any) => filter.property === 'eventType')?.value,
    signallingStartDate: filters.find((filter: any) => filter.property === 'signallingDate')?.value[0],
    signallingEndDate: filters.find((filter: any) => filter.property === 'signallingDate')?.value[1],
    state: filters.find((filter: any) => filter.property === 'state')?.value,
    notificationStartDate: filters.find((filter: any) => filter.property === 'notificationDate')?.value[0],
    notificationEndDate: filters.find((filter: any) => filter.property === 'notificationDate')?.value[1],
    code: filters.find((filter: any) => filter.property === 'code')?.value,
    reportType: filters.find((filter: any) => filter.property === 'reportType')?.value
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/pharmacovigilance/count`,
    method: 'get',
    params
  })
  return response
}

async function getPatientsUserData (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/user-data/`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientAdherence (patientId: string, setStatus: any): Promise<AdherenceType> {
  const params = {
    patientId
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/adherence/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getSummaryAdherence (filters: Record<string, any>): Promise<AdherenceType> {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/summary/adherence/',
    method: 'get',
    params: filters
  })
  return response
}

async function getDiaryMorisky (filters: Record<string, any>, setStatus: any) {
  const params = {
    therapy: filters?.therapy,
    size: filters?.rowsPerPage,
    page: filters?.page
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/diary-morisky/',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getDiaryMoriskyCount (filters: Record<string, any>, setStatus: any) {
  const params = {
    therapy: filters?.therapy
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/diary-morisky/count',
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientDiaryMorisky (patientId: string, filters: Record<string, any>, setStatus: any) {
  const params = {
    therapy: filters?.therapy,
    size: filters?.rowsPerPage,
    page: filters?.page
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/diary-morisky/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientDiaryMoriskyCount (patientId: string, filters: Record<string, any>, setStatus: any) {
  const params = {
    therapy: filters?.therapy
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/diary-morisky/count`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function generatePdf (url: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/generate-pdf/',
    method: 'post',
    responseType: 'arraybuffer',
    data: {url}
  }, setStatus)
  return response
}

async function getSurveyGraphData (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/survey-graphs/',
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientSurveyGraphData (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/survey-graphs/`,
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientWeights (patientId: string, filters: Record<string, any>, paginationFilters: Record<string, any>, setStatus: any) {
  const params = {
    startDate: filters.find((filter: any) => filter.property === 'date')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'date')?.value[1],
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/weights/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientWeightsCount (patientId: string, filters: Record<string, any>, setStatus: any) {
  const params = {
    startDate: filters.find((filter: any) => filter.property === 'date')?.value[0],
    endDate: filters.find((filter: any) => filter.property === 'date')?.value[1]
  }

  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/weights/count`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getFeedbackConfig (type: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/feedbacks-config/',
    method: 'get',
    params: {type}
  }, setStatus)
  return response
}

async function getPatientsFeedbacksValues (type: string, patientId: string, setStatus: any, paginationFilters: Record<string, any>) {
  const params = {
    type,
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/feedbacks-values/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsFeedbacksValuesCount (type: string, patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/feedbacks-values/count`,
    method: 'get',
    params: {type}
  }, setStatus)
  return response
}

async function getDigitalEnrollment (filters: any, setStatus: any, paginationFilters?: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/digital-enrollment/',
    method: 'get',
    params: {
      firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
      lastName: filters.find((filter: any) => filter.property === 'lastName')?.value,
      therapyTypeId: filters.find((filter: any) => filter.property === 'therapyTypeId')?.value,
      state: filters.find((filter: any) => filter.property === 'state')?.value,
      size: paginationFilters?.rowsPerPage,
      page: paginationFilters?.page,
      sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
    }
  }, setStatus)

  return response
}

async function getDigitalEnrollmentCount (filters: any, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/digital-enrollment/count',
    method: 'get',
    params: {
      firstName: filters.find((filter: any) => filter.property === 'firstName')?.value,
      lastName: filters.find((filter: any) => filter.property === 'lastName')?.value,
      therapyTypeId: filters.find((filter: any) => filter.property === 'therapyTypeId')?.value,
      state: filters.find((filter: any) => filter.property === 'state')?.value
    }
  }, setStatus)

  return response
}

async function getByIdDigitalEnrollment (requestId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/digital-enrollment/${requestId}`,
    method: 'get'
  }, setStatus)

  return response
}

async function postDigitalEnrollment (values: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/digital-enrollment/',
    method: 'post',
    data: values
  }, setStatus)
}

async function patchDigitalEnrollment (values: any, id: string, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/digital-enrollment/${id}`,
    method: 'patch',
    data: values
  }, setStatus)
}

async function postSelfRegistration (values: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/self-registration',
    method: 'post',
    data: values
  }, setStatus)
}

async function getTherapiesTypes (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/therapy-types/',
    method: 'get'
  }, setStatus)
  return response
}

async function getPackages (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/packages/',
    method: 'get'
  }, setStatus)
  return response
}

async function getRegions (search: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/regions/',
    params: {
      search
    },
    method: 'get'
  }, setStatus)

  return response
}

async function getProvinces (query: {search?: string, region?: string}, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/provinces/',
    params: {
      region: query.region,
      search: query.search
    },
    method: 'get'
  }, setStatus)

  return response
}

async function getCities (query: {search?: string, province?: string}, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/cities/',
    params: {
      province: query.province,
      search: query.search
    },
    method: 'get'
  }, setStatus)

  return response
}

async function sendConfirmEmail (requestId: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/send-confirm-email/',
    method: 'post',
    data: {requestId}
  }, setStatus)
}

async function sendConfirmSelfRegistration (email: any, setStatus: any) {
  await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/send-confirm-self-registration',
    method: 'post',
    data: {email}
  }, setStatus)
}

async function downloadPharmacovigilance (fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/pharmacovigilance/${fileId}`,
    method: 'get',
    responseType: 'arraybuffer'
  }, setStatus, true)
  return response
}

async function checkUsername (username: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/check-username/',
    method: 'post',
    data: {username}
  }, setStatus)

  return response
}

async function checkEmail (email: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/check-email/',
    method: 'post',
    data: {email}
  }, setStatus)

  return response
}

async function getDrugs (search: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/drugs/',
    params: {
      search
    },
    method: 'get'
  }, setStatus)

  return response
}

async function downloadPatientsDossierExport (patientId: string, fileId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/dossiers/${fileId}/download`,
    method: 'get',
    responseType: 'arraybuffer'
  }, setStatus, true)
  return response
}

async function getPatientsDossiersExports (patientId: string, setStatus: any, paginationFilters: Record<string, any>) {
  const params = {
    size: paginationFilters?.rowsPerPage,
    page: paginationFilters?.page,
    sort: (paginationFilters?.order && paginationFilters.order.length > 0) ? paginationFilters.order : undefined
  }
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/dossiers/exports/`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getPatientsDossiersExportsCount (patientId: string, setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/dossiers/exports/count`,
    method: 'get'
  }, setStatus)
  return response
}

async function getProgram () {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/program',
    method: 'get'
  })
  return response
}

async function getPatientsFeedbacksChartValues (type: string, patientId: string, setStatus: any) {
  const params = {type}
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/feedbacks-chart-values`,
    method: 'get',
    params
  }, setStatus)
  return response
}

async function getQualifications (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/qualifications/',
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientsData (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/patients-data/',
    method: 'get'
  }, setStatus)
  return response
}

async function getUserPerformances (setStatus: any, paginationFilters: PaginationType) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/performances/',
    params: {
      size: paginationFilters?.rowsPerPage,
      page: paginationFilters?.page
    },
    method: 'get'
  }, setStatus)
  return response
}

async function getUserPerformancesCount (setStatus: any) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: 'psp-backend/performances/count',
    method: 'get'
  }, setStatus)
  return response
}

async function getPatientProducts (patientId: string) {
  const response = await apiClientRequest(apiClient(), undefined, {
    url: `psp-backend/patients/${patientId}/products`,
    method: 'get'
  })
  return response
}

export default {
  login,
  logout,
  changePassword,
  getProfile,
  loginConfiguration,
  pspConfig,
  postPatientEnrollment,
  postPatientsSearch,
  getTestDbs,
  postTestDbs,
  postAppointment,
  patchAppointment,
  getTestDbsCount,
  getDocuments,
  postDocuments,
  getDocumentsCount,
  deleteDocument,
  download,
  getMedicalCenters,
  getMedicalCentersCount,
  getCaregivers,
  getCaregiversCount,
  getPatientDiary,
  getPatientDiaryCount,
  getPatientDiaryExport,
  getTherapies,
  getExportCount,
  getExports,
  postExport,
  downloadExport,
  getNotifications,
  getNotificationsCount,
  patchDocuments,
  getInfoMaterials,
  getInfoMaterialsCount,
  getLinks,
  getLinksCount,
  getGraphs,
  getVirtualMeetings,
  getVirtualMeetingsCount,
  patchVirtualMeetings,
  deleteVirtualMeetings,
  postVirtualMeetings,
  postRequestVirtualMeetings,
  postPatientsSearchCount,
  getPrograms,
  getClients,
  getClientsCount,
  patchClients,
  getAvatars,
  getByIdAvatars,
  getMedicalCenterDetail,
  getPatientsCaregivers,
  getPatientsCaregiversCount,
  getPatientsDocuments,
  getPatientsDocumentsCount,
  patientsDownload,
  deletePatientsDocument,
  postPatientsDocuments,
  patchPatientsDocuments,
  getPatientsActivities,
  getUserEvents,
  getUserEventsTypes,
  getPatientsUserEvents,
  getPatientsUserEventsCount,
  getPersonalEvents,
  postContactRequest,
  getPatientsTherapies,
  getPatientsAssumptions,
  getPatientsAssumptionsCount,
  getSurveys,
  getSurveysCount,
  getPatientsSurveys,
  getPatientsSurveysCount,
  getDoctorsSearch,
  getDoctorsCount,
  getActivities,
  getActivitiesCount,
  getPatientsPharmacies,
  getPatientsPharmaciesCount,
  patchNotificationMessage,
  patchUserDataHttp,
  getProgramImage,
  getPatientAdherence,
  getPharmacovigilanceEvents,
  getPharmacovigilanceEventsCount,
  getPatientsUserData,
  getSummaryAdherence,
  getDiaryMorisky,
  getDiaryMoriskyCount,
  getPatientDiaryMorisky,
  getPatientDiaryMoriskyCount,
  generatePdf,
  getSurveyGraphData,
  getPatientSurveyGraphData,
  getPatientWeights,
  getPatientWeightsCount,
  getActivitiesDocuments,
  getActivitiesDocumentsCount,
  patchActivitiesDocuments,
  activitiesDocumentDownload,
  deleteActivitiesDocument,
  getFeedbackConfig,
  getPatientsFeedbacksValues,
  getPatientsFeedbacksValuesCount,
  getDigitalEnrollment,
  getDigitalEnrollmentCount,
  getByIdDigitalEnrollment,
  postDigitalEnrollment,
  patchDigitalEnrollment,
  postSelfRegistration,
  getTherapiesTypes,
  getPackages,
  getRegions,
  getProvinces,
  getCities,
  sendConfirmEmail,
  downloadPharmacovigilance,
  checkUsername,
  checkEmail,
  getDrugs,
  downloadPatientsDossierExport,
  getPatientsDossiersExports,
  getPatientsDossiersExportsCount,
  getProgram,
  getPatientsFeedbacksChartValues,
  getQualifications,
  getPatientsData,
  getUserPerformances,
  getUserPerformancesCount,
  getPatientProducts,
  sendConfirmSelfRegistration
}
