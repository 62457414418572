import React, {useEffect} from 'react'
import {Pharmacy} from './Pharmacies'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {Checkbox, DatePicker, Form, Input, Select} from 'antd'
import moment from 'moment'
import {StyledPharmacyDetailModal} from './StyledPharmacyDetailModal'
import {Typography} from '@mui/material'
import locale from 'antd/es/date-picker/locale/it_IT'

interface PharmacyDetailModalProps {
  open: boolean,
  onClose?: () => void,
  pharmacy?: Pharmacy
}

export default function PharmacyDetailModal ({
  open,
  pharmacy,
  onClose
}: PharmacyDetailModalProps) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldValue('code', pharmacy?.code)
    form.setFieldValue('type', pharmacy?.type)
    form.setFieldValue('createdAt', pharmacy?.createdAt ? moment(pharmacy.createdAt) : undefined)
    form.setFieldValue('openingTimes', pharmacy?.openingTimes)
    form.setFieldValue('name', pharmacy?.name)
    form.setFieldValue('phoneNumber', pharmacy?.phoneNumber)
    form.setFieldValue('fax', pharmacy?.fax)
    form.setFieldValue('email', pharmacy?.email)
    form.setFieldValue('country', pharmacy?.address?.country)
    form.setFieldValue('region', pharmacy?.address?.region)
    form.setFieldValue('province', pharmacy?.address?.province)
    form.setFieldValue('city', pharmacy?.address?.city)
    form.setFieldValue('address', pharmacy?.address?.address)
    form.setFieldValue('streetNumber', pharmacy?.address?.streetNumber)
    form.setFieldValue('postalCode', pharmacy?.address?.postalCode)
    form.setFieldValue('locality', pharmacy?.address?.locality)
  }, [form, pharmacy])

  const body = (
    <StyledPharmacyDetailModal key='anagrafica'>
      <CardCustom title='Dati principali'>
        <Form
          form={form}
          id='main-data-form'
          layout='vertical'
        >
          <Form.Item
            className='code'
            label='Codice'
            name='code'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='type'
            label='Tipo'
            name='type'
          >
            <Checkbox.Group disabled>
              <Checkbox value='HOSPITAL'>
                <Typography variant='body2'>{'Ospedaliera'}</Typography>
              </Checkbox>
              <Checkbox value='EXTERNAL'>
                <Typography variant='body2'>{'Esterna'}</Typography>
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            className='createdAt'
            label='Data inserimento'
            name='createdAt'
          >
            <DatePicker
              disabled
              format='ddd DD MMM YYYY HH:MM'
              getPopupContainer={(trigger) => trigger}
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          <Form.Item
            className='openingTimes'
            label='Orari farmacia'
            name='openingTimes'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='name'
            label='Nome'
            name='name'
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </CardCustom>
      <CardCustom title='Dati di contatto'>
        <Form
          form={form}
          id='contact-data-form'
          layout='vertical'
        >
          <Form.Item
            className='phoneNumber'
            label='Telefono'
            name='phoneNumber'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='fax'
            label='Fax'
            name='fax'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='email'
            label='Email'
            name='email'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='state'
            label='Stato'
            name='country'
          >
            <Select
              disabled
              getPopupContainer={(trigger) => trigger}
            />
          </Form.Item>
          <Form.Item
            className='region'
            label='Regione'
            name='region'
          >
            <Select
              disabled
              getPopupContainer={(trigger) => trigger}
            />
          </Form.Item>
          <Form.Item
            className='province'
            label='Provincia'
            name='province'
          >
            <Select
              disabled
              getPopupContainer={(trigger) => trigger}
            />
          </Form.Item>
          <Form.Item
            className='city'
            label='Città'
            name='city'
          >
            <Select
              disabled
              getPopupContainer={(trigger) => trigger}
            />
          </Form.Item>
          <Form.Item
            className='address'
            label='Indirizzo'
            name='address'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='civicNumber'
            label='Civico'
            name='streetNumber'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='CAP'
            label='Codice Postale'
            name='postalCode'
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            className='locality'
            label='Località'
            name='locality'
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </CardCustom>
    </StyledPharmacyDetailModal>
  )

  return (
    <DialogCustom
      body={body}
      maxWidth='lg'
      onClose={onClose}
      open={open}
      title={{text: pharmacy?.name ?? '', format: false}}
    />
  )
}
