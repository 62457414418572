import {styled} from '@mui/system'

export const CalendarToolbarStyled = styled('div')(({theme}) => ({
  '.view-div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingBottom: theme.spacing(8),

    '.today-button': {
      margin: theme.spacing(4)
    }
  },

  '.navigation-div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    color: 'white',
    background: theme.palette.primary.main,
    height: theme.spacing(25),
    fontSize: theme.spacing(10),
    fontWeight: 600,
    padding: `0 ${theme.spacing(8)}`,

    '.chevron-icon': {
      cursor: 'pointer'
    }

  }

}))
