import React from 'react'
import {HeaderSummaryCardStyle} from './HeaderSummaryCardStyle'
import {Button} from '@mui/material'

export type HeaderSummaryCardProps = {
  title: string,
  onSeeOthers: () => void,
}

const HeaderSummaryCard: React.FC<HeaderSummaryCardProps> = ({title, onSeeOthers}) => {
  return (
    <HeaderSummaryCardStyle>
      <div style={{fontSize: '24px', fontWeight: 600}}>{title}</div>
      <Button
        onClick={() => onSeeOthers()}
        style={{fontSize: '18px'}}
        variant='text'
      >
        {'VEDI TUTTI'}
      </Button>
    </HeaderSummaryCardStyle>

  )
}

export default HeaderSummaryCard
