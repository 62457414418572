import {FormattedMessage} from 'react-intl'
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'
import {View} from 'react-big-calendar'
import moment from 'moment'
import {CalendarToolbarStyled} from './CalendarToolbarStyled'
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js'
import Icon from '@mdi/react'
import {Typography} from '@mui/material'

type CalendarToolbarProps = {
  date: Date;
  view: View;
  handleDateChange: (date: Date) => void;
  handleViewChange: (view: View) => void
}

const CalendarToolbar: React.FC<CalendarToolbarProps> = ({
  date,
  view,
  handleDateChange,
  handleViewChange
}) => {
  const addMonths = (currentDate: Date, months : number) => {
    const d = currentDate.getDate()
    currentDate.setMonth(currentDate.getMonth() + months)
    if (currentDate.getDate() !== d) {
      currentDate.setDate(0)
    }
    return currentDate
  }

  const addWeeks = (currentDate: Date, weeks : number) => {
    currentDate.setDate(currentDate.getDate() + 7 * weeks)
    return currentDate
  }

  const addDays = (currentDate: Date, days : number) => {
    currentDate.setDate(currentDate.getDate() + days)
    return currentDate
  }

  const goToDayView = () => {
    handleViewChange('day')
  }
  const goToWeekView = () => {
    handleViewChange('week')
  }
  const goToMonthView = () => {
    handleViewChange('month')
  }
  const goToAgendaView = () => {
    handleDateChange(moment(date).startOf('month').toDate())
    handleViewChange('agenda')
  }

  const goToToday = () => {
    if (view === 'agenda') {
      handleDateChange(moment(new Date()).startOf('month').toDate())
    } else {
      handleDateChange(new Date())
    }
  }

  const goToBack = () => {
    if (view === 'week') {
      handleDateChange(addWeeks(date, -1))
    } else if (view === 'day') {
      handleDateChange(addDays(date, -1))
    } else {
      handleDateChange(addMonths(date, -1))
    }
  }

  const goToNext = () => {
    if (view === 'week') {
      handleDateChange(addWeeks(date, +1))
    } else if (view === 'day') {
      handleDateChange(addDays(date, +1))
    } else {
      handleDateChange(addMonths(date, +1))
    }
  }

  const dateFormat = (currentDate: Date) => {
    if (view === 'month' || view === 'agenda') {
      return moment(currentDate).format('MMMM YYYY')
    } else if (view === 'week') {
      return `${moment(currentDate).startOf('week').format('DD')} - ${moment(currentDate).endOf('week').format('DD MMM YYYY')}`
    } else {
      return moment(currentDate).format('DD MMMM YYYY')
    }
  }
  return (
    <CalendarToolbarStyled>
      <div className='view-div'>
        <PrimaryButton className='today-button' onClick={goToToday}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='today' />
          </Typography>
        </PrimaryButton>
        <SecondaryButton onClick={goToDayView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='dayView' />
          </Typography>
        </SecondaryButton>
        <SecondaryButton onClick={goToWeekView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='weekView' />
          </Typography>
        </SecondaryButton>
        <SecondaryButton onClick={goToMonthView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='monthView' />
          </Typography>
        </SecondaryButton>
        <SecondaryButton onClick={goToAgendaView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='agendaView' />
          </Typography>
        </SecondaryButton>
      </div>
      <div className='navigation-div'>
        <div className='chevron-icon' onClick={goToBack}>
          <Icon path={mdiChevronLeft} size={1} />
        </div>
        <span>{dateFormat(date)}</span>
        <div className='chevron-icon' onClick={goToNext}>
          <Icon path={mdiChevronRight} size={1} />
        </div>
      </div>
    </CalendarToolbarStyled>
  )
}

export default CalendarToolbar
