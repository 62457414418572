import {styled} from '@mui/system'

export const FiltersStyled = styled('div')(({theme}) => ({

  '.filter-form': {
    display: 'grid',
    columnGap: '10px',

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-overflow': {
          height: theme.spacing(15),
          flexWrap: 'nowrap',
          marginTop: '-5px',
          whiteSpace: 'nowrap',
          borderRadius: '32px',
          overflow: 'hidden',
          '.ant-tag': {
            borderRadius: '16px'
          },
          '.ant-select-selection-item': {
            borderRadius: '16px',
            paddingTop: '2px'
          },
          '.ant-select-selection-item-content': {
            marginRight: 0,
            fontSize: '12px'
          }
        }
      }
    }
  },

  '.user-content': {
    display: 'flex',

    '.user-buttons': {
      display: 'flex',

      '.user-button': {
        color: theme.palette.neutral.main,
        borderRadius: theme.spacing(21),
        minWidth: theme.spacing(17),
        height: theme.spacing(21),
        '&:hover': {
          backgroundColor: theme.palette.neutral[200]
        }
      }
    }
  },

  '.footer-div': {
    display: 'flex',
    justifyContent: 'flex-end',

    button: {
      margin: theme.spacing(2)
    }
  }
}))
