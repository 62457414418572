import {Snackbar, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Chart from 'src/components/elements/Chart/Chart'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import SurveyDialog from 'src/components/templates/SurveyDialog/SurveyDialog'
import {STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'

export interface Survey {
  _id: string,
  name: string,
  state: string,
  compiledBy: string,
  confirmedBy: string,
  creationDate: string,
  submissionDate: string
}

const COLUMNS = [
  {
    id: 'name',
    label: 'NOME'
  },
  {
    id: 'translatedState',
    label: 'STATO'
  },
  {
    id: 'createdAt',
    label: 'DATA CREAZIONE',
    type: 'date',
    format: 'ddd DD MMM YYYY'
  },
  {
    id: 'requestDate',
    label: 'DATA SOTTOMISSIONE',
    type: 'date',
    format: 'ddd DD MMM YYYY'
  }
]

const STATE_TYPES: Record<string, string> = {
  CONFIRMED: 'Confermato',
  COMPILED: 'Compilato',
  TO_COMPILE: 'Da compilare'
}

const Surveys: React.FC<{patientId: string}> = ({patientId}) => {
  const {graphs} = useAppSelector(state => state.pspConfigReducer.data.patientDetailConfiguration.surveysConfiguration ?? {})
  const [selectedSurvey, setSelectedSurvey] = useState<Survey | undefined>()
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [rows, setRows] = useState<{data: Record<string, any>[], count: number}>({data: [], count: 0})
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [graphData, setGraphData] = useState<any>({
    moriskyScores: [],
    compilationDiaryScores: [],
    satisfactionScores: []
  })

  useEffect(() => {
    if (graphs) {
      fetchGraphsData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const fetchGraphsData = async () => {
    const fetchedData = await apiClient.getPatientSurveyGraphData(patientId, setStatus) ?? []
    setGraphData(fetchedData)
  }

  const fetchData = async () => {
    Promise.all([apiClient.getPatientsSurveys(patientId, setStatus, pagination), apiClient.getPatientsSurveysCount(patientId, setStatus)])
      .then(([surveys, count]) => {
        setRows({
          data: surveys.map((el: any) => ({...el, translatedState: STATE_TYPES[el.state] ?? el.state})),
          count
        })
      })
  }

  return (
    <>
      {graphs &&
        <div>
          <Chart
            colors={['#4472C4']}
            data={graphData.moriskyScores}
            title='Questionario Morisky'
            type='morisky'
          />
          <Chart
            colors={['#4472C4']}
            data={graphData.satisfactionScores}
            title='Questionario di rilevazione soddisfazione per il programma'
            type='morisky'
          />
          <Chart
            colors={['#4472C4']}
            data={graphData.compilationDiaryScores}
            title='Questionario monitoraggio diario'
            type='morisky'
          />
        </div>}
      <TableCustom
        columns={COLUMNS}
        onPaginationChange={setPagination}
        onRowClick={(row: any) => setSelectedSurvey(row)}
        pagination={pagination}
        rows={rows?.data}
        totalElements={rows?.count}
      />
      {selectedSurvey && (selectedSurvey.state === 'COMPILED' || selectedSurvey.state === 'CONFIRMED') &&
        <SurveyDialog
          onClose={() => setSelectedSurvey(undefined)}
          surveyData={selectedSurvey}
        />}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i questionari'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </>
  )
}

export default Surveys
