import {useEffect, useState} from 'react'
import {mdiDownloadCircleOutline} from '@mdi/js'
import {Snackbar, Typography} from '@mui/material'
import Icon from '@mdi/react'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {PATHS, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import {Select} from 'antd'
import {TherapyDiaryStyled} from './TherapyDiaryStyled'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'

const assumptionsStateOptions = [
  {
    value: 'COMPLETED',
    label: 'Effettuata'
  },
  {
    value: 'PLANNED',
    label: 'Pianificata'
  },
  {
    value: 'TO_BE_PLANNED',
    label: 'In pianificazione'
  },
  {
    value: 'CANCELLED',
    label: 'Non effettuata'
  }
]

const TherapyDiary = () => {
  const {columns = [], tableTitle = '', showExport, showTherapiesFilter, diaryType} = useAppSelector(state => state.pspConfigReducer.data.therapyDiaryConfigurations ?? {})
  const {serviceConf, firstName: userFirstName, lastName: userLastName} = useAppSelector(state => state.userReducer.data)

  const [data, setData] = useState<Record<string, unknown>[]>([])
  const [selectedTherapy, setSelectedTherapy] = useState<string>()
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [therapyOptions, setTherapyOptions] = useState<Record<string, unknown>[]>([])
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const fetchData = async () => {
    if (diaryType === 'DIARY_MORISKY') {
      const fetchedData = await apiClient.getDiaryMorisky({...pagination, therapy: selectedTherapy}, setStatus) ?? []
      setData(fetchedData)
      const fetchedDataCount = await apiClient.getDiaryMoriskyCount({therapy: selectedTherapy}, setStatusCount) ?? []
      setCount(fetchedDataCount)
    } else {
      const fetchedData = await apiClient.getPatientDiary({...pagination, therapy: selectedTherapy}, setStatus) ?? []
      const parsedData = fetchedData.map((assumption: any) => {
        return {
          ...assumption,
          state: assumptionsStateOptions.find((option) => option.value === assumption.state)?.label
        }
      })
      setData(parsedData)
      const fetchedDataCount = await apiClient.getPatientDiaryCount({therapy: selectedTherapy}, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }
  }

  const fetchTherapies = async () => {
    const fetchedTherapies = await apiClient.getTherapies(setStatus) ?? []
    setTherapyOptions(fetchedTherapies.map((therapy: any) => ({label: therapy.planName, value: therapy._id})))
    if (fetchedTherapies.length) {
      setSelectedTherapy(fetchedTherapies[0]._id)
    }
  }

  useEffect(() => {
    fetchTherapies()
  }, [])

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTherapy, pagination])

  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [statusDownload, setStatusDownload] = useState<string>()

  const handleModalView = async (row: any) => {
    setOpenViewModal(true)
    const fileResponse = await apiClient.generatePdf(`${new URL(window.location.href).origin}/${PATHS.PDF_DIARY}?filters=${JSON.stringify({})}`, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: 'application/pdf'})
    setFile((window.URL || window.webkitURL).createObjectURL(blob))
    setFileName(`Diario - ${userFirstName} ${userLastName}`)
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])

  return (

    <LayoutContainer breadcrumbs={[{label: 'Diario paziente', href: PATHS.AGENDA}]}>
      <TherapyDiaryStyled>
        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '16px', padding: '14px'}}>
          {
            showTherapiesFilter && (
              <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                <Typography>{'Terapia:'}</Typography>
                <Select
                  disabled
                  getPopupContainer={(trigger) => trigger}
                  options={[
                    {
                      label: data[0]?.drug,
                      value: data[0]?.drug
                    }
                  ]}
                  value={data[0]?.drug}
                />
              </div>
            )
          }
          <FileViewerModal
            MIMETypeValue={'application/pdf'}
            fileName={fileName}
            filepath={file}
            handleClose={() => { setOpenViewModal(false); setFile(undefined); setFileName(undefined) }}
            open={openViewModal}
            status={statusDownload}
          />
          {showExport && (
            <PrimaryButton
              onClick={handleModalView}
              startIcon={<Icon path={mdiDownloadCircleOutline} size={1} />}
            >{'Esporta'}
            </PrimaryButton>
          )}
        </div>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
          open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom
              onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
              severity='error' sx={{width: '100%'}}
            >
              <Typography variant='h5'>
                {'Errore nel recuperare il diario paziente.'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
        <div style={{padding: '25px 25px 40px', border: '1px solid #EEE'}}>
          <Typography variant='h4'>{tableTitle}</Typography>
        </div>
        <TableCustom
          columns={columns.filter((col: TableColumn) => (col.id !== 'adherence' || serviceConf === 'EXTRA'))}
          onPaginationChange={setPagination}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </TherapyDiaryStyled>
    </LayoutContainer>
  )
}

export default TherapyDiary
