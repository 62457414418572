import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CustomerUserDialogStyled} from './CustomerUserDialogStyled'
import {FormattedMessage} from 'react-intl'
import {Divider, Typography} from '@mui/material'
import {Form, Input, Radio, Select} from 'antd'
import {useEffect} from 'react'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {ITALIAN_REGIONS} from 'src/constants/mock/county'
import {ITALIAN_PROVINCE} from 'src/constants/mock/province'
import {ITALIAN_CITY} from 'src/constants/mock/city'

const radioOptions = [
  {label: 'Sì', value: 'true'},
  {label: 'No', value: 'false'}
]

type CustomerUserDialogProps = {
  user: Record<string, any>,
  readOnly: boolean,
  onClose: () => void;
  onSave: (user: Record<string, any>) => void;
};

const CustomerUserDialog: React.FC<CustomerUserDialogProps> = ({user, readOnly, onSave, onClose}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldValue('title', user?.title)
    form.setFieldValue('lastName', user?.lastName)
    form.setFieldValue('firstName', user?.firstName)
    form.setFieldValue('consuntiveCode', user?.consuntiveCode)
    form.setFieldValue('sex', user?.sex)
    form.setFieldValue('nationality', user?.nationality)
    form.setFieldValue('phoneNumber', user?.phoneNumber)
    form.setFieldValue('cellPhoneNumber', user?.cellPhoneNumber)
    form.setFieldValue('faxNumber', user?.faxNumber)
    form.setFieldValue('email', user?.email)
    form.setFieldValue('skypeId', user?.skypeId)
    form.setFieldValue('country', user?.address?.country)
    form.setFieldValue('region', user?.address?.region)
    form.setFieldValue('province', user?.address?.province)
    form.setFieldValue('city', user?.address?.city)
    form.setFieldValue('address', user?.address?.address)
    form.setFieldValue('streetNumber', user?.address?.streetNumber)
    form.setFieldValue('postalCode', user?.address?.postalCode)
    form.setFieldValue('locality', user?.address?.locality)
    form.setFieldValue('EMAIL', user.preferredContacts ? user?.preferredContacts?.includes('EMAIL') : false)
    form.setFieldValue('SMS', user.preferredContacts ? user?.preferredContacts?.includes('SMS') : false)
    form.setFieldValue('PUSH', user.preferredContacts ? user?.preferredContacts?.includes('PUSH') : false)
  }, [form, user])

  const handleGetRegion = (value: string) => {
    form.setFieldValue('country', value)
    form.setFieldValue('region', undefined)
    form.setFieldValue('province', undefined)
    form.setFieldValue('city', undefined)
  }

  const handleGetProvince = (value: string) => {
    form.setFieldValue('region', value)
    form.setFieldValue('province', undefined)
    form.setFieldValue('city', undefined)
  }

  const handleGetCity = (value: string) => {
    form.setFieldValue('province', value)
    form.setFieldValue('city', undefined)
  }

  const body = (
    <CustomerUserDialogStyled>
      <Form
        form={form}
        id='user-form'
        layout='vertical'
      >
        <div className='container'>
          <div className='container-title'>
            <Typography variant='h5'>
              <FormattedMessage id='mainData' />
            </Typography>
          </div>
          <Divider />
          <div className='container-body'>
            <div className='container-body-row'>
              <div className='container-body-element'>
                <Form.Item
                  label='Titolo'
                  name='title'
                >
                  <Select
                    allowClear
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        label: 'Sig.',
                        value: 'Sig.'
                      },
                      {
                        label: 'Sig.na',
                        value: 'Sig.na'
                      },
                      {
                        label: 'Sig.ra',
                        value: 'Sig.ra'
                      },
                      {
                        label: 'Dott.',
                        value: 'Dott.'
                      },
                      {
                        label: 'Dott.ssa',
                        value: 'Dott.ssa'
                      },
                      {
                        label: 'Prof.',
                        value: 'Prof.'
                      },
                      {
                        label: 'Prof.ssa',
                        value: 'Prof.ssa'
                      }
                    ]}
                  />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Cognome'
                  name='lastName'
                  required
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Nome'
                  name='firstName'
                  required
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
            </div>

            <div className='container-body-row-flex'>
              <div className='container-body-element'>
                <Form.Item
                  label='Codice Consultivo'
                  name='consuntiveCode'
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Sesso'
                  name='sex'
                >
                  <Select
                    allowClear
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        value: 'MALE',
                        label: 'Maschio'
                      },
                      {
                        value: 'FEMALE',
                        label: 'Femmina'
                      },
                      {
                        value: 'NOT_DECLARED',
                        label: 'Non dichiarato'
                      },
                      {
                        value: 'OTHER',
                        label: 'Altro'
                      }
                    ]}
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Nazionalità'
                  name='nationality'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='container-title'>
            <Typography variant='h5'>
              <FormattedMessage id='contactsData' />
            </Typography>
          </div>
          <Divider />
          <div className='container-body'>
            <div className='container-body-element'>
              <Form.Item
                label='Metodi di comunicazione'
              >
                <Form.Item
                  className='ant-radio-row'
                  label='Email'
                  name='EMAIL'
                >
                  <Radio.Group disabled={readOnly} options={radioOptions} />
                </Form.Item>
                <Form.Item
                  className='ant-radio-row'
                  label='Sms'
                  name='SMS'
                >
                  <Radio.Group disabled={readOnly} options={radioOptions} />
                </Form.Item>
                <Form.Item
                  className='ant-radio-row'
                  label='Push'
                  name='PUSH'
                >
                  <Radio.Group disabled={readOnly} options={radioOptions} />
                </Form.Item>
              </Form.Item>
            </div>

            <div className='container-body-row'>
              <div className='container-body-element'>
                <Form.Item
                  label='Telefono'
                  name='phoneNumber'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Cellulare'
                  name='cellPhoneNumber'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Fax'
                  name='faxNumber'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
            </div>

            <div className='container-body-row container-body-row-column'>
              <div className='container-body-element'>
                <Form.Item
                  label='Email'
                  name='email'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Contatto Skype'
                  name='skypeId'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
            </div>

            <div className='container-body-row container-body-row-column'>
              <div className='container-body-element'>
                <Form.Item
                  label='Stato'
                  name='country'
                >
                  <Select
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetRegion(event)}
                    options={[{
                      value: 'IT',
                      label: 'Italia'
                    }]}
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Regione'
                  name='region'
                >
                  <Select
                    allowClear
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetProvince(event)}
                    options={ITALIAN_REGIONS}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Provincia'
                  name='province'
                >
                  <Select
                    allowClear
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetCity(event)}
                    options={ITALIAN_PROVINCE}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Città'
                  name='city'
                >
                  <Select
                    allowClear
                    disabled={readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={ITALIAN_CITY}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>
            </div>
            <div className='container-body-row container-body-row-long'>
              <div className='container-body-element'>
                <Form.Item
                  label='Indirizzo'
                  name='address'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Civico'
                  name='streetNumber'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Codice Postale'
                  name='postalCode'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Località'
                  name='locality'
                >
                  <Input disabled={readOnly} />
                </Form.Item>
              </div>
            </div>

          </div>
        </div>
      </Form>
    </CustomerUserDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%', gap: '13px'}}>
      <PrimaryButton
        disabled={readOnly}
        form='user-form'
        key='submit'
        onClick={() => { onSave(form.getFieldsValue()) }}
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      maxWidth='lg'
      onClose={onClose}
      title={{text: `${user?.lastName ? user?.lastName : ''}  ${user?.firstName ? user?.firstName : ''}`, format: false}}
    />
  )
}

export default CustomerUserDialog
