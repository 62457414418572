import {Typography} from '@mui/material'
import {Checkbox, Divider, Form, Select} from 'antd'
import {FormattedMessage} from 'react-intl'
import {OthersDataStyled} from './OthersDataStyled'

const radioOptions = [
  {label: '', value: 'true'}
]

type OthersDataType = {
  profileConfiguration: Record<string, any>,
  readOnly?: boolean
}

const OthersData: React.FC<OthersDataType> = ({profileConfiguration, readOnly = false}) => {
  if (profileConfiguration?.drugDeliveryService?.hidden && profileConfiguration?.serviceConf?.hidden) {
    return null
  }

  return (
    <OthersDataStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            <FormattedMessage id='othersData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-row'>
            {!profileConfiguration?.serviceConf?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Configurazione di servizio'
                  name='serviceConf'
                  required
                >
                  <Select
                    disabled={profileConfiguration?.serviceConf?.readOnly || readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={[{
                      value: 'EXTRA',
                      label: 'Extra'
                    },
                    {
                      value: 'SMART',
                      label: 'Smart'
                    }]}
                  />
                </Form.Item>
              </div>}
            {!profileConfiguration?.drugDeliveryService?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  className='ant-radio-row'
                  label='Servizio consegna farmaco'
                  name='drugDeliveryService'
                >
                  <Checkbox.Group disabled={profileConfiguration?.drugDeliveryService?.readOnly || readOnly} options={radioOptions} />
                </Form.Item>
              </div>}
          </div>
        </div>
      </div>
    </OthersDataStyled>
  )
}

export default OthersData
