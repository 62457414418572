import {Box} from '@mui/material'
import {styled} from '@mui/system'

export const BoxAderenzaStyle = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.primary[500],
  width: '100%',
  borderRadius: theme.spacing(4),
  margin: `${theme.spacing(0)} ${theme.spacing(8)}`,
  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  '.box-aderenza-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.box-aderenza-primary': {
      color: '#eff6e4',
      fontWeight: 400,
      fontSize: 36,
      textAlign: 'center'
    },
    '.box-aderenza-secondary': {
      color: '#eff6e4',
      fontWeight: 400,
      fontSize: 16,
      textAlign: 'center'
    }
  }
}))
