import React, {useEffect, useState} from 'react'
import {TherapyCardStyled} from './TherapyCardStyled'
import {DatePicker, Form, Input} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import {Snackbar, Typography} from '@mui/material'
import moment from 'moment'
import {mdiArrowLeft, mdiArrowRight} from '@mdi/js'
import Icon from '@mdi/react'
import {STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'
import _ from 'lodash'

const TherapyCard: React.FC<{patientId: string}> = ({patientId}) => {
  const {patientDetailConfiguration: {therapiesConfiguration}} = useAppSelector(state => state.pspConfigReducer.data)

  const [form] = Form.useForm()
  const [data, setData] = useState<any[]>([])
  const [therapyIndex, setTherapyIndex] = useState<number>()
  const [selectedTherapy, setSelectedTherapy] = useState<any>()
  const [status, setStatus] = useState<any>()

  const fetchData = async () => {
    const therapies = await apiClient.getPatientsTherapies(patientId, setStatus)
    setData(therapies)
    setTherapyIndex(therapies.length - 1)
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId])

  useEffect(() => {
    therapiesConfiguration.dataSchema.forEach((element: any) => {
      if (element.type === 'date') {
        form.setFieldValue(element.value, selectedTherapy && _.get(selectedTherapy, element.value) ? moment(_.get(selectedTherapy, element.value)) : undefined)
      } else {
        form.setFieldValue(element.value, selectedTherapy ? _.get(selectedTherapy, element.value) : undefined)
      }
    })
  }, [form, selectedTherapy, therapiesConfiguration])

  useEffect(() => {
    if (data.length > 0 && therapyIndex !== undefined) {
      setSelectedTherapy(data[therapyIndex])
    }
  }, [data, therapyIndex])

  const updateTherapy = (update: number) => {
    if (data.length > 0 && therapyIndex !== undefined) {
      const updatedIndex = therapyIndex + update
      if (updatedIndex >= 0 && updatedIndex <= data.length - 1) {
        setTherapyIndex(updatedIndex)
      }
    }
  }

  const createTherapyContent = () => {
    return therapiesConfiguration.dataSchema.map((element : any) => {
      if (element.type === 'date') {
        return (
          <Form.Item
            key={element.value}
            label={element.name}
            name={element.value}
          >
            <DatePicker
              disabled
              format='ddd DD MMM YYYY'
              getPopupContainer={(trigger) => trigger}
              locale={locale}
              placeholder=''
            />
          </Form.Item>
        )
      } else {
        return (
          <Form.Item
            key={element.value}
            label={element.name}
            name={element.value}
          >
            <Input disabled />
          </Form.Item>
        )
      }
    })
  }

  return (
    <TherapyCardStyled>
      <div className='header'>
        {
          data.length > 1 &&
            <div className='header-element' onClick={() => updateTherapy(-1)} style={{padding: '0 30px'}}>
              <Icon color={therapyIndex === 0 ? 'grey' : 'black'} path={mdiArrowLeft} size={1} />
            </div>
        }
        <div className='header-element'>
          <Typography variant='h4'>
            {data && selectedTherapy ? `${selectedTherapy.name}` : 'Terapia non presente'}
          </Typography>
        </div>
        {
          data.length > 1 &&
            <div className='header-element' onClick={() => updateTherapy(1)} style={{padding: '0 30px'}}>
              <Icon color={data.length - 1 === therapyIndex ? 'grey' : 'black'} path={mdiArrowRight} size={1} />
            </div>
        }
      </div>
      <Form
        form={form}
        id='therapy-form'
        layout='vertical'
      >
        {createTherapyContent()}
      </Form>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </TherapyCardStyled>
  )
}

export default TherapyCard
