import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {AxiosInstance, AxiosRequestConfig} from 'axios'
import {STATUS} from 'src/constants/constants'

type InitialStateConfig = {
  status: string,
  error: string,
  data: {
    scope: string;
    theme: Record<string, any>;
    logoPSP: string;
    sidebarItems: Record<string, any>;
    headerConfiguration: Record<string, any>;
    footerConfiguration: Record<string, any>;
    patientsConfigurations: Record<string, any>;
    patientDetailConfiguration: Record<string, any>;
    agendaConfigurations: Record<string, any>;
    exportConfigurations: Record<string, any>;
    profileConfiguration: Record<string, any>;
    documentsConfigurations: Record<string, any>;
    therapyDiaryConfigurations: Record<string, any>;
    summaryConfiguration: Record<string, any>;
    virtualMeetingsConfigurations: Record<string, any>;
    infoMaterialsConfigurations: Record<string, any>;
    documentsAndInfoMaterialsConfigurations: Record<string, any>;
    surveysConfiguration: Record<string, any>;
    patientActivitiesConfigurations: Record<string, any>;
    pharmacovigilanceType: Record<string, any>;
    logoImagePSP: string;
    PSPTitleAndIcon: Record<string, any>;
    digitalEnrollmentConfiguration: Record<string, any>;
    firstLoginConfiguration: Record<string, any>;
  }
}

export const initialState: InitialStateConfig = {
  status: '',
  data: {
    scope: '',
    theme: {},
    logoPSP: '',
    logoImagePSP: '',
    sidebarItems: {},
    headerConfiguration: {},
    footerConfiguration: {},
    patientsConfigurations: {},
    patientDetailConfiguration: {},
    agendaConfigurations: {},
    exportConfigurations: {},
    profileConfiguration: {},
    documentsConfigurations: {},
    therapyDiaryConfigurations: {},
    summaryConfiguration: {},
    virtualMeetingsConfigurations: {},
    infoMaterialsConfigurations: {},
    documentsAndInfoMaterialsConfigurations: {},
    surveysConfiguration: {},
    patientActivitiesConfigurations: {},
    pharmacovigilanceType: {},
    digitalEnrollmentConfiguration: {},
    PSPTitleAndIcon: {},
    firstLoginConfiguration: {}
  },
  error: ''
}

export const fetchPspConfig = createAsyncThunk('psp-config/fetchPspConfig', async ({httpClient, config} : {httpClient: AxiosInstance, config: AxiosRequestConfig}) => {
  return await httpClient.request(config)
})

export const pspConfigSlice = createSlice({
  name: 'psp-config',
  initialState,
  reducers: {
    clearPspConfig: () => initialState,
    updateScope: (state, action: PayloadAction<string>) => {
      state.data.scope = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPspConfig.pending, state => {
      state.status = STATUS.PENDING
    })
    builder.addCase(fetchPspConfig.fulfilled, (state, action: any) => {
      state.status = STATUS.FULFILLED
      state.data = {...state, ...action.payload.data}
      state.error = ''
    })
    builder.addCase(fetchPspConfig.rejected, (state, action) => {
      state.status = STATUS.REJECTED
    })
  }
})

export const {clearPspConfig, updateScope} = pspConfigSlice.actions
export default pspConfigSlice.reducer
