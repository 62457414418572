import React, {useState} from 'react'
import {ListItemText, Menu, MenuItem, MenuList} from '@mui/material'
import Icon from '@mdi/react'
import {ActionsMenuStyled} from './ActionsMenuStyled'

export type Action = {
  id: string;
  label: string;
  icon: string;
  action: (data?: Record<string, any>) => void;
  condition?: (data?: Record<string, any>) => boolean
}

type ActionsMenuProps ={
  actions: Action[],
  data?: Record<string, any>;
  icon: string
}

const ActionsMenu: React.FC<ActionsMenuProps> = ({actions, icon, data}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <div>
      <div className='menuButton' onClick={handleClick} style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Icon className='icon' path={icon} size={'24px'} />
      </div>
      <Menu
        MenuListProps={{sx: {padding: 0}}}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClick={handleClose}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <ActionsMenuStyled sx={{minWidth: 125, maxWidth: '100%', boxShadow: 'none'}}>
          <MenuList className='actions-list'>
            {
              actions.map((action) => {
                return (
                  <MenuItem className='action-item' key={action.id} onClick={() => action.action(data)}>
                    <ListItemText className='text-list' disableTypography>
                      <Icon className='action-icon' path={action.icon} size={'20px'} />
                      <span>{action.label}</span>
                    </ListItemText>
                  </MenuItem>
                )
              })
            }
          </MenuList>
        </ActionsMenuStyled>
      </Menu>
    </div>
  )
}

export default ActionsMenu
