import {styled} from '@mui/system'
import {Button} from '@mui/material'

export const AvatarStyled = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'width' &&
    prop !== 'selected' &&
    prop !== 'backgroundColor'
})<{width: string, selected: boolean, backgroundColor?: string}>(({
  width, selected, backgroundColor
}) => ({theme}) => ({
  borderRadius: '50%',
  width: 'fit-content',
  height: 'fit-content',
  padding: 0,
  '.avatar': {
    width: width,
    height: width,
    borderRadius: '50%',
    backgroundColor: `${!backgroundColor ? theme.palette.primary[100] : backgroundColor} !important`,
    display: 'grid',
    placeItems: 'center',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      borderRadius: '50%'
    },
    border: selected ? `5px solid ${theme.palette.primary.main}` : '',
    '&:hover': {
      border: `5px solid ${theme.palette.primary.main}`
    }
  }
}))
