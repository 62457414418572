import {styled} from '@mui/system'

export const HomeStyled = styled('div')(({theme}) => ({
  width: '100%',
  '.card-container': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '.card-aderenza': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(8),
    justifyContent: 'space-between',
    width: '50%',
    height: '25%'
  },
  '.text-aderenza': {
    width: '48%',
    padding: theme.spacing(8)
  },
  '.chart-grid-container': {
    padding: `${theme.spacing(8)} ${theme.spacing(0)}`
  }
}))
