/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import {IconButton, Typography} from '@mui/material'
import {
  Divider,
  Form,
  Select,
  Input,
  Checkbox,
  FormInstance,
  Radio
} from 'antd'
import {FormattedMessage} from 'react-intl'
import {ContactDataStyled} from './ContactDataStyled'
import Icon from '@mdi/react'
import {mdiAccount, mdiClose, mdiMagnify, mdiPencil} from '@mdi/js'
import {useEffect, useState} from 'react'
import CaregiversDialog from '../CaregiversDialog/CaregiversDialog'
import apiClient from 'src/services/apiClient'
import CaregiverDetailDialog from '../CaregiverDetailDialog/CaregiverDetailDialog'
import {ITALIAN_REGIONS} from 'src/constants/mock/county'
import {ITALIAN_PROVINCE} from 'src/constants/mock/province'
import {ITALIAN_CITY} from 'src/constants/mock/city'
import {formatWeekDay} from 'src/components/utils/day-time'
import {TimeslotProps} from 'src/types/types'
import TimeslotsDetailDialog from '../TimeslotsDialog/TimeslotsDialog'

const reminderOptions = [
  {label: 'SMS', value: 'SMS'},
  {label: 'Email', value: 'EMAIL'},
  {label: 'Chiamata', value: 'CALL'},
  {label: 'Push', value: 'PUSH'}
]

const DELEGATE_1 = 'delegate1'
const DELEGATE_2 = 'delegate2'
const CAREGIVER = 'caregiver'

type ContactDataType = {
  handleGetRegion?: (value: string) => void;
  handleGetProvince?: (value: string) => void;
  handleGetCity?: (value: string) => void;
  setTimeslots?: (value: any) => void;
  form?: FormInstance<any>;
  profileConfiguration: Record<string, any>;
  secondary?: boolean;
  delegate1Values: { delegate1?: any; setDelegate1: any };
  delegate2Values: { delegate2?: any; setDelegate2: any };
  caregiverValues: { caregiver?: any; setCaregiver: any };
  patientId?: string;
  readOnly?: boolean;
  preferredTimeSlots?: TimeslotProps[]
};

const ContactData: React.FC<ContactDataType> = ({
  handleGetRegion = () => undefined,
  handleGetProvince = () => undefined,
  handleGetCity = () => undefined,
  setTimeslots = () => undefined,
  form,
  profileConfiguration,
  secondary = false,
  delegate1Values,
  delegate2Values,
  caregiverValues,
  patientId,
  preferredTimeSlots,
  readOnly = false
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState('')
  const [elementToFilter, setElementToFilter] = useState<Record<string, any>>()
  const [caregiverDetail, setCaregiverDetail] = useState<Record<string, any>>()
  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [openTimeslotsDialog, setOpenTimeslotsDialog] = useState<boolean>(false)
  const {delegate1, setDelegate1} = delegate1Values
  const {delegate2, setDelegate2} = delegate2Values
  const {caregiver, setCaregiver} = caregiverValues

  const handleTimeslotsChange = (newTimeslots: TimeslotProps[]) => {
    setTimeslots(newTimeslots)
    setOpenTimeslotsDialog(false)
  }

  const handleOpenDialog = (delegate?: Record<string, any>) => {
    setCaregiverDetail(delegate)
    setOpenDetailDialog(true)
  }

  const handleOnSave = (newEl: Record<string, any>) => {
    if (dialogType === DELEGATE_1) {
      setDelegate1(newEl)
      form?.setFieldValue('delegateId_1', newEl._id)
    } else if (dialogType === DELEGATE_2) {
      setDelegate2(newEl)
      form?.setFieldValue('delegateId_2', newEl._id)
    } else {
      setCaregiver(newEl)
      form?.setFieldValue('caregiverId', newEl._id)
    }

    setOpenDialog(false)
  }

  const onClickOpenDialog = (dialogTypeName: string) => {
    setDialogType(dialogTypeName)
    setOpenDialog(true)
    setElementToFilter(
      dialogTypeName === DELEGATE_1 ?
        delegate1 :
        dialogTypeName === DELEGATE_2 ?
          delegate2 :
          caregiver
    )
  }

  const fetchData = async () => {
    if (readOnly === true && patientId) {
      const data = await apiClient.getPatientsCaregivers(patientId, () => {})
      setDelegate1(
        data.find((el: any) => el._id === form?.getFieldValue('delegateId_1'))
      )
      setDelegate2(
        data.find((el: any) => el._id === form?.getFieldValue('delegateId_2'))
      )
      setCaregiver(
        data.find((el: any) => el._id === form?.getFieldValue('caregiverId'))
      )
    }
    if (readOnly === false) {
      const data = await apiClient.getCaregivers([], () => undefined)
      setDelegate1(
        data.find((el: any) => el._id === form?.getFieldValue('delegateId_1'))
      )
      setDelegate2(
        data.find((el: any) => el._id === form?.getFieldValue('delegateId_2'))
      )
      setCaregiver(
        data.find((el: any) => el._id === form?.getFieldValue('caregiverId'))
      )
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, patientId])

  const delegateDialog = (
    <CaregiversDialog
      elementToFilter={elementToFilter}
      onClose={() => setOpenDialog(false)}
      onSave={handleOnSave}
    />
  )

  const detailDialog = (
    <CaregiverDetailDialog
      detail={caregiverDetail}
      onClose={() => setOpenDetailDialog(false)}
    />
  )

  const timeslotsDialog = (
    <TimeslotsDetailDialog
      onClose={() => setOpenTimeslotsDialog(false)}
      onEdit={handleTimeslotsChange}
      timeslots={preferredTimeSlots}
      title={'Modifica fasce orarie'}
    />
  )

  return (
    <ContactDataStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            <FormattedMessage id='contactsData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          {profileConfiguration?.preferredContacts &&
            !profileConfiguration?.preferredContacts?.hidden && (
            <div className='container-body-element'>
              <Form.Item label='Metodi di comunicazione'>
                <Form.Item
                  className='ant-radio-row'
                  label='Email'
                  name='EMAIL'
                >
                  <Radio.Group
                    disabled={
                      profileConfiguration?.preferredContacts?.readOnly ||
                    readOnly
                    }
                  >
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item className='ant-radio-row' label='Sms' name='SMS'>
                  <Radio.Group
                    disabled={
                      profileConfiguration?.preferredContacts?.readOnly ||
                    readOnly
                    }
                  >
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item className='ant-radio-row' label='Push' name='PUSH'>
                  <Radio.Group
                    disabled={
                      profileConfiguration?.preferredContacts?.readOnly ||
                    readOnly
                    }
                  >
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form.Item>
            </div>
          )}
          {profileConfiguration?.preferredTimeSlots &&
            !profileConfiguration?.preferredTimeSlots?.hidden && (
            <div className='container-flex'>
              <div className='block-1'>
                <div className='container-flex-label'>
                  <label>{'Fasce orarie preferite'}</label>
                </div>
                <div className='container-flex-content'>
                  {preferredTimeSlots ? preferredTimeSlots.map(({day, from, to}) => (
                    <li key={`${day}-${from}-${to}`}>{`${formatWeekDay(
                        day
                      )}, ${from} ${to}`}
                    </li>
                  )) : '-'}
                </div>
              </div>
              {!profileConfiguration?.preferredTimeSlots?.readOnly &&
              <div className='container-flex-button'>
                <IconButton aria-label='close' edge='end' onClick={() => setOpenTimeslotsDialog(true)}>
                  <Icon path={mdiPencil} size={1} />
                </IconButton>
              </div>}
            </div>
          )}

          {secondary ? (
            <div className='container-body-row container-body-row-column'>
              {profileConfiguration?.email &&
                !profileConfiguration?.email?.hidden && (
                <div className='container-body-element'>
                  <Form.Item label='Email' name='email'>
                    <Input
                      disabled={
                        profileConfiguration?.email?.readOnly || readOnly
                      }
                    />
                  </Form.Item>
                </div>
              )}
              {profileConfiguration?.phoneNumber &&
                !profileConfiguration?.phoneNumber?.hidden && (
                <div className='container-body-element'>
                  <Form.Item label='Telefono' name='phoneNumber'>
                    <Input
                      disabled={
                        profileConfiguration?.phoneNumber?.readOnly ||
                        readOnly
                      }
                    />
                  </Form.Item>
                </div>
              )}
              {profileConfiguration?.cellPhoneNumber &&
                !profileConfiguration?.cellPhoneNumber?.hidden && (
                <div className='container-body-element'>
                  <Form.Item label='Cellulare' name='cellPhoneNumber'>
                    <Input
                      disabled={
                        profileConfiguration?.cellPhoneNumber?.readOnly ||
                        readOnly
                      }
                    />
                  </Form.Item>
                </div>
              )}

              {profileConfiguration?.faxNumber &&
                !profileConfiguration?.faxNumber?.hidden && (
                <div className='container-body-element'>
                  <Form.Item label='Fax' name='faxNumber'>
                    <Input
                      disabled={
                        profileConfiguration?.faxNumber?.readOnly || readOnly
                      }
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className='container-body-row'>
                {profileConfiguration?.phoneNumber &&
                  !profileConfiguration?.phoneNumber?.hidden && (
                  <div className='container-body-element'>
                    <Form.Item label='Telefono' name='phoneNumber'>
                      <Input
                        disabled={
                          profileConfiguration?.phoneNumber?.readOnly ||
                          readOnly
                        }
                      />
                    </Form.Item>
                  </div>
                )}
                {profileConfiguration?.cellPhoneNumber &&
                  !profileConfiguration?.cellPhoneNumber?.hidden && (
                  <div className='container-body-element'>
                    <Form.Item label='Cellulare' name='cellPhoneNumber'>
                      <Input
                        disabled={
                          profileConfiguration?.cellPhoneNumber?.readOnly ||
                          readOnly
                        }
                      />
                    </Form.Item>
                  </div>
                )}

                {profileConfiguration?.faxNumber &&
                  !profileConfiguration?.faxNumber?.hidden && (
                  <div className='container-body-element'>
                    <Form.Item label='Fax' name='faxNumber'>
                      <Input
                        disabled={
                          profileConfiguration?.faxNumber?.readOnly ||
                          readOnly
                        }
                      />
                    </Form.Item>
                  </div>
                )}
              </div>

              <div className='container-body-row container-body-row-column'>
                {profileConfiguration?.email &&
                  !profileConfiguration?.email?.hidden && (
                  <div className='container-body-element'>
                    <Form.Item label='Email' name='email'>
                      <Input
                        disabled={
                          profileConfiguration?.email?.readOnly || readOnly
                        }
                      />
                    </Form.Item>
                  </div>
                )}

                {profileConfiguration?.skypeId &&
                  !profileConfiguration?.skypeId?.hidden && (
                  <div className='container-body-element'>
                    <Form.Item label='Contatto Skype' name='skypeId'>
                      <Input
                        disabled={
                          profileConfiguration?.skypeId?.readOnly || readOnly
                        }
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            </>
          )}

          <div className='container-body-row container-body-row-column'>
            {profileConfiguration?.address?.country &&
              !profileConfiguration?.address?.country?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Stato' name='country'>
                  <Select
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetRegion(event)}
                    options={[
                      {
                        value: 'IT',
                        label: 'Italia'
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.region &&
              !profileConfiguration?.address?.region?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Regione' name='region'>
                  <Select
                    allowClear
                    disabled={
                      profileConfiguration?.address?.region?.readOnly ||
                      readOnly
                    }
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetProvince(event)}
                    options={ITALIAN_REGIONS}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.province &&
              !profileConfiguration?.address?.province?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Provincia' name='province'>
                  <Select
                    allowClear
                    disabled={
                      profileConfiguration?.address?.province?.readOnly ||
                      readOnly
                    }
                    getPopupContainer={(trigger) => trigger}
                    onChange={(event) => handleGetCity(event)}
                    options={ITALIAN_PROVINCE}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.city &&
              !profileConfiguration?.address?.city?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Città' name='city'>
                  <Select
                    allowClear
                    disabled={
                      profileConfiguration?.address?.city?.readOnly ||
                      readOnly
                    }
                    getPopupContainer={(trigger) => trigger}
                    options={ITALIAN_CITY}
                    placeholder='Cerca...'
                    showSearch
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className='container-body-row container-body-row-long'>
            {profileConfiguration?.address?.address &&
              !profileConfiguration?.address?.address?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Indirizzo' name='address'>
                  <Input
                    disabled={
                      profileConfiguration?.address?.address?.readOnly ||
                      readOnly
                    }
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.streetNumber &&
              !profileConfiguration?.address?.streetNumber?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Civico' name='streetNumber'>
                  <Input
                    disabled={
                      profileConfiguration?.address?.streetNumber?.readOnly ||
                      readOnly
                    }
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.postalCode &&
              !profileConfiguration?.address?.postalCode?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Codice Postale' name='postalCode'>
                  <Input
                    disabled={
                      profileConfiguration?.address?.postalCode?.readOnly ||
                      readOnly
                    }
                  />
                </Form.Item>
              </div>
            )}

            {profileConfiguration?.address?.locality &&
              !profileConfiguration?.address?.locality?.hidden && (
              <div className='container-body-element'>
                <Form.Item label='Località' name='locality'>
                  <Input
                    disabled={
                      profileConfiguration?.address?.locality?.readOnly ||
                      readOnly
                    }
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className='container-body-row container-body-row-column'>
            {profileConfiguration?.delegateId_1 &&
              !profileConfiguration?.delegateId_1?.hidden && (
              <div className='container-body-element container-body-element-icon'>
                <Form.Item label='Delegato 1'>
                  <Input
                    disabled={
                      profileConfiguration?.delegateId_1?.readOnly || readOnly
                    }
                    value={
                      delegate1 ?
                          `${delegate1?.title || ''} ${
                              delegate1?.firstName || ''
                            } ${delegate1?.lastName || ''}` :
                        ''
                    }
                  />
                </Form.Item>
                {!readOnly && (
                  <IconButton
                    disabled={profileConfiguration?.delegateId_1?.readOnly}
                    onClick={() => onClickOpenDialog(DELEGATE_1)}
                  >
                    <Icon path={mdiMagnify} size={1} />
                  </IconButton>
                )}
                <IconButton
                  disabled={!delegate1}
                  onClick={() => handleOpenDialog(delegate1)}
                >
                  <Icon path={mdiAccount} size={1} />
                </IconButton>
                {!readOnly && (
                  <IconButton
                    disabled={
                      profileConfiguration?.delegateId_1?.readOnly ||
                        !delegate1
                    }
                    onClick={() => {
                      setDelegate1(undefined)
                      form?.setFieldValue('delegateId_1', undefined)
                    }}
                  >
                    <Icon path={mdiClose} size={1} />
                  </IconButton>
                )}
              </div>
            )}

            {profileConfiguration?.delegateId_2 &&
              !profileConfiguration?.delegateId_2?.hidden && (
              <div className='container-body-element container-body-element-icon'>
                <Form.Item label='Delegato 2'>
                  <Input
                    disabled={
                      profileConfiguration?.delegateId_2?.readOnly || readOnly
                    }
                    value={
                      delegate2 ?
                          `${delegate2?.title || ''} ${
                              delegate2?.firstName || ''
                            } ${delegate2?.lastName || ''}` :
                        ''
                    }
                  />
                </Form.Item>
                {!readOnly && (
                  <IconButton
                    disabled={profileConfiguration?.delegateId_2?.readOnly}
                    onClick={() => onClickOpenDialog(DELEGATE_2)}
                  >
                    <Icon path={mdiMagnify} size={1} />
                  </IconButton>
                )}
                <IconButton
                  disabled={!delegate2}
                  onClick={() => handleOpenDialog(delegate2)}
                >
                  <Icon path={mdiAccount} size={1} />
                </IconButton>
                {!readOnly && (
                  <IconButton
                    disabled={
                      profileConfiguration?.delegateId_2?.readOnly ||
                        !delegate2
                    }
                    onClick={() => {
                      setDelegate2(undefined)
                      form?.setFieldValue('delegateId_2', undefined)
                    }}
                  >
                    <Icon path={mdiClose} size={1} />
                  </IconButton>
                )}
              </div>
            )}
            {profileConfiguration?.caregiverId &&
              !profileConfiguration?.caregiverId?.hidden && (
              <div className='container-body-element container-body-element-icon'>
                <Form.Item label='Caregiver'>
                  <Input
                    disabled={
                      profileConfiguration?.caregiverId?.readOnly || readOnly
                    }
                    value={
                      caregiver ?
                          `${caregiver?.title || ''} ${
                              caregiver?.firstName || ''
                            } ${caregiver?.lastName || ''}` :
                        ''
                    }
                  />
                </Form.Item>
                {!readOnly && (
                  <IconButton
                    disabled={
                      profileConfiguration?.caregiverId?.readOnly || readOnly
                    }
                    onClick={() => onClickOpenDialog(CAREGIVER)}
                  >
                    <Icon path={mdiMagnify} size={1} />
                  </IconButton>
                )}
                <IconButton
                  disabled={!caregiver}
                  onClick={() => handleOpenDialog(caregiver)}
                >
                  <Icon path={mdiAccount} size={1} />
                </IconButton>
                {!readOnly && (
                  <IconButton
                    disabled={
                      profileConfiguration?.caregiverId?.readOnly ||
                        !caregiver
                    }
                    onClick={() => {
                      setCaregiver(undefined)
                      form?.setFieldValue('caregiver', undefined)
                    }}
                  >
                    <Icon path={mdiClose} size={1} />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          {profileConfiguration?.remindTherapyExpiryTypes &&
            !profileConfiguration?.remindTherapyExpiryTypes?.hidden && (
            <Form.Item
              label='Tipo remind scadenza terapia'
              name='remindTherapyExpiryTypes'
            >
              <Checkbox.Group
                disabled={
                  profileConfiguration?.remindTherapyExpiryTypes?.readOnly ||
                readOnly
                }
                options={reminderOptions}
              />
            </Form.Item>
          )}

          {profileConfiguration?.preferredContactMethods &&
            !profileConfiguration?.preferredContactMethods?.hidden && (
            <Form.Item
              label='Metodi di contatto'
              name='preferredContactMethods'
            >
              <Checkbox.Group
                disabled={
                  profileConfiguration?.preferredContactMethods?.readOnly ||
                readOnly
                }
                options={reminderOptions}
              />
            </Form.Item>
          )}
        </div>
      </div>
      {openDialog && delegateDialog}
      {openDetailDialog && detailDialog}
      {openTimeslotsDialog && timeslotsDialog}
    </ContactDataStyled>
  )
}

export default ContactData
