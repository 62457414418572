import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CaregiversDialogStyle} from './CaregiversDialogStyled'
import Filters, {FilterItem} from '../Filters/Filters'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'

type CaregiversDialogProps = {
  elementToFilter?: Record<string, any>;
  onSave: (caregiver: Record<string, any>) => void;
  onClose: () => void;
};

const CaregiversDialog: React.FC<CaregiversDialogProps> = ({elementToFilter, onSave, onClose}) => {
  const [filters, setFilters] = useState([])

  const [fetchedData, setFetchedData] = useState<{data: Record<string, any>[], count: number}>({
    data: [],
    count: 0
  })

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  const columns: TableColumn[] = [
    {
      id: 'title',
      label: 'Titolo'
    },
    {
      id: 'lastName',
      label: 'Cognome'
    },
    {
      id: 'firstName',
      label: 'Nome'
    },
    {
      id: 'phoneNumber',
      label: 'Telefono'
    },
    {
      id: 'cellPhoneNumber',
      label: 'Cellulare'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'firstName',
      label: 'Nome',
      type: 'text'
    },
    {
      id: 'lastName',
      label: 'Cognome',
      type: 'text'
    }
  ]

  const onSubmitFilters = (newFilters: any) => {
    setFilters(newFilters)
    setPagination(prev => ({...prev, page: 0}))
  }

  const onRowClick = (row: Record<string, any>) => {
    onSave(row)
  }

  const fetchData = async () => {
    Promise.all([apiClient.getCaregivers(filters, () => undefined, pagination), apiClient.getCaregiversCount(filters, () => undefined)])
      .then(([caregivers, count]) => {
        setFetchedData({
          data: caregivers.filter((el: any) => el._id !== elementToFilter?._id),
          count
        })
      })
  }

  const body = (
    <CaregiversDialogStyle>
      <Filters config={filterConfig} key='filters' onSubmit={onSubmitFilters} />
      <div style={{marginTop: '16px', width: '100%'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClick}
          pagination={pagination}
          rows={fetchedData.data}
          totalElements={fetchedData.count}
        />
      </div>

    </CaregiversDialogStyle>
  )

  return (
    <DialogCustom
      body={body}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: 'caregiverList', format: true}}
    />
  )
}

export default CaregiversDialog
