import {Card} from '@mui/material'
import {styled} from '@mui/system'

export const CardCustomStyle = styled(Card)(({theme}) => ({
  height: 'auto',
  borderRadius: '0px',
  boxShadow: 'none',

  '.MuiCardHeader-root': {
    padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`,
    borderTop: '2px solid #eee',
    border: '1px solid #edf1f2'
  },
  '.MuiCardContent-root': {
    padding: `${theme.spacing(8)} !important`,
    border: '1px solid #edf1f2',
    height: '100%'
  }
}))
