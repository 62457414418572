import {FormattedMessage} from 'react-intl'
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'
import moment from 'moment'
import {AgendaToolbarStyled} from './AgendaToolbarStyled'
import {mdiChevronLeft, mdiChevronRight} from '@mdi/js'
import Icon from '@mdi/react'
import {Typography} from '@mui/material'

type AgendaToolbarProps = {
  date: Date;
  range: number;
  handleDateChange: (date: Date) => void;
  handleRangeChange: (range: number) => void
}

const AgendaToolbar: React.FC<AgendaToolbarProps> = ({
  date,
  range,
  handleDateChange,
  handleRangeChange
}) => {
  const addMonths = (currentDate: Date, months : number) => {
    const d = currentDate.getDate()
    currentDate.setMonth(currentDate.getMonth() + months)
    if (currentDate.getDate() !== d) {
      currentDate.setDate(0)
    }
    return currentDate
  }

  const addWeeks = (currentDate: Date, weeks : number) => {
    currentDate.setDate(currentDate.getDate() + 7 * weeks)
    return currentDate
  }

  const addDays = (currentDate: Date, days : number) => {
    currentDate.setDate(currentDate.getDate() + days)
    return currentDate
  }

  const goToDayView = () => {
    handleRangeChange(0)
  }
  const goToWeekView = () => {
    handleDateChange(moment(date).startOf('week').toDate())
    handleRangeChange(7)
  }
  const goToMonthView = () => {
    handleDateChange(moment(date).startOf('month').toDate())
    handleRangeChange(30)
  }

  const goToToday = () => {
    if (range === 7) {
      handleDateChange(moment(new Date()).startOf('week').toDate())
    } else if (range === 0) {
      handleDateChange(new Date())
    } else {
      handleDateChange(moment(new Date()).startOf('month').toDate())
    }
  }

  const goToBack = () => {
    if (range === 7) {
      handleDateChange(addWeeks(date, -1))
    } else if (range === 0) {
      handleDateChange(addDays(date, -1))
    } else {
      handleDateChange(addMonths(date, -1))
    }
  }

  const goToNext = () => {
    if (range === 7) {
      handleDateChange(addWeeks(date, +1))
    } else if (range === 0) {
      handleDateChange(addDays(date, +1))
    } else {
      handleDateChange(addMonths(date, +1))
    }
  }

  const dateFormat = (currentDate: Date) => {
    if (range === 30) {
      return moment(currentDate).format('MMMM YYYY')
    } else if (range === 7) {
      return `${moment(currentDate).startOf('week').format('DD MMM')}/${moment(currentDate).endOf('week').format('DD MMM YYYY')}`
    } else {
      return moment(currentDate).format('DD MMMM YYYY')
    }
  }
  return (
    <AgendaToolbarStyled>
      <div className='view-div'>
        <PrimaryButton className='today-button' onClick={goToToday}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='today' />
          </Typography>
        </PrimaryButton>
        <SecondaryButton onClick={goToDayView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='day' />
          </Typography>
        </SecondaryButton>
        <SecondaryButton onClick={goToWeekView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='week' />
          </Typography>
        </SecondaryButton>
        <SecondaryButton onClick={goToMonthView}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            <FormattedMessage id='month' />
          </Typography>
        </SecondaryButton>
      </div>
      <div className='navigation-div'>
        <div className='chevron-icon' onClick={goToBack}>
          <Icon path={mdiChevronLeft} size={1} />
        </div>
        <span>{dateFormat(date)}</span>
        <div className='chevron-icon' onClick={goToNext}>
          <Icon path={mdiChevronRight} size={1} />
        </div>
      </div>
    </AgendaToolbarStyled>
  )
}

export default AgendaToolbar
