import {styled} from '@mui/material'

export const PerformancesStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),

  '.cell-icon-table': {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  }
}))
