import {styled} from '@mui/system'

export const VirtualMeetingOrganizeDialogStyled = styled('div')(({theme}) => ({

  padding: theme.spacing(8),

  '#organize-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: '10px',

    '.ant-row': {
      width: '100%'
    },

    '.title': {
      gridColumn: '1 / 5',
      gridRow: 1
    },

    '.startDay': {
      gridColumn: '5 / 7',
      gridRow: 1
    },

    '.startDate': {
      gridColumn: '1 / 4',
      gridRow: 2
    },

    '.endDate': {
      gridColumn: '4 / 7',
      gridRow: 2
    },

    '.patient': {
      gridColumn: '1 / 4',
      gridRow: 3,
      display: 'flex',

      '.patient-content': {
        display: 'flex',

        '.ant-form-item': {
          width: '100%'
        },

        '.patient-buttons': {
          display: 'flex',

          '.patient-button': {
            color: theme.palette.neutral.main,
            borderRadius: theme.spacing(21),
            minWidth: theme.spacing(17),
            height: theme.spacing(21),
            '&:hover': {
              backgroundColor: theme.palette.neutral[200]
            }
          }
        },

        '.ant-input[disabled]': {
          backgroundColor: '#eee',
          borderColor: '#ccc',
          color: '#555'
        }
      }
    },

    '.email': {
      gridColumn: '4 / 7',
      gridRow: 3
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        }
      }
    }
  }
}))
