import {Grid, Typography, useTheme} from '@mui/material'
import BoxAderenza from 'src/components/elements/BoxAderenza/BoxAderenza'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {HomeStyled} from './HomeStyled'
import {PATHS, STATUS} from 'src/constants/constants'
import {useEffect, useState} from 'react'
import Chart from 'src/components/elements/Chart/Chart'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {ITALIAN_REGIONS} from 'src/constants/mock/county'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'
import Loader from 'src/components/elements/Loader/Loader'

export type AdherenceType = {
  pspAdherencePercentage?: number,
  totalAdherencePercentage?: number,
  pspAdherencePercentageYear?: number,
  totalAdherencePercentageYear?: number
}

const Home = () => {
  const theme = useTheme()
  const {summaryConfiguration} = useAppSelector(state => state.pspConfigReducer.data)
  const [status, setStatus] = useState<any>(undefined)

  const [filters, setFilters] = useState<Record<string, any>>([])

  const [patientSex, setPatientSex] = useState([])
  const [patientCurrentAge, setPatientCurrentAge] = useState([])
  const [patientStatus, setPatientStatus] = useState([])
  const [patientVialDosages, setPatientVialDosages] = useState([])
  const [patientDrug, setPatientDrug] = useState([])
  const [patientMonthlyAdherence, setPatientMonthlyAdherence] = useState({})
  const [patientRegionResidence, setPatientRegionResidence] = useState({})
  const [doctorRegionResidence, setDoctorRegionResidence] = useState({})
  const [patientServices, setPatientServices] = useState([])
  const [mdsActive, setMdsActive] = useState([])
  const [skippedAssumption, setSkippedAssumption] = useState([])
  const [patientAdherenceActivations, setPatientAdherenceActivations] = useState({})
  const [monthlyDrugAdherence, setMonthlyDrugAdherence] = useState({})
  const [assumptionsRegion, setAssumptionsRegion] = useState({})
  const [regionAdherence, setRegionAdherence] = useState({})

  const [adherenceValues, setAdherenceValues] = useState<any>({})
  const defaultColors = ['#A5C842', '#495CA2', '#028B96', '#E87D1E', '#003DA5', '#085AA6', '#731472', '#3599B8']
  const {scope, theme: themeColors} = useAppSelector(state => state.pspConfigReducer.data)
  const [colors, setColors] = useState<string[]>((scope === 'quiperte' && themeColors) ? [themeColors?.primaryColor, themeColors?.secondaryColor].concat(defaultColors) : defaultColors)

  // const [patientDisabled, setPatientDisabled] = useState([])
  // const [doctorDisabled, setDoctorDisabled] = useState([])
  // const [doctorAge, setDoctorAge] = useState([])
  // const [doctorStatus, setDoctorStatus] = useState([])
  // const [doctorMonthlyAdherence, setDoctorMonthlyAdherence] = useState({})

  useEffect(() => {
    fetchData()
    fetchProgram()
    fetchAdherence()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const fetchData = async () => {
    const fetchedData = await apiClient.getGraphs(filters, setStatus) ?? []
    setPatientSex(fetchedData?.patientSex || [])
    setPatientCurrentAge(fetchedData?.patientCurrentAge || [])
    setPatientStatus(fetchedData?.patientStatus || [])
    setPatientRegionResidence(fetchedData?.patientRegionResidence || {})
    setDoctorRegionResidence(fetchedData?.doctorRegionResidence || {})
    setPatientMonthlyAdherence(fetchedData?.patientMonthlyAdherence || {})
    setPatientDrug(fetchedData?.patientDrug || [])
    setPatientServices(fetchedData?.patientServices || [])
    setMdsActive(fetchedData?.mdsActive || [])
    setSkippedAssumption(fetchedData?.skippedAssumption || [])
    setPatientAdherenceActivations(fetchedData?.patientAdherenceActivations || {})
    setMonthlyDrugAdherence(fetchedData?.monthlyDrugAdherence || {})
    setAssumptionsRegion(fetchedData?.assumptionsRegion || {})
    setRegionAdherence(fetchedData?.regionAdherence || {})
    setPatientVialDosages(fetchedData?.patientVialDosages || [])

    // setPatientDisabled(fetchedData?.patientDisabled || [])
    // setDoctorDisabled(fetchedData?.doctorDisabled || [])
    // setDoctorMonthlyAdherence(fetchedData?.doctorAccessionData || {})
    // setDoctorAge(fetchedData?.doctorCurrentAge || [])
    // setDoctorStatus(fetchedData?.doctorStatus || [])
  }

  const fetchAdherence = async () => {
    const fetchedData = await apiClient.getSummaryAdherence(filters) ?? []
    setAdherenceValues(fetchedData)
  }

  const fetchProgram = async () => {
    const program = await apiClient.getProgram()
    if (program) {
      setColors((scope === 'quiperte' && themeColors) ? [themeColors?.primaryColor, themeColors?.secondaryColor].concat(program.programPalette.colors) : program.programPalette.colors)
    }
  }

  const filterConfig: FilterItem[] = [
    ...(summaryConfiguration?.kpiIdentifiers.patientSex ? [{
      id: 'sex',
      label: 'Filtra pazienti per genere',
      type: 'select',
      options: [
        {label: 'Tutti i generi', value: ''},
        {label: 'Maschio', value: 'MALE'},
        {label: 'Femmina', value: 'FEMALE'}
      ]
    }] : []),
    ...(summaryConfiguration?.kpiIdentifiers.patientStatus ? summaryConfiguration?.patientsStateFilters : []),
    ...(summaryConfiguration?.kpiIdentifiers.doctorStatus ? [{
      id: 'doctorStatus',
      label: 'Filtra medici per stato',
      type: 'select',
      options: [
        {label: 'Tutti gli stati', value: ''},
        {label: 'In attivazione', value: 'IN_ACTIVATION'},
        {label: 'Attivo', value: 'ACTIVE'},
        {label: 'Sospeso', value: 'SUSPENDED'},
        {label: 'Drop-Out', value: 'EXITED'}
      ]
    }] : []),
    ...(summaryConfiguration?.kpiIdentifiers.patientDrug ? summaryConfiguration?.drugFilters : []),
    ...(summaryConfiguration?.kpiIdentifiers.patientRegionResidence ? [{
      id: 'patientRegion',
      label: 'Filtra pazienti per regione di residenza',
      type: 'select',
      options: ITALIAN_REGIONS
    }] : []),
    ...(summaryConfiguration?.kpiIdentifiers.patientCurrentAge ? [{
      id: 'patientAgeRange',
      label: "Filtra pazienti per fasce d'età",
      type: 'select',
      options: [
        {label: "Tutte le fasce d'età", value: ''},
        {label: 'Da 0 a 5 anni', value: 'FROM_0_TO_6'},
        {label: 'Da 6 a 13 anni', value: 'FROM_6_TO_13'},
        {label: 'Da 14 a 17 anni', value: 'FROM_14_TO_17'},
        {label: 'Da 18 a 25 anni', value: 'FROM_18_TO_25'},
        {label: 'Da 26 a 40 anni', value: 'FROM_26_TO_40'},
        {label: 'Da 41 a 60 anni', value: 'FROM_41_TO_60'},
        {label: 'Da 61 a 80 anni', value: 'FROM_61_TO_80'},
        {label: 'Oltre i 80 anni', value: 'FROM_81_TO_END'}
      ]
    }] : []),
    // ...(summaryConfiguration?.kpiIdentifiers.doctorAge ? [{
    //   id: 'doctorAgeRange',
    //   label: "Filtra medici per fasce d'età",
    //   type: 'select',
    //   options: [
    //     {label: "Tutte le fasce d'età", value: ''},
    //     {label: 'Da 0 a 5 anni', value: 'FROM_0_TO_6'},
    //     {label: 'Da 6 a 13 anni', value: 'FROM_6_TO_13'},
    //     {label: 'Da 14 a 17 anni', value: 'FROM_14_TO_17'},
    //     {label: 'Da 18 a 25 anni', value: 'FROM_18_TO_25'},
    //     {label: 'Da 26 a 40 anni', value: 'FROM_26_TO_40'},
    //     {label: 'Da 41 a 60 anni', value: 'FROM_41_TO_60'},
    //     {label: 'Da 61 a 80 anni', value: 'FROM_61_TO_80'},
    //     {label: 'Oltre i 80 anni', value: 'FROM_81_TO_END'}
    //   ]
    // }] : []),
    // ...(summaryConfiguration?.kpiIdentifiers.doctorsRegion ? [{
    //   id: 'doctorRegion',
    //   label: 'Filtra medici per regione di residenza',
    //   type: 'select',
    //   options: ITALIAN_REGIONS
    // }] : []),
    {
      id: 'date',
      label: 'Data',
      type: 'rangePicker'
    }
  ]

  const onSubmit = (newFilters: any) => {
    const filtersValues = newFilters.reduce((result: any, item: any) => {
      if (item.type === 'rangePicker') {
        result.startDate = item.value?.[0]
        result.endDate = item.value?.[1]
        return result
      }
      result[item.property] = item.value
      return result
    }, {})
    setFilters(filtersValues)
  }

  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [statusDownload, setStatusDownload] = useState<string>()
  const {firstName: userFirstName, lastName: userLastName} = useAppSelector(state => state.userReducer.data)

  const handleModalView = async (row: any) => {
    setOpenViewModal(true)
    const fileResponse = await apiClient.generatePdf(`${new URL(window.location.href).origin}/${PATHS.PDF_SUMMARY}?filters=${JSON.stringify({filters})}`, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: 'application/pdf'})
    setFile((window.URL || window.webkitURL).createObjectURL(blob))
    setFileName(`Riepilogo - ${userFirstName} ${userLastName}`)
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])

  return (
    <>
      <LayoutContainer
        breadcrumbs={[{label: 'Riepilogo', href: PATHS.SUMMARY}]}
        buttons={[
          summaryConfiguration.enableExport && <PrimaryButton key={'export'} onClick={handleModalView}>{'Esporta'}</PrimaryButton>,
          <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
        ]}
      >
        <FileViewerModal
          MIMETypeValue={'application/pdf'}
          fileName={fileName}
          filepath={file}
          handleClose={() => { setOpenViewModal(false); setFile(undefined); setFileName(undefined) }}
          open={openViewModal}
          status={statusDownload}
        />
        <HomeStyled>
          {summaryConfiguration.averagePatientTrend &&
            <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Andamento medio pazienti'}</Typography>}>
              <div className='card-container'>
                <div className='card-aderenza'>
                  {
                    summaryConfiguration.adherenceKeys.map((item: {label: string, key: string}) => {
                      return <BoxAderenza key={item.key} subtitle={item.label} title={adherenceValues[item.key]} />
                    })
                  }
                </div>
                <div className='text-aderenza'>
                  <Typography variant='body2'>
                    {
                      summaryConfiguration.adherenceDescription
                    }
                  </Typography>
                </div>
              </div>
            </CardCustom>}
          <Grid className='chart-grid-container' columnSpacing={theme.spacing(8)} columns={{xs: 4, sm: 8, md: 12}} container rowSpacing={theme.spacing(4)}>
            {summaryConfiguration?.kpiIdentifiers.patientStatus &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per stato'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientStatus}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientServices &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Distribuzione dei pazienti per tipologia di servizio'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientServices}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientSex &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per genere'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientSex}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientCurrentAge &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per fasce d\'età'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientCurrentAge}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {/* {summaryConfiguration?.kpiIdentifiers.doctorAge &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Medici per fasce d\'età'}</Typography>}>
                  <Chart
                    colors={colors}
                    data={doctorAge}
                    legend
                    type='pie'
                  />
                </CardCustom>
              </Grid>} */}
            {summaryConfiguration?.kpiIdentifiers.mdsActive &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Attivazioni MDS'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={mdsActive}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.skippedAssumption &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Distribuzione delle infusioni saltate'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={skippedAssumption}
                        legend={false}
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientDrug &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per farmaco'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientDrug}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {/* {summaryConfiguration?.kpiIdentifiers.doctorStatus &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Medici per stato'}</Typography>}>
                  <Chart
                    colors={colors}
                    data={doctorStatus}
                    legend
                    type='pie'
                  />
                </CardCustom>
              </Grid>} */}
            {summaryConfiguration?.kpiIdentifiers.patientVialDosages &&
              <Grid item md={6} sm={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Fiala in uso'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientVialDosages}
                        legend
                        type='pie'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientRegionResidence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero Pazienti per regione'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientRegionResidence}
                        type='column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.assumptionsRegion &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero di infusioni per regione del paziente'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={assumptionsRegion}
                        type='column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientAdherenceActivations &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero di adesioni/attivazioni per mese'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientAdherenceActivations}
                        type='column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.doctorRegionResidence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero Medici per regione'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={doctorRegionResidence}
                        type='column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.patientMonthlyAdherence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza per mese'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={patientMonthlyAdherence}
                        percentage
                        type='stacking-column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.regionAdherence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza per regione'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={regionAdherence}
                        percentage
                        type='stacking-column'

                      />
                  }
                </CardCustom>
              </Grid>}
            {summaryConfiguration?.kpiIdentifiers.monthlyDrugAdherence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza tutor per mese per farmaco'}</Typography>}>
                  {
                    status === STATUS.PENDING ?
                      <Loader /> :
                      <Chart
                        colors={colors}
                        data={monthlyDrugAdherence}
                        percentage
                        type='column'
                      />
                  }
                </CardCustom>
              </Grid>}
            {/* {summaryConfiguration?.kpiIdentifiers.monthlyDoctorAdherence &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Adesioni medici per mese'}</Typography>}>
                  <Chart
                    colors={colors}
                    data={doctorMonthlyAdherence}
                    type='area'
                  />
                </CardCustom>
              </Grid>} */}
            {/* {summaryConfiguration?.kpiIdentifiers.patientDisabled &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti disattivati'}</Typography>}>
                  <Chart
                    colors={colors}
                    data={patientDisabled}
                    type='area'
                  />
                </CardCustom>
              </Grid>} */}
            {/* {summaryConfiguration?.kpiIdentifiers.doctorDisabled &&
              <Grid item md={12}>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Medici disattivati'}</Typography>}>
                  <Chart
                    colors={colors}
                    data={doctorDisabled}
                    type='area'
                  />
                </CardCustom>
              </Grid>} */}
          </Grid>
        </HomeStyled>
      </LayoutContainer>
    </>
  )
}

export default Home
