import {PropsWithChildren} from 'react'
import {PrimaryButtonStyled} from './PrimaryButtonStyled'
import {Button, ButtonProps} from '@mui/material'

const PrimaryButton: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...other
}) => {
  return (
    <PrimaryButtonStyled>
      <Button {...other} variant='contained'>
        {children}
      </Button>
    </PrimaryButtonStyled>
  )
}

export default PrimaryButton
