import {IconButton, Typography} from '@mui/material'
import {Divider, Form, Select, Input, DatePicker, FormInstance} from 'antd'
import {FormattedMessage} from 'react-intl'
import locale from 'antd/es/date-picker/locale/it_IT'
import {UserDataStyled} from './UserDataStyled'
import {mdiAccount} from '@mdi/js'
import Icon from '@mdi/react'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import {useState} from 'react'
import DepartMentDialog from '../DepartmentDialog/DepartmentDialog'
import apiClient from 'src/services/apiClient'

type UserDataType = {
  form?: FormInstance<any>;
  patologies?: Record<string, any>[],
  profileConfiguration: Record<string, { hidden: boolean, readOnly?: boolean }>,
  readOnly?: boolean
}

const UserData: React.FC<UserDataType> = ({
  form,
  patologies = [],
  profileConfiguration,
  readOnly = false
}) => {
  const [department, setDepartment] = useState<Record<string, any>>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const handleOpenDialog = async () => {
    const medicalCenterId = form?.getFieldValue('medicalCenterId')
    const data = await apiClient.getMedicalCenterDetail(medicalCenterId, undefined)
    setDepartment(data)
  }

  return (
    <UserDataStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            <FormattedMessage id='mainData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-row-first'>
            {!profileConfiguration?.title?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Titolo'
                  name='title'
                >
                  <Select
                    allowClear
                    disabled={profileConfiguration?.title?.readOnly || readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        label: 'Sig.',
                        value: 'signore'
                      },
                      {
                        label: 'Sig.na',
                        value: 'signora'
                      },
                      {
                        label: 'Sig.ra',
                        value: 'signorina'
                      },
                      {
                        label: 'Dott.',
                        value: 'dottore'
                      },
                      {
                        label: 'Dott.ssa',
                        value: 'dottoressa'
                      },
                      {
                        label: 'Prof.',
                        value: 'professore'
                      },
                      {
                        label: 'Prof.ssa',
                        value: 'professoressa'
                      }
                    ]}
                  />
                </Form.Item>
              </div>}
            {!profileConfiguration?.lastName?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Cognome'
                  name='lastName'
                  required
                >
                  <Input disabled={profileConfiguration?.lastName?.readOnly || readOnly} />
                </Form.Item>
              </div>}
            {!profileConfiguration?.firstName?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Nome'
                  name='firstName'
                  required
                >
                  <Input disabled={profileConfiguration?.firstName?.readOnly || readOnly} />
                </Form.Item>
              </div>}
          </div>

          <div className={(!profileConfiguration?.consuntiveCode?.hidden && !profileConfiguration?.sex?.hidden &&
            !profileConfiguration?.nationality?.hidden && !profileConfiguration?.birthDate?.hidden) ? 'container-body-row-second' : 'container-body-row'}
          >
            {!profileConfiguration?.consuntiveCode?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Codice Consultivo'
                  name='consuntiveCode'
                >
                  <Input disabled={profileConfiguration?.consuntiveCode?.readOnly || readOnly} />
                </Form.Item>
              </div>}
            {!profileConfiguration?.sex?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Sesso'
                  name='sex'
                >
                  <Select
                    allowClear
                    disabled={profileConfiguration?.sex?.readOnly || readOnly}
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        value: 'MALE',
                        label: 'Maschio'
                      },
                      {
                        value: 'FEMALE',
                        label: 'Femmina'
                      },
                      {
                        value: 'NOT_DECLARED',
                        label: 'Non dichiarato'
                      },
                      {
                        value: 'OTHER',
                        label: 'Altro'
                      }
                    ]}
                  />
                </Form.Item>
              </div>}
            {!profileConfiguration?.nationality?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Nazionalità'
                  name='nationality'
                >
                  <Input disabled={profileConfiguration?.nationality?.readOnly || readOnly} />
                </Form.Item>
              </div>}

            {!profileConfiguration?.birthDate?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Data di Nascita'
                  name='birthDate'
                >
                  <DatePicker
                    disabled={profileConfiguration?.birthDate?.readOnly || readOnly}
                    format='DD MMM YYYY'
                    locale={locale}
                    placeholder=''
                  />
                </Form.Item>
              </div>}

            {!profileConfiguration?.specialization?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Specializzazione'
                  name='specialization'
                >
                  <Input disabled={profileConfiguration?.specialization?.readOnly || readOnly} />
                </Form.Item>
              </div>}
            {!profileConfiguration?.medicalCenterId?.hidden &&

              <div className='container-body-element  container-body-element-icon'>
                <Form.Item
                  label='Centro Medico'
                  name='medicalCenter'
                  required
                >
                  <Input disabled={profileConfiguration?.medicalCenterId?.readOnly || readOnly} onClick={handleOpenDialog} />
                </Form.Item>
                <IconButton onClick={handleOpenDialog}>
                  <Icon path={mdiAccount} size={1} />
                </IconButton>
              </div>}
          </div>

          <div className='container-body-row'>

            {!profileConfiguration?.prescribingDoctorId?.hidden &&
              <div className='container-body-element container-body-element-icon'>
                <Form.Item
                  label='Medico prescrittore'
                  name='prescribingDoctor'
                >
                  <Input disabled={profileConfiguration?.prescribingDoctorId?.readOnly || readOnly} />
                </Form.Item>
                <IconButton disabled={profileConfiguration?.prescribingDoctorId?.readOnly || readOnly}>
                  <Icon path={mdiAccount} size={1} />
                </IconButton>
              </div>}
            {!profileConfiguration?.createdAt?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Data di inserimento'
                  name='createdAt'
                >
                  <DatePicker
                    disabled={profileConfiguration?.createdAt?.readOnly || readOnly}
                    format='DD MMM YYYY'
                    locale={locale}
                    placeholder=''
                  />
                </Form.Item>
              </div>}
            {(profileConfiguration?.fiscalCode && !profileConfiguration?.fiscalCode?.hidden) &&
              <div className='container-body-element'>
                <Form.Item
                  label='Codice fiscale'
                  name='fiscalCode'
                >
                  <Input disabled={profileConfiguration?.fiscalCode?.readOnly || readOnly} />
                </Form.Item>
              </div>}
          </div>
        </div>
      </div>
      {!profileConfiguration?.diseases?.hidden &&
        <div className='container'>
          <div className='container-title'>
            <Typography variant='h4'>
              <FormattedMessage id='pathology' />
            </Typography>
          </div>
          <TableCustom
            columns={[{id: 'name', label: 'Nome'}, {id: 'description', label: 'Descrizione'}]}
            onPaginationChange={setPagination}
            pagination={pagination}
            rows={patologies}
            showPagination={false}
            totalElements={20}
          />
        </div>}
      {department && <DepartMentDialog department={department} onClose={() => setDepartment(undefined)} />}
    </UserDataStyled>
  )
}

export default UserData
