/* eslint-disable react-hooks/exhaustive-deps */
import {View, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import React, {useState} from 'react'
import CalendarToolbar from 'src/components/elements/CalendarToolbar/CalendarToolbar'
import {CalendarStyled} from './CalendarStyled'
import CalendarDialog from '../CalendarDialog/CalendarDialog'
import {IconButton, Modal, Popover, Typography} from '@mui/material'
import Icon from '@mdi/react'
import {mdiAccountMultiple, mdiCalendarMonth, mdiClose} from '@mdi/js'
import {CalendarPaperStyled} from './CalendarPaperStyled'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {useAppSelector} from 'src/hooks'
import VirtualMeetingDetail from 'src/components/elements/VirtualMeetingsDetail/VirtualMeetingDetail'

const localizer = momentLocalizer(moment)

const dateTimeFormat = new Intl.DateTimeFormat('it', {weekday: 'short', day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false})

export type CalendarEvent = {
  _id: string;
  startDate: string;
  endDate: string;
  title: string,
  participants: Record<string, any>[],
  organizer?: Record<string, any>,
  type?: string,
  site?: string;
  shareWithPatient?: boolean;
  link?: string,
  activityStatus?: string,
  channels?: string[];
  reminderMilliseconds?: number,
  warning?: {
    value: number,
    unit: number
  },
  user?: string
}

type CalendarCustomProps = {
  events: CalendarEvent[],
  eventsTypes: Record<string, any>[],
  onSave: (event: CalendarEvent) => void,
  onUpdate: (event: CalendarEvent, id: string | undefined) => void,
  startDate: Date,
  setStartDate: any,
  endDate: Date,
  setEndDate: any
}

const EventBox = (event: CalendarEvent) => {
  const patient = event.participants?.find((user: Record<string, any>) => user.type === 'patients' && user?.id)
  return (
    <div className='event-box'>
      <div>
        <div id='event-chip' />
        <Typography style={{fontSize: '11px', fontWeight: 600}}>
          <span>{`${moment(event.startDate).format('HH:mm')} - ${moment(event.endDate).format('HH:mm')}`}</span>
        </Typography>
      </div>

      <Typography className='event-reason' variant='body2'>
        <span>{patient ? `${patient.firstName} ${patient.lastName} - ${event.title}` : event.title}</span>
      </Typography>
    </div>
  )
}

const AgendaEventBox = (event: Record<string, any>) => {
  return (
    <div className='event-box'>
      <Typography className='event-reason' variant='body2'>
        <span className='time-label'>{`${moment(event?.startDate).format('HH:mm')} - ${moment(event?.endDate).format('HH:mm')}`}</span>
        <span className='event-chip' />
        <span className='title-label'>{event.title}</span>
      </Typography>
    </div>
  )
}

const DateBox = (day: Date) => {
  return (
    <div className='date-box'>
      <Typography className='event-reason' variant='body2'>
        <span>{moment(day).format('ddd')}</span>
      </Typography>
      <Typography className='event-reason' variant='body2'>
        <span>{moment(day).format('DD MMMM YYYY')}</span>
      </Typography>
    </div>
  )
}

const CalendarCustom: React.FC<CalendarCustomProps> = ({
  events,
  eventsTypes,
  onSave,
  onUpdate,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const [calendarView, setCalendarView] = useState<View>('month')
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()
  const [isEventModal, setIsEventModal] = useState<boolean>(false)
  const [newEvent, setNewEvent] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [openOnDemandPopover, setOpenOnDemandPopover] = useState<any>(false)
  const [openVirtualMeetingDetails, setOpenVirtualMeetingDetails] = useState<any>(false)
  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)

  const handleViewChange = (view: View) => {
    setCalendarView(view)
  }

  const startOfMonth = (startDateOfRange: Date) => {
    if (moment(startDateOfRange).toISOString() === moment(startDateOfRange).startOf('month').toISOString()) {
      return moment(startDateOfRange).toISOString()
    } else {
      return moment(startDateOfRange).endOf('month').toISOString()
    }
  }

  const endOfMonth = (endDateOfRange: Date) => {
    if (moment(endDateOfRange).toISOString() === moment(endDateOfRange).endOf('month').toISOString()) {
      return moment(endDateOfRange).toISOString()
    } else {
      return moment(endDateOfRange).startOf('month').toISOString()
    }
  }

  const handleDateChange = (date: Date) => {
    if (!(startOfMonth(startDate) <= moment(date).toISOString() && moment(date).toISOString() <= endOfMonth(endDate))) {
      setStartDate(moment(date).startOf('month').startOf('week'))
      setEndDate(moment(date).endOf('month').endOf('week'))
    }
    setCalendarDate(new Date(date))
  }

  const calendarComponents = {
    toolbar: () => CalendarToolbar(
      {
        date: calendarDate,
        view: calendarView,
        handleDateChange: handleDateChange,
        handleViewChange: handleViewChange
      }),
    week: {
      event: ({event} : any) => EventBox(event)
    },
    day: {
      event: ({event} : any) => EventBox(event)
    },
    month: {
      event: ({event} : any) => EventBox(event)
    },
    agenda: {
      event: ({event} : any) => AgendaEventBox(event),
      date: ({day}: any) => DateBox(day)
    }
  }

  const updateSelectedEvent = (event: any, range: boolean) => {
    setNewEvent(range)
    range ?
      setSelectedEvent({
        _id: '',
        startDate: event.start.toISOString(),
        endDate: event.end.toISOString(),
        participants: [],
        title: '',
        organizer: userInfoData
      }) :
      setSelectedEvent({...event})
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    setOpenOnDemandPopover(false)
  }

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClosePopover}
        open={openOnDemandPopover}
        style={{zIndex: '1000'}}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {selectedEvent &&
          <CalendarPaperStyled>
            <div className='popover-title'>
              {selectedEvent?.title}
              <div className='icon-popover'>
                <IconButton
                  onClick={() => handleClosePopover()}
                >
                  <Icon path={mdiClose} size={'12px'} />
                </IconButton>
              </div>
            </div>
            <hr className='hr-popover' />
            <div className='popover-container'>
              <div className='popover-row'>
                <div className='icon-popover'>
                  <Icon path={mdiCalendarMonth} size={'12px'} />
                </div>
                {dateTimeFormat.format(new Date(selectedEvent.startDate))}
              </div>
              <div className='popover-row'>
                <div className='icon-popover'>
                  <Icon path={mdiAccountMultiple} size={'12px'} />
                </div>
                {selectedEvent.participants &&
                  selectedEvent.participants.map((participant: any, index: any) => {
                    return (
                      <div className='personalData-popover' key={index}>
                        <div>{participant.firstName}</div>
                        <div>{participant.lastName}</div>
                      </div>
                    )
                  })}
              </div>
              <div className='popover-row'>
                <SecondaryButton
                  onClick={() => setOpenVirtualMeetingDetails(true)}
                >
                  <Icon path={mdiCalendarMonth} size={'12px'} />
                  {'Dettaglio'}
                </SecondaryButton>
              </div>
            </div>
          </CalendarPaperStyled>}
      </Popover>
      <CalendarStyled
        components={calendarComponents}
        culture='it'
        date={calendarDate}
        dayLayoutAlgorithm='no-overlap'
        endAccessor={(event: any) => new Date(event.endDate)}
        events={events}
        localizer={localizer}
        onNavigate={handleDateChange}
        onSelectEvent={(event: any, target: any) => {
          setAnchorEl(target.currentTarget)
          updateSelectedEvent(event, false)
          if (event.type !== 'VIRTUAL_MEETING') {
            setIsEventModal(true)
          } else {
            setOpenOnDemandPopover(true)
          }
        }}
        onSelectSlot={(event: any) => {
          updateSelectedEvent(event, true)
          setIsEventModal(true)
        }}
        onView={handleViewChange}
        scrollToTime={new Date(calendarDate.setHours(7))}
        selectable
        startAccessor={(event: any) => new Date(event.startDate)}
        style={{height: 'calc(100vh - 180px)'}}
        view={calendarView}
      />
      {isEventModal &&
        <CalendarDialog
          event={selectedEvent}
          eventsTypes={eventsTypes}
          onClose={() => setIsEventModal(false)}
          onSave={(values) => {
            newEvent ? onSave(values) : onUpdate(values, selectedEvent?._id)
            setIsEventModal(false)
          }}
        />}
      <Modal
        onClose={() => setOpenVirtualMeetingDetails(false)}
        open={openVirtualMeetingDetails}
      >
        <VirtualMeetingDetail
          event={selectedEvent}
          handleClose={() => setOpenVirtualMeetingDetails(false)}
        />
      </Modal>
    </>
  )
}

export default CalendarCustom
