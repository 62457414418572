import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {MedicalCenterDialogStyled} from './MedicalCenterDialogStyled'
import {FormattedMessage} from 'react-intl'
import {Divider, Typography} from '@mui/material'
import {Checkbox, Select} from 'antd'

enum MEDICAL_CENTER_TYPE {
  ASL = 'ASL',
  HOSPITAL = 'Ospedale',
  OTHER = 'Altro'
}

type MedicalCenterDialogProps = {
  medicalCenter: {
    type?: MEDICAL_CENTER_TYPE;
    name?: string;
    code?: string,
    phoneNumber?: string,
    faxNumber?: string,
    email?: string,
    country?: string,
    region?: string,
    province?: string,
    city?: string,
    address?: string,
    streetNumber?: string,
    postalCode?: string,
    locality?: string
  },
  onClose: () => void;
};

const MedicalCenterDialog: React.FC<MedicalCenterDialogProps> = ({medicalCenter, onClose}) => {
  const body = (
    <MedicalCenterDialogStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h5'>
            <FormattedMessage id='mainData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-element'>
            <Typography variant='body2'>
              <FormattedMessage id='type' />{'*'}
            </Typography>
            {medicalCenter.type &&
              <Checkbox.Group
                defaultValue={[medicalCenter.type]}
                disabled
                options={[
                  {value: MEDICAL_CENTER_TYPE.HOSPITAL, label: 'Ospedale'},
                  {value: MEDICAL_CENTER_TYPE.ASL, label: 'ASL'},
                  {value: MEDICAL_CENTER_TYPE.OTHER, label: 'Altro'}]}
              />}
          </div>
          <div className='container-body-element'>
            <Typography variant='body2'>
              <FormattedMessage id='name' />{'*'}
            </Typography>
            <div className='container-body-value'>
              <Typography variant='body2'>
                {medicalCenter.name}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='container-title'>
          <Typography variant='h5'>
            <FormattedMessage id='contactsData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-row'>
            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='code' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.code}
                </Typography>
              </div>
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='phone' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.phoneNumber}
                </Typography>
              </div>
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='fax' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.faxNumber}
                </Typography>
              </div>
            </div>
          </div>

          <div className='container-body-element'>
            <Typography variant='body2'>
              <FormattedMessage id='email' />
            </Typography>
            <div className='container-body-value'>
              <Typography variant='body2'>
                {medicalCenter.email}
              </Typography>
            </div>
          </div>

          <div className='container-body-row container-body-row-column'>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='country' />
              </Typography>
              <Select
                disabled
                value={medicalCenter.country}
              />
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='county' />
              </Typography>
              <Select
                disabled
                value={medicalCenter.region}
              />
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='province' />
              </Typography>
              <Select
                disabled
                value={medicalCenter.province}
              />
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='city' />
              </Typography>
              <Select
                disabled
                value={medicalCenter.city}
              />
            </div>
          </div>

          <div className='container-body-row container-body-row-long'>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='address' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.address}
                </Typography>
              </div>
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='civic' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.streetNumber}
                </Typography>
              </div>
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='postalCode' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.postalCode}
                </Typography>
              </div>
            </div>

            <div className='container-body-element'>
              <Typography variant='body2'>
                <FormattedMessage id='place' />
              </Typography>
              <div className='container-body-value'>
                <Typography variant='body2'>
                  {medicalCenter.locality}
                </Typography>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h5'>
            <FormattedMessage id='othersData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body' />
      </div>
    </MedicalCenterDialogStyled>
  )

  return (
    <DialogCustom
      body={body}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: medicalCenter.name || '', format: false}}
    />
  )
}

export default MedicalCenterDialog
