import {styled} from '@mui/system'

export const FirstLoginHeaderStyled = styled('div')(({theme}) => ({
  height: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  paddingRight: theme.spacing(8),
  gap: theme.spacing(4),
  width: '100%',
  zIndex: '15',
  backgroundColor: theme.palette.primary.main,

  '.logo': {
    backgroundColor: 'white',
    height: '100%',

    img: {
      height: '100%'
    }
  }
}))
