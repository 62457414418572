import {styled} from '@mui/system'

export const CommunicationDialogStyled = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.neutral[100]}`,
  borderRadius: theme.spacing(1),
  paddingBottom: theme.spacing(18),
  '.container': {
    '&-title': {
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`
    },
    '.MuiDivider-root': {
      borderColor: theme.palette.neutral[100]
    },
    '&-body': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(7.5),
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`,
      '&-element': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5)
      },
      '&-value': {
        cursor: 'no-drop',
        minHeight: theme.spacing(18),
        padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
        backgroundColor: theme.palette.neutral[100],
        '&-long': {
          minHeight: theme.spacing(101)
        }
      }
    }
  }
}))
