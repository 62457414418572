import React from 'react'
import HeaderMenu from 'src/components/elements/Menus/HeaderMenu/HeaderMenu'
import {HeaderStyled} from './HeaderStyled'

const Header: React.FC<{showMenu?: boolean, logoImage?: any}> = ({showMenu = true, logoImage}) => {
  return (
    <HeaderStyled className='header'>
      <div style={{display: 'flex', gap: '32px'}}>
        <div className='logo'>
          <img alt='IA logo' src={'/assets/images/logo_IA.png'} />
        </div>
        {logoImage &&
          <div className='logo'>
            <img alt='IA logo' src={`data:image/png;base64,${logoImage}`} />
          </div>}
      </div>
      {showMenu && <HeaderMenu />}
    </HeaderStyled>
  )
}

export default Header
