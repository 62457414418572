import {mdiBellRing, mdiDoctor, mdiDomain, mdiNavigation, mdiTooltipAccount} from '@mdi/js'
import Icon from '@mdi/react'
import {Typography} from '@mui/material'
import {PatientSummaryStyled, SnackbarStyled} from './PatientSummaryStyled'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import {useAppSelector} from 'src/hooks'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import {CalendarEvent} from 'src/components/templates/Calendar/Calendar'

const timeFormat = new Intl.DateTimeFormat('it', {hour: 'numeric', minute: 'numeric'})

const dateFormat = new Intl.DateTimeFormat('it', {weekday: 'short', day: '2-digit', month: 'short'})

const yearFormat = new Intl.DateTimeFormat('it', {year: 'numeric'})

const parseStates = (state: any) => {
  switch (state) {
    case 'TO_ACTIVE':
      return 'Da attivare'
    case 'ACTIVATING':
      return 'In attivazione'
    case 'ACTIVE':
      return 'Attivo'
    case 'ACTIVE_DELIVERY':
      return 'Attivo delivery'
    case 'SUSPENDED':
      return 'Sospeso'
    case 'WAITING_TO_EXIT':
      return 'In attesa di uscita'
    case 'EXITED':
      return 'Drop-out'
    default:
      return '-'
  }
}

function PatientSummary () {
  const [patientTimelineData, setPatientTimelineData] = useState<CalendarEvent[]>([])
  const [status, setStatus] = useState<any>(undefined)
  const [appointmentIndexAfterToday, setAppointmentIndexAfterToday] = useState<number>(0)
  const [avatarImageSrc, setAvatarImageSrc] = useState<string>()
  const [medicalCenter, setMedicalCenter] = useState<Record<string, any>>()

  const userInfo = useAppSelector(({userReducer}) => userReducer.data)

  const fetchData = async () => {
    const fetchedData = await apiClient.getPersonalEvents(setStatus) ?? []
    setAppointmentIndexAfterToday(fetchedData.findLastIndex((element: any) => element.startDate > new Date().toISOString()))
    setPatientTimelineData(fetchedData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchAvatar = async () => {
    if (userInfo?.avatarId) {
      const fetchedData = await apiClient.getByIdAvatars(userInfo?.avatarId) ?? []
      setAvatarImageSrc(fetchedData.imageBase64)
    }
  }

  const fetchMedicalCenter = async () => {
    if (userInfo?.medicalCenterId) {
      const fetchedData = await apiClient.getMedicalCenterDetail(userInfo?.medicalCenterId, setStatus)
      setMedicalCenter(fetchedData)
    }
  }

  useEffect(() => {
    fetchMedicalCenter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.medicalCenterId])

  useEffect(() => {
    fetchAvatar()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.avatarId])

  return (
    <PatientSummaryStyled>
      <div>
        <SnackbarStyled
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => setStatus(undefined)}
          open={status === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => setStatus(undefined)} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Errore nel recuperare i dati.'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </SnackbarStyled>
        <div className='layout-container-header'>
          <div className='userDashboard'>
            <div className='userDet'>
              <div className='avatar'>
                <div className='btn btn-rounded btn-default btn-lg btn-icon waves-effect waves-light' style={{cursor: 'default'}}>
                  <div className='trasparency'>
                    {avatarImageSrc ?
                      <img
                        alt='avatar'
                        className='img-responsive'
                        src={`data:image/png;base64,${avatarImageSrc}`}
                      /> :
                      <Icon color={'black'} path={mdiTooltipAccount} size={2} />}
                  </div>
                </div>
              </div>
              <div>
                <p style={{margin: 0}}>{'Benvenuto'}</p>
                <Typography style={{marginTop: 0, marginBottom: '20px'}} variant='h4'>{userInfo.avatarNickName || '-'}</Typography>
                <div className='label'>{`Stato: ${parseStates(userInfo.state)}`}</div>
              </div>
            </div>
            <div className='doctDet'>
              <div className='d-flex'>
                <div className='icon-container icon-grey'>
                  <Icon path={mdiDoctor} size={'40px'} />
                </div>
                <div className='text-container'>
                  <p style={{margin: 0, fontSize: '12px'}}>{'Il tuo medico'}</p>
                  <p style={{margin: 0, fontSize: '14px', fontWeight: 600}}>{userInfo.prescribingDoctor || '-'}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='icon-container purple-border'>
                  <Icon path={mdiDomain} size={'40px'} />
                </div>
                <div className='text-container'>
                  <p style={{margin: 0}}>{userInfo.medicalCenter || '-'}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='icon-container blue-border'>
                  <Icon className='icon-trasform-blue' path={mdiNavigation} size={'40px'} />
                </div>
                <div className='text-container'>
                  <p style={{margin: 0}}>{`${medicalCenter?.address ? `${medicalCenter.address},` : ''}
                  ${medicalCenter?.streetNumber ? `${medicalCenter.streetNumber},` : ''} 
                  ${medicalCenter?.city ? `${medicalCenter.city}` : ''}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='layout-body'>
          <Timeline>
            {patientTimelineData.length > 0 && patientTimelineData.map((timelineItem: any, index: number) => {
              return (
                <>
                  {index === appointmentIndexAfterToday + 1 && <div className='label today-button'>{'Oggi'}</div>}
                  <TimelineItem key={index}>
                    <TimelineOppositeContent
                      align='right'
                    >
                      <div className='date-container'>
                        <div>
                          {dateFormat.format(new Date(timelineItem.startDate))}
                        </div>
                        {yearFormat.format(new Date(timelineItem.startDate))}
                      </div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector className='timeline-color-connector' />
                      <TimelineDot className='timeline-color-dot' variant='outlined' />
                      <TimelineConnector className='timeline-color-connector' />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div className='appointment-container'>
                        <span className='arrow left pull-up hidden-left' />
                        <div className='app-container'>
                          <div className='app-type-container'>
                            <Icon path={mdiBellRing} size={'40px'} />
                            {timelineItem.title}
                          </div>
                          <div className='time-container'>
                            <div>
                              {'Ore '}
                              {timeFormat.format(new Date(timelineItem.startDate))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                  {(index + 1 === patientTimelineData.length) && (patientTimelineData.length === appointmentIndexAfterToday + 1) && <div className='label today-button'>{'Oggi'}</div>}
                </>
              )
            })}
          </Timeline>
        </div>
      </div>
    </PatientSummaryStyled>
  )
}

export default PatientSummary
