import {Paper} from '@mui/material'
import {styled} from '@mui/system'

export const TableHeaderStyled = styled(Paper)(({theme}) => ({
  padding: `${theme.spacing(7.5)}`,
  border: 'solid 1px #dee5e7',
  borderRadius: 0,

  '.header-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))
