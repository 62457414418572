import {AxiosInstance, AxiosRequestConfig} from 'axios'
import {STATUS} from 'src/constants/constants'
import {dispatch} from 'src/redux-store'

async function apiClientRequest (
  httpClient: AxiosInstance,
  action: any,
  config?: AxiosRequestConfig,
  setStatus?: any,
  responseWithHeaders = false
) {
  if (action) {
    dispatch(action({httpClient, config}))
  } else {
    if (config) {
      if (setStatus) {
        const httpCallFunction = async () => {
          try {
            const response = await httpClient.request<any>(config)
            setStatus(STATUS.FULFILLED)
            if (responseWithHeaders) { return response }
            return response.data
          } catch {
            setStatus(STATUS.REJECTED)
          }
        }
        setStatus(STATUS.PENDING)

        return await httpCallFunction()
      }
      const response = await httpClient.request<any>(config)
      if (responseWithHeaders) { return response }
      return response.data
    }
  }
}

export default apiClientRequest
