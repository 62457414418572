import {styled} from '@mui/system'

export const CaregiversDialogStyle = styled('div')(({theme}) => ({
  borderRadius: theme.spacing(1),
  paddingBottom: theme.spacing(18),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  height: '100%'
}))
