import {Button, Snackbar, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import LoginTextField from 'src/components/elements/TextFields/LoginTextField/LoginTextField'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'
import {PasswordRecoveryPageStyled} from './PasswordRecoveryPageStyled'
import {Link} from 'react-router-dom'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'

const EMAIL_REGEX = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

const PasswordRecoveryPage = () => {
  const [user, setUser] = useState({identifier: '', email: ''})
  const [config, setConfig] = useState<any>()
  const [statusLogin, setStatusLogin] = useState<string>()

  const handleTextFieldChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
    if (statusLogin === STATUS.REJECTED) {
      setStatusLogin(undefined)
    }
  }

  const handleSubmit = () => {
    if (user.identifier.length < 6 || !user.email.match(EMAIL_REGEX)) {
      setStatusLogin(STATUS.REJECTED)
    } else {
      apiClient.changePassword(user, setStatusLogin)
    }
  }

  useEffect(() => {
    async function getLoginConfig () {
      const response = await apiClient.loginConfiguration()
      setConfig(response)
    }
    getLoginConfig()
  }, [])

  useEffect(() => {
    if (statusLogin === STATUS.FULFILLED) {
      setTimeout(() => {
        window.location.href = `${window.location.protocol}//${window.location.host}/login`
      }, 3000)
    }
  }, [statusLogin])

  return (
    config ?
      <PasswordRecoveryPageStyled>
        <div className='login-form-container'>
          {config.logoIA &&
            <div className='img-container'>
              <img alt='logoIA' className='img-style' src={`data:image/png;base64,${config.logoIA}`} />
            </div>}
          {config.logoPSP &&
            <div className='img-container'>
              <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
            </div>}
          <div className='form-container'>
            <div className='form-item'>
              <Typography className='form-text' variant='body2'>{'Utente*'}</Typography>
              <LoginTextField
                error={statusLogin === STATUS.REJECTED}
                loginConfiguration={config}
                name='identifier'
                onChange={handleTextFieldChange}
                placeholder='Username'
                value={user.identifier}
                variant='outlined'
              />
            </div>
            <div className='form-item'>
              <Typography className='form-text' variant='body2'>{'Email*'}</Typography>
              <LoginTextField
                error={statusLogin === STATUS.REJECTED}
                loginConfiguration={config}
                name='email'
                onChange={handleTextFieldChange}
                placeholder='Email'
                type='email'
                value={user.email}
                variant='outlined'
              />
            </div>
            <Button
              className='send-button'
              onClick={handleSubmit}
              sx={{
                backgroundColor: config.primaryColor,
                '&:hover': {
                  backgroundColor: config.primaryColor
                }
              }}
              variant='contained'
            >
              {'Send'}
            </Button>
            <Link to={PATHS.LOGIN}>
              <Typography className='form-text' variant='body2'>{'Torna a login'}</Typography>
            </Link>
            <div className='sponsored-containter'>
              {'Sponsored by'}
              <img alt='logoPSP' className='img-sponsored' src={`data:image/png;base64,${config.logoPSP}`} />
            </div>
          </div>
        </div>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={statusLogin === STATUS.REJECTED}>
          <div>
            <SnackbarAlertCustom onClose={() => setStatusLogin(undefined)} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>{'Richeista recupero password fallita'}</Typography>
              {'Alcuni dati inseriti non sono corretti.'}
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
        <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={3000} open={statusLogin === STATUS.FULFILLED}>
          <div>
            <SnackbarAlertCustom onClose={() => setStatusLogin(undefined)} severity='success' sx={{width: '100%'}}>
              <Typography variant='h5'>{'Richeista recupero password completata con successo'}</Typography>
              {'A breve riceverai un\'email con il link per procedere alla modifica della tua password'}
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
      </PasswordRecoveryPageStyled> :
      <></>
  )
}

export default PasswordRecoveryPage
