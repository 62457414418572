
import {Snackbar} from '@mui/material'
import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const PatientSummaryStyled = styled('div')(({theme}) => ({

  height: '100%',
  gridArea: 'main',
  position: 'relative',
  width: `calc(100vw - ${theme.spacing(STYLE.SIDEBAR_WIDTH)})`,
  zIndex: '10',
  overflowX: 'hidden',

  '.layout-container-header': {
    height: `${theme.spacing(70)}`,
    backgroundColor: 'white',
    borderRadius: `${theme.spacing(20)} ${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)}`,
    padding: `${theme.spacing(15)} ${theme.spacing(10)} ${theme.spacing(10)} ${theme.spacing(10)}`
  },

  '.userDashboard': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },

  '.userDet': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '30%'
  },

  '.userDet .avatar': {
    width: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none'
  },

  '.avatar .btn.btn-lg.btn-icon': {
    width: '120px',
    border: 'none',
    height: '120px',
    boxShadow: 'none !important',
    borderRadius: '120px !important',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '.trasparency': {
    background: 'rgba(255,255,255,.7)',
    borderRadius: '120px !important',
    height: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center'
  },

  '.img-responsive': {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  },

  '.label': {
    textShadow: 'none',
    color: '#fff',
    fontSize: '13px',
    padding: '8px 17px',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: '10px',
    fontWeight: '600',
    display: 'initial'
  },

  '.doctDet': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    placeSelf: 'flex-start',
    paddingTop: '10px',
    width: '80%'
  },

  '.d-flex': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '20vw',
    minWidth: '15vw'
  },

  '.icon-container': {
    borderRadius: '50px',
    height: '60px',
    width: '60px',
    padding: '3%',
    borderColor: theme.palette.background.default,
    borderWidth: '3px',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '.icon-grey': {
    backgroundColor: theme.palette.background.default
  },

  '.text-container': {
    marginLeft: '20px',
    marginRight: '20px',
    fontSize: '14px !important'
  },

  '.purple-border': {
    borderRight: '3px solid #7266ba'
  },

  '.blue-border': {
    borderColor: `${theme.palette.background.default} ${theme.palette.background.default} #23b7e5 #23b7e5`,
    transform: 'rotate(-45deg)'
  },

  '.yellow-border': {
    borderColor: `${theme.palette.background.default} ${theme.palette.background.default} #fad733  ${theme.palette.background.default}`,
    transform: 'rotate(30deg)'
  },

  '.icon-trasform-blue': {
    transform: 'rotate(45deg)'
  },

  '.icon-trasform-yellow': {
    transform: 'rotate(-30deg)'
  },

  '.date-container': {
    display: 'flex',
    fontSize: '14px',
    flexDirection: 'column'
  },

  '.appointment-container': {
    height: '125px',
    backgroundColor: 'white',
    margin: '15px 0px',
    width: '35vw'
  },

  '.MuiTimelineOppositeContent-root': {
    alignSelf: 'center'
  },

  '.MuiTimeline-root': {
    width: '65vw'
  },

  '.today-button': {
    fontSize: '14px',
    textAlign: 'center',
    width: '90px',
    height: '40px',
    alignSelf: 'end',
    marginRight: 'calc(35vw - 5px)',
    paddingTop: '10px'
  },

  '.layout-body': {
    paddingTop: '40px'
  },

  '.timeline-color-connector': {
    backgroundColor: theme.palette.primary.main,
    width: '4px'
  },

  '.timeline-color-dot': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: '3px',
    padding: '2px'
  },

  '.arrow': {
    borderWidth: '9px',
    zIndex: '10',
    position: 'absolute',
    display: 'block',
    width: 0,
    height: 0,
    borderColor: 'transparent',
    borderStyle: 'solid'
  },

  '.arrow.left': {
    right: ' calc(35vw + 16px)',
    marginTop: '55px',
    borderLeftWidth: 0,
    borderRightColor: 'white'
  },

  '.arrow.pull-up': {
    top: '19px'
  },

  '.time-container': {
    height: '50px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: 'white',
    fontSize: '14px'
  },

  '.app-type-container': {
    flexGrow: 1,
    padding: '10px 15px 10px 15px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '18px',
    fontWeight: 600
  },

  '.app-container': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}))

export const SnackbarStyled = styled(Snackbar)(({theme}) => ({
  top: '70px !important'
}))
