import {Button, Typography} from '@mui/material'
import {Checkbox, Form, Input} from 'antd'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CalendarEvent} from '../Calendar/Calendar'
import {useState} from 'react'
import Icon from '@mdi/react'
import {mdiAccount, mdiClose, mdiMagnify} from '@mdi/js'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import {VirtualMeetingRequestDialogStyled} from './VirtualMeetingRequestDialogStyled'
import TextArea from 'antd/lib/input/TextArea'

type VirtualMeetingRequestDialogProps = {
  onClose: () => void,
  onSave: (values: CalendarEvent) => void;
  qualifications: Record<string, any>[]
};

const VirtualMeetingRequestDialog: React.FC<VirtualMeetingRequestDialogProps> = ({
  onClose,
  onSave,
  qualifications
}) => {
  const [form] = Form.useForm()
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [currentPatient, setCurrentPatient] = useState<Record<string, any>>()

  const onFinish = (values : any) => {
    let medicalSupportService = false
    let qualificationsArray
    if (values.qualifications && values.qualifications.length > 0) {
      qualificationsArray = [...values.qualifications]
      const index = qualificationsArray.indexOf('medicalSupportService')
      if (index !== -1) {
        qualificationsArray.splice(index, 1)
        medicalSupportService = true
      }
    }

    onSave(
      {
        ...values,
        patient: currentPatient,
        medicalSupportService,
        qualifications: qualificationsArray
      }
    )
  }

  const body = (
    <VirtualMeetingRequestDialogStyled>
      <Form
        form={form}
        id='request-form'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          className='title'
          label='Titolo'
          name='title'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='patient'
          label='Paziente'
        >
          <div className='patient-content'>
            <Form.Item
              name='patient'
              rules={[{required: true, message: 'Campo obbligatorio'}]}
            >
              <Input disabled />
            </Form.Item>
            <div className='patient-buttons'>
              <Button
                className='patient-button'
                onClick={() => setIsPatientsModal(true)}
              >
                <Icon path={mdiMagnify} size={1} />
              </Button>
              <Button
                className='patient-button'
                disabled={currentPatient === undefined}
              >
                <Icon path={mdiAccount} size={1} />
              </Button>
              <Button
                className='patient-button'
                disabled={currentPatient === undefined}
                onClick={() => form.setFieldValue('patient', undefined)}
              >
                <Icon path={mdiClose} size={1} />
              </Button>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          className='email'
          label='Email paziente'
          name='email'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='participationRequest'
          label='Richiesta la partecipazione di'
          name='qualifications'
        >
          <Checkbox.Group>
            <Checkbox key={'medicalSupportService'} value={'medicalSupportService'}>
              <Typography variant='body2'>{'Medico del Servizio'}</Typography>
            </Checkbox>
            {qualifications.map((qualification: any) => {
              return (
                <Checkbox key={qualification._id} value={qualification.name}>
                  <Typography variant='body2'>{qualification.name}</Typography>
                </Checkbox>
              )
            })}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          className='notes'
          label='Date e fasce orarie di disponibilità ed eventuali Note'
          name='notes'
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </VirtualMeetingRequestDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton
        form='request-form'
        key='submit'
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <>
      <DialogCustom
        body={body}
        footer={footer}
        maxWidth='xl'
        onClose={onClose}
        title={{text: 'requestVirtualMeeting', format: true}}
      />
      {isPatientsModal &&
        <PatientLookupDialog
          onClose={() => setIsPatientsModal(false)}
          onRowClick={(row) => {
            setCurrentPatient(row)
            form.setFieldValue('patient', `${row.firstName} ${row.lastName}`)
            form.setFieldValue('email', row.email)
            setIsPatientsModal(false)
          }}
        />}
    </>
  )
}

export default VirtualMeetingRequestDialog
