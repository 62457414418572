import {Snackbar, Typography} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import ActivitiesAgenda from 'src/components/templates/ActivitiesAgenda/ActivitiesAgenda'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {PATHS, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'

const PatientActivities = () => {
  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [events, setEvents] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [start, setStart] = useState<any>(moment().startOf('month'))
  const [end, setEnd] = useState<any>(moment().endOf('month'))
  const {drugs} = useAppSelector(state => state.pspConfigReducer.data.patientActivitiesConfigurations)

  const filterConfig: FilterItem[] = [
    {
      id: 'beneficiaryLastName',
      label: 'Cognome',
      type: 'string'
    },
    {
      id: 'beneficiaryFirstName',
      label: 'Nome',
      type: 'string'
    },
    {
      id: 'drugId',
      label: 'Terapia (Farmaco)',
      type: 'select',
      options: drugs
    },
    {
      id: 'activityType',
      label: 'Tipo di attività',
      type: 'string'
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getPatientsActivities(filters, start, end, setStatus) ?? []
      setEvents(fetchedData)
    }
    fetchData()
  }, [start, end, filters])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Attività Pazienti', href: PATHS.PATIENT_ACTIVITIES}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <ActivitiesAgenda endDate={end} events={events} setEndDate={setEnd} setStartDate={setStart} startDate={start} />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default PatientActivities
