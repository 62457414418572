/* eslint-disable react-hooks/exhaustive-deps */
import {Snackbar, Typography} from '@mui/material'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import {STATUS} from 'src/constants/constants'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import WarningPatientEmptyData from 'src/components/elements/WarningEmptyCard/WarningPatientEmptyData'

const FeedbacksParametersDiary: React.FC<{
  patientId: string,
}> = ({patientId}) => {
  const VITAL_SIGNS_COLLECTION = 'vital-signs-collection'
  const [dataVitalParams, setDataVitalParams] = useState<Record<string, any>[]>([])
  const [countVitalParams, setCountVitalParams] = useState<number>(0)
  const [paginationVitalParams, setPaginationVitalParams] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [vitalParamsColumns, setVitalParamsColumns] = useState([])
  const [statusVitalParams, setStatusVitalParams] = useState<string>()
  const [statusVitalParamsCount, setStatusVitalParamsCount] = useState<string>()
  const [statusVitalParamsColums, setStatusVitalParamsColums] = useState<string>()
  const [warningEmptyVitalParams, setWarningEmptyVitalParams] = useState<boolean>(false)

  const THERAPEUTICAL_PARAMS_COLLECTION = 'therapeutic-parameters-collection'
  const [dataTherapeuticalParams, setDataTherapeuticalParams] = useState<Record<string, any>[]>([])
  const [countTherapeuticalParams, setCountTherapeuticalParams] = useState<number>(0)
  const [paginationTherapeuticalParams, setPaginationTherapeuticalParams] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [therapeuticalParamsColumns, setTherapeuticalParamsColumns] = useState([])
  const [statusTherapeuticalParams, setStatusTherapeuticalParams] = useState<string>()
  const [statusTherapeuticalParamsCount, setStatusTherapeuticalParamsCount] = useState<string>()
  const [statusTherapeuticalParamsColums, setStatusTherapeuticalParamsColums] = useState<string>()
  const [warningEmptyTherapeuticalParams, setWarningEmptyTherapeuticalParams] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      const fetchColumns = await apiClient.getFeedbackConfig(VITAL_SIGNS_COLLECTION, setStatusVitalParamsColums)

      if (fetchColumns.length > 0) {
        fetchColumns.unshift({id: 'plannedDate', label: 'Data', type: 'date', format: 'DD/MM/YYYY HH:mm'})
        setVitalParamsColumns(fetchColumns)

        const fetchedData = await apiClient.getPatientsFeedbacksValues(VITAL_SIGNS_COLLECTION, patientId, setStatusVitalParams, paginationVitalParams) ?? []
        setDataVitalParams(fetchedData)
        const fetchedDataCount = await apiClient.getPatientsFeedbacksValuesCount(VITAL_SIGNS_COLLECTION, patientId, setStatusVitalParamsCount) ?? []
        setCountVitalParams(fetchedDataCount)
      } else {
        setWarningEmptyVitalParams(true)
      }
    }

    fetchData()
  }, [paginationVitalParams])

  useEffect(() => {
    const fetchData = async () => {
      const fetchColumns = await apiClient.getFeedbackConfig(THERAPEUTICAL_PARAMS_COLLECTION, setStatusTherapeuticalParamsColums)

      if (fetchColumns.length > 0) {
        fetchColumns.unshift({id: 'plannedDate', label: 'Data', type: 'date', format: 'DD/MM/YYYY HH:mm'})
        setTherapeuticalParamsColumns(fetchColumns)
        const fetchedData = await apiClient.getPatientsFeedbacksValues(THERAPEUTICAL_PARAMS_COLLECTION, patientId, setStatusTherapeuticalParams, paginationTherapeuticalParams) ?? []
        setDataTherapeuticalParams(fetchedData)
        const fetchedDataCount = await apiClient.getPatientsFeedbacksValuesCount(THERAPEUTICAL_PARAMS_COLLECTION, patientId, setStatusTherapeuticalParamsCount) ?? []
        setCountTherapeuticalParams(fetchedDataCount)
      } else {
        setWarningEmptyTherapeuticalParams(true)
      }
    }

    fetchData()
  }, [paginationTherapeuticalParams])

  const usefulTherapeuticalParams = (
    <div>
      {!warningEmptyTherapeuticalParams ?
        <TableCustom
          columns={therapeuticalParamsColumns}
          onPaginationChange={setPaginationTherapeuticalParams}
          pagination={paginationTherapeuticalParams}
          rows={dataTherapeuticalParams}
          totalElements={countTherapeuticalParams}
        /> : <WarningPatientEmptyData subject='parametro terapeutico' />}
    </div>
  )

  const usefulVitalParams = (
    <div>
      {!warningEmptyVitalParams ?
        <TableCustom
          columns={vitalParamsColumns}
          onPaginationChange={setPaginationVitalParams}
          pagination={paginationVitalParams}
          rows={dataVitalParams}
          totalElements={countVitalParams}
        /> : <WarningPatientEmptyData subject='parametro vitale' />}
    </div>
  )

  return (
    <div>
      <TabsCustom
        contents={[
          usefulVitalParams,
          usefulTherapeuticalParams
        ]}
        tabs={[
          {
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Parametri vitali'}</Typography>
          },
          {
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Parametri terapeutici'}</Typography>
          }
        ]}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatusVitalParams(undefined); setStatusVitalParamsCount(undefined); setStatusVitalParamsColums(undefined) }}
        open={statusVitalParams === STATUS.REJECTED || statusVitalParamsCount === STATUS.REJECTED || statusVitalParamsColums === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatusVitalParams(undefined); setStatusVitalParamsCount(undefined); setStatusVitalParamsColums(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati dei parametri vitali.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatusTherapeuticalParams(undefined); setStatusTherapeuticalParamsCount(undefined); setStatusTherapeuticalParamsColums(undefined) }}
        open={statusTherapeuticalParams === STATUS.REJECTED || statusTherapeuticalParamsCount === STATUS.REJECTED || statusTherapeuticalParamsColums === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatusTherapeuticalParams(undefined); setStatusTherapeuticalParamsCount(undefined); setStatusTherapeuticalParamsColums(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati dei parametri terapeutici.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )
}

export default FeedbacksParametersDiary
