import styled from '@emotion/styled'
import {IconButton} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {ExportFileType} from './Pharmacovigilance'

type ExportFilesModalProps = {
    open: boolean,
    handleClose: () => void,
    handleOpen: (index: number) => void,
    exportFiles: ExportFileType[]
}

const PharmacovigilanceCard = styled('div')(({theme}: any) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary[100]
  },
  padding: '16px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid lightgrey'
}))

export default function ExportFilesModal ({handleClose, handleOpen, open, exportFiles}: ExportFilesModalProps) {
  const modalBody = (
    <div style={{fontSize: '16px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
      {'Tipi di Form generabili per questo evento:'}
      <div style={{display: 'grid', gridTemplateColumns: '1fr', border: '1px solid lightgrey'}}>
        {exportFiles.map((file: ExportFileType, index: number) => {
          return (
            <PharmacovigilanceCard key={index}>
              {file.fileName}
              <IconButton edge='end' onClick={() => handleOpen(index)} style={{border: '1px solid lightgrey', backgroundColor: '#F0F3F4'}}>
                <AssignmentIcon fontSize='small' />
              </IconButton>
            </PharmacovigilanceCard>
          )
        })}
      </div>
    </div>
  )
  return (
    <DialogCustom
      body={modalBody}
      footer={
        <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
          <SecondaryButton onClick={() => handleClose()}>{'Chiudi'}</SecondaryButton>
        </div>
      }
      onClose={() => handleClose()}
      open={open}
      title={{text: 'Generazione Forms Farmaco Vigilanza', format: false}}
    />
  )
}
