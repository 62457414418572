import React, {useState} from 'react'
import UserData from '../../UserData/UserData'
import ContactData from '../../ContactData/ContactData'
import {Form, FormInstance} from 'antd'
import ProgramMembershipData from '../../ProgramMembershipData/ProgramMembershipData'
import {useAppSelector} from 'src/hooks'
import OthersData from '../../OthersData/OthersData'

export type UserInfoProps = {
    form: FormInstance<any>,
    patientId: string,
    patientDiseases?: any
}

const UserInfo: React.FC<UserInfoProps> = ({form, patientId, patientDiseases}) => {
  const {patientDetailConfiguration: {profileConfig}} = useAppSelector(state => state.pspConfigReducer.data)

  const [delegate1, setDelegate1] = useState<Record<string, any>>()
  const [delegate2, setDelegate2] = useState<Record<string, any>>()
  const [caregiver, setCaregiver] = useState<Record<string, any>>()

  return (
    <Form form={form} layout='vertical'>
      <UserData patologies={patientDiseases} profileConfiguration={profileConfig} readOnly />
      <ContactData
        caregiverValues={{caregiver, setCaregiver}}
        delegate1Values={{delegate1, setDelegate1}}
        delegate2Values={{delegate2, setDelegate2}}
        form={form}
        patientId={patientId}
        profileConfiguration={profileConfig}
        readOnly
      />
      <ProgramMembershipData profileConfiguration={profileConfig} readOnly />
      <OthersData profileConfiguration={profileConfig} readOnly />
    </Form>
  )
}

export default UserInfo
