import {Paper} from '@mui/material'
import {styled} from '@mui/system'

export const ActionsMenuStyled = styled(Paper)(({theme}) => ({
  cursor: 'pointer',

  '.action-item': {
    padding: '16px',
    height: '24px',
    fontSize: '14px',

    '.text-list': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      svg: {
        marginRight: theme.spacing(8)
      }
    }
  }
}))
