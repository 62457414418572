import {Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'

type ConfirmationDialogProps = {
  messageId: string,
  titleId: string,
  onClose: () => void,
  onConfirm: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  messageId,
  titleId,
  onClose,
  onConfirm
}) => {
  const body = <Typography variant='body1'><FormattedMessage id={messageId} /></Typography>

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <SecondaryButton onClick={onConfirm} style={{marginRight: '8px'}}>
        <FormattedMessage id='ok' />
      </SecondaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      onClose={onClose}
      title={{text: titleId, format: true}}
    />
  )
}

export default ConfirmationDialog
