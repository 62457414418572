import TableCustom, {TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import {beneficiaryOptions} from 'src/constants/constants'
import {ErodedPackage} from 'src/types/types'

type PricingTableProps = {
    erodedPackage: ErodedPackage
}

const PricingTable: React.FC<PricingTableProps> = ({erodedPackage}) => {
  const erodedHourColumns = erodedPackage.erodedHour ? Object.keys(erodedPackage.erodedHour) : []
  const erodedEuroColumns = erodedPackage.erodedEuro ? Object.keys(erodedPackage.erodedEuro) : []
  const erodedPerformancesColumns = erodedPackage.erodedPerformances ? Object.keys(erodedPackage.erodedPerformances) : []

  const columns: TableColumn[] = beneficiaryOptions.filter((option) => {
    return erodedHourColumns.includes(option.value) || erodedEuroColumns.includes(option.value) || erodedPerformancesColumns.includes(option.value)
  }).map((item) => {
    return {
      id: item.value,
      label: item.label
    }
  })

  const rows: Record<string, any>[] = []

  if (erodedPackage.erodedHour) {
    rows.push({
      key: 'Eroso ore', ...erodedPackage.erodedHour
    })
  }
  if (erodedPackage.erodedEuro) {
    rows.push({
      key: 'Eroso euro', ...erodedPackage.erodedEuro
    })
  }
  if (erodedPackage.erodedPerformances) {
    rows.push({
      key: 'Eroso prestazioni', ...erodedPackage.erodedPerformances
    })
  }

  return (
    <TableCustom
      columns={[{id: 'key', label: 'Servizio'}, ...columns]}
      onPaginationChange={() => {}}
      pagination={{
        page: 0,
        rowsPerPage: 0,
        order: ''
      }}
      rows={rows} showPagination={false} totalElements={3}
    />
  )
}

export default PricingTable
