
import {PDFDiaryStyled} from './PDFDiaryStyled'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'

type PatientType = {
  prescribingDoctor: string,
  title: string,
  firstName: string,
  lastName: string,
  serviceConf: string
}

const assumptionsStateOptions = [
  {
    value: 'COMPLETED',
    label: 'Completata'
  },
  {
    value: 'PLANNED',
    label: 'Pianificata'
  },
  {
    value: 'TO_BE_PLANNED',
    label: 'In pianificazione'
  },
  {
    value: 'CANCELLED',
    label: 'Non effettuata'
  }
]

const PDFDiary = () => {
  const {logoImagePSP} = useAppSelector(state => state.pspConfigReducer.data)
  const {columns = []} = useAppSelector(state => state.pspConfigReducer.data.therapyDiaryConfigurations ?? {})
  const {diaryConfigurations: {diaryType}} = useAppSelector(state => state.pspConfigReducer.data.patientDetailConfiguration ?? {})

  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const filters: string = searchParams.get('filters') || ''
  const filtersObj: {patientId: string, isPatientDetail: boolean} = JSON.parse(filters)
  const [patient, setPatient] = useState<PatientType | undefined>()
  const patientData = useAppSelector(state => state.userReducer.data)

  const [data, setData] = useState<Record<string, unknown>[]>([])
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [count, setCount] = useState(0)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 12,
    order: ''
  })

  useEffect(() => {
    if (filtersObj?.patientId) {
      const fetchPatient = async () => {
        const patientDetailData = await apiClient.getPatientsUserData(filtersObj.patientId, () => {}) ?? []
        setPatient(patientDetailData)
      }

      fetchPatient()
    } else {
      setPatient(patientData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersObj?.patientId])

  const fetchDataPatient = async () => {
    if (diaryType === 'DIARY_MORISKY') {
      const fetchedData = await apiClient.getDiaryMorisky({...pagination, therapy: undefined}, () => {}) ?? []
      setData(fetchedData)
      const fetchedDataCount = await apiClient.getDiaryMoriskyCount({therapy: undefined}, () => {}) ?? []
      setCount(fetchedDataCount)
    } else {
      const fetchedData = await apiClient.getPatientDiary({...pagination, therapy: undefined}, () => {}) ?? []
      const parsedData = fetchedData.map((assumption: any) => {
        return {
          ...assumption,
          activityState: assumptionsStateOptions.find((option) => option.value === assumption.activityState)?.label
        }
      })
      setData(parsedData)
      const fetchedDataCount = await apiClient.getPatientDiaryCount({therapy: undefined}, () => {}) ?? []
      setCount(fetchedDataCount)
    }
  }

  const fetchDataPatientDetail = async () => {
    if (diaryType === 'DIARY_MORISKY') {
      const fetchedData = await apiClient.getPatientDiaryMorisky(filtersObj?.patientId, {...pagination}, () => {}) ?? []
      setData(fetchedData)
      const fetchedDataCount = await apiClient.getPatientDiaryMoriskyCount(filtersObj?.patientId, {}, () => {}) ?? []
      setCount(fetchedDataCount)
    } else {
      const fetchedData = await apiClient.getPatientsAssumptions(filtersObj?.patientId, () => {}, pagination) ?? []
      const parsedData = fetchedData.map((assumption: any) => {
        return {
          ...assumption,
          activityState: assumptionsStateOptions.find((option) => option.value === assumption.activityState)?.label
        }
      })
      setData(parsedData)
      const fetchedDataCount = await apiClient.getPatientsAssumptionsCount(filtersObj?.patientId, () => {}) ?? []
      setCount(fetchedDataCount)
    }
  }

  useEffect(() => {
    if (filtersObj?.patientId) {
      fetchDataPatientDetail()
    } else {
      fetchDataPatient()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return (
    <>
      <PDFDiaryStyled>
        <table className='print-container' style={{height: '100vh'}}>
          <thead className='print-header' style={{height: '50px', paddingTop: '32px'}}>
            <tr>
              <th className='report-header-cell'>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <img alt='IA logo' src={'/assets/images/logo_IA.png'} />
                  </div>
                  <div>
                    <img alt='logo PSP' src={`data:image/png;base64,${logoImagePSP}`} />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='print-content'>
            <tr>
              <td className='print-content-cell'>
                <div style={{textAlign: 'left'}}>
                  <div style={{fontSize: '18px', color: '#495055', fontWeight: '600', margin: '24px 0'}}>
                    {'PROFILO DI ADERENZA PAZIENTE'}
                  </div>
                  <div style={{display: 'flex', gap: '40px', marginBottom: '16px'}}>
                    <div style={{fontWeight: '600'}}>
                      <div>
                        {'Paziente:'}
                      </div>
                      <div>
                        {'Data estrazione:'}
                      </div>
                      <div>
                        {'Servizio:'}
                      </div>
                      <div>
                        {'Medico:'}
                      </div>
                    </div>
                    <div>
                      <div>
                        {`${patient?.title ? `${patient?.title}` : ''}${patient?.firstName} ${patient?.lastName}`}
                      </div>
                      <div>
                        {moment(new Date()).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        {patient?.serviceConf || '-'}
                      </div>
                      <div>
                        {patient?.prescribingDoctor}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TableCustom
                    columns={columns
                      .filter((col: TableColumn) => (col.id !== 'adherence' || patient?.serviceConf === 'EXTRA'))
                      .map((col: TableColumn) => ({...col, noSort: true}))}
                    onPaginationChange={setPagination}
                    pagination={pagination}
                    rows={data}
                    showPagination={false}
                    totalElements={count}
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <div className='footer'>
              <div style={{border: '1px solid black', padding: '8px'}}>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                  <div>
                    <b>{'LEGENDA QUESTIONARIO MORISKY *'}</b>
                    <ul>
                      <li>{'0, 1 = bassa aderenza'}</li>
                      <li>{'2, 3 = media aderenza'}</li>
                      <li>{'4 = alta aderenza'}</li>
                    </ul>
                  </div>
                  <div>
                    <b>{'LEGENDA QUESTIONARIO COMPILAZIONE DIARIO'}</b>
                    <ul>
                      <li>{'0, 1 = incompleto'}</li>
                      <li>{'2, 3 = parzialmente completo'}</li>
                      <li>{'4 = completo'}</li>
                    </ul>
                  </div>
                  <div>
                    <b>{'LEGENDA QUESTIONARIO SODDISFAZIONE PROGRAMMA'}</b>
                    <ul>
                      <li>{"1, 2, 3, 4 = per niente d'accordo"}</li>
                      <li>{"5, 6, 7 = d'accordo"}</li>
                      <li>{"8, 9, 10 = molto d'accordo"}</li>
                    </ul>
                  </div>
                </div>
                <div>
                  {'* Scala di Morisky (MMAS Morisky Medication Adherence Scale), basato su 4 domande semplici, con le seguenti possibilità di risposta: SI (0 punti) - NO (1 punto). Il punteggio totale ottenuto permette di suddividere velocemente il campione analizzato in: alta aderenza (4 punti), media (2-3 punti) e bassa (0-1 punti) aderenza terapeutica.'}
                </div>
                <div className='legend'>
                  {'rilevata da device PluggyMed (by PhutureMed) - FASCE DI ADERENZA'}
                  <div className='color-box' style={{background: 'green'}} />
                  {'100%'}
                  <div className='color-box' style={{background: 'lightgreen'}} />
                  {'99 - 97%'}
                  <div className='color-box' style={{background: 'yellow'}} />
                  {'96 - 90%'}
                  <div className='color-box' style={{background: 'orange'}} />
                  {'89 - 80%'}
                  <div className='color-box' style={{background: 'red'}} />
                  {'sotto 80%'}
                </div>
              </div>
            </div>
          </tfoot>
        </table>
      </PDFDiaryStyled>
    </>
  )
}

export default PDFDiary
