import {styled} from '@mui/system'

export const TabsLayoutStyled = styled('div')(({theme}) => ({
  '.MuiTabs-root': {
    borderRadius: '40px 40px 0 0',
    background: theme.palette.background.default,
    overflow: 'visible',

    '.MuiTabs-flexContainer': {
      justifyContent: 'center'
    },

    '.MuiTab-iconWrapper': {
      marginBottom: '10px'
    },

    '.MuiButtonBase-root': {
      color: '#222',
      padding: '15px 30px'
    },

    '.MuiButtonBase-root.MuiTabScrollButton-root': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      width: '24px',
      borderRadius: '10px',
      padding: 0
    }
  }
}))
