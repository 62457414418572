import React, {useEffect, useState} from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import {useAppSelector} from 'src/hooks'

// accessibility
require('highcharts/modules/accessibility')(Highcharts)

export type ChartProps = {
    data:any,
    type: any,
    title?: string,
    colors: string[],
    percentage?: boolean,
    legend?: boolean
}

const pieDataLabelFormat = (legend: boolean, userGroup: string) => {
  if (userGroup.toUpperCase().includes('DOCTOR')) {
    return legend ? '{point.y}' : '{point.name} {point.y}'
  } else return legend ? '{point.percentage:.1f} % ({point.y})' : '{point.name} {point.percentage:.1f} % ({point.y})'
}

const pieOptions = (legend: boolean, userGroup: string) : Highcharts.Options => ({
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: ''
  },
  tooltip: {
    enabled: false
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    series: {
      animation: false
    },
    pie: {
      allowPointSelect: false,
      cursor: 'pointer',
      showInLegend: legend,
      dataLabels: {
        enabled: true,
        format: pieDataLabelFormat(legend, userGroup),
        distance: 30,
        filter: {
          operator: '>',
          property: 'y',
          value: 0
        },
        style: {
          color: 'black',
          textOutline: 'none',
          fontSize: '12px',
          fontWeight: '900'
        }
      }
    }
  },
  legend: {
    align: 'right',
    layout: 'vertical',
    verticalAlign: 'top'
  },
  credits: {
    enabled: false
  }
})

const splineOptions = (userGroup: string) : Highcharts.Options => ({
  chart: {
    type: 'spline'
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e/%m/%y',
      week: '%e/%m/%y',
      month: '%m/%y',
      year: '%Y'
    }
  },
  tooltip: {
    dateTimeLabelFormats: {
      millisecond: '%e/%m/%y %H:%M:%S',
      second: '%e/%m/%y %H:%M:%S',
      minute: '%e/%m/%y %H:%M',
      hour: '%e/%m/%y %H:%M',
      day: '%e/%m/%y %H:%M',
      week: '%e/%m/%y %H:%M',
      month: '%m/%y %H:%M',
      year: '%m/%y %H:%M'
    },
    valueDecimals: userGroup.toUpperCase().includes('DOCTOR') ? 0 : 1
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle'
  },
  plotOptions: {
    series: {
      marker: {
        symbol: 'circle',
        enabled: true,
        radius: 3,
        lineWidth: 2,
        lineColor: undefined
      }
    }
  },
  credits: {
    enabled: false
  }
})

const areaOptions = (userGroup: string) : Highcharts.Options => ({
  chart: {
    renderTo: 'container',
    type: 'area'
  },
  title: {
    text: ''
  },
  tooltip: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: 0,
      style: {
        color: 'black',
        textOutline: 'none',
        fontSize: '12px',
        fontWeight: '400'
      }
    }
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  plotOptions: {
    series: {
      animation: false
    },
    area: {
      lineWidth: 5,
      marker: {
        radius: 6
      },
      dataLabels: {
        enabled: true,
        style: {
          color: 'black',
          textOutline: 'none',
          fontSize: '12px',
          fontWeight: '100'
        },
        format: userGroup.toUpperCase().includes('DOCTOR') ? '{point.y:.0f}' : '{point.y:.1f}'
      }
    }
  },
  credits: {
    enabled: false
  }
})

const columnOptions = (userGroup: string) : Highcharts.Options => ({
  chart: {
    renderTo: 'container',
    type: 'column'
  },
  tooltip: {
    enabled: true
  },
  title: {
    text: ''
  },
  subtitle: {
    text: '',
    align: 'center',
    verticalAlign: 'bottom',
    style: {
      fontSize: '14px',
      fontWeight: '700',
      color: 'black'
    }
  },
  legend: {
    enabled: false
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: 0,
      style: {
        fontSize: '12px',
        fontWeight: '400',
        lineWidth: 1
      }
    }
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  credits: {
    enabled: false
  }
})

const stackingColumnOptions = (userGroup: string) : Highcharts.Options => ({
  chart: {
    renderTo: 'container',
    type: 'column'
  },
  tooltip: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: '',
    align: 'center',
    verticalAlign: 'bottom',
    style: {
      fontSize: '14px',
      fontWeight: '700',
      color: 'black'
    }
  },
  legend: {
    enabled: true
  },
  plotOptions: {
    series: {
      animation: false
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        inside: true
      }
    }
  },
  credits: {
    enabled: false
  }
})

const Chart: React.FC<ChartProps> = (
  {
    data,
    type,
    title,
    colors,
    legend = true,
    percentage = false
  }
) => {
  const {summaryConfiguration} = useAppSelector(state => state.pspConfigReducer.data)
  const [options, setOptions] = useState<Highcharts.Options>()
  const {userGroup} = useAppSelector(state => state.userReducer.data)

  Highcharts.setOptions({
    time: {
      timezone: 'Europe/Rome',
      useUTC: false
    }
  })

  useEffect(() => {
    if (type === 'pie') {
      setOptions({
        ...pieOptions(legend, userGroup),
        colors,
        series: [{
          type: type,
          data: data
        }]
      })
    }

    if (type === 'spline') {
      setOptions({
        ...splineOptions(userGroup),
        colors,
        series: data
      })
    }

    if (type === 'area') {
      setOptions({
        ...areaOptions(userGroup),
        colors,
        series: [{
          type: type,
          data: data
        }]
      })
    }

    if (type === 'column') {
      if (Array.isArray(data)) {
        setOptions({
          ...columnOptions(userGroup),
          plotOptions: {
            series: {
              animation: false
            },
            column: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                inside: true,
                enabled: true,
                format: percentage ? (userGroup.toUpperCase().includes('DOCTOR') ? '{y:.0f}%' : '{y:.1f}%') : undefined
              }
            }
          },
          subtitle: {
            ...columnOptions(userGroup).subtitle,
            text: `Totale ${data.reduce((sum, point) => sum + point.y, 0)}`
          },
          colors,
          series: [{
            type: type,
            data: data
          }]
        })
      } else {
        const series = percentage && data.series ? data.series.map((el: any) => (
          {
            name: el.name,
            data: el.data.map((value: number) => Math.round((value * 100 + Number.EPSILON) * 100) / 100),
            color: el.color
          }
        )) : data.series

        setOptions({
          ...columnOptions(userGroup),
          plotOptions: {
            series: {
              animation: false
            },
            column: {
              pointPadding: 0,
              borderWidth: 0,
              dataLabels: {
                inside: true,
                enabled: true,
                allowOverlap: true,
                padding: 0,
                format: percentage ? (userGroup.toUpperCase().includes('DOCTOR') ? '{y:.0f}%' : '{y:.1f}%') : undefined
              }
            }
          },
          legend: {
            enabled: true
          },
          xAxis: {
            ...columnOptions(userGroup).xAxis,
            categories: data.xAxis
          },
          colors,
          series
        })
      }
    }

    if (type === 'stacking-column') {
      const series = percentage && data.series ? data.series.map((el: any) => (
        {
          name: el.name,
          data: el.data.map((value: number) => Math.round((value * 100 + Number.EPSILON) * 10) / 10),
          color: el.color
        }
      )) : data.series

      setOptions({
        ...stackingColumnOptions(userGroup),
        xAxis: {categories: data.xAxis},
        yAxis: {
          title: {
            text: ''
          },
          stackLabels: {
            enabled: true,
            format: percentage ? '{total}%' : undefined
          }
        },
        colors,
        subtitle: {
          ...stackingColumnOptions(userGroup).subtitle
        },
        series: series,
        plotOptions: percentage ? {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              format: userGroup.toUpperCase().includes('DOCTOR') ? '{y:.0f} %' : '{y:.1f} %'
            }
          }
        } : undefined
      })
    }

    if (type === 'morisky') {
      const xAxisLabels = [
        moment().subtract(3, 'months').format('MMM YYYY'),
        moment().subtract(2, 'months').format('MMM YYYY'),
        moment().subtract(1, 'months').format('MMM YYYY'),
        moment().format('MMM YYYY')
      ]
      setOptions({
        chart: {
          renderTo: 'container',
          type: 'column'
        },
        title: {
          text: title
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: xAxisLabels
        },
        yAxis: {
          min: 0
        },
        colors,
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            dataLabels: {
              enabled: true,
              style: {
                color: 'black',
                textOutline: 'none',
                fontSize: '12px',
                fontWeight: '100'
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          type: 'column',
          data: data
        }]
      })
    }
  }, [data, type, title, summaryConfiguration, colors, percentage, legend, userGroup])

  return (
    <>
      {(Array.isArray(data) && data.length > 0) || (Array.isArray(data.series) && data.series.length > 0) ?
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        /> :
        <div style={{backgroundColor: '#FCF8E3', padding: '15px', color: '#8a6d3b'}}>
          <span>{'Non ci sono statistiche per questo grafico'}</span>
        </div>}
    </>
  )
}

export default Chart
