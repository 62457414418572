import React, {useCallback, useEffect, useState} from 'react'
import {Checkbox, DatePicker, Form, Input, Popover, Tag, TimePicker} from 'antd'
import FilterButton from 'src/components/elements/Buttons/FilterButton/FilterButton'
import {FiltersStyled} from './FiltersStyled'
import {FormattedMessage} from 'react-intl'
import Select from 'antd/lib/select'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {Button, Typography} from '@mui/material'
import locale from 'antd/es/date-picker/locale/it_IT'
import Icon from '@mdi/react'
import {mdiClose, mdiMagnify} from '@mdi/js'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import {ClickAwayListener} from '@mui/base'
import DoctorsLookupDialog from '../DoctorsLookupDialog/DoctorsLookupDialog'

export type FilterItem = {
  id: string;
  label: string;
  type: string;
  defaultValue?: string[];
  options?: {
    label: string,
    value: string
  }[];
};

type FiltersProps = {
  formId?: string,
  config: FilterItem[];
  onSubmit: (values: any) => void;
};

const Filters: React.FC<FiltersProps> = ({formId = 'filter-form', config = [], onSubmit}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [patient, setPatient] = useState<Record<string, any>>()
  const [isDoctorsModal, setIsDoctorsModal] = useState<boolean>(false)
  const [doctor, setDoctor] = useState<Record<string, any>>()
  const [numOptionSelected, setNumOptionSelected] = useState(0)
  const [form] = Form.useForm()
  const {RangePicker} = DatePicker

  useEffect(() => {
    config.forEach(({id, defaultValue}) => form.setFieldValue(id, defaultValue))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clipLabelTag = (label: any, numSedi:any) => {
    switch (numSedi) {
      case 1:
        return label.slice(0, 14)
      case 2:
        return label.slice(0, 6)
      case 3:
        return label.slice(0, 3)
      default:
        return label.slice(0, 3)
    }
  }

  const tagRender = useCallback((property) => {
    const {label, closable, onClose} = property
    const onPreventMouseDown = (event: any) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const labelToRender = clipLabelTag(label, numOptionSelected)

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        onMouseDown={onPreventMouseDown}
        role='option'
      >
        {label === labelToRender ? labelToRender : labelToRender + '..'}
      </Tag>
    )
  }, [numOptionSelected])

  const onFinish = (values: any) => {
    const postBody = Object.keys(values).map((key) => {
      const element = config.find((el: FilterItem) => el.id === key)
      if (element?.type === 'rangePicker' && values[key]) {
        return {
          property: key,
          type: element.type,
          value: [values[key]?.at(0).startOf('day').toISOString(), values[key]?.at(1)?.endOf('day').toISOString()]
        }
      }
      if (element?.type === 'datePicker' && values[key]) {
        return {
          property: key,
          type: element.type,
          value: values[key]?.startOf('day').toISOString()
        }
      }
      if (element?.type === 'patientLookup') {
        return {
          property: key,
          type: element.type,
          value: patient
        }
      }
      if (element?.type === 'doctorLookup') {
        return {
          property: key,
          type: element.type,
          value: doctor
        }
      }
      return {
        property: key,
        type: element?.type,
        value: values[key]
      }
    }).filter((el) => el.value)
    onSubmit(postBody)
  }

  const onCancel = () => {
    config.forEach(({id, defaultValue}) => form.setFieldValue(id, defaultValue))
    form.submit()
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const getFormItem = (element: FilterItem) => {
    switch (element.type) {
      case 'datePicker':
        return (
          <DatePicker
            format='DD MMM YYYY'
            locale={locale}
            placeholder=''
            popupStyle={{zIndex: 1400}}
          />
        )
      case 'timePicker':
        return (
          <TimePicker
            locale={locale}
            placeholder=''
            popupStyle={{zIndex: 1400}}
          />
        )
      case 'rangePicker':
        return (
          <RangePicker
            format='DD MMM YYYY'
            locale={locale}
            placeholder={['', '']}
            popupStyle={{zIndex: 1400}}
          />
        )
      case 'checkbox':
        return (
          <Checkbox.Group>
            {
              element.options?.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  <Typography variant='body2'>{option.label}</Typography>
                </Checkbox>

              ))
            }
          </Checkbox.Group>
        )
      case 'select':
        return (
          <Select
            allowClear
            dropdownStyle={{zIndex: 1400}}
            getPopupContainer={(targetNode) => targetNode}
            options={element.options}
            placeholder='Cerca...'
            showSearch
          />
        )
      case 'multiSelect':
        return (
          <Select
            allowClear
            dropdownStyle={{zIndex: 1400}}
            getPopupContainer={(targetNode) => targetNode}
            maxTagCount={2}
            maxTagPlaceholder={(omit) => `+${omit.length}..`}
            mode='multiple'
            onChange={(selected) => setNumOptionSelected(selected.length)}
            options={element.options}
            tagRender={tagRender}
          />
        )
      case 'patientLookup':
        return (
          <div className='user-content'>
            <Form.Item
              name={'patient'}
            >
              <Input disabled />
            </Form.Item>
            <div className='user-buttons'>
              <Button
                className='user-button'
                onClick={() => setIsPatientsModal(true)}
              >
                <Icon path={mdiMagnify} size={1} />
              </Button>
              <Button
                className='user-button'
                onClick={() => { form.setFieldValue(element.id, undefined); setPatient(undefined) }}
              >
                <Icon path={mdiClose} size={1} />
              </Button>
            </div>
          </div>
        )
      case 'doctorLookup':
        return (
          <div className='user-content'>
            <Form.Item
              name={'doctor'}
            >
              <Input disabled />
            </Form.Item>
            <div className='user-buttons'>
              <Button
                className='user-button'
                onClick={() => setIsDoctorsModal(true)}
              >
                <Icon path={mdiMagnify} size={1} />
              </Button>
              <Button
                className='user-button'
                onClick={() => { form.setFieldValue(element.id, undefined); setDoctor(undefined) }}
              >
                <Icon path={mdiClose} size={1} />
              </Button>
            </div>
          </div>
        )
      default:
        return <Input />
    }
  }

  const menu = (
    <FiltersStyled>
      <Form
        className='filter-form'
        form={form}
        id={formId}
        layout='vertical'
        onFinish={(values) => { onFinish(values) }}
        style={{gridTemplateColumns: `repeat(${config.length >= 3 ? 3 : config.length}, 1fr)`}}
      >
        {config.map((element) => {
          return (
            <Form.Item
              key={element.id}
              label={element.label}
              name={element.id}
            >
              {getFormItem(element)}
            </Form.Item>
          )
        })}
      </Form>
      <div className='footer-div'>
        <PrimaryButton
          form={formId}
          key='submit'
          type='submit'
        >
          <FormattedMessage id='search' />
        </PrimaryButton>
        <SecondaryButton onClick={() => onCancel()}>
          <FormattedMessage id='cancel' />
        </SecondaryButton>
      </div>
    </FiltersStyled>
  )

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className='filters'>
        <Popover
          content={menu}
          getPopupContainer={(triggerNode) => triggerNode}
          open={open}
          placement='bottomLeft'
          showArrow={false}
          trigger={'click'}
        >
          <FilterButton
            onClick={handleClick}
            open={open}
          />
        </Popover>
        {isPatientsModal &&
          <PatientLookupDialog
            onClose={() => setIsPatientsModal(false)}
            onRowClick={(row) => {
              setPatient(row)
              form.setFieldValue('patient', `${row.firstName} ${row.lastName}`)
              setIsPatientsModal(false)
            }}
          />}
        {isDoctorsModal &&
          <DoctorsLookupDialog
            onClose={() => setIsDoctorsModal(false)}
            onRowClick={(row) => {
              setDoctor(row._id)
              form.setFieldValue('doctor', `${row.firstName} ${row.lastName}`)
              setIsDoctorsModal(false)
            }}
          />}
      </div>
    </ClickAwayListener>
  )
}

export default Filters
