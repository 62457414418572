import {InputNumber, Select} from 'antd'

export type UnitSelectorProp = {
  disabled: boolean,
  onChange: (values: any) => void,
  unitTypeOptions: {
    label: string,
    value: number | string,
  }[],
  placeholder?: string,
  value?:{
    value: number | string,
    unitType: string
  }
}

const UnitSelector: React.FC<UnitSelectorProp> = ({
  value,
  disabled,
  placeholder,
  unitTypeOptions,
  onChange
}) => {
  const unitTypeSelector = (
    <Select
      disabled={disabled}
      dropdownMatchSelectWidth={130}
      getPopupContainer={(trigger) => trigger}
      listHeight={300}
      onChange={unitType => onChange({...value, unitType})}
      options={unitTypeOptions}
      style={{minWidth: 120}}
      value={value?.unitType ?? unitTypeOptions[0].value}
    />
  )

  return (
    <InputNumber
      addonAfter={unitTypeSelector}
      decimalSeparator=','
      disabled={disabled}
      min={0}
      onChange={newValue => onChange({...value, value: newValue})}
      placeholder={placeholder ?? 'Inserisci un numero'}
      value={value?.value}
    />
  )
}

export default UnitSelector
