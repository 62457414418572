import {styled} from '@mui/system'

// @ts-ignore
export const LoginTextFieldStyled = styled('div')(({theme, loginConfiguration}) => ({

  '.MuiFormControl-root': {
    width: '100%'
  },

  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: loginConfiguration.primaryColor
    }
  },

  '.login-text-field': {
    fontSize: theme.spacing(7),
    borderRadius: theme.spacing(1)
  }
}))
