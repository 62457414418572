import {mdiDelete, mdiDownload} from '@mdi/js'
import Icon from '@mdi/react'
import {Popover, Snackbar, Tooltip, Typography} from '@mui/material'
import {Input, Select, Upload} from 'antd'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import UploadButton from 'src/components/elements/Buttons/UploadButton/UploadButton'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import {ModalSubmitFileStyled, UploaderStyled} from './UploaderStyled'

type UploaderProps = {
  handleSubmitRefresh: () => void,
  handleSubmitFile: (file: any, metadata: any, statusSetter: any) => void
}

const Uploader: React.FC<UploaderProps> = ({handleSubmitRefresh, handleSubmitFile}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [fileValues, setFileValues] = useState<any>([])
  const [fileMetadatas, setFileMetadatas] = useState<any>([])
  const [openUploadFile, setOpenUploadFile] = useState<any>({isOpen: false, indexFileToSubmit: undefined})
  const [status, setStatus] = useState<any>(undefined)
  const [snackbarMessage, setSnackbarMessage] = useState<any>()

  const {Dragger} = Upload

  const pspDocumentsConfigurations = useAppSelector(state => state.pspConfigReducer.data.documentsConfigurations)

  const categoryOptions = pspDocumentsConfigurations.categoryOptions.categoriesUpload

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (open) {
      setAnchorEl(null)
      setOpen(false)
    } else {
      setAnchorEl(e.currentTarget)
      setOpen(true)
    }
  }

  const handleSubmitAllFiles = async () => {
    for (let index = 0; index < fileValues.length; index++) {
      await handleSubmitFile(fileValues[index], fileMetadatas[index], setStatus)
    }
    await handleSubmitRefresh()
    clearData()
  }

  const clearData = () => {
    setFileValues([])
    setFileMetadatas([])
  }

  return (
    <div className='uploader-container'>
      <UploadButton
        onClick={handleClick}
        open={open}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handleClick}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <UploaderStyled>
          <div>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              onClose={() => setStatus(undefined)}
              open={status === STATUS.REJECTED}
            >
              <div>
                <SnackbarAlertCustom onClose={() => setStatus(undefined)} severity='error' sx={{width: '100%'}}>
                  {snackbarMessage}
                </SnackbarAlertCustom>
              </div>
            </Snackbar>
            <div className='document-upload'>
              <ModalSubmitFileStyled
                footer={null}
                onCancel={() => { clearData(); setOpenUploadFile({isOpen: false, indexFileToSubmit: undefined}) }}
                open={openUploadFile.isOpen}
                zIndex={2000}
              >
                <div style={{borderRadius: '10px'}}>
                  <div style={{fontSize: '16px', marginTop: '30px'}}>
                    <FormattedMessage id='modalSubmitDocument' />
                  </div>
                  <div style={{display: 'flex', gap: '5px', justifyContent: 'end'}}>
                    <SecondaryButton
                      onClick={async () => {
                        await handleSubmitFile(fileValues[openUploadFile.indexFileToSubmit], fileMetadatas[openUploadFile.indexFileToSubmit], setStatus)
                        setSnackbarMessage(
                          <div>
                            <Typography variant='h5'>
                              {'Caricamento del file non riuscito'}
                            </Typography>
                            <div>
                              {`Il file ${fileValues[openUploadFile.indexFileToSubmit].name} non è stato caricato`}
                            </div>
                          </div>
                        )
                        clearData()
                        setOpenUploadFile({isOpen: false, indexFileToSubmit: undefined})
                        await handleSubmitRefresh()
                      }}
                    >
                      {'Ok'}
                    </SecondaryButton>
                    <SecondaryButton
                      onClick={() => { clearData(); setOpenUploadFile({isOpen: false, indexFileToSubmit: undefined}) }}
                    >
                      {'Annulla'}
                    </SecondaryButton>
                  </div>
                </div>
              </ModalSubmitFileStyled>
              <div>
                <Dragger
                  beforeUpload={() => false}
                  multiple
                  name='file'
                  onChange={(values) => {
                    const updatedArrayOnChangeFiles: any = []
                    fileValues.forEach((metadata: any) => {
                      updatedArrayOnChangeFiles.push(metadata)
                    })
                    updatedArrayOnChangeFiles.push(values.fileList[values.fileList.length - 1])
                    setFileValues(updatedArrayOnChangeFiles)
                    setFileMetadatas([...fileMetadatas, {category: []}])
                  }}
                  showUploadList={false}
                >
                  <img alt='uploader' src={'/assets/images/upload.png'} />
                  <Typography>
                    <span>{'Clicca o trascina qui per caricare il file con il modulo di adesione'}</span>
                  </Typography>
                </Dragger>
              </div>
              {fileValues.map((file: any, index: number) => {
                return (
                  <>
                    {(index === 0) &&
                      <div className='upload-items-header'>
                        <div>
                          <Typography style={{fontSize: '13px'}} variant='h5'>
                            <span>{'FILE'}</span>
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{fontSize: '13px'}} variant='h5'>
                            <span>{'CATEGORIA'}</span>
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{fontSize: '13px'}} variant='h5'>
                            <span>{'NOME'}</span>
                          </Typography>
                        </div>
                        <div>
                          <Typography style={{fontSize: '13px'}} variant='h5'>
                            <span>{'DIM.'}</span>
                          </Typography>
                        </div>
                      </div>}
                    <div className='upload-item'>
                      <div className='upload-item-cell'>
                        <Typography variant='h5'>
                          <span>{file.name}</span>
                        </Typography>
                      </div>
                      <div className='upload-item-cell'>
                        <Select
                          getPopupContainer={(trigger) => trigger}
                          mode='multiple'
                          onChange={(values) => {
                            const updatedArrayOnChange: any = []
                            fileMetadatas.forEach((metadata: any) => {
                              updatedArrayOnChange.push(metadata)
                            })
                            updatedArrayOnChange[index] = {...fileMetadatas[index], category: values}
                            setFileMetadatas(updatedArrayOnChange)
                          }}
                          options={categoryOptions}
                          value={fileMetadatas[index]?.category}
                        />
                      </div>
                      <div className='upload-item-cell'>
                        <Input
                          onChange={(e) => {
                            const updatedArrayOnChange: any = []
                            fileMetadatas.forEach((metadata: any) => {
                              updatedArrayOnChange.push(metadata)
                            })
                            updatedArrayOnChange[index] = {...fileMetadatas[index], name: e.target.value}
                            setFileMetadatas(updatedArrayOnChange)
                          }}
                          value={fileMetadatas[index]?.name || file.name}
                        />
                      </div>
                      <div>
                        <Typography variant='h5'>
                          <span>{`${Math.round(file.size / (1024 ** 2) * 100) / 100} MB`}</span>
                        </Typography>
                      </div>
                      <div style={{display: 'flex', gap: '5px'}}>
                        <Tooltip
                          followCursor
                          title={fileMetadatas[index].category.length === 0 ? 'Inserisci categoria per caricare il file' : 'Carica'}
                        >
                          <div>
                            <SecondaryButton
                              disabled={fileMetadatas[index].category.length === 0}
                              onClick={() => {
                                setOpenUploadFile({isOpen: true, indexFileToSubmit: index})
                              }}
                              style={{padding: 'unset'}}
                            >
                              <Icon path={mdiDownload} size={1} />
                            </SecondaryButton>
                          </div>
                        </Tooltip>
                        <Tooltip
                          followCursor
                          title='Cancella'
                        >
                          <div>
                            <SecondaryButton
                              onClick={() => {
                                setFileValues((prev: any) => {
                                  const left = prev.slice(0, index)
                                  const right = prev.slice(index + 1)
                                  return [...left, ...right]
                                })
                                setFileMetadatas((prev: any) => {
                                  const left = prev.slice(0, index)
                                  const right = prev.slice(index + 1)
                                  return [...left, ...right]
                                })
                              }}
                              style={{padding: 'unset'}}
                            >
                              <Icon path={mdiDelete} size={1} />
                            </SecondaryButton>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            <div className='footer-div' style={{display: 'flex', gap: '5px', marginTop: '20px'}}>
              <Tooltip
                followCursor
                title={!fileMetadatas.every((obj: any) => obj.category?.length > 0) ? 'Inserisci tutte le categorie per caricare i file' : ''}
              >
                <div>
                  <SecondaryButton
                    disabled={!fileMetadatas.every((obj: any) => obj.category?.length > 0)}
                    onClick={() => {
                      handleSubmitAllFiles()
                      setSnackbarMessage(
                        <div>
                          <Typography variant='h5'>
                            {'Errore in fase di caricamento'}
                          </Typography>
                          <div>
                            {'Alcuni dati inseriti non sono corretti'}
                          </div>
                        </div>
                      )
                    }}
                  >
                    <Icon path={mdiDownload} size={1} />
                    {'Carica tutto'}
                  </SecondaryButton>
                </div>
              </Tooltip>
              <SecondaryButton onClick={() => clearData()}>
                <Icon path={mdiDelete} size={1} />
                {'Rimuovi tutto'}
              </SecondaryButton>
            </div>
          </div>
        </UploaderStyled>
      </Popover>
    </div>
  )
}

export default Uploader
