import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {WeightDialogStyled} from './WeightDialogDialogStyled'
import locale from 'antd/es/date-picker/locale/it_IT'
import {DatePicker, Form, Input} from 'antd'
import moment from 'moment'
import {Typography} from '@mui/material'
import FormItem from 'antd/es/form/FormItem'

type WeightDialogProps = {
  record: Record<string, any>,
  onClose: () => void;
};

const WeightDialog: React.FC<WeightDialogProps> = ({record, onClose}) => {
  const body = (
    <WeightDialogStyled>
      <div className='title'>
        <Typography variant='h4'>{'Dati principali'}</Typography>
      </div>
      <Form layout='vertical'>
        <FormItem label='Data'>
          <DatePicker
            disabled
            format='DD MMM YYYY'
            locale={locale}
            value={moment(record?.date)}
          />
        </FormItem>
        <FormItem label='Peso' required>
          <Input disabled value={record?.weight} />
        </FormItem>
      </Form>
    </WeightDialogStyled>
  )

  return (
    <DialogCustom
      body={body}
      maxWidth='lg'
      onClose={onClose}
      title={{text: 'Peso', format: false}}
    />
  )
}

export default WeightDialog
