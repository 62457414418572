import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CommunicationDialogStyled} from './CommunicationDialogStyled'
import {FormattedMessage} from 'react-intl'
import {Divider, Typography} from '@mui/material'
import DOMPurify from 'dompurify'

type CommunicationDialogProps = {
  title?: string;
  date: string;
  message: string;
  type: string;
  htmlMessage: boolean;
  onClose: () => void;
};

const CommunicationDialog: React.FC<CommunicationDialogProps> = ({title, date, message, type, htmlMessage, onClose}) => {
  const body = (
    <CommunicationDialogStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h5'>
            <FormattedMessage id='message' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-element'>
            <Typography variant='body2'>
              <FormattedMessage id='receivedDate' />
            </Typography>
            <div className='container-body-value'>
              <Typography variant='body2'>
                {date}
              </Typography>
            </div>
          </div>
          <div className='container-body-element'>

            <Typography variant='body2'>
              <FormattedMessage id='type' />
            </Typography>
            <div className='container-body-value'>
              <Typography variant='body2'>
                {type}
              </Typography>
            </div>
          </div>
          <div className='container-body-element'>

            <Typography variant='body2'>
              <FormattedMessage id='titleObgect' />
            </Typography>
            <div className='container-body-value'>
              <Typography variant='body2'>
                {title}
              </Typography>
            </div>
          </div>
          <div className='container-body-element'>

            <Typography variant='body2'>
              <FormattedMessage id='message' />
            </Typography>
            {htmlMessage ?
              <div className='container-body-value container-body-value-long' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}} /> :
              <div className='container-body-value container-body-value-long'>
                <Typography variant='body2'>
                  {message}
                </Typography>
              </div>}
          </div>

        </div>

      </div>
    </CommunicationDialogStyled>
  )

  return (
    <DialogCustom
      body={body}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: title || '', format: false}}
    />
  )
}

export default CommunicationDialog
