import {styled} from '@mui/system'

export const DocumentsInfoModalStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10vh',
  marginTop: '5vh',
  marginRight: '10vh',
  height: '90vh',
  padding: theme.spacing(15),
  backgroundColor: '#fff',
  borderRadius: theme.spacing(7.5),
  fontSize: theme.spacing(12.5),
  fontWeight: 600,

  '.title-container': {
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: theme.spacing(10)
  },

  '.save-button-container': {
    display: 'flex',
    justifyContent: 'end',
    padding: `${theme.spacing(5)} 0px`
  },

  '.container-virtual-meeting': {
    overflow: 'auto'
  },

  '#main-data-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: theme.spacing(8),

    '.ant-row': {
      width: '100%'
    },

    '.name': {
      gridColumn: '1 / 7',
      gridRow: 1
    },

    '.description': {
      gridColumn: '1 / 7',
      gridRow: 2
    },

    '.type': {
      gridColumn: '1 / 7',
      gridRow: 3
    },

    '.category': {
      gridColumn: '1 / 7',
      gridRow: 4
    },

    '.fileName': {
      gridColumn: '1 /  5',
      gridRow: 5
    },

    '.size': {
      gridColumn: '5 /  7',
      gridRow: 5
    },

    '.field-content': {
      display: 'flex',

      '.ant-form-item': {
        width: '100%'
      },

      '.field-buttons': {
        display: 'flex',

        '.field-button': {
          color: theme.palette.neutral.main,
          borderRadius: theme.spacing(21),
          minWidth: theme.spacing(17),
          height: theme.spacing(21),
          '&:hover': {
            backgroundColor: theme.palette.neutral[200]
          }
        },

        '.video-button': {
          color: 'white',
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.spacing(21),
          minWidth: theme.spacing(17),
          height: theme.spacing(21),
          marginLeft: theme.spacing(2),
          '&:hover': {
            backgroundColor: theme.palette.primary[100]
          }
        }
      }
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-picker-input > input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    }
  },

  '.participants': {
    marginTop: theme.spacing(16)
  }
}))
