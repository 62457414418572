export const ITALIAN_PROVINCE = [
  {value: 'Agrigento', label: 'Agrigento'},
  {value: 'Alessandria', label: 'Alessandria'},
  {value: 'Ancona', label: 'Ancona'},
  {value: 'Aosta', label: 'Aosta'},
  {value: 'Arezzo', label: 'Arezzo'},
  {value: 'Ascoli Piceno', label: 'Ascoli Piceno'},
  {value: 'Asti', label: 'Asti'},
  {value: 'Avellino', label: 'Avellino'},
  {value: 'Bari', label: 'Bari'},
  {value: 'Barletta-Andria-Trani', label: 'Barletta-Andria-Trani'},
  {value: 'Belluno', label: 'Belluno'},
  {value: 'Benevento', label: 'Benevento'},
  {value: 'Bergamo', label: 'Bergamo'},
  {value: 'Biella', label: 'Biella'},
  {value: 'Bologna', label: 'Bologna'},
  {value: 'Bolzano', label: 'Bolzano'},
  {value: 'Brescia', label: 'Brescia'},
  {value: 'Brindisi', label: 'Brindisi'},
  {value: 'Cagliari', label: 'Cagliari'},
  {value: 'Caltanissetta', label: 'Caltanissetta'},
  {value: 'Campobasso', label: 'Campobasso'},
  {value: 'Carbonia-Iglesias', label: 'Carbonia-Iglesias'},
  {value: 'Caserta', label: 'Caserta'},
  {value: 'Catania', label: 'Catania'},
  {value: 'Catanzaro', label: 'Catanzaro'},
  {value: 'Chieti', label: 'Chieti'},
  {value: 'Como', label: 'Como'},
  {value: 'Cosenza', label: 'Cosenza'},
  {value: 'Cremona', label: 'Cremona'},
  {value: 'Crotone', label: 'Crotone'},
  {value: 'Cuneo', label: 'Cuneo'},
  {value: 'Enna', label: 'Enna'},
  {value: 'Fermo', label: 'Fermo'},
  {value: 'Ferrara', label: 'Ferrara'},
  {value: 'Firenze', label: 'Firenze'},
  {value: 'Foggia', label: 'Foggia'},
  {value: 'Forlì-Cesena', label: 'Forlì-Cesena'},
  {value: 'Frosinone', label: 'Frosinone'},
  {value: 'Genova', label: 'Genova'},
  {value: 'Gorizia', label: 'Gorizia'},
  {value: 'Grosseto', label: 'Grosseto'},
  {value: 'Imperia', label: 'Imperia'},
  {value: 'Isernia', label: 'Isernia'},
  {value: 'La Spezia', label: 'La Spezia'},
  {value: 'Latina', label: 'Latina'},
  {value: 'Lecce', label: 'Lecce'},
  {value: 'Lecco', label: 'Lecco'},
  {value: 'Livorno', label: 'Livorno'},
  {value: 'Lodi', label: 'Lodi'},
  {value: 'Lucca', label: 'Lucca'},
  {value: 'Macerata', label: 'Macerata'},
  {value: 'Mantova', label: 'Mantova'},
  {value: 'Massa-Carrara', label: 'Massa-Carrara'},
  {value: 'Matera', label: 'Matera'},
  {value: 'Messina', label: 'Messina'},
  {value: 'Milano', label: 'Milano'},
  {value: 'Modena', label: 'Modena'},
  {value: 'Monza e Brianza', label: 'Monza e Brianza'},
  {value: 'Napoli', label: 'Napoli'},
  {value: 'Novara', label: 'Novara'},
  {value: 'Nuoro', label: 'Nuoro'},
  {value: 'Ogliastra', label: 'Ogliastra'},
  {value: 'Olbia-Tempio', label: 'Olbia-Tempio'},
  {value: 'Oristano', label: 'Oristano'},
  {value: 'Padova', label: 'Padova'},
  {value: 'Palermo', label: 'Palermo'},
  {value: 'Parma', label: 'Parma'},
  {value: 'Pavia', label: 'Pavia'},
  {value: 'Perugia', label: 'Perugia'},
  {value: 'Pesaro e Urbino', label: 'Pesaro e Urbino'},
  {value: 'Pescara', label: 'Pescara'},
  {value: 'Piacenza', label: 'Piacenza'},
  {value: 'Pisa', label: 'Pisa'},
  {value: 'Pistoia', label: 'Pistoia'},
  {value: 'Pordenone', label: 'Pordenone'},
  {value: 'Potenza', label: 'Potenza'},
  {value: 'Prato', label: 'Prato'},
  {value: 'Ragusa', label: 'Ragusa'},
  {value: 'Ravenna', label: 'Ravenna'},
  {value: 'Reggio Calabria', label: 'Reggio Calabria'},
  {value: 'Reggio Emilia', label: 'Reggio Emilia'},
  {value: 'Rieti', label: 'Rieti'},
  {value: 'Rimini', label: 'Rimini'},
  {value: 'Roma', label: 'Roma'},
  {value: 'Rovigo', label: 'Rovigo'},
  {value: 'Salerno', label: 'Salerno'},
  {value: 'Medio Campidano', label: 'Medio Campidano'},
  {value: 'Sassari', label: 'Sassari'},
  {value: 'Savona', label: 'Savona'},
  {value: 'Siena', label: 'Siena'},
  {value: 'Siracusa', label: 'Siracusa'},
  {value: 'Sondrio', label: 'Sondrio'},
  {value: 'Taranto', label: 'Taranto'},
  {value: 'Teramo', label: 'Teramo'},
  {value: 'Terni', label: 'Terni'},
  {value: 'Torino', label: 'Torino'},
  {value: 'Ogliastra', label: 'Ogliastra'},
  {value: 'Trapani', label: 'Trapani'},
  {value: 'Trento', label: 'Trento'},
  {value: 'Treviso', label: 'Treviso'},
  {value: 'Trieste', label: 'Trieste'},
  {value: 'Udine', label: 'Udine'},
  {value: 'Varese', label: 'Varese'},
  {value: 'Venezia', label: 'Venezia'},
  {value: 'Verbano', label: 'Verbano-Cusio-Ossola'},
  {value: 'Vercelli', label: 'Vercelli'},
  {value: 'Verona', label: 'Verona'},
  {value: 'Vibo', label: 'Vibo'},
  {value: 'Vicenza', label: 'Vicenza'},
  {value: 'Viterbo', label: 'Viterbo'}
]
