
import {styled} from '@mui/system'
import {Calendar} from 'react-big-calendar'

export const CalendarStyled = styled(Calendar)(({theme}) => ({

  '.rbc-time-view': {
    background: '#F6F8F8'
  },

  '.rbc-month-view': {
    background: '#F6F8F8'
  },

  '.rbc-month-row': {
    overflow: 'visible'
  },

  '.rbc-today': {
    backgroundColor: theme.palette.primary[100]
  },

  '.rbc-header.rbc-today': {
    backgroundColor: 'rgba(255, 255, 221, 0.38) !important'
  },

  '.rbc-label': {
    fontSize: theme.spacing(7)
  },

  '.rbc-slot-selection': {
    backgroundColor: 'rgba(173, 216, 230, 0.5)'
  },

  '.rbc-event': {
    backgroundColor: theme.palette.primary[100],
    lineHeight: '1.3',
    border: 'unset',
    borderRadius: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    color: 'black',

    '.rbc-event-label': {
      display: 'none'
    },

    '.event-reason': {
      fontSize: '12px',
      paddingLeft: theme.spacing(4)
    },

    '#event-chip': {
      width: theme.spacing(3),
      height: theme.spacing(7),
      backgroundColor: '#5cb85c',
      float: 'left',
      display: 'inline',
      marginRight: theme.spacing(1)
    }
  },

  '.rbc-agenda-table': {
    thead: {
      display: 'none'
    },

    '.rbc-agenda-time-cell': {
      display: 'none'
    },

    tr: {
      display: 'flex',
      flexDirection: 'column'
    },

    '.rbc-agenda-date-cell': {
      background: '#eee',
      padding: `${theme.spacing(4)} ${theme.spacing(7)}`,

      '.date-box': {
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'capitalize'
      }
    },

    '.rbc-agenda-event-cell': {
      padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
      borderLeft: 'solid #27c24c',
      background: '#f5f5f5',

      '.time-label': {
        paddingRight: theme.spacing(7)
      },

      '.event-chip': {
        display: 'inline-block',
        width: theme.spacing(3),
        height: theme.spacing(8),
        backgroundColor: '#5cb85c',
        marginLeft: theme.spacing(7)
      },

      '.title-label': {
        paddingLeft: theme.spacing(7)
      }
    }

  }
}))
