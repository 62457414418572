import {Snackbar, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters from 'src/components/templates/Filters/Filters'
import {PATHS, STATUS, statesOptions} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import {useHistory} from 'react-router-dom'
import {useCookies} from 'react-cookie'

const buildDefaultFiltersFromConfig = (filterConfig = []) => {
  return filterConfig.reduce<Record<string, any>[]>((acc, {id, type, defaultValue}) => {
    if (defaultValue) {
      acc.push({
        property: id,
        type,
        value: defaultValue
      })
    }
    return acc
  }, [])
}

const Patients = () => {
  const pspPatientsConfigurations = useAppSelector(state => state.pspConfigReducer.data.patientsConfigurations)

  const columns = pspPatientsConfigurations.columns ?? []
  const filterConfig = pspPatientsConfigurations.filters ?? []
  const currentScope = useAppSelector(state => state.pspConfigReducer.data.scope)
  const history = useHistory()
  const [cookies] = useCookies(['tokens'])
  const [filters, setFilters] = useState<Record<string, string | string[]>[]>(buildDefaultFiltersFromConfig(filterConfig))
  const [patients, setPatients] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()
  const [programs, setPrograms] = useState<Record<string, any>[]>([])
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  useEffect(() => {
    const fetchPatients = async () => {
      const fetchedData = await apiClient.postPatientsSearch(filters, setStatus, pagination) ?? []
      const parsedData = fetchedData.map((patient: any) => {
        return {
          ...patient,
          state: statesOptions.find((option) => option.value === patient.state)?.label
        }
      })
      setPatients(parsedData)
      const fetchedDataCount = await apiClient.postPatientsSearchCount(filters, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }

    const fetchPrograms = async () => {
      const fetchedPrograms = await apiClient.getPrograms()
      setPrograms(fetchedPrograms)
    }

    fetchPatients()
    fetchPrograms()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const onPatientRowClick = ({_id, scope}: any) => {
    if (scope === currentScope) {
      history.push(`${PATHS.PATIENTS}/${_id}`)
    } else {
      const host = programs.find(({scope: programScope}) => programScope === scope)?.url ?? window.location.host
      const patientUrl = new URL(`${PATHS.PATIENTS}/${_id}`, host)
      window.location.href = cookies.tokens ? `${patientUrl.href}?cookies=${cookies.tokens}&scope=${scope}` : patientUrl.href
    }
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Pazienti', href: PATHS.PATIENTS}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onPatientRowClick}
          pagination={pagination}
          rows={patients}
          totalElements={count}
        />
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000} onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              <FormattedMessage id='patientsWarning' />
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default Patients
