/* eslint-disable react-hooks/exhaustive-deps */
import {mdiAccount, mdiDownload, mdiFolder, mdiFolderAccount} from '@mdi/js'
import Icon from '@mdi/react'
import {Snackbar, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {docTypesOptions, PATHS, STATUS} from 'src/constants/constants'
import FileInfoDialog from 'src/components/templates/FileInfoDialog/FileInfoDialog'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'
import {useAppSelector} from 'src/hooks'

const InfoMaterials = () => {
  const [isFileInfoDialog, setIsFileInfoDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState <Record<string, any>>()
  const [filtersMaterials, setFiltersMaterials] = useState<Record<string, any>[]>([])
  const [paginationMaterials, setPaginationMaterials] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [dataMaterials, setDataMaterials] = useState<Record<string, any>[]>([])
  const [statusMaterials, setStatusMaterials] = useState<string>()
  const [countMaterials, setCountMaterials] = useState<number>(0)
  const [filtersLinks, setFiltersLinks] = useState<Record<string, any>[]>([])
  const [paginationLinks, setPaginationLinks] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [dataLinks, setDataLinks] = useState<Record<string, any>[]>([])
  const [statusLinks, setStatusLinks] = useState<string>()
  const [countLinks, setCountLinks] = useState<number>(0)

  const [statusDownload, setStatusDownload] = useState<any>(undefined)
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [MIMETypeValue, setMIMETypeValue] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)

  const fetchFile = async (fileId: string, mime?: string) => {
    const {data: fileResponse} = await apiClient.download(fileId, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: mime})
    return (window.URL || window.webkitURL).createObjectURL(blob)
  }

  const dowloadFile = async (fileId: string, fileNameDowload: string) => {
    const filepath = await fetchFile(fileId, '')
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', fileNameDowload)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const MIMEtype = (fileNameMime?: string) => {
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'JPEG' || fileNameMime.split('.')?.pop()?.toUpperCase() === 'JPG')) {
      return 'image/jpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'AVI')) {
      return 'video/x-msvideo'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MP3')) {
      return 'audio/mpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MP4')) {
      return 'video/mp4'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MPEG')) {
      return 'video/mpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'PNG')) {
      return 'image/png'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'PDF')) {
      return 'application/pdf'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'SVG')) {
      return 'image/svg+xml'
    }
  }

  const handleModalView = async (row: any) => {
    setSelectedFile(row)
    const mime = MIMEtype(row.fileName)
    if (mime) {
      setOpenViewModal(true)
      const filePath = await fetchFile(row.fileId, MIMEtype(row.fileName))
      setMIMETypeValue(MIMEtype(row.fileName))
      setFile(filePath)
      setFileName(row.fileName)
    } else {
      dowloadFile(row.fileId, row.fileName)
    }
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])
  const {categoryOptions: materialsCategoryOptions, filtersConfig: usefulMaterialsFilterConfig} = useAppSelector(state => state.pspConfigReducer.data.infoMaterialsConfigurations)

  const usefulMaterialsActions : Action[] = [
    {
      id: 'info',
      label: 'Info file',
      icon: mdiAccount,
      action: (row) => { setSelectedFile(row); setIsFileInfoDialog(true) }
    },
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: handleModalView
    }
  ]

  const parseMaterialsCategoryToLabel = (categoryValue: string) => {
    return materialsCategoryOptions.find((category: any) => category.value === categoryValue)?.label
  }

  const parseMaterialsCategories = (category: any) => {
    if (category) {
      if (category.length > 0) {
        return category.map((categ: any) => {
          return parseMaterialsCategoryToLabel(categ)
        }).join(', ')
      }
      return '-'
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getInfoMaterials(filtersMaterials, setStatusMaterials, paginationMaterials) ?? []
      const parsedData = fetchedData.map((document: any) => {
        return {
          ...document,
          category: parseMaterialsCategories(document.category),
          type: docTypesOptions.find((docType: any) => docType.value === document.type)?.label
        }
      })
      setDataMaterials(parsedData)
      const fetchedDataCount = await apiClient.getInfoMaterialsCount(filtersMaterials, setStatusMaterials) ?? []
      setCountMaterials(fetchedDataCount)
    }

    fetchData()
  }, [filtersMaterials, paginationMaterials])

  const usefulMaterialsFilterSubmit = (values: any) => {
    setFiltersMaterials(values)
  }

  const usefulMaterialsColumns : TableColumn[] = [
    {
      id: 'category',
      label: 'CATEGORIA'
    },
    {
      id: 'createdAt',
      label: 'CARICATO IL',
      type: 'date',
      format: 'ddd DD MMM YYYY HH:mm'
    },
    {
      id: 'name',
      label: 'NOME',
      default: 'fileName'
    },
    {
      id: 'type',
      label: 'TIPO'
    }
  ]

  const usefulLinksFilterConfig: FilterItem[] = [
    {
      id: 'name',
      label: 'Titolo',
      type: 'string'
    },
    {
      id: 'rangeDates',
      label: 'Data',
      type: 'rangePicker'
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getLinks(filtersLinks, setStatusLinks, paginationLinks) ?? []
      setDataLinks(fetchedData)
      const fetchedDataCount = await apiClient.getLinksCount(filtersLinks, setStatusLinks) ?? []
      setCountLinks(fetchedDataCount)
    }

    fetchData()
  }, [filtersLinks, paginationLinks])

  const usefulLinksFilterSubmit = (values: any) => {
    setFiltersLinks(values)
  }

  const usefulLinksColumns : TableColumn[] = [
    {
      id: 'createdAt',
      label: 'CARICATO IL',
      type: 'date',
      format: 'ddd DD MMM YYYY HH:mm'
    },
    {
      id: 'name',
      label: 'TITOLO'
    },
    {
      id: 'url',
      label: 'URL'
    }
  ]

  const usefulMaterialContent = (
    <div>
      <div style={{display: 'flex', padding: '16px', justifyContent: 'end'}}>
        <Filters config={usefulMaterialsFilterConfig} onSubmit={usefulMaterialsFilterSubmit} />
      </div>
      <TableCustom
        actions={usefulMaterialsActions}
        columns={usefulMaterialsColumns}
        onPaginationChange={setPaginationMaterials}
        onRowClick={handleModalView}
        pagination={paginationMaterials}
        rows={dataMaterials}
        totalElements={countMaterials}
      />
    </div>
  )

  const usefulLinksContent = (
    <div>
      <div style={{display: 'flex', padding: '16px', justifyContent: 'end'}}>
        <Filters config={usefulLinksFilterConfig} onSubmit={usefulLinksFilterSubmit} />
      </div>
      <TableCustom
        columns={usefulLinksColumns}
        onPaginationChange={setPaginationLinks}
        onRowClick={(row) => window.open(row.url, '_blank')}
        pagination={paginationLinks}
        rows={dataLinks}
        totalElements={countLinks}
      />
    </div>
  )

  return (
    <LayoutContainer breadcrumbs={[{label: 'Materiali utili', href: PATHS.INFO_MATERIALS}]}>
      <TabsCustom
        contents={[
          usefulMaterialContent,
          usefulLinksContent
        ]}
        tabs={[
          {
            icon: <Icon path={mdiFolderAccount} size={1} />,
            label: <Typography style={{fontSize: 12, fontWeight: 600}}><FormattedMessage id='usefulMaterials' /></Typography>
          },
          {
            icon: <Icon path={mdiFolder} size={1} />,
            label: <Typography style={{fontSize: 12, fontWeight: 600}}><FormattedMessage id='usefulLinks' /></Typography>
          }
        ]}
      />
      {isFileInfoDialog &&
        <FileInfoDialog
          categoryOptions={materialsCategoryOptions}
          data={selectedFile}
          onClose={() => setIsFileInfoDialog(false)}
        />}
      <FileViewerModal
        MIMETypeValue={MIMETypeValue}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setMIMETypeValue(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={statusDownload}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={2000} onClose={() => setStatusMaterials(undefined)} open={statusMaterials === 'REJECTED'}>
        <div>
          <SnackbarAlertCustom onClose={() => setStatusMaterials(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti condivisi.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={2000} onClose={() => setStatusLinks(undefined)} open={statusLinks === 'REJECTED'}>
        <div>
          <SnackbarAlertCustom onClose={() => setStatusLinks(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i link.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default InfoMaterials
