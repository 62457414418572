import {mdiMenu} from '@mdi/js'
import {Typography} from '@mui/material'
import ActionsMenu, {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import {TableHeaderStyled} from './TableHeaderStyled'

type TableHeaderProps = {
  children: any,
  actions?: Action[]
}

const TableHeader: React.FC<TableHeaderProps> = ({
  children,
  actions
}) => {
  return (
    <TableHeaderStyled>
      <div className='header-content'>
        <Typography variant='h4'>
          {children}
        </Typography>
        {actions ? <ActionsMenu actions={actions} icon={mdiMenu} /> : <></>}
      </div>
    </TableHeaderStyled>
  )
}

export default TableHeader
