import {styled} from '@mui/system'

export const ExportDialogStyled = styled('div')(({theme}) => ({
  padding: theme.spacing(8),

  '#export-form': {
    display: 'grid',
    columnGap: '10px',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',

    '.element-icon': {
      display: 'flex',
      gap: theme.spacing(2.5),
      flexDirection: 'row',
      alignItems: 'flex-end',
      '.MuiButtonBase-root': {
        padding: '4px'
      }
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },
    '.ant-input': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        }
      }
    },

    '.user': {
      display: 'flex',
      '.ant-row': {
        width: '100%'
      },

      '.user-content': {
        display: 'flex',

        '.ant-form-item': {
          width: '100%'
        },

        '.user-buttons': {
          display: 'flex',

          '.user-button': {
            color: theme.palette.neutral.main,
            borderRadius: theme.spacing(21),
            minWidth: theme.spacing(17),
            height: theme.spacing(21),
            '&:hover': {
              backgroundColor: theme.palette.neutral[200]
            }
          }
        },

        '.ant-input[disabled]': {
          backgroundColor: '#eee',
          borderColor: '#ccc',
          color: '#555'
        }
      }
    }
  }
}))
