/* eslint-disable react-hooks/exhaustive-deps */
import {mdiClose} from '@mdi/js'
import Icon from '@mdi/react'
import {IconButton, Typography} from '@mui/material'
import {DocumentsInfoModalStyled} from './DocumentsInfoModalStyled'
import CardCustom from '../CardCustom/CardCustom'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'
import {Form, Input, Select} from 'antd'
import {useEffect} from 'react'
const {TextArea} = Input

const DocumentsInfoModal = ({documentInfo, handleClose, categoryOptions, readOnly, handleEdit}: any) => {
  const [form] = Form.useForm()

  const registryContent = (
    <div>
      <div className='container-virtual-meeting'>
        <CardCustom title={<Typography fontWeight={700} variant='h4'><FormattedMessage id='mainData' /></Typography>}>
          <Form
            form={form}
            id='main-data-form'
            layout='vertical'
          >
            <Form.Item
              className='name'
              label='Nome'
              name='name'
            >
              <Input disabled={readOnly} />
            </Form.Item>
            <Form.Item
              className='description'
              label='Descrizione'
              name='description'
            >
              <TextArea disabled={readOnly} rows={4} />
            </Form.Item>
            <Form.Item
              className='type'
              label='Tipo Documento'
              name='type'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className='category'
              label='Categoria'
              name='category'
              rules={[{required: true, message: 'Categoria è un campo necessario'}]}
            >
              <Select
                disabled={readOnly}
                getPopupContainer={(trigger) => trigger}
                mode='multiple'
                options={categoryOptions}
              />
            </Form.Item>
            <Form.Item
              className='fileName'
              label='Nome file'
              name='fileName'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className='size'
              label='Dimensione'
              name='size'
            >
              <Input disabled />
            </Form.Item>
          </Form>
        </CardCustom>
      </div>
      <div className='save-button-container'>
        <PrimaryButton
          disabled={readOnly}
          key='save'
          onClick={() => handleEdit(form, documentInfo._id)}
        >
          <FormattedMessage id='save' />
        </PrimaryButton>
      </div>
    </div>
  )

  useEffect(() => {
    form.setFieldValue('name', documentInfo?.name || documentInfo?.fileName)
    form.setFieldValue('description', documentInfo?.description)
    form.setFieldValue('type', documentInfo?.type)
    form.setFieldValue('fileName', documentInfo?.fileName)
    form.setFieldValue('size', documentInfo?.size ? `${Math.round(documentInfo?.size / (1024 ** 2) * 100) / 100} MB` : '-')
    form.setFieldValue('category', documentInfo?.category || [])
  }, [form, documentInfo])

  return (
    <DocumentsInfoModalStyled>
      <div className='title-container'>
        {documentInfo.name || documentInfo.fileName}
        <IconButton
          onClick={handleClose}
        >
          <Icon path={mdiClose} size={'25px'} />
        </IconButton>
      </div>
      {registryContent}
    </DocumentsInfoModalStyled>
  )
}

export default DocumentsInfoModal
