import {styled} from '@mui/system'

export const FilterButtonStyled = styled('div')(({theme}) => ({
  '.filter-button': {
    color: 'white',
    borderRadius: theme.spacing(25),
    width: theme.spacing(65),
    height: theme.spacing(22),
    textTransform: 'none',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },

    '.filter-text': {
      padding: '10px 30px 10px 10px'
    },

    '& .MuiButton-startIcon': {
      borderRadius: theme.spacing(25),
      backgroundColor: 'rgba(255,255,255,.2)',
      padding: theme.spacing(3)
    }
  },

  '.close-button': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    borderRadius: theme.spacing(25),
    width: theme.spacing(65),
    height: theme.spacing(22),
    textTransform: 'none',
    display: 'flex',
    '&:hover': {
      backgroundColor: 'white'
    },

    '.close-text': {
      paddingRight: theme.spacing(8)
    },

    '& .MuiButton-startIcon': {
      borderRadius: theme.spacing(25),

      backgroundColor: 'white',
      padding: theme.spacing(3)
    }
  }
}))
