import {Button, Typography} from '@mui/material'
import {Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Space, TimePicker} from 'antd'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CalendarEvent} from '../Calendar/Calendar'
import {CalendarDialogStyled} from './CalendarDialogStyled'
import locale from 'antd/es/date-picker/locale/it_IT'
import moment from 'moment'
import {useEffect, useState} from 'react'
import Icon from '@mdi/react'
import {mdiClose, mdiMagnify} from '@mdi/js'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import {useAppSelector} from 'src/hooks'
import DoctorsLookupDialog from '../DoctorsLookupDialog/DoctorsLookupDialog'

type CalendarDialogProps = {
  open?: boolean,
  event?: CalendarEvent,
  eventsTypes?: Record<string, any>[],
  onClose: () => void,
  onSave: (values: CalendarEvent) => void;
};

const CalendarDialog: React.FC<CalendarDialogProps> = ({
  open,
  event,
  eventsTypes,
  onClose,
  onSave
}) => {
  const {participantUserGroup, eventTypes: eventTypesConfig} = useAppSelector(state => state.pspConfigReducer.data.agendaConfigurations)
  const {_id} = useAppSelector(state => state.userReducer.data)

  const [form] = Form.useForm()
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [isDoctorsModal, setIsDoctorsModal] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<Record<string, any>>()
  const [title, setTitle] = useState<string>('')
  const profileConfig = useAppSelector(state => state.userReducer.data)

  const warningOptions = [
    {
      label: 'Giorni',
      value: 86400000
    },
    {
      label: 'Settimane',
      value: 604800000
    },
    {
      label: 'Mesi',
      value: 2592000000
    },
    {
      label: 'Anni',
      value: 31536000000
    }
  ]

  const computeWarning = (reminderMilliseconds: number | undefined) => {
    if (reminderMilliseconds === undefined) {
      return {
        value: undefined,
        unit: undefined
      }
    } else if (reminderMilliseconds < 604800000) {
      return {
        value: reminderMilliseconds / 86400000,
        unit: 86400000
      }
    } else if (reminderMilliseconds < 2592000000) {
      return {
        value: reminderMilliseconds / 604800000,
        unit: 604800000
      }
    } else if (reminderMilliseconds < 31536000000) {
      return {
        value: reminderMilliseconds / 2592000000,
        unit: 2592000000
      }
    } else {
      return {
        value: reminderMilliseconds / 31536000000,
        unit: 31536000000
      }
    }
  }

  useEffect(() => {
    form.setFieldValue('eventTypeId', eventsTypes?.find(eventsType => event?.title === eventsType.name)?._id)
    form.setFieldValue('site', event?.site)
    form.setFieldValue('startDay', moment(event?.startDate))
    form.setFieldValue('startDate', moment(event?.startDate))
    form.setFieldValue('endDate', moment(event?.endDate))
    form.setFieldValue('channels', event?.channels)
    form.setFieldValue('shareWithPatient', event?.shareWithPatient)
    form.setFieldValue(['warning', 'unit'], computeWarning(event?.reminderMilliseconds).unit)
    form.setFieldValue(['warning', 'value'], computeWarning(event?.reminderMilliseconds).value)

    setTitle(event?.title ?? '')
    const user = participantUserGroup === 'DOCTOR' ?
      event?.participants?.find((participant) => participant.type === 'doctors' && participant._id !== _id) :
      event?.participants?.find((participant) => participant.type === 'patients')
    form.setFieldValue('user', user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : event?.user)
    setCurrentUser(user)
  }, [form, event, participantUserGroup, _id, eventsTypes])

  const reasonOptions = eventsTypes
    ?.filter(({name}) => eventTypesConfig?.includes(name))
    ?.map(eventType => ({label: eventType.name, value: eventType._id})) ?? []

  const siteOptions = [
    {
      label: 'Centro clinico',
      value: 'CLINICAL_CENTER'
    },
    {
      label: 'Laboratorio analisi',
      value: 'ANALYSIS_LABORATORY'
    },
    {
      label: 'Ambulatorio MMG/PLS',
      value: 'MMG_PLS_CLINIC'
    }
  ]

  const onFinish = (values : any) => {
    const body = {
      ...values,
      title,
      startDate: values.startDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString(),
      endDate: values.endDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString(),
      user: currentUser ?? values.user,
      shareWithPatient: values.shareWithPatient ?? false,
      type: 'PERSONAL_EVENT',
      participantUserGroup
    }

    delete body.startDay

    onSave(body)
  }

  const body = (
    <CalendarDialogStyled>
      <Form
        form={form}
        id='event-form'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          className='reason'
          label='Motivo'
          name='eventTypeId'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Select
            allowClear
            disabled={profileConfig.userGroup.toUpperCase().includes('PATIENT') && event?.shareWithPatient}
            getPopupContainer={(trigger) => trigger}
            onChange={(value) => setTitle(eventsTypes?.find(eventType => eventType._id === value)?.name)}
            options={reasonOptions}
            placeholder='Seleziona motivo evento...'
          />
        </Form.Item>
        <Form.Item
          className='site'
          label='Dove'
          name='site'
        >
          <Select
            allowClear
            disabled={profileConfig.userGroup.toUpperCase().includes('PATIENT') && event?.shareWithPatient}
            getPopupContainer={(trigger) => trigger}
            options={siteOptions}
          />
        </Form.Item>
        <Form.Item
          className='startDay'
          label='Data evento'
          name='startDay'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <DatePicker
            disabled={profileConfig.userGroup.toUpperCase().includes('PATIENT') && event?.shareWithPatient}
            format='DD MMM YYYY'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='startDate'
          label='Dalle'
          name='startDate'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <TimePicker
            disabled={profileConfig.userGroup.toUpperCase().includes('PATIENT') && event?.shareWithPatient}
            format='HH:mm'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='endDate'
          label='Alle'
          name='endDate'
          rules={[
            {required: true, message: 'Campo obbligatorio'},
            () => ({
              validator (_, value) {
                if (
                  value.set('date', form.getFieldValue('startDate').date()).isBefore(form.getFieldValue('startDate')) ||
                  value.set('date', form.getFieldValue('startDate').date()).isSame(form.getFieldValue('startDate'))
                ) {
                  return Promise.reject(new Error('L\'orario di inizio deve essere inferiore all\'orario di fine'))
                }
                return Promise.resolve()
              }
            })
          ]}
        >
          <TimePicker
            disabled={profileConfig.userGroup.toUpperCase().includes('PATIENT') && event?.shareWithPatient}
            format='HH:mm'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='warning'
          label='Avvisami'
        >
          <Form.Item
            name={['warning', 'value']}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            className='warning-unit'
            name={['warning', 'unit']}
          >
            <Select
              allowClear
              getPopupContainer={(trigger) => trigger}
              options={warningOptions}
              placeholder='Seleziona unità di tempo'
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          className='channels'
          label='Metodo di avviso'
          name='channels'
        >
          <Checkbox.Group>
            <Space direction='vertical'>
              <Checkbox key={'sms'} value={'sms'}>
                <Typography variant='body2'>{'Notifica tramite sms'}</Typography>
              </Checkbox>
              <Checkbox key={'e-mail'} value={'e-mail'}>
                <Typography variant='body2'>{'Notifica tramite e-mail'}</Typography>
              </Checkbox>
              <Checkbox key={'push'} value={'push'}>
                <Typography variant='body2'>{'Notifica tramite push'}</Typography>
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        {(!profileConfig.userGroup.toUpperCase().includes('PATIENT') && !profileConfig.userGroup.toUpperCase().includes('INSURANCEUSER')) &&
          <>
            {participantUserGroup ?
              <Form.Item
                className='patient'
                label={participantUserGroup === 'PATIENT' ? 'Paziente' : 'Altro partecipante'}
              >
                <div className='patient-content'>
                  <Form.Item
                    name='user'
                    rules={[{required: true, message: 'Campo obbligatorio'}]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <div className='patient-buttons'>
                    <Button
                      className='patient-button'
                      onClick={() => participantUserGroup === 'DOCTOR' ? setIsDoctorsModal(true) : setIsPatientsModal(true)}
                    >
                      <Icon path={mdiMagnify} size={1} />
                    </Button>
                    <Button
                      className='patient-button'
                      onClick={() => form.setFieldValue('user', undefined)}
                    >
                      <Icon path={mdiClose} size={1} />
                    </Button>
                  </div>
                </div>
              </Form.Item> :
              <Form.Item
                className='patient'
                label={'Altro partecipante'}
                name='user'
              >
                <Input />
              </Form.Item>}
            {participantUserGroup === 'PATIENT' &&
              <Form.Item
                className='sharedWithPatients'
                label='Condividi con il paziente'
                name='shareWithPatient'
              >
                <Radio.Group>
                  <Radio value>{'Sì'}</Radio>
                  <Radio value={false}>{'No'}</Radio>
                </Radio.Group>
              </Form.Item>}
          </>}
      </Form>
    </CalendarDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton
        form='event-form'
        key='submit'
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <>
      <DialogCustom
        body={body}
        footer={footer}
        maxWidth='md'
        onClose={onClose}
        open={open}
        title={{text: participantUserGroup === 'DOCTOR' ? 'appointment' : 'patientEvent', format: true}}
      />
      {isPatientsModal &&
        <PatientLookupDialog
          onClose={() => setIsPatientsModal(false)}
          onRowClick={(row) => {
            setCurrentUser(row)
            form.setFieldValue('user', `${row.firstName} ${row.lastName}`)
            setIsPatientsModal(false)
          }}
        />}
      {isDoctorsModal &&
        <DoctorsLookupDialog
          onClose={() => setIsDoctorsModal(false)}
          onRowClick={(row) => {
            setCurrentUser(row)
            form.setFieldValue('user', `${row.firstName} ${row.lastName}`)
            setIsDoctorsModal(false)
          }}
        />}
    </>
  )
}

export default CalendarDialog
