import {CardContent, CardHeader} from '@mui/material'
import {PropsWithChildren} from 'react'
import {CardCustomStyle} from './CardCustomStyle'

type CardCustomProps = {
    title: React.ReactNode
}

const CardCustom: React.FC<PropsWithChildren<CardCustomProps>> = ({title, children}) => {
  return (
    <CardCustomStyle>
      <CardHeader title={title} />
      <CardContent>
        {children}
      </CardContent>
    </CardCustomStyle>
  )
}

export default CardCustom
