import {styled} from '@mui/system'

export const SnackbarAlertCustomStyled = styled('div')(({theme}) => ({
  '.MuiAlert-root': {
    opacity: '0.8',
    color: 'white',
    width: '350px'
  },
  '.MuiAlert-standardError': {
    backgroundColor: '#bd362f'
  },
  '.MuiAlert-standardSuccess': {
    backgroundColor: '#038a0e'
  },

  '.MuiAlert-icon': {
    color: 'white !important',
    alignSelf: 'center'
  },

  '.MuiTypography-root': {
    color: 'white'
  },

  '.MuiAlert-message': {
    alignSelf: 'center'
  }
}))
