import {Dialog, Paper, styled} from '@mui/material'

export const DialogCustomStyled = styled(Dialog)(({disableShadow}: any) => ({
  '.MuiPaper-root': {
    borderRadius: '16px',
    alignSelf: 'baseline',
    width: '100%',
    boxShadow: disableShadow && 'none',
    border: disableShadow && '1px solid lightgrey'
  },
  '.MuiTypography-root': {
    padding: 0
  },
  '.MuiDialogContent-root': {
    padding: 0
  },
  '.MuiDialogActions-root': {
    padding: 0
  },
  '.MuiBackdrop-root': {
    backgroundColor: disableShadow && 'transparent'
  }
}))

export const DialogPaperStyled = styled(Paper)(({theme}) => ({
  '.dialog-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(10)} ${theme.spacing(15)}`
  },
  '.dialog-title': {
    margin: 0
  },
  '.dialog-body': {
    padding: `${theme.spacing(0)} ${theme.spacing(15)}`
  },
  '.dialog-footer': {
    padding: `${theme.spacing(10)} ${theme.spacing(15)}`
  }
}))
