import {Snackbar} from '@mui/material'
import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const ProfileStyle = styled('div')(({theme}) => ({
  height: '100%',
  gridArea: 'main',
  position: 'relative',
  width: `calc(100vw - ${theme.spacing(STYLE.SIDEBAR_WIDTH)})`,
  zIndex: '10',
  overflowX: 'hidden',
  marginBottom: theme.spacing(12.5),

  '.layout-container-header': {
    backgroundColor: 'white',
    borderRadius: `${theme.spacing(20)} ${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)}`,
    padding: `${theme.spacing(15)} ${theme.spacing(10)} ${theme.spacing(10)} ${theme.spacing(10)}`,
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'flex-end',
    '.MuiButtonBase-root': {
      borderRadius: '50%',
      width: 'fit-content',
      height: 'fit-content',
      padding: 0
    },
    '.avatar': {
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary[100],
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '50%'
      },
      '&:hover': {
        border: `5px solid ${theme.palette.primary.main}`
      }
    },
    '.label': {
      textShadow: 'none',
      width: 'fit-content',
      color: '#fff',
      fontSize: '13px',
      padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main,
      fontWeight: '600'
    }
  },
  '.layout-container-body': {
    backgroundColor: 'white',
    padding: theme.spacing(8)
  }
}))

export const SnackbarStyled = styled(Snackbar)(({theme}) => ({
  top: '70px !important'
}))
