import React from 'react'
import {SurveyDialogStyle} from './SurveyDialogStyle'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'

export type SurveyDialogProps = {
  onClose: () => void;
  surveyData: any;
}

const SurveyDialog: React.FC<SurveyDialogProps> = ({onClose, surveyData}) => {
  const url = surveyData.state === 'TO_COMPILE' ?
    `/form-service/#/visualizer/fill-form/?formSchemaId=${surveyData.formSchemaId}` :
    `/form-service/#/visualizer/${surveyData.formDataId}`

  const body = (
    <SurveyDialogStyle>
      <iframe height='100%' src={url} title={surveyData.name} width='100%' />
    </SurveyDialogStyle>
  )

  return (
    <DialogCustom
      body={body}
      maxWidth='xl'
      onClose={onClose}
      title={{text: surveyData.name, format: false}}
    />
  )
}

export default SurveyDialog
