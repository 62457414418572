import Icon from '@mdi/react'
import {Snackbar, Typography} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import CommunicationDialog from 'src/components/templates/CommunicationDialog/CommunicationDialog'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {PATHS, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import {mdiEmailOpen, mdiEmail} from '@mdi/js'
import {useDispatch} from 'react-redux'
import {setUnreadNotificationsCount} from 'src/slices/userSlice'

const NOTIFICATION_TYPES: Record<string, string> = {
  email: 'Messaggio Email',
  sms: 'SMS',
  push: 'Notifica Push',
  web: 'Comunicazione Utente'
}

export const renderNotificationType = (type: string) => NOTIFICATION_TYPES[type] ?? type

const Communications = () => {
  const dispatch = useDispatch()
  const profileConfig = useAppSelector(state => state.userReducer.data)

  profileConfig.userGroup.toUpperCase().includes('PATIENT')
  const columns: TableColumn[] = [
    {
      id: 'hasBeenRead',
      render: hasBeenRead => <Icon path={hasBeenRead ? mdiEmailOpen : mdiEmail} size={1} />
    },
    {
      id: 'sentDate',
      label: 'DATA INVIO',
      type: 'date',
      format: 'DD/MM/YYYY HH:mm'
    },
    {
      id: 'plainTextMessage',
      label: 'MESSAGGIO'
    },
    {
      id: 'type',
      label: 'TIPO'
    },
    {
      id: 'mdiArrowBottomLeft'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'date',
      label: 'Data',
      type: 'rangePicker'
    }
  ]

  const [filters, setFilters] = useState<Record<string, string | string[]>>({})
  const [notifications, setNotifications] = useState<Record<string, string>[]>([])
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [status, setStatus] = useState()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedMessage, setSelectedMessage] = useState<Record<string, any>>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const fetchNotifications = async () => {
    const filtersWithPagination = {
      ...filters,
      size: pagination.rowsPerPage,
      page: pagination.page,
      sort: pagination.order
    }
    const data = await apiClient.getNotifications(filtersWithPagination, setStatus)
    setNotifications(data ?? [])

    const notificationCount = await apiClient.getNotificationsCount(filters)
    setNotificationsCount(notificationCount)

    const unreadNotificationsCount = await apiClient.getNotificationsCount({hasBeenRead: false})
    dispatch(setUnreadNotificationsCount(unreadNotificationsCount))
  }

  useEffect(() => {
    fetchNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filters])

  const onRowClicked = async (row: Record<string, any>) => {
    setSelectedMessage({...row, message: row.message[1]})
    setOpen(true)
    if (!row.hasBeenRead) {
      const [notificationId, messageIndex] = row.id.split('-')
      await apiClient.patchNotificationMessage(notificationId, messageIndex, {hasBeenRead: true})
      await fetchNotifications()
    }
  }

  const onCloseDialog = () => {
    setOpen(false)
    setSelectedMessage(undefined)
  }

  const onFiltersSubmit = ([{value}]: any) => {
    setFilters({
      startDate: value[0],
      endDate: value[1]
    })
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Notifiche e messaggi', href: PATHS.COMMUNICATIONS}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onFiltersSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClicked}
          pagination={pagination}
          rows={notifications.map(notification => ({
            ...notification,
            message: [notification?.title, notification.message],
            type: renderNotificationType(notification.type)
          }))}
          totalElements={notificationsCount}
        />
      </div>
      {(open && selectedMessage) &&
        <CommunicationDialog
          date={selectedMessage?.sentDate ? moment(selectedMessage.sentDate).format('DD/MM/YYYY HH:mm') : ''}
          htmlMessage={!!selectedMessage?.message}
          message={selectedMessage?.message || selectedMessage?.plainTextMessage}
          onClose={onCloseDialog}
          title={selectedMessage?.title}
          type={renderNotificationType(selectedMessage.type)}
        />}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatus(undefined)}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatus(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare le notifiche.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default Communications
