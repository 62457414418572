import {styled} from '@mui/system'

export const FileInfoDialogStyled = styled('div')(({theme}) => ({

  '#file-info-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '10px',

    '.name': {
      gridColumn: '1 / 4',
      gridRow: 1
    },

    '.description': {
      gridColumn: '1 / 4',
      gridRow: 2,
      textarea: {
        height: theme.spacing(40)
      }
    },

    '.type': {
      gridColumn: '1 / 4',
      gridRow: 3
    },

    '.category': {
      gridColumn: '1 / 4',
      gridRow: 4
    },

    '.filename': {
      gridColumn: '1 / 3',
      gridRow: 5
    },

    '.size': {
      gridColumn: '3',
      gridRow: 5
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset',
          borderRadius: '42px',
          fontSize: '12px',
          color: '#555'

        }
      }
    },

    '.ant-select-disabled': {

      '.ant-select-selector': {
        backgroundColor: '#eee !important',
        borderColor: '#ccc',
        color: '#555 !important'
      }
    }
  }
}))
