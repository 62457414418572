import {Form, Select} from 'antd'
import {WEEK_DAY_OPTIONS, TIMESLOT_OPTIONS} from '../../utils/day-time'
import {DeleteOutlined} from '@ant-design/icons'
import {FlexColumnStyled, FormStyle, SlotSelector, SlotSelectorContainer} from './TimeslotsSelectorStyled'
import {TimeslotProps} from 'src/types/types'
import {IconButton} from '@mui/material'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'

interface TimeslotSelectorProps {
  value?: TimeslotProps;
  onChange: (value: TimeslotProps) => void;
}

export function TimeslotSelector ({
  value = {},
  onChange
}: TimeslotSelectorProps) {
  return (
    <SlotSelector>
      <Form.Item label='Giorno' style={{marginBottom: 0}}>
        <Select
          getPopupContainer={(trigger) => trigger}
          onSelect={(day: string) => onChange({...value, day})}
          options={WEEK_DAY_OPTIONS}
          placeholder='Seleziona'
          style={{width: 110}}
          value={value.day}
        />
      </Form.Item>
      <Form.Item label='Dalle' style={{marginBottom: 0}}>
        <Select
          getPopupContainer={(trigger) => trigger}
          onSelect={(from: string) => onChange({...value, from})}
          options={TIMESLOT_OPTIONS}
          placeholder='--:--'
          style={{width: 80}}
          value={value.from}
        />
      </Form.Item>
      <Form.Item label='Alle' style={{marginBottom: 0}}>
        <Select
          getPopupContainer={(trigger) => trigger}
          onSelect={(to: string) => onChange({...value, to})}
          options={TIMESLOT_OPTIONS}
          placeholder='--:--'
          style={{width: 80}}
          value={value.to}
        />
      </Form.Item>
    </SlotSelector>
  )
}

interface TimeslotsSelectorProps {
  value?: TimeslotProps[];
  onChange: (value: TimeslotProps[]) => void;
}

export default function TimeslotsSelector ({
  value = [],
  onChange
}: TimeslotsSelectorProps) {
  const onAddTimeslot = () => {
    onChange([
      ...value,
      {day: undefined, from: undefined, to: undefined}
    ])
  }

  const onEditTimeslot = (index: number, timeslot: TimeslotProps) => {
    const updatedTimeslots = [...value]
    updatedTimeslots[index] = timeslot
    onChange(updatedTimeslots)
  }

  const onDeleteTimeslot = (index: number) => {
    const updatedTimeslots = [...value]
    updatedTimeslots.splice(index, 1)
    onChange(updatedTimeslots)
  }

  const timeslotSelectors = value.map((timeslot, index) => (
    <SlotSelectorContainer key={index}>
      <TimeslotSelector
        onChange={(item: TimeslotProps) => onEditTimeslot(index, item)}
        value={timeslot}
      />
      <IconButton
        onClick={() => onDeleteTimeslot(index)}
      ><DeleteOutlined style={{color: 'red'}} />
      </IconButton>
    </SlotSelectorContainer>
  ))

  return (
    <FlexColumnStyled>
      <SecondaryButton onClick={onAddTimeslot} style={{maxWidth: '200px'}}>
        {'Aggiungi fascia oraria'}
      </SecondaryButton>
      <FormStyle>
        {timeslotSelectors}
      </FormStyle>
    </FlexColumnStyled>
  )
}
