import {Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import ButtonText from 'src/components/elements/Buttons/ButtonText/ButtonText'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'

type WarningDialogProps = {
  onClose: () => void;
};

const WarningDialog: React.FC<WarningDialogProps> = ({onClose}) => {
  const body = <Typography variant='body1'><FormattedMessage id='warningDialogBody' /></Typography>

  const footer = (
    <ButtonText onClick={onClose}>
      <FormattedMessage id='warningDialogButton' />
    </ButtonText>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      onClose={onClose}
      title={{text: 'warningDialogTitle', format: true}}
    />
  )
}

export default WarningDialog
