
import React from 'react'
import {Button, Typography} from '@mui/material'
import Icon from '@mdi/react'
import {mdiCloseCircleOutline, mdiProgressUpload} from '@mdi/js'
import {UploadButtonStyled} from './UploadButtonStyled'

type UploadButtonProps = {
  open: boolean,
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

const UploadButton: React.FC<UploadButtonProps> = (
  {
    open,
    onClick
  }
) => {
  return (
    <UploadButtonStyled>
      {open ?
        <Button
          className='close-button'
          onClick={onClick}
          startIcon={<Icon path={mdiCloseCircleOutline} size={1.2} />}
          variant='contained'
        >
          <div className='close-text'>
            <Typography variant='body1'>
              {'Chiudi UPLOADER'}
            </Typography>
          </div>
        </Button> :
        <Button
          className='upload-button'
          onClick={onClick}
          startIcon={<Icon path={mdiProgressUpload} size={1} />}
          variant='contained'
        >
          <div className='upload-text'>
            <Typography variant='body1'>
              {'Carica documento'}
            </Typography>
          </div>
        </Button>}
    </UploadButtonStyled>
  )
}

export default UploadButton
