import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {DepartmentDialogStyle} from './DepartmentDialogStyle'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import ContactData from '../ContactData/ContactData'
import {useForm} from 'antd/lib/form/Form'
import {Form, Input} from 'antd'
import {useEffect, useState} from 'react'

export type DepartMentDialogProps = {
  department: Record<string, any>,
  onClose: () => void;
}

const DepartMentDialog: React.FC<DepartMentDialogProps> = (
  {
    department,
    onClose
  }
) => {
  const [form] = useForm()
  const [delegate1, setDelegate1] = useState<Record<string, any>>()
  const [delegate2, setDelegate2] = useState<Record<string, any>>()
  const [caregiver, setCaregiver] = useState<Record<string, any>>()

  const departmentConfig = {
    phoneNumber: {hiddent: false, readOnly: true},
    cellPhoneNumber: {hiddent: false, readOnly: true},
    email: {hiddent: false, readOnly: true},
    faxNumber: {hiddent: false, readOnly: true},
    address: {
      region: {hiddent: false, readOnly: true},
      province: {hiddent: false, readOnly: true},
      city: {hiddent: false, readOnly: true},
      address: {hiddent: false, readOnly: true},
      streetNumber: {hiddent: false, readOnly: true},
      postalCode: {hiddent: false, readOnly: true},
      locality: {hiddent: false, readOnly: true}
    }
  }

  useEffect(() => {
    form.setFieldValue('code', department?.code)
    form.setFieldValue('name', department?.name)
    form.setFieldValue('description', department?.description)
    form.setFieldValue('referentFirstName', department?.referentFirstName)
    form.setFieldValue('referentLastName', department?.referentLastName)
    form.setFieldValue('referentLastNameQualification', department?.referentLastNameQualification)
    form.setFieldValue('email', department?.email)
    form.setFieldValue('phoneNumber', department?.phoneNumber)
    form.setFieldValue('cellPhoneNumber', department?.cellPhoneNumber)
    form.setFieldValue('faxNumber', department?.faxNumber)
    form.setFieldValue('country', 'IT')
    form.setFieldValue('region', department?.region)
    form.setFieldValue('province', department?.province)
    form.setFieldValue('city', department?.city)
    form.setFieldValue('locality', department?.locality)
    form.setFieldValue('address', department?.address)
    form.setFieldValue('streetNumber', department?.streetNumber)
    form.setFieldValue('postalCode', department?.postalCode)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const body = (
    <DepartmentDialogStyle>
      <Form form={form} layout='vertical'>
        <CardCustom title='Dati principali'>
          <div className='card-start'>
            <div className='container-body-element'>
              <Form.Item
                label='Codice'
                name='code'
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div className='container-body-element'>
              <Form.Item
                label='Nome'
                name='name'
                required
              >
                <Input disabled />
              </Form.Item>
            </div>
          </div>
          <div className='container-body-element'>
            <Form.Item
              label='Descrizione'
              name='description'
            >
              <Input disabled style={{minHeight: '82px'}} />
            </Form.Item>
          </div>
          <div className='card-end'>
            <div className='container-body-element'>
              <Form.Item
                label='Nome referente responsabile'
                name='referentFirstName'
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div className='container-body-element'>
              <Form.Item
                label='Cognome referente responsabile'
                name='referentLastName'
              >
                <Input disabled />
              </Form.Item>
            </div>
            <div className='container-body-element'>
              <Form.Item
                label='Qualifica referente responsabile'
                name='referentLastNameQualification'
              >
                <Input disabled />
              </Form.Item>
            </div>
          </div>
        </CardCustom>
        <ContactData
          caregiverValues={{caregiver, setCaregiver}}
          delegate1Values={{delegate1, setDelegate1}}
          delegate2Values={{delegate2, setDelegate2}}
          form={form}
          profileConfiguration={departmentConfig}
          secondary
        />
      </Form>
    </DepartmentDialogStyle>
  )

  return (
    <DialogCustom
      body={body}
      maxWidth='lg'
      onClose={onClose}
      title={{text: department?.name, format: false}}
    />
  )
}

export default DepartMentDialog
