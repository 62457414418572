import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import {PatientDigitalEnrollmentStyled} from './PatientDigitalEnrollmentStyled'
import {Checkbox, DatePicker, Form, Input, Radio, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import locale from 'antd/es/date-picker/locale/it_IT'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {Snackbar, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import Header from 'src/components/templates/Header/Header'
import ReactHtmlParser from 'react-html-parser'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'

const GENDERS = [
  {value: 'FEMALE', label: 'Femmina'},
  {value: 'MALE', label: 'Maschio'},
  {value: 'NOT_DECLARED', label: 'Non dichiarato'},
  {value: 'OTHER', label: 'Altro'}
]

const POSTAL_CODE_REGEX = /^[0-9]*$/
const USERNAME_REGEX = /^[a-zA-Z0-9_+\-.!#$'^`~@]+$/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/

const preferredContactMethodsOptions = [
  {value: 'PHONE_NUMBER', label: 'Telefono'},
  {value: 'CELL_PHONE_NUMBER', label: 'Cellulare'},
  {value: 'EMAIL', label: 'Email'},
  {value: 'SMS', label: 'SMS'}
]

const consentOptions = [
  {value: 'CONSENT', label: 'Esprimo il consenso'},
  {value: 'NOT_CONSENT', label: 'Nego il consenso'}
]

type Option = {
  value: string,
  label: string,
}

const PatientDigitalEnrollment = () => {
  const [config, setConfig] = useState<any>()
  const [request, setRequest] = useState<any>()
  const [status, setStatus] = useState<any>(undefined)
  const [regions, setRegions] = useState<Option[]>([])
  const [provinces, setProvinces] = useState<Option[]>([])
  const [cities, setCities] = useState<Option[]>([])
  const [regionQuery, setRegionQuery] = useState<string>('')
  const [provinceQuery, setProvinceQuery] = useState<{search?: string, region?: string}>({})
  const [cityQuery, setCityQuery] = useState<{search?: string, province?: string}>({})
  // const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true)
  const [step, setStep] = useState<number>(0)
  const [formValues, setFormValues] = useState<any>({})

  const [form] = useForm()
  const id = window.location.pathname.split('/')[2]

  async function getLoginConfig () {
    const response = await apiClient.loginConfiguration()
    setConfig(response)
  }

  async function getRequest () {
    const response = await apiClient.getByIdDigitalEnrollment(id, setStatus)
    setRequest(response)
    form.setFieldValue('firstName', response?.firstName)
    form.setFieldValue('lastName', response?.lastName)
    form.setFieldValue('email', response?.email)
    form.setFieldValue('cellPhoneNumber', response?.cellPhoneNumber)
    form.setFieldValue('country', 'Italia')
  }

  async function getRegions () {
    const regionsData = await apiClient.getRegions(regionQuery, setStatus)
    setRegions(regionsData)
  }

  async function getProvinces () {
    form.setFieldValue('province', undefined)
    form.setFieldValue('city', undefined)
    const provincesData = await apiClient.getProvinces(provinceQuery, setStatus)
    setProvinces(provincesData)
  }

  async function getCities () {
    form.setFieldValue('city', undefined)
    const citiesData = await apiClient.getCities(cityQuery, setStatus)
    setCities(citiesData)
  }

  // async function checkUsername (username:string) {
  //   const validUsername = await apiClient.checkUsername(username, setStatus)
  //   setIsUsernameValid(validUsername)
  // }

  useEffect(() => {
    getLoginConfig()
    getRequest()
    getRegions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQuery])

  useEffect(() => {
    getProvinces()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceQuery])

  useEffect(() => {
    getCities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityQuery])

  const onFinish = async (values: Record<string, any>) => {
    delete formValues.confirmPassword
    delete values.consentPassword

    const privacyConsents: string[] = []
    config.consentCheckboxOptions.forEach((checkbox: any) => {
      if (values[checkbox.value] === 'CONSENT') {
        privacyConsents.push(checkbox.value)
      }
      delete values[checkbox.value]
    })

    const body = {
      ...formValues,
      birthDate: values.birthDate?.toISOString(),
      privacyConsents
    }

    await apiClient.patchDigitalEnrollment(body, id, setStatus)
    getRequest()
  }

  const sendEmail = async () => {
    await apiClient.sendConfirmEmail(id, setStatus)
  }

  const renderConsentCheckbox = () => {
    return config.consentCheckboxOptions.map((checkbox:any) => (
      <Form.Item
        className='consent'
        key={checkbox.value}
        label={checkbox.label}
        name={checkbox.value}
        rules={[
          {required: true, message: 'Questo campo è obbligatorio'},
          () => ({
            validator (_, value) {
              if ((checkbox.required && value === 'CONSENT') || !checkbox.required) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Questo consenso è obbligatorio'))
            }
          })
        ]}
      >
        <Radio.Group options={consentOptions} />
      </Form.Item>
    ))
  }

  const renderContent = () => {
    switch (request?.state) {
      case ('DOCTOR_COMPILED'):
        return (
          <div className='card-container'>
            {config.logoPSP &&
              <div className='img-container'>
                <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
              </div>}
            {step === 0 &&
              <>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='mainData' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='firstName'
                      label='Nome'
                      name='firstName'
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      className='lastName'
                      label='Cognome'
                      name='lastName'
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      className='sex'
                      label='Sesso'
                      name='sex'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        options={GENDERS}
                      />
                    </Form.Item>
                    <Form.Item
                      className='birthDate'
                      label='Data di nascita'
                      name='birthDate'
                    >
                      <DatePicker
                        format='DD MMM YYYY'
                        getPopupContainer={(trigger) => trigger}
                        locale={locale}
                        placeholder=''
                      />
                    </Form.Item>
                    <Form.Item
                      className='email'
                      label='Email'
                      name='email'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {type: 'email', message: 'Formato email non valido'},
                        () => ({
                          async validator (_, value) {
                            const isEmailValid = await apiClient.checkEmail(value, setStatus)
                            if (isEmailValid) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Email non disponibile'))
                          }
                        })
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='cellPhoneNumber'
                      label='Numero cellulare'
                      name='cellPhoneNumber'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='preferredContactMethods'
                      label='Metodo di contatto'
                      name='preferredContactMethods'
                    >
                      <Checkbox.Group options={preferredContactMethodsOptions} />
                    </Form.Item>
                    <Form.Item
                      className='username'
                      label='Username'
                      name='username'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {
                          pattern: USERNAME_REGEX,
                          message: 'Formato username non valido'
                        },
                        () => ({
                          async validator (_, value) {
                            const isUsernameValid = await apiClient.checkUsername(value, setStatus)
                            if (isUsernameValid) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Username non disponibile'))
                          }
                        })
                      ]}
                    >
                      {/* <Input onChange={(e) => checkUsername(e.target.value)} /> */}
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='password'
                      label='Password'
                      name='password'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {pattern: PASSWORD_REGEX, message: 'Password troppo debole'}
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      className='confirmPassword'
                      label='Conferma password'
                      name='confirmPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le due password non corrispondono!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='address' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='state'
                      label='Stato'
                      name='country'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        disabled
                        getPopupContainer={(trigger) => trigger}
                      />
                    </Form.Item>
                    <Form.Item
                      className='region'
                      label='Regione'
                      name='region'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setProvinceQuery({...provinceQuery, region: value})}
                        onSearch={(value) => setRegionQuery(value)}
                        options={regions}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='province'
                      label='Provincia'
                      name='province'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setCityQuery({...cityQuery, province: value})}
                        onSearch={(value) => setProvinceQuery({...provinceQuery, search: value})}
                        options={provinces}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='city'
                      label='Città'
                      name='city'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onSearch={(value) => setCityQuery({...cityQuery, search: value})}
                        options={cities}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='address'
                      label='Indirizzo'
                      name='address'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='streetNumber'
                      label='Civico'
                      name='streetNumber'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='postalCode'
                      label='Codice Postale'
                      name='postalCode'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {
                          pattern: POSTAL_CODE_REGEX,
                          message: 'Formato codice postale non valido'
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='locality'
                      label='Località'
                      name='locality'
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='caregiverData' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='caregiverFirstName'
                      label='Nome Caregiver'
                      name='caregiverFirstName'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='caregiverLastName'
                      label='Cognome Caregiver'
                      name='caregiverLastName'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='caregiverPhoneNumber'
                      label='Numero telefonico del caregiver'
                      name='caregiverPhoneNumber'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='caregiverCellPhoneNumber'
                      label='Numero cellulare del caregiver'
                      name='caregiverCellPhoneNumber'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='caregiverEmail'
                      label='Email del caregiver'
                      name='caregiverEmail'
                      rules={[{type: 'email', message: 'Formato email non valido'}]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <div className='button-container'>
                  <SecondaryButton
                    disabled
                  >
                    {'Indietro'}
                  </SecondaryButton>
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => {
                      form.validateFields().then((values) => {
                        setFormValues(values)
                        setStep(step + 1)
                      })
                    }}
                    variant='contained'
                  >
                    {'Avanti'}
                  </PrimaryButton>
                </div>
              </>}
            {step === 1 &&
              <>
                <div style={{marginBottom: '8px', backgroundColor: '#F0F3F4'}}>
                  {ReactHtmlParser(config.consentHtml)}
                </div>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='consensus' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <p style={{width: 'max-content'}}>{'Confermo di aver letto l’Informativa in materia di protezione dei Dati Personali che precede e, pertanto:'}</p>
                    {renderConsentCheckbox()}
                    <Form.Item
                      className='consentPassword'
                      label='Reinserisci la password per confermare i consensi'
                      name='consentPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le password non corrisponde con quella precedenti!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <div className='button-container'>
                  <SecondaryButton
                    className='submit-button'
                    onClick={() => {
                      setStep(step - 1)
                      form.setFieldsValue(formValues)
                    }}
                    variant='contained'
                  >
                    {'Indietro'}
                  </SecondaryButton>
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => form.submit()}
                    variant='contained'
                  >
                    {'Invia'}
                  </PrimaryButton>

                </div>
              </>}

          </div>
        )
      case ('PATIENT_COMPILED'):
        return (
          <div className='card-container'>
            {config.logoPSP &&
              <div className='img-container'>
                <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
              </div>}
            <div className='message-container'>
              {'Ti abbiamo inviata una email. Per completare l\'adesione al programma conferma la tua email.'}
              <span className='span-link' onClick={() => sendEmail()}>{'Richiedi il rinvio della email'}</span>
            </div>
          </div>
        )
      default:
        return (
          <div className='card-container'>
            {config.logoPSP &&
              <div className='img-container'>
                <img alt='logoPSP' className='img-style' src={`data:image/png;base64,${config.logoPSP}`} />
              </div>}
            <div className='message-container'>
              {'Adesione completata con successo. Verrà presto contattato da un nostro operatore.'}
            </div>
          </div>
        )
    }
  }

  return (
    config && request ?
      <PatientDigitalEnrollmentStyled>
        <Header showMenu={false} />
        {renderContent()}
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={status === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
      </PatientDigitalEnrollmentStyled> :
      <></>
  )
}

export default PatientDigitalEnrollment
