/* eslint-disable react-hooks/exhaustive-deps */
import {mdiAccount, mdiDeleteOutline, mdiDownload, mdiFolder, mdiFolderAccount} from '@mdi/js'
import Icon from '@mdi/react'
import {Modal, Snackbar, Typography} from '@mui/material'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters from 'src/components/templates/Filters/Filters'
import {docTypesOptions, PATHS, STATUS} from 'src/constants/constants'
import FileInfoDialog from 'src/components/templates/FileInfoDialog/FileInfoDialog'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'
import {useAppSelector} from 'src/hooks'
import DocumentsInfoModal from 'src/components/elements/DocumentsInfoModal/DocumentsInfoModal'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import Uploader from 'src/components/templates/Uploader/Uploader'

const DocumentsAndInfoMaterials = () => {
  const [selectedFile, setSelectedFile] = useState <any>(undefined)
  const {materialsCategoryOptions, materialsFiltersConfig, documentsCategoryOptions, documentsFiltersConfig, documentsUploadEnabled} = useAppSelector(state => state.pspConfigReducer.data.documentsAndInfoMaterialsConfigurations)

  // materials states and constants

  const [isFileInfoDialog, setIsFileInfoDialog] = useState(false)
  const [filtersMaterials, setFiltersMaterials] = useState<Record<string, any>[]>([])
  const [paginationMaterials, setPaginationMaterials] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-createdAt'
  })
  const [dataMaterials, setDataMaterials] = useState<Record<string, any>[]>([])
  const [statusMaterials, setStatusMaterials] = useState<string>()
  const [countMaterials, setCountMaterials] = useState<number>(0)

  // documents states and constants

  const [filtersDoc, setFiltersDoc] = useState<Record<string, any>[]>([])
  const [paginationDoc, setPaginationDoc] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [dataDoc, setDataDoc] = useState<Record<string, any>[]>([])
  const [statusDoc, setStatusDoc] = useState<string>()
  const [countDoc, setCountDoc] = useState<number>(0)
  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)
  const [statusDownload, setStatusDownload] = useState<any>(undefined)
  const [statusDelete, setStatusDelete] = useState<any>(undefined)
  const [statusPatch, setStatusPatch] = useState<any>(undefined)
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [MIMETypeValue, setMIMETypeValue] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  // modal view or download functions

  const fetchFile = async (fileId: string, mime?: string) => {
    const {data: fileResponse} = await apiClient.download(fileId, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: mime})
    return (window.URL || window.webkitURL).createObjectURL(blob)
  }

  const dowloadFileMaterials = async (fileId: string, fileNameMaterials: string) => {
    const filepath = await fetchFile(fileId, '')
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', fileNameMaterials)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const dowloadFileDocuments = async (fileId: string, user: any, category: any, extension: string) => {
    const filepath = await fetchFile(fileId, '')
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', `${user?.firstName}_${user?.lastName}_${parseCategoryToLabel(category[0], documentsCategoryOptions)}.${extension}`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const MIMEtype = (fileNameMime?: string) => {
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'JPEG' || fileNameMime.split('.')?.pop()?.toUpperCase() === 'JPG')) {
      return 'image/jpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'AVI')) {
      return 'video/x-msvideo'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MP3')) {
      return 'audio/mpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MP4')) {
      return 'video/mp4'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'MPEG')) {
      return 'video/mpeg'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'PNG')) {
      return 'image/png'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'PDF')) {
      return 'application/pdf'
    }
    if (fileNameMime && (fileNameMime.split('.')?.pop()?.toUpperCase() === 'SVG')) {
      return 'image/svg+xml'
    }
  }

  const handleModalViewMaterials = async (row: any) => {
    setSelectedFile(row)
    const mime = MIMEtype(row.fileName)
    if (mime) {
      setOpenViewModal(true)
      const filePath = await fetchFile(row.fileId, MIMEtype(row.fileName))
      setMIMETypeValue(MIMEtype(row.fileName))
      setFile(filePath)
      setFileName(row.fileName)
    } else {
      dowloadFileMaterials(row.fileId, row.fileName)
    }
  }

  const handleModalViewDocuments = async (row: any) => {
    setSelectedFile(row)
    const mime = MIMEtype(row.fileName)
    let extension = ''
    if (row.fileName.includes('.')) {
      extension = row.fileName.split('.')?.pop()
    }
    if (mime) {
      setOpenViewModal(true)
      const filePath = await fetchFile(row.fileId, MIMEtype(row.fileName))
      setMIMETypeValue(MIMEtype(row.fileName))
      setFile(filePath)
      setFileName(`${row?.user?.firstName}_${row?.user?.lastName}_${parseCategoryToLabel(row?.category[0], documentsCategoryOptions)}.${extension}`)
    } else {
      dowloadFileDocuments(row.fileId, row?.user, row.category, extension)
    }
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])

  // parse category functions

  const parseCategoryToLabel = (categoryValue: string, categoryOptions: any) => {
    return categoryOptions?.find((category: any) => category.value === categoryValue)?.label
  }

  const parseCategories = (category: any, categoryOptions: any) => {
    if (category) {
      if (category.length > 0) {
        return category.map((categ: any) => {
          return parseCategoryToLabel(categ, categoryOptions)
        }).join(', ')
      }
      return '-'
    }
  }

  // actions, filters and columns

  const usefulMaterialsActions : Action[] = [
    {
      id: 'info',
      label: 'Info file',
      icon: mdiAccount,
      action: (row) => { setSelectedFile(row); setIsFileInfoDialog(true) }
    },
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: handleModalViewMaterials
    }
  ]

  const usefulDocActions : Action[] = [
    {
      id: 'info',
      label: 'Info file',
      icon: mdiAccount,
      action: (row: any) => { setOpenInfoModal(true); setSelectedFile(row) }
    },
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: handleModalViewDocuments
    },
    {
      id: 'delete',
      label: 'Cancella',
      icon: mdiDeleteOutline,
      action: (row: any) => { setOpenDeleteModal(true); setSelectedFile(row) },
      condition: (row: any) => { return row.creatorId === userInfoData.authUserId }
    }
  ]

  const usefulColumns : TableColumn[] = [
    {
      id: 'categoryReadable',
      label: 'CATEGORIA'
    },
    {
      id: 'createdAt',
      label: 'CARICATO IL',
      type: 'date',
      format: 'ddd DD MMM YYYY HH:mm'
    },
    {
      id: 'name',
      label: 'NOME',
      default: 'fileName'
    },
    {
      id: 'type',
      label: 'TIPO'
    }
  ]

  // data fetch

  const fetchMaterialsData = async () => {
    const fetchedData = await apiClient.getInfoMaterials(filtersMaterials, setStatusMaterials, paginationMaterials) ?? []
    const parsedData = fetchedData.map((document: any) => {
      return {
        ...document,
        categoryReadable: parseCategories(document.category, materialsCategoryOptions),
        type: docTypesOptions?.find((docType: any) => docType.value === document.type)?.label
      }
    })
    setDataMaterials(parsedData)
    const fetchedDataCount = await apiClient.getInfoMaterialsCount(filtersMaterials, setStatusMaterials) ?? []
    setCountMaterials(fetchedDataCount)
  }

  useEffect(() => {
    fetchMaterialsData()
  }, [filtersMaterials, paginationMaterials])

  const usefulMaterialsFilterSubmit = (values: any) => {
    setFiltersMaterials(values)
  }

  const fetchDocumentsData = async () => {
    const fetchedData = await apiClient.getDocuments(filtersDoc, setStatusDoc, paginationDoc) ?? []
    const parsedData = fetchedData.map((document: any) => {
      return {
        ...document,
        categoryReadable: parseCategories(document.category, documentsCategoryOptions),
        type: docTypesOptions?.find((docType: any) => docType.value === document.type)?.label
      }
    })
    setDataDoc(parsedData)
    const fetchedDataCount = await apiClient.getDocumentsCount(filtersDoc, setStatusDoc) ?? []
    setCountDoc(fetchedDataCount)
  }

  useEffect(() => {
    fetchDocumentsData()
  }, [filtersDoc, paginationDoc])

  const usefulDocFilterSubmit = (values: any) => {
    setFiltersDoc(values)
  }

  // tabs contents

  const handleSubmitFile = async (fileValue: any, metadata: any, statusSetter: any) => {
    const {originFileObj} = fileValue
    const {category, name} = metadata
    const formData = new FormData()

    for (let i = 0; i < category.length; i++) {
      formData.append('category', category[i])
    }
    formData.append('name', name || originFileObj.name)
    formData.append('file', originFileObj, originFileObj.name)
    await apiClient.postDocuments(formData, statusSetter)
  }

  const handleEdit = async (form: any, id: string) => {
    await apiClient.patchDocuments(id, {
      category: form.getFieldValue('category'),
      name: form.getFieldValue('name') || '',
      description: form.getFieldValue('description') || ''
    }, setStatusPatch)
    await fetchDocumentsData()
    setOpenInfoModal(false)
  }

  const usefulMaterialContent = (
    <div>
      <div style={{display: 'flex', padding: '16px', justifyContent: 'end'}}>
        <Filters config={materialsFiltersConfig} onSubmit={usefulMaterialsFilterSubmit} />
      </div>
      <TableCustom
        actions={usefulMaterialsActions}
        columns={usefulColumns}
        onPaginationChange={setPaginationMaterials}
        onRowClick={handleModalViewMaterials}
        pagination={paginationMaterials}
        rows={dataMaterials}
        totalElements={countMaterials}
      />
    </div>
  )

  const usefulDocContent = (
    <div>
      <div style={{display: 'flex', padding: '16px', gap: '5px', flexDirection: 'row', justifyContent: 'end'}}>
        {documentsUploadEnabled &&
          <Uploader
            handleSubmitFile={handleSubmitFile}
            handleSubmitRefresh={() => { fetchDocumentsData() }}
            key='uploader'
          />}
        <Filters config={documentsFiltersConfig} onSubmit={usefulDocFilterSubmit} />
      </div>
      <TableCustom
        actions={usefulDocActions}
        columns={usefulColumns}
        onPaginationChange={setPaginationDoc}
        onRowClick={handleModalViewDocuments}
        pagination={paginationDoc}
        rows={dataDoc}
        totalElements={countDoc}
      />
    </div>
  )

  return (
    <LayoutContainer breadcrumbs={[{label: 'Materiali utili', href: PATHS.INFO_MATERIALS}]}>
      <TabsCustom
        contents={[
          usefulMaterialContent,
          usefulDocContent
        ]}
        tabs={[
          {
            icon: <Icon path={mdiFolderAccount} size={1} />,
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'DOCUMENTI CONDIVISI'}</Typography>
          },
          {
            icon: <Icon path={mdiFolder} size={1} />,
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'DOCUMENTI PERSONALI'}</Typography>
          }
        ]}
      />
      {isFileInfoDialog &&
        <FileInfoDialog
          categoryOptions={materialsCategoryOptions}
          data={selectedFile}
          onClose={() => setIsFileInfoDialog(false)}
        />}
      <FileViewerModal
        MIMETypeValue={MIMETypeValue}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setMIMETypeValue(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={statusDownload}
      />
      <Modal
        onClose={() => setOpenInfoModal(false)}
        open={openInfoModal}
      >
        <DocumentsInfoModal
          categoryOptions={documentsCategoryOptions}
          documentInfo={selectedFile}
          handleClose={() => setOpenInfoModal(false)}
          handleEdit={handleEdit}
          readOnly={!(selectedFile?.creatorId === userInfoData.authUserId)}
        />
      </Modal>
      <DialogCustom
        body={<p>{'Vuoi eliminare questo documento?'}</p>}
        footer={
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
            <SecondaryButton
              onClick={async () => {
                await apiClient.deleteDocument(selectedFile._id || '', setStatusDelete)
                await fetchDocumentsData()
                setOpenDeleteModal(false)
              }}
            >{'Ok'}
            </SecondaryButton>
            <SecondaryButton onClick={() => setOpenDeleteModal(false)}>{'Annulla'}</SecondaryButton>
          </div>
        }
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        title={{text: 'Conferma eliminazione documento', format: false}}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusPatch(undefined)}
        open={statusPatch === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusPatch(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel modificare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDelete(undefined)}
        open={statusDelete === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDelete(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel cancellare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={2000} onClose={() => setStatusMaterials(undefined)} open={statusMaterials === 'REJECTED'}>
        <div>
          <SnackbarAlertCustom onClose={() => setStatusMaterials(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti condivisi.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={2000} onClose={() => setStatusDoc(undefined)} open={statusDoc === 'REJECTED'}>
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDoc(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default DocumentsAndInfoMaterials
