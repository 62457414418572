import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const LayoutContainerStyled = styled('div')(({theme}) => ({
  height: '100%',
  gridArea: 'main',
  width: 'calc(100vw - (100vw - 100%))',
  overflow: 'hidden',
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',

  '.layout-container-header': {
    height: `${theme.spacing(40)}`,
    zIndex: '10',
    position: 'fixed',
    width: `calc(100vw - ${theme.spacing(STYLE.SIDEBAR_WIDTH)})`,

    '.container-header': {
      height: '96px',
      backgroundColor: 'white',
      borderRadius: `${theme.spacing(20)} ${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)}`,
      padding: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`
    },

    h3: {
      padding: '8px 0  0 20px'
    },

    '.buttons': {
      alignItems: 'end',
      display: 'flex',
      justifyContent: 'end',
      marginRight: theme.spacing(10),

      '.button': {
        marginLeft: theme.spacing(4)
      }
    }
  },

  '.layout-container-body': {
    overflow: 'auto',
    height: 'calc(100vh - 160px)',
    backgroundColor: 'white',
    marginTop: '96px',
    padding: `0px ${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(8)}`
  },

  '.layout-container-footer': {
    height: '20px',
    margin: '15px',
    width: `calc(100% - ${theme.spacing(STYLE.SIDEBAR_WIDTH)})`,
    background: '#F0F3F4',
    fontStyle: 'italic'
  }
}))
