/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {DatePicker, Form, Input, Select} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import moment from 'moment'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {ExportDialogStyled} from './ExportDialogStyled'
import {EXPORT_TYPES} from 'src/pages/export/Export'
import {Button, IconButton} from '@mui/material'
import Icon from '@mdi/react'
import {mdiAccount, mdiClose, mdiMagnify} from '@mdi/js'
import {useEffect, useState} from 'react'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import DoctorsLookupDialog from '../DoctorsLookupDialog/DoctorsLookupDialog'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'

type Option = {
  value: string,
  label: string,
}

type ExportDialogProps = {
  exportId: string,
  onClose: () => void,
  onSubmit: (values: any) => void,
  setStatus: (values: any) => void
};

const ExportDialog: React.FC<ExportDialogProps> = ({
  exportId,
  onClose,
  onSubmit,
  setStatus
}) => {
  const profileConfig = useAppSelector(state => state.userReducer.data)
  const scope = useAppSelector(state => state.pspConfigReducer.data.scope)
  const [form] = Form.useForm()
  const [drugQuery, setDrugQuery] = useState<string>('')
  const [drugOptions, setDrugOptions] = useState<Option[]>([])
  const [medicalClinicQuery, setMedicalClinicQuery] = useState<string>('')
  const [medicalClinicOptions, setMedicalClinicOptions] = useState<Option[]>([])
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [currentPatient, setCurrentPatient] = useState<Record<string, any>>()
  const [isDoctorsModal, setIsDoctorsModal] = useState<boolean>(false)
  const [currentDoctor, setCurrentDoctor] = useState<Record<string, any>>()

  useEffect(() => {
    getMedicalClinicOptions(medicalClinicQuery)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalClinicQuery])

  useEffect(() => {
    getDrugOptions(drugQuery)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugQuery])

  useEffect(() => {
    getDrugOptions('')
    getMedicalClinicOptions('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values: any) => {
    values.startDate = moment(values.startDate).startOf('day').toISOString()
    values.endDate = moment(values.endDate).endOf('day').toISOString()
    values.exportType = exportId
    values.patient = currentPatient
    values.doctor = currentDoctor?._id
    onSubmit(values)
  }

  async function getMedicalClinicOptions (search: string) {
    const medicalCenters = await apiClient.getMedicalCenters([{property: 'name', value: search}], setStatus)
    const options :Option[] = medicalCenters.map((medicalCenter: Record<string, any>) => ({
      value: medicalCenter._id,
      label: medicalCenter.name
    }))
    setMedicalClinicOptions(options)
  }

  async function getDrugOptions (search: string) {
    const drugs = await apiClient.getDrugs(search, setStatus)
    const options :Option[] = drugs.map((drug: Record<string, any>) => ({
      value: drug._id,
      label: drug.name
    }))
    setDrugOptions(options)
  }

  const body = (
    <ExportDialogStyled>
      <Form
        form={form}
        id='export-form'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          className='startDate'
          label='Data inizio report'
          name='startDate'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <DatePicker
            format='DD/MM/YYYY'
            locale={locale}
            placeholder=''
            popupStyle={{zIndex: 1400}}
          />
        </Form.Item>
        <Form.Item
          className='endDate'
          label='Data fine report'
          name='endDate'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <DatePicker
            format='DD/MM/YYYY'
            locale={locale}
            placeholder=''
            popupStyle={{zIndex: 1400}}
          />
        </Form.Item>
        {profileConfig.userGroup.toUpperCase().includes('CLIENT') &&
          <>
            <Form.Item
              className='patient'
              label={scope === 'tutor' ? 'DM Paziente' : 'Codice paziente'}
              name='patientCode'
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='user'
              label='Medico'
            >
              <div className='user-content'>
                <Form.Item
                  name='doctor'
                >
                  <Input disabled />
                </Form.Item>
                <div className='user-buttons'>
                  <Button
                    className='user-button'
                    onClick={() => setIsDoctorsModal(true)}
                  >
                    <Icon path={mdiMagnify} size={1} />
                  </Button>
                  <Button
                    className='user-button'
                    onClick={() => form.setFieldValue('doctor', undefined)}
                  >
                    <Icon path={mdiClose} size={1} />
                  </Button>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label='Farmaco'
              name='drug'
            >
              <Select
                filterOption={false}
                getPopupContainer={(trigger) => trigger}
                onSearch={(value) => setDrugQuery(value)}
                options={drugOptions}
                showSearch
              />
            </Form.Item>
            <Form.Item
              label='Centro Medico'
              name='medicalClinic'
            >
              <Select
                filterOption={false}
                getPopupContainer={(trigger) => trigger}
                onSearch={(value) => setMedicalClinicQuery(value)}
                options={medicalClinicOptions}
                showSearch
              />
            </Form.Item>
          </>}
        {profileConfig.userGroup.toUpperCase().includes('DOCTOR') &&
          <>
            <Form.Item
              className='user'
              label='Paziente'
            >
              <div className='user-content'>
                <Form.Item
                  name='patient'
                >
                  <Input disabled />
                </Form.Item>
                <div className='user-buttons'>
                  <Button
                    className='user-button'
                    onClick={() => setIsPatientsModal(true)}
                  >
                    <Icon path={mdiMagnify} size={1} />
                  </Button>
                  <Button
                    className='user-button'
                    onClick={() => form.setFieldValue('patient', undefined)}
                  >
                    <Icon path={mdiClose} size={1} />
                  </Button>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label='Farmaco'
              name='drug'
            >
              <Select
                filterOption={false}
                getPopupContainer={(trigger) => trigger}
                onSearch={(value) => setDrugQuery(value)}
                options={drugOptions}
                showSearch
              />
            </Form.Item>
          </>}
      </Form>
    </ExportDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton
        form='export-form'
        key='submit'
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <>
      <DialogCustom
        body={body}
        footer={footer}
        maxWidth='md'
        onClose={onClose}
        title={{text: exportId, format: true}}
      />
      {isPatientsModal &&
        <PatientLookupDialog
          onClose={() => setIsPatientsModal(false)}
          onRowClick={(row) => {
            setCurrentPatient(row)
            form.setFieldValue('patient', `${row.firstName} ${row.lastName}`)
            setIsPatientsModal(false)
          }}
        />}
      {isDoctorsModal &&
        <DoctorsLookupDialog
          onClose={() => setIsDoctorsModal(false)}
          onRowClick={(row) => {
            setCurrentDoctor(row)
            form.setFieldValue('doctor', `${row.firstName} ${row.lastName}`)
            setIsDoctorsModal(false)
          }}
        />}
    </>

  )
}

export default ExportDialog
