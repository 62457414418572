export const CONSENTS = [
  {
    label: 'Testo consenso 1',
    value: 'PRIVACY_ENROLLMENT_APP_WEB',
    required: true
  },
  {
    label: 'Testo consenso 2',
    value: 'PRIVACY_PHONE_SURVEY',
    required: true
  }
]

export const CONSENTS_HTML = `<!DOCTYPE html>
<html>
<head>
  <meta http-equiv="content-type" content="text/html; charset=utf-8"/>
  <title></title>
  <meta name="generator" content="LibreOffice 7.6.4.1 (Linux)"/>
  <meta name="author" content="Diego De Luca"/>
  <meta name="created" content="2024-03-12T10:26:00"/>
  <meta name="changed" content="00:00:00"/>
  <style type="text/css">
    @page { size: 8.27in 11.69in; margin-left: 0.79in; margin-right: 0.79in; margin-top: 0.98in; margin-bottom: 0.79in }
    p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
    p.western { font-size: 10pt }
    p.cjk { font-family: ; font-size: 10pt; so-language: it-IT }
    p.ctl { font-size: 10pt }
    a:link { color: #000080; text-decoration: underline }
  </style>
</head>
<body lang="it-IT" link="#000080" vlink="#800000" dir="ltr"><p align="justify" style="line-height: 100%; margin-bottom: 0in, background: white !important">
<font size="2" style="font-size: 10pt"><font color="#c00000"><font size="3" style="font-size: 12pt"><b>PROGRAMMA
SiCURA E INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">Il
Programma SiCura è offerto dall’azienda Dompé farmaceutici S.p.A.
in collaborazione con ItaliAssistenza.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">I
servizi inclusi nel Programma SiCura vengono erogati - su indicazione
del Medico che li ha in cura - ai pazienti affetti da ipertensione
arteriosa polmonare in trattamento con il farmaco Remodulin®. Il
Programma è finalizzato a ottimizzare la gestione della terapia.
Prevede la reperibilità telefonica e il supporto infermieristico
domiciliare e/o da remoto funzionale ad addestrare il Paziente nella
somministrazione continua sottocute di Remodulin® tramite pompa
infusionale portatile. <font color="#000000">Maggiori informazioni
sul Programma sono disponibili sul Materiale Informativo che </font>le
ha<font color="#000000"> consegnato </font>il<font color="#000000">
suo medico o che trova su </font><a href="http://www.dompe.com/"><font color="#1155cc"><u><b>www.dompe.com</b></u></font></a>
alla pagina<font color="#0000ff"> </font><font color="#4a86e8"><b>ipertensione
arteriosa polmonare</b></font> <font color="#000000">o contattando la
Centrale Operativa di Italiassistenza al </font><font color="#38761d"><b>numero
verde</b></font><font color="#000000"> </font><font color="#000000"><b>800.835.945</b></font><font color="#000000">
o all’indirizzo email: </font><a href="mailto:sicura@italiassistenza.it"><font color="#0000ff"><u>sicura@italiassistenza.it</u></font></a><font color="#000000">
</font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><span style="font-variant: small-caps"><font color="#000000"><font size="2" style="font-size: 11pt"><b>INFORMATIVA
PRIVACY</b></font></font></span></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#000000"><b>ai
sensi degli artt. 13 e 14 del Reg. UE n. 679/2016 sulla protezione
dei dati personali (“GDPR”)</b></font></font></p>
<p align="justify" style="margin-top: 0.07in; margin-bottom: 0.14in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.14in"><font size="2" style="font-size: 10pt"><b>Titolo
del programma di supporto:</b> SiCura (di seguito <b>Programma</b>)</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0.14in"><font size="2" style="font-size: 10pt"><b>Organizzatore:
</b>Dompé farmaceutici S.p.A., Milano, Italia (di seguito <b>Dompé</b>)</font></p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-right: 0.31in; margin-top: 0.07in; margin-bottom: 0.14in">
<font size="2" style="font-size: 10pt"><font color="#000000">Gentile
Signora/Signore,</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000"><b>Dompé
</b></font><font color="#000000">Le fornisce le seguenti informazioni
circa le finalità e le modalità con cui raccoglierà e utilizzerà
i Suoi Dati Personali</font><font color="#000000"><b> </b></font><font color="#000000">per
il tramite della società ItaliAssistenza (responsabile del
trattamento</font>) al fine di erogare i servizi inclusi<b> </b>nel
Programma.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Identità e dati di
contatto del Titolare e del DPO</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo i nostri riferimenti</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt"><b>Dompé,</b>
con sede legale in via San Martino 12, 20122, Milano, Italia, in
persona del suo legale rappresentante pro-tempore </font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">e-mail:
privacy@dompe.com</font></p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-right: 0.31in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt">DPO:dpo@dompe.com</font></p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<font size="2" style="font-size: 10pt">Le ricordiamo che, per
l’esercizio dei Suoi diritti, potrà contattare in prima battuta il
Centro medico di riferimento, così come previsto nell’art. 4.7 del
Codice Deontologico di Farmindustria.</font></p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Finalità e base giuridica
del trattamento</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le spieghiamo perché raccogliamo i Suoi dati</i></font></font></p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-right: -0.06in; margin-bottom: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; orphans: 0; widows: 0; margin-right: -0.06in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">I Suoi
Dati Personali sono da noi trattati in virtù delle condizioni di
seguito elencate (c.d. basi giuridiche del trattamento):</font></font></p>
<ul>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><u><b>A.
  con il Suo consenso (art. 6.1 (a), GDPR):</b></u></font></font></p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-left: 0.98in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000"><b>Per
la prestazione del servizio di supporto domiciliare nella gestione
della terapia con Remodulin®, prescritta dal Suo Medico Specialista,
nello specifico:</b></font></font></p>
<ul>
  <ol>
    <ul>
      <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
      <font size="2" style="font-size: 10pt"><font color="#000000">offrirle
      il servizio di formazione domiciliare e/o telefonico, da parte di
      infermieri specializzati, per il corretto utilizzo della pompa
      infusionale e per garantire una gestione appropriata della terapia
      con Remodulin®;</font></font></p></li>
      <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
      <font size="2" style="font-size: 10pt"><font color="#000000">facilitare
      la comunicazione e lo scambio di informazioni tra Lei e il Suo
      Medico specialista attraverso un diario compilato dall’Infermiere
      e ospitato da una piattaforma WEB dedicata al Medico;</font></font></p></li>
      <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
      <font size="2" style="font-size: 10pt"><font color="#000000">offrirle
      supporto tecnico telefonico relativo alla pompa infusionale;</font></font></p></li>
      <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
      <font size="2" style="font-size: 10pt"><font color="#000000">condividere
      i suoi Dati Personali con altri specialisti per finalità
      cliniche.</font></font></p></li>
    </ul>
  </ol>
</ul>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-left: 0.2in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000"><u>Il
suo</u></font><u> consenso per queste finalità è facoltativo</u>,
con la conseguenza che Lei potrà decidere di non prestarlo, ovvero,
di revocare in ogni momento la possibilità di continuare a trattare
dati che ci ha già fornito. Tuttavia, qualora decidesse di non
fornire il Suo consenso al trattamento dei dati personali per le
finalità sopra indicate, potremmo non essere in grado di consentire
la sua adesione e/o partecipazione al Programma.</font></p>
<ul>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><u><b>B.
  senza il Suo consenso </b></u></font></font>
  </p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-left: 0.95in; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000"><b>L’esecuzione
di adempimenti obbligatori di legge (art. 6.1, (c), GDPR), ossia: </b></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-left: 0.95in; margin-bottom: 0in; border: none; padding: 0in; background: #ffffff">
<font size="2" style="font-size: 10pt"><font color="#000000"><u><b>in
materia di vigilanza</b></u></font><font color="#000000"><u>,</u></font><font color="#000000">
ai quali noi siamo tenuti e che consentono alle autorità di svolgere
attività di controllo </font><font color="#1f1f1f">sulla sicurezza
del farmaco</font><font color="#000000">, nonché di tracciare e
monitorare segnalazioni di reazioni avverse potenzialmente associate
all’uso del farmaco e che ci dovessero eventualmente pervenire o di
cui si venga a conoscenza.</font></font></p>
<p align="justify" style="line-height: 100%; margin-left: 0.95in; margin-bottom: 0in; border: none; padding: 0in; background: #ffffff">
<font size="2" style="font-size: 10pt"><font color="#000000"><u><b>di
natura amministrativa</b></u></font><font color="#000000"> previsti
dalla normativa nazionale applicabile.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in; background: #ffffff">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in; background: #ffffff">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Categorie di dati personali</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo quali dati raccogliamo</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in; background: #ffffff">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#000000">Nell’ambito
della conduzione del Programma, potremmo trattare i seguenti Dati
Personali:</font></font></p>
<ul>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><b>Dati
  identificativi</b></font><font color="#000000">: nome, cognome,
  genere, data di nascita, n. di codice fiscale e di tesserino
  sanitario; </font></font>
  </p></li>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><b>Dati
  di contatto</b></font><font color="#000000">: luogo di residenza o
  domicilio, numero di telefono (fisso o cellulare), indirizzo email; </font></font>
  </p></li>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><b>Dati
  idonei a rilevare lo</b></font><font color="#000000"> </font><font color="#000000"><b>stato
  della terapia: </b></font><font color="#000000">inizio terapia con
  Remodulin ® o già in terapia; dosaggio di Remodulin ® e numero
  AIC; l’eventuale sospensione della terapia con Remodulin, con
  conseguente sospensione del Programma, la durata della sospensione e
  la motivazione; l’eventuale uscita dal Programma e la motivazione;
  la riattivazione del Programma; riferimenti del</font>la persona che
  le presta assistenza<font color="#000000">; ogni ulteriore dato che
  possa riferirsi al Suo stato di salute, fisico e mentale in tale
  contesto;</font></font></p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">(artt.
4, n. 1 e n.15 e 9, GDPR).</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.30j0zll"></a>
<font size="2" style="font-size: 10pt">I Suoi Dati Personali sono
raccolti dal Centro clinico di riferimento a cui Lei si è rivolto e
che ha accesso alla Sua documentazione clinica originaria. La
presente informativa privacy contiene, dunque, le informazioni
necessarie affinché Lei sappia come utilizziamo i Suoi Dati
Personali che non ci ha fornito direttamente ma che abbiamo ottenuto
dal Centro clinico di riferimento (informativa ex art. 14, GDPR).</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Modalità del trattamento
dei dati</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo come usiamo i Suoi dati personali</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">I Suoi
Dati Personali vengono raccolti dal medico specialista che ha
valutato la Sua idoneità a partecipare al Programma, dal momento in
cui Lei manifesta la volontà di aderire. </font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">I dati
relativi alle singole visite domiciliari da parte dell’infermiere
sono raccolti nel </font><font color="#000000"><i>Diario del
Paziente</i></font><font color="#000000">, in versione cartacea e
sulla piattaforma web e che rappresenta il principale strumento di
comunicazione tra il </font>m<font color="#000000">edico specialista
e l’Infermiere. Quest’ultimo inserisce nel Diario i dati relativi
al Programma, ad esempio dati relativi agli accessi domiciliari, i
dati anagrafici e clinici del </font>p<font color="#000000">aziente
che potranno essere consultati dal medico specialista tramite
l’utilizzo del Portale WEB Medico a lui dedicato.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">Il
trattamento dei Suoi Dati Personali avverrà – secondo i principi
di correttezza, liceità e trasparenza, limitazione delle finalità –
tramite supporti e/o strumenti informatici, manuali e/o telematici
(ad es. Diario) con logiche strettamente correlate alle finalità del
trattamento e, comunque, garantendo la riservatezza e sicurezza dei
dati stessi e il rispetto degli obblighi specifici sanciti dalla
legge.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Destinatari o categorie di
destinatari dei dati</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo chi utilizza i Suoi dati personali</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#000000">In
relazione alle finalità sopra indicate, i Suoi Dati Personali sono
trattati anche da:</font></font></p>
<ul>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><u>In
  qualità di Titolari autonomi</u></font><font color="#000000">: </font></font>
  </p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">Organismi
sanitari di controllo, organi della pubblica amministrazione,
autorità pubbliche o autorità giudiziaria, ai quali il Titolare
debba comunicare i Suoi Dati Personali in forza di disposizioni di
legge o di ordini delle autorità;</font></font></p>
<p align="justify" style="line-height: 100%; margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in">
<br/>

</p>
<ul>
  <li><p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
  <font size="2" style="font-size: 10pt"><font color="#000000"><u>In
  qualità di Responsabili e sub-responsabili del trattamento,
  nominati ai sensi dell’art. 28, GDPR</u></font><font color="#000000">,
  si veda l’elenco completo ed aggiornato dei destinatari dei dati,
  oltreché dei soggetti autorizzati ai sensi dell’art. 29, GDPR,
  che può esserci richiesto ai recapiti sopra indicati. </font></font>
  </p></li>
</ul>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Trasferimento estero dei
dati</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo se i Suoi dati sono trasmessi fuori
della UE e con quali garanzie</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt"><font color="#000000">I
Suoi Dati Personali potrebbero essere comunicati a soggetti terzi,
situati in paesi al di fuori dell’Unione Europea. In tale caso, Le
garantiamo che tale trasferimento avverrà unicamente in conformità
ai principi stabiliti espressamente dal GDPR in modo da proteggere
adeguatamente i dati stessi.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Durata di conservazione dei
dati</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo per quanto tempo conserveremo i Suoi
dati</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">I Suoi
Dati Personali (in modalità pseudonimizzata), contenuti nel
fascicolo permanente del Programma, sono conservati per perseguire le
finalità sopra indicate in conformità alla normativa relativa ai
programmi di assistenza sanitaria e alle norme di buona pratica
clinica, a garanzia della salute pubblica, per un periodo di 10 anni
dall’uscita del programma e/o chiusura del programma. </font><font color="#000000"><u>
</u></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font size="2" style="font-size: 10pt"><font color="#000000">Al
termine del periodo di conservazione, i Suoi Dati Personali saranno
cancellati, distrutti o resi anonimi, fatti salvi gli eventuali
ulteriori termini di conservazione previsti dalla legge.</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Diritti degli Interessati</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>In
questa sezione Le indichiamo di quali diritti gode in relazione ai
Suoi dati</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">In
ogni momento Lei potrà ottenere la conferma dell'esistenza o meno
dei Suoi Dati Personali presso di noi e di conoscerne il contenuto e
l'origine; inoltre, qualora possibile, tenuto conto delle modalità
di svolgimento del Programma e di quanto previsto per legge, Lei
potrà chiederne l’integrazione, la rettifica, la portabilità, la
limitazione d’uso, la cancellazione, nonché di opporsi al loro
trattamento. </font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">Il
testo integrale degli articoli da 15 a 22, GDPR è consultabile sul
sito del Garante per la Protezione dei dati Personali
(<a href="https://www/"><font color="#0000ff"><u>https://www</u></font></a>.garanteprivacy.it/Regolamentoue/diritti-degli-interessati).
</font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">Le
ricordiamo che, per l’esercizio dei Suoi diritti, potrà contattare
anche il Centro medico di riferimento.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">Poiché
Dompé non è in grado autonomamente di ricollegare i Suoi Dati
Personali al Suo nominativo, al fine di garantire il mantenimento
della riservatezza della Sua identità nei nostri confronti, La
invitiamo, ove ne avesse bisogno, a contattare in prima istanza il
Centro medico di riferimento.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Revoca del consenso</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>Le
evidenziamo il Suo diritto a revocare in qualsiasi momento il
consenso</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; background: #ffffff">
<font size="2" style="font-size: 10pt">Potrà revocare il consenso
prestato in qualsiasi momento senza che la revoca pregiudichi la
legittimità del trattamento effettuato in precedenza. </font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><b>Reclamo al Garante</b></font></p>
<p align="justify" style="line-height: 100%; margin-top: 0.07in; margin-bottom: 0in">
<font size="2" style="font-size: 10pt"><font size="2" style="font-size: 9pt"><i>Le
evidenziamo il Suo diritto di potersi rivolgere al Garante</i></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font size="2" style="font-size: 10pt">Lei
ha anche il diritto di proporre reclamo all’Autorità Garante per
la Protezione dei dati Personali qualora ritenga che i Suoi diritti
non siano stati rispettati o che non abbia ricevuto riscontro alle
Sue richieste secondo legge.</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
</body>
</html>`
