import {Divider, Form, Input} from 'antd'
import {FamilyCardStyled} from './FamilyCardStyled'
import {IconButton, Typography} from '@mui/material'
import {mdiAccount} from '@mdi/js'
import Icon from '@mdi/react'
import {Caregiver} from 'src/types/types'
import {useState} from 'react'
import CaregiverDetailDialog from '../CaregiverDetailDialog/CaregiverDetailDialog'
import {familyTypes} from 'src/constants/constants'

type FamilyCardProps = {
  caregivers: Caregiver[];
    readOnly?: boolean,
  }

const FamilyCard: React.FC<FamilyCardProps> = (
  {
    caregivers,
    readOnly = false
  }
) => {
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)
  const [dialogCaregiver, setDialogCaregiver] = useState<Caregiver>()

  const handleOpenDialog = (caregiver: Caregiver) => {
    setDialogCaregiver(caregiver)
    setOpenDetailDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDetailDialog(false)
    setDialogCaregiver(undefined)
  }

  const detailDialog = (
    <CaregiverDetailDialog
      caregiverOptions={Object.entries(familyTypes).map(([value, label]) => ({label, value}))}
      detail={dialogCaregiver}
      familyCardInfo
      onClose={() => handleCloseDialog()}
    />
  )

  return (
    <FamilyCardStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            {'Nucleo familiare'}
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-row'>
            {caregivers.map((caregiver, index) => {
              return (
                <div className='container-body-element container-body-element-icon' key={index}>
                  <Form.Item label={`Familiare ${index + 1}`}>
                    <Input
                      disabled={readOnly}
                      value={`${caregiver?.title || ''} ${caregiver?.firstName || ''} ${caregiver?.lastName || ''}`}
                    />
                  </Form.Item>
                  <IconButton disabled={!caregiver} onClick={() => handleOpenDialog(caregiver)}>
                    <Icon path={mdiAccount} size={1} />
                  </IconButton>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {openDetailDialog && detailDialog}
    </FamilyCardStyled>
  )
}

export default FamilyCard
