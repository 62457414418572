/*
 * Copyright 2023 Mia srl
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {configureStore} from '@reduxjs/toolkit'
import reducers from './slices'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import {persistReducer, persistStore} from 'redux-persist'
import {NODE_ENV} from './config'
import {combineReducers} from 'redux'

const middlewares = [thunkMiddleware]

if (NODE_ENV === 'development') {
  const {createLogger} = require('redux-logger')
  const logger = createLogger({
    // @ts-ignore
    collapsed: (getState, action, logEntry) => !logEntry.error
  })
  middlewares.push(logger)
}

const rootReducer = combineReducers(reducers)

const persistConfig = {
  key: 'external-users-frontend',
  storage,
  whitelist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStoreToolkit = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares
  })

  if (NODE_ENV === 'development') {
    // @ts-ignore
    if (module.hot) {
      // @ts-ignore
      module.hot.accept('./slices', () => {
        store.replaceReducer(persistedReducer)
      })
    }
  }
  return store
}

export const store = configureStoreToolkit()

export const dispatch = store.dispatch

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
