import React, {ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {Typography, ListItemIcon} from '@mui/material'
import {NavbarItemStyled} from './NavbarItemStyled'

export type MenuItem = {
  key: string,
  name: string | ReactNode,
  selected: boolean,
  icon: ReactNode,
  link?: string,
  onClick? : () => void
}

type NavbarItemProp = {
  menuItem: MenuItem
}

const NavbarItem: React.FC<NavbarItemProp> = ({menuItem}) => {
  const {link, icon, selected, onClick} = menuItem

  const iconClassName = (menuItemIsSelected: boolean) => {
    return menuItemIsSelected ? 'selectedIconListItem' : 'unselectedIconListItem'
  }

  const typographyClassName = (menuItemIsSelected: any) => {
    return menuItemIsSelected ? 'selectedText' : 'unselectedText'
  }

  const listItemClassName = `flexItem ${iconClassName(selected)}`
  const TypographyClassName = `${typographyClassName(selected)} textStyle`

  return (
    <NavbarItemStyled className={listItemClassName}>
      {link ?
        <Link
          className='menuLink'
          to={link}
        >
          <ListItemIcon>
            {icon}
            <Typography className={TypographyClassName} color='white' style={{marginLeft: 30}} variant='h5'>{menuItem.name}</Typography>
          </ListItemIcon>
        </Link> :
        <div
          className='menuLink'
          onClick={onClick}
          style={{cursor: 'pointer'}}
        >
          <ListItemIcon className={listItemClassName}>
            {icon}
            <Typography className={TypographyClassName} color='white' style={{marginLeft: 30}} variant='h5'>{menuItem.name}</Typography>
          </ListItemIcon>
        </div>}
    </NavbarItemStyled>
  )
}

export default NavbarItem
