import {styled} from '@mui/system'

export const ProgramMembershipDataStyled = styled('div')(({theme}) => ({
  marginBottom: theme.spacing(18),
  '.ant-form': {
    paddingBottom: theme.spacing(18),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(11)
  },
  '.container': {
    border: `1px solid ${theme.palette.neutral[100]}`,
    borderRadius: theme.spacing(1),
    '&-title': {
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`
    },
    '&-body': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(7.5),
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`,
      '&-row': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: theme.spacing(15)
      },
      '&-element': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5),
        '&-icon': {
          flexDirection: 'row',
          alignItems: 'flex-end'
        }
      }
    },
    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    '.ant-picker': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%'
    },
    '.ant-form-item': {
      marginBottom: 0,
      width: '100%'
    },
    '.ant-radio-row': {
      '.ant-radio-inner': {
        borderRadius: 0,
        '&:after': {
          borderRadius: 0
        }
      },
      '.ant-form-item-row': {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'center',
        '.ant-form-item-label': {
          padding: 0
        }
      }
    },
    '.ant-divider': {
      margin: 0
    }
  }
}))
