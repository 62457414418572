/* eslint-disable react-hooks/exhaustive-deps */
import {mdiAccount, mdiDeleteOutline, mdiDownload} from '@mdi/js'
import {Modal, Snackbar, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DocumentsInfoModal from 'src/components/elements/DocumentsInfoModal/DocumentsInfoModal'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {docTypesOptions, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import getFileMIMEtype from 'src/pages/documents/getFileMIMEType'

type DocumentsProps = {
  activityId: string,
  beneficiaryId: string
}

const ActivityDocuments: React.FC<DocumentsProps> = ({activityId, beneficiaryId}) => {
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [modalDocumentInfo, setModalDocumentInfo] = useState<any>(undefined)
  const pspDocumentsConfigurations = useAppSelector(state => state.pspConfigReducer.data.documentsConfigurations)
  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-createdAt'
  })
  const [count, setCount] = useState<number>(0)
  const [file, setFile] = useState<any>()
  const [MIMETypeValue, setMIMETypeValue] = useState<any>()
  const [fileName, setFileName] = useState<any>()

  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [statusDelete, setStatusDelete] = useState<any>(undefined)
  const [statusPatch, setStatusPatch] = useState<any>(undefined)
  const [statusDownload, setStatusDownload] = useState<any>(undefined)

  const categoryOptions = pspDocumentsConfigurations.categoryOptions.categoriesFilters

  const parseCategoryToLabel = (categoryValue: string) => {
    return categoryOptions.find((category: any) => category.value === categoryValue)?.label
  }

  const parseCategories = (category: any) => {
    if (category) {
      if (category.length > 0) {
        return category.map((categ: any) => {
          return parseCategoryToLabel(categ)
        }).join(', ')
      }
      return '-'
    }
  }

  const fetchFile = async (fileId: string, mime?: string) => {
    const fileResponse = await apiClient.activitiesDocumentDownload(activityId, fileId, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: mime})
    return (window.URL || window.webkitURL).createObjectURL(blob)
  }

  const dowloadFile = async (fileId: string, user: any, category: any, extension: string) => {
    const filepath = await fetchFile(fileId, '')
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', `${user?.firstName}_${user?.lastName}_${parseCategoryToLabel(category[0])}.${extension}`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleModalInfo = (row: any) => {
    setOpenInfoModal(true)
    setModalDocumentInfo(row)
  }

  const handleModalDelete = (row: any) => {
    setOpenDeleteModal(true)
    setModalDocumentInfo(row)
  }

  const handleModalView = async (row: any) => {
    setModalDocumentInfo(row)
    const mime = getFileMIMEtype(row.fileName)
    let extension = ''
    if (row.fileName.includes('.')) {
      extension = row.fileName.split('.')?.pop()
    }
    if (mime) {
      setOpenViewModal(true)
      const filePath = await fetchFile(row.fileId, getFileMIMEtype(row.fileName))
      setMIMETypeValue(getFileMIMEtype(row.fileName))
      setFile(filePath)
      setFileName(`${row?.user?.firstName}_${row?.user?.lastName}_${parseCategoryToLabel(row?.category[0])}.${extension}`)
    } else {
      dowloadFile(row.fileId, row?.user, row.category, extension)
    }
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])

  const rowActions : Action[] = [
    {
      id: 'info',
      label: 'Info file',
      icon: mdiAccount,
      action: handleModalInfo
    },
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: handleModalView
    },
    {
      id: 'delete',
      label: 'Cancella',
      icon: mdiDeleteOutline,
      action: handleModalDelete,
      condition: (row: any) => { return row.creatorId === userInfoData.authUserId }
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'category',
      label: 'Categoria',
      type: 'select',
      options: categoryOptions
    },
    {
      id: 'rangeDates',
      label: 'Data inserimento',
      type: 'rangePicker'
    }
  ] || []

  const columns : TableColumn[] = [
    {
      id: 'categoryReadable',
      label: 'CATEGORIA'
    },
    {
      id: 'uploadDate',
      label: 'CARICATO IL',
      type: 'date',
      format: 'DD MMMM YYYY, HH:mm'
    },
    {
      id: 'name',
      label: 'NOME',
      default: 'fileName'
    },
    {
      id: 'type',
      label: 'TIPO'
    }
  ] || []

  const [filters, setFilters] = useState<Record<string, string | string[]>[]>([])

  const [data, setData] = useState<any>([])

  const fetchData = async () => {
    const fetchedData = await apiClient.getActivitiesDocuments(activityId, beneficiaryId, filters, setStatus, pagination) ?? []
    const parsedData = fetchedData.map((document: any) => {
      return {
        ...document,
        categoryReadable: parseCategories(document.category),
        type: docTypesOptions.find((docType: any) => docType.value === document.type)?.label
      }
    })
    setData(parsedData)
    const fetchedDataCount = await apiClient.getActivitiesDocumentsCount(activityId, filters, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  useEffect(() => {
    fetchData()
  }, [activityId, filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const handleEdit = async (form: any, id: string) => {
    await apiClient.patchActivitiesDocuments(activityId, id, {
      category: form.getFieldValue('category'),
      name: form.getFieldValue('name') || '',
      description: form.getFieldValue('description') || ''
    }, setStatusPatch)
    await fetchData()
    setOpenInfoModal(false)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'end'}}>
      <div style={{display: 'flex', gap: '8px'}}>
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined); setStatusCount(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Modal
        onClose={() => setOpenInfoModal(false)}
        open={openInfoModal}
      >
        <DocumentsInfoModal
          categoryOptions={categoryOptions}
          documentInfo={modalDocumentInfo}
          handleClose={() => setOpenInfoModal(false)}
          handleEdit={handleEdit}
          readOnly={!(modalDocumentInfo?.creatorId === userInfoData.authUserId)}
        />
      </Modal>

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusPatch(undefined)}
        open={statusPatch === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusPatch(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel modificare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <DialogCustom
        body={<p>{'Vuoi eliminare questo documento?'}</p>}
        footer={
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
            <SecondaryButton
              onClick={async () => {
                await apiClient.deleteActivitiesDocument(activityId, modalDocumentInfo._id, setStatusDelete)
                await fetchData()
                setOpenDeleteModal(false)
              }}
            >{'Ok'}
            </SecondaryButton>
            <SecondaryButton onClick={() => setOpenDeleteModal(false)}>{'Annulla'}</SecondaryButton>
          </div>
        }
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        title={{text: 'Conferma eliminazione documento', format: false}}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDelete(undefined)}
        open={statusDelete === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDelete(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel cancellare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <FileViewerModal
        MIMETypeValue={MIMETypeValue}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setMIMETypeValue(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={statusDownload}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <TableCustom
        actions={rowActions}
        columns={columns}
        onPaginationChange={setPagination}
        onRowClick={handleModalView}
        pagination={pagination}
        rows={data}
        totalElements={count}
      />
    </div>
  )
}

export default ActivityDocuments
