import axios from 'axios'
import {API_SECRET} from 'src/config'
import interceptors from '../authentication/interceptors'

function createApiClient () {
  const apiClient = axios.create({
    baseURL: `${window.location.protocol}//${window.location.host}/`,
    withCredentials: false,
    headers: {
      secret: API_SECRET
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, interceptors.response.onRejected
  )
  return apiClient
}

export default createApiClient
