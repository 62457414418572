import {styled} from '@mui/material'

export const LoaderStyled = styled('div')(({theme}) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.MuiCircularProgress-root': {
    color: 'grey !important'
  }
}))
