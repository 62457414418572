import {styled} from '@mui/system'

export const UploadButtonStyled = styled('div')(({theme}) => ({
  '.upload-button': {
    color: 'white',
    borderRadius: theme.spacing(25),
    width: theme.spacing(105),
    height: theme.spacing(22),
    textTransform: 'none',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },

  '.close-button': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    borderRadius: theme.spacing(25),
    width: theme.spacing(105),
    height: theme.spacing(22),
    textTransform: 'none',
    display: 'flex',
    '&:hover': {
      backgroundColor: 'white'
    },

    '& .MuiButton-startIcon': {
      borderRadius: theme.spacing(25),

      backgroundColor: 'white',
      padding: theme.spacing(3)
    }
  }
}))
