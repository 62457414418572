import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const MainRoutingStyled = styled('div')(({theme}) => ({
  height: '100%',
  '.parent': {
    height: '100%',
    display: 'grid',
    gridTemplateRows: `${theme.spacing(STYLE.HEADER_HEIGHT)} auto`,
    gridTemplateColumns: `${theme.spacing(STYLE.SIDEBAR_WIDTH)} auto`,
    gridTemplateAreas:
    `"navbar header"
    "navbar main"`
  },
  '.childNavbar': {
    gridArea: 'navbar',
    width: `${theme.spacing(STYLE.SIDEBAR_WIDTH)}`,
    position: 'fixed',
    height: '100%',
    zIndex: 10
  },
  '.childHeader': {
    gridArea: 'header',
    height: `${theme.spacing(STYLE.HEADER_HEIGHT + 20)}`,
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  '.childLogo': {
    width: `${theme.spacing(STYLE.SIDEBAR_WIDTH)}`,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 15,
    marginTop: `${theme.spacing(10)}`
  }
}))

export const FirstLoginStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',

  '.stepContent': {
    margin: '64px'
  },

  '.stepButton': {
    display: 'flex',
    justifyContent: 'end',
    margin: '16px 64px'
  },

  '.warning': {
    fontWeight: 'bold',
    fontStyle: 'italic'
  },

  '.step2': {
    margin: '64px 256px',

    p: {
      lineHeight: '100%',
      marginBottom: '0.11in',
      fontFamily: 'Roboto, serif',
      fontSize: '11pt'
    }
  },

  '.cookie-card': {
    padding: '16px 0',

    '.MuiPaper-root': {
      backgroundColor: '#F0F3F4'
    },

    '.MuiCardHeader-root': {
      border: '1px solid #000000 !important'
    },

    '.MuiCardContent-root': {
      border: '1px solid #000000 !important'
    }
  }

}))
