import {Dialog} from '@mui/material'
import {styled} from '@mui/system'

export const VirtualMeetingDetailStyled = styled('div')(({theme}) => ({

  padding: theme.spacing(8),
  height: '100%',
  minHeight: '100vh',
  '.custom-card-header': {
    margin: `${theme.spacing(5)} 0px`
  },

  '.table-header': {
    margin: theme.spacing(5)
  },

  '#main-data-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: theme.spacing(8),

    '.ant-row': {
      width: '100%'
    },

    '.title': {
      gridColumn: '1 / 5',
      gridRow: 1
    },

    '.code': {
      gridColumn: '5 / 7',
      gridRow: 1
    },

    '.startDay': {
      gridColumn: '1 / 3',
      gridRow: 2
    },

    '.startDate': {
      gridColumn: 3,
      gridRow: 2
    },

    '.endDate': {
      gridColumn: 4,
      gridRow: 2
    },

    '.link': {
      gridColumn: '5 /  7',
      gridRow: 2
    },

    '.organizer': {
      gridColumn: '1 /  4',
      gridRow: 3,
      display: 'flex',
      '&-second': {
        gridColumn: '1/ 4'
      }
    },

    '.date': {
      gridColumn: '4/ 6'
    },

    '.participationRequest': {
      gridColumn: '1/ 6'
    },

    '.note': {
      gridColumn: '1/ 7'
    },

    '.field-content': {
      display: 'flex',

      '.ant-form-item': {
        width: '100%'
      },

      '.field-buttons': {
        display: 'flex',

        '.field-button': {
          color: theme.palette.neutral.main,
          borderRadius: theme.spacing(21),
          minWidth: theme.spacing(17),
          height: theme.spacing(21),
          '&:hover': {
            backgroundColor: theme.palette.neutral[200]
          }
        },

        '.video-button': {
          color: 'white',
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.spacing(21),
          minWidth: theme.spacing(17),
          height: theme.spacing(21),
          marginLeft: theme.spacing(2),
          '&:hover': {
            backgroundColor: theme.palette.primary[100]
          }
        }
      }
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-picker-input > input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    }
  },

  '.participants': {
    marginTop: theme.spacing(16)
  }
}))

export const DeleteVirtualMeetingDialogStyled = styled(Dialog)(({theme}) => ({
  '.MuiDialog-container': {
    alignItems: 'baseline'
  },

  '.MuiPaper-root': {
    width: '600px',
    borderRadius: '15px'
  },

  '.modal-header': {
    padding: '20px 30px',
    fontSize: '25px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },

  '.footer': {
    display: 'flex',
    gap: '5px',
    justifyContent: 'end',
    padding: '20px 30px'
  }
}))
