import {Dialog} from '@mui/material'
import {styled} from '@mui/system'

export const FileViewerModalStyled = styled(Dialog)(({theme}) => ({
  '.modal-container': {
    height: 'calc(100vh - 150px)',
    width: 'calc(100vw - 150px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  '.MuiPaper-root': {
    maxWidth: '100%',
    borderRadius: '15px',
    padding: '15px'
  }
}))
