import {styled} from '@mui/system'

export const PDFSummaryStyled = styled('div')(({theme}) => ({
  img: {
    height: '50px'
  },

  'table.print-container': {
    padding: '50px',
    pageBreakAfter: 'always',
    width: 'calc(100vw - 112px)',
    margin: '0 56px'
  },

  'tfoot.print-footer': {
    display: 'table-footer-group'
  },

  'thead.print-header': {
    display: 'table-header-group'
  },

  'thead.print-header th': {
    paddingBottom: '16px',
    fontWeight: 'normal',
    paddingTop: '56px'
  },

  'thead.print-header .header-container': {
    height: '72px',
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  'thead.print-header .header-container .grid-container': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '8px',
    textalign: 'start'
  },

  'thead.print-header .header-container .label': {
    fontsize: '10px',
    color: '#8B8B8B'
  },

  'thead.print-header .header-container .value': {
    fontsize: '10px',
    color: '#393939'
  },

  'table.print-container .card-container': {
    border: '1px solid #DDDDDD',
    pageBreakInside: 'avoid'
  },

  'table.print-container .card-container .card-title': {
    color: '#555555',
    padding: '8px 14px'
  },

  'table.print-container .card-container .card-content': {
    color: '#555555',
    padding: '8px 14px'
  },

  'table.print-container .card-container .label': {
    color: '#8B8B8B'
  },

  'table.print-container .data.padding': {
    paddingBottom: '8px'
  },

  'table.print-container .grid-container': {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto'
  },

  'table.print-container .circadian-profile-card': {
    pageBreakInside: 'avoid'
  },

  'table.print-container .tab-container-padding': {
    padding: '0px',
    overflowY: 'unset'
  },

  'table.print-container .summary-subtitle': {
    color: '#555555'
  },

  'table.print-container .summary-container': {
    pageBreakInside: 'avoid',
    marginBottom: '16px'
  },

  'table.print-container .summary-container .stats-table': {
    marginTop: '8px',
    pageBreakInside: 'avoid'
  },

  'table.print-container .ant-table': {
    color: '#555555',
    borderBottom: '1px solid #555555',
    border: '1px solid #DDDDDD'
  },

  'table.print-container .ant-table .ant-table-body': {
    maxHeight: '100% !important'
  },

  'table.print-container .ant-table .ant-table-thead': {
    display: 'table-header-group'
  },

  'table.print-container .ant-table .ant-table-thead>tr>th': {
    color: '#555555',
    borderBottom: '1px solid #555555',
    padding: '8px'
  },

  'table.print-container .ant-table .ant-table-tbody>tr>td': {
    padding: '8px'
  },

  '@page': {
    size: 'A4 portrait',
    margin: 0
  },

  '.dashboard-table th': {
    lineHeight: 'normal',
    fontSize: '10px'
  },

  '.dashboard-table td': {
    lineHeight: 'normal',
    fontSize: '10px'
  },

  '.MuiGrid-item': {
    width: '100%'
  },

  '.chart-container': {
    display: 'flex',
    justifyContent: 'center',
    height: '300px'
  },

  '.color-box': {
    width: '15px',
    height: '15px',
    alignSelf: 'center',
    WebkitPrintColorAdjust: 'exact'
  },

  '.legend': {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    margin: '10px',
    fontSize: '10px',
    paddingBottom: '32px'
  }
}))
