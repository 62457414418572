import React, {useEffect, useState} from 'react'
import {DiaryStyle} from './DiaryStyle'
import {Select} from 'antd'
import {Snackbar, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import BoxAderenza from 'src/components/elements/BoxAderenza/BoxAderenza'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import Icon from '@mdi/react'
import {mdiDownloadCircleOutline} from '@mdi/js'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import {PATHS, STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {AdherenceType} from 'src/pages/home/Home'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'

type DiaryProps = {
  patientId: string,
  serviceConf: string,
  userFirstName: string,
  userLastName: string
};

const assumptionsStateOptions = [
  {
    value: 'COMPLETED',
    label: 'Effettuata'
  },
  {
    value: 'PLANNED',
    label: 'Pianificata'
  },
  {
    value: 'TO_BE_PLANNED',
    label: 'In pianificazione'
  },
  {
    value: 'CANCELLED',
    label: 'Non effettuata'
  }
]

const Diary: React.FC<DiaryProps> = ({patientId, serviceConf, userFirstName, userLastName}) => {
  const {diaryConfigurations: {diaryType, columns = [], tableTitle = '', showExport, showTherapiesFilter, adherenceBox}} = useAppSelector(state => state.pspConfigReducer.data.patientDetailConfiguration ?? {})
  const [data, setData] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [fileName, setFileName] = useState<any>('Diario')
  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()
  const [patientAdherence, setPatientAdherence] = useState<AdherenceType>()
  const [statusAdherence, setStatusAdherence] = useState<string>()

  useEffect(() => {
    const fetchAdherence = async () => {
      const fetchedData = await apiClient.getPatientAdherence(patientId, setStatusAdherence)
      setPatientAdherence(fetchedData)
    }

    fetchAdherence()
  }, [patientId])

  useEffect(() => {
    const fetchData = async () => {
      if (diaryType === 'DIARY_MORISKY') {
        const fetchedData = await apiClient.getPatientDiaryMorisky(patientId, {...pagination}, setStatus) ?? []
        setData(fetchedData)
        const fetchedDataCount = await apiClient.getPatientDiaryMoriskyCount(patientId, {}, setStatusCount) ?? []
        setCount(fetchedDataCount)
      } else {
        const fetchedData = await apiClient.getPatientsAssumptions(patientId, setStatus, pagination) ?? []
        const parsedData = fetchedData.map((assumption: any) => {
          return {
            ...assumption,
            state: assumptionsStateOptions.find((option) => option.value === assumption.state)?.label
          }
        })
        setData(parsedData)
        const fetchedDataCount = await apiClient.getPatientsAssumptionsCount(patientId, setStatusCount) ?? []
        setCount(fetchedDataCount)
      }
    }

    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const getTherapy = () => {
    if (data.length > 0) {
      return {
        label: data[0].drug,
        value: data[0].drug
      }
    }
  }

  const [file, setFile] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [statusDownload, setStatusDownload] = useState<string>()

  const handleModalView = async (row: any) => {
    setOpenViewModal(true)
    const fileResponse = await apiClient.generatePdf(`${new URL(window.location.href).origin}/${PATHS.PDF_DIARY}?filters=${JSON.stringify({patientId})}`, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse)], {type: 'application/pdf'})
    setFile((window.URL || window.webkitURL).createObjectURL(blob))
    setFileName(`Diario - ${userFirstName} ${userLastName}`)
  }

  useEffect(() => {
    if (statusDownload === STATUS.REJECTED) {
      setOpenViewModal(false)
    }
  }, [statusDownload])

  return (
    <DiaryStyle>
      {adherenceBox &&
        <div className='card header'>
          <div>
            <Typography variant='body1'>
              <FormattedMessage id='therapy' />
            </Typography>
            <Select
              disabled
              value={getTherapy()}
            />
          </div>
          <div style={{display: 'flex'}}>
            <BoxAderenza subtitle='Aderenza infusioni tutor' title={patientAdherence?.pspAdherencePercentage ? patientAdherence?.pspAdherencePercentage + '%' : '-'} />
            <BoxAderenza subtitle='Aderenza Complessiva' title={patientAdherence?.totalAdherencePercentage ? patientAdherence?.totalAdherencePercentage + '%' : '-'} />
          </div>
        </div>}
      <FileViewerModal
        MIMETypeValue={'application/pdf'}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={statusDownload}
      />
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '16px', padding: '14px'}}>
        {
          showTherapiesFilter && (
            <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
              <Typography>{'Terapia:'}</Typography>
              <Select
                disabled
                getPopupContainer={(trigger) => trigger}
                options={[
                  {
                    label: 'Cerdelga',
                    value: 'Cerdelga'
                  }
                ]}
                value={'Cerdelga'}
              />
            </div>
          )
        }
        {showExport && (
          <PrimaryButton
            onClick={handleModalView}
            startIcon={<Icon path={mdiDownloadCircleOutline} size={1} />}
          >{'Esporta'}
          </PrimaryButton>
        )}
      </div>
      <div className='card' style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{padding: '25px 25px 40px', border: '1px solid #EEE'}}>
          <Typography variant='h4'>{tableTitle}</Typography>
        </div>
        <TableCustom
          columns={columns.filter((col: TableColumn) => (col.id !== 'adherence' || serviceConf === 'EXTRA'))}
          onPaginationChange={setPagination}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED || statusAdherence === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined); setStatusAdherence(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </DiaryStyle>
  )
}

export default Diary
