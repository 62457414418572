/* eslint-disable react-hooks/exhaustive-deps */
import {mdiClose, mdiDelete} from '@mdi/js'
import Icon from '@mdi/react'
import {IconButton, Snackbar, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router-dom'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'

import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import VirtualMeetingOrganizeDialog from 'src/components/templates/VirtualMeetingOrganizeDialog/VirtualMeetingOrganizeDialog'
import VirtualMeetingRequestDialog from 'src/components/templates/VirtualMeetingRequestDialog/VirtualMeetingRequestDialog'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'
import {DeleteVirtualMeetingDialogStyled} from './VirtualMeetingDetailStyled'
import {useAppSelector} from 'src/hooks'

const VirtualMeetings = () => {
  const {virtualMeetingsConfigurations: {organize, request, participantUserGroup}} = useAppSelector(app => app.pspConfigReducer.data)

  const columns : TableColumn[] = [
    {
      id: 'startDate',
      label: 'DATA',
      type: 'date',
      format: 'ddd DD MMM YYYY'
    },
    {
      id: 'startDate',
      label: 'ORARIO',
      type: 'date',
      format: 'HH:mm'
    },
    {
      id: 'title',
      label: 'TITOLO'
    },
    {
      id: 'participantsReadable',
      label: 'PARTECIPANTI'
    },
    {
      id: 'appointmentStatus',
      label: 'STATO'
    }
  ]

  const statusOptions = [
    {
      value: 'PLANNING',
      label: 'Da pianificare'
    },
    {
      value: 'PLANNED',
      label: 'Pianificato'
    },
    {
      value: 'COMPLETED',
      label: 'Completato'
    },
    {
      value: 'CANCELED',
      label: 'Cancellato'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'date',
      label: 'Data',
      type: 'rangePicker'
    },
    {
      id: 'appointmentStatus',
      label: 'Stato',
      type: 'select',
      options: statusOptions
    },
    participantUserGroup === 'PATIENT' ? {
      id: 'user',
      label: 'Paziente',
      type: 'patientLookup'
    } : {
      id: 'user',
      label: 'Dottore',
      type: 'doctorLookup'
    }
  ]

  const [filters, setFilters] = useState<Record<string, string | string[]>[]>([])
  const [data, setData] = useState<Record<string, any>[]>([])
  const [count, setCount] = useState<number>(0)
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [isOrganizeModal, setIsOrganizeModal] = useState<boolean>(false)
  const [isRequestModal, setIsRequestModal] = useState<boolean>(false)
  const history = useHistory()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-startDate'
  })
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedVirtualMeeting, setSelectedVirtualMeeting] = useState<any>(undefined)
  const [statusDelete, setStatusDelete] = useState<any>(undefined)
  const [statusPost, setStatusPost] = useState<any>(undefined)
  const [postMessage, setPostMessage] = useState<any>(undefined)
  const {virtualMeetingsConfigurations: {qualifications}} = useAppSelector(app => app.pspConfigReducer.data)
  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)

  const handleModalDelete = (row: any) => {
    setOpenDeleteModal(true)
    setSelectedVirtualMeeting(row)
  }

  const rowActions : Action[] = [
    {
      id: 'cancel',
      label: 'Cancella',
      icon: mdiDelete,
      action: handleModalDelete,
      condition: (row: any) => { return row.creatorId === userInfoData.authUserId }
    }
  ]

  const fetchData = async () => {
    const fetchedData = await apiClient.getVirtualMeetings(filters, setStatus, pagination) ?? []
    const parsedData = fetchedData.map((meeting: any) => {
      return {
        ...meeting,
        participantsReadable: meeting.participants.map((participant: any) => `${participant.userProperties.firstName} ${participant.userProperties.lastName}`).join(', '),
        appointmentStatus: statusOptions.find((option) => option.value === meeting.appointmentStatus)?.label,
        participants: meeting.participants.map((participant: any) => participant.userProperties)
      }
    })
    setData(parsedData)
    const fetchedDataCount = await apiClient.getVirtualMeetingsCount(filters, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  const saveVirtualMeeting = async (values: any) => {
    setPostMessage("Errore nell'organizzare l'incontro")
    await apiClient.postVirtualMeetings(values, setStatusPost)
    await fetchData()
    setIsOrganizeModal(false)
  }

  const saveVirtualMeetingRequest = async (values: any) => {
    setPostMessage("Errore nel richiedere l'incontro")
    await apiClient.postRequestVirtualMeetings(values, setStatusPost)
    await fetchData()
    setIsRequestModal(false)
  }

  useEffect(() => {
    fetchData()
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Lista incontri virtuali', href: PATHS.VIRTUAL_MEETINGS}]}
      buttons={[
        organize &&
          <PrimaryButton key='organize' onClick={() => setIsOrganizeModal(true)}>
            <Typography style={{fontSize: 12, fontWeight: 600}}>
              <FormattedMessage id='organize' />
            </Typography>
          </PrimaryButton>,
        request &&
          <PrimaryButton key='request' onClick={() => setIsRequestModal(true)}>
            <Typography style={{fontSize: 12, fontWeight: 600}}>
              <FormattedMessage id='request' />
            </Typography>
          </PrimaryButton>,
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          actions={rowActions}
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={(row) => history.push(PATHS.VIRTUAL_MEETING_DETAIL, data.find((el) => el._id === row._id))}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </div>
      {isOrganizeModal &&
        <VirtualMeetingOrganizeDialog
          onClose={() => setIsOrganizeModal(false)}
          onSave={saveVirtualMeeting}
          participantUserGroup={participantUserGroup}
        />}
      {isRequestModal &&
        <VirtualMeetingRequestDialog
          onClose={() => setIsRequestModal(false)}
          onSave={saveVirtualMeetingRequest}
          qualifications={qualifications}
        />}

      <DeleteVirtualMeetingDialogStyled
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
      >
        <div style={{flexDirection: 'row'}}>
          <div className='modal-header'>
            {'Conferma cancellazione'}
            <div>
              <IconButton
                onClick={() => setOpenDeleteModal(false)}
              >
                <Icon path={mdiClose} size={'15px'} />
              </IconButton>
            </div>
          </div>
          <div style={{padding: '0px 30px'}}>
            {"La cancellazione è un'operazione irreversibile, si desidera procedere?"}
          </div>
          <div className='footer'>
            <SecondaryButton
              onClick={async () => {
                await apiClient.deleteVirtualMeetings(selectedVirtualMeeting._id, setStatusDelete)
                await fetchData()
                setOpenDeleteModal(false)
              }}
            >
              {'Ok'}
            </SecondaryButton>
            <SecondaryButton
              onClick={() => setOpenDeleteModal(false)}
            >
              {'Annulla'}
            </SecondaryButton>
          </div>
        </div>
        <div />
      </DeleteVirtualMeetingDialogStyled>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined); setStatusCount(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel caricare i dati della pagina.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatusDelete(undefined) }}
        open={statusDelete === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatusDelete(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel cancellare.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatusPost(undefined) }}
        open={statusPost === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatusPost(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {postMessage}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default VirtualMeetings
