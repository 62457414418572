import {styled} from '@mui/system'

export const TherapyDiaryStyled = styled('div')(({theme}) => ({
  '.ant-select': {
    width: '100%',

    '.ant-select-selector': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(21),

      '.ant-select-selection-placeholder': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-item': {
        lineHeight: 'unset'
      }
    }
  }
}))
