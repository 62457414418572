import {Menu} from '@mui/material'
import {styled} from '@mui/system'

export const MenuStyled = styled(Menu)(({theme}) => ({
  cursor: 'pointer',
  '.MuiMenu-paper': {
    marginTop: `${theme.spacing(8)}`,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid #ccc',
    borderRadius: '2px'
  },
  '.MuiList-root': {
    padding: '0 !important'
  },
  '.MuiMenuItem-root': {
    padding: '0 !important'
  },
  '.textList': {
    padding: `${theme.spacing(7)} ${theme.spacing(8)}`
  }
}))

export const HeaderMenuStyled = styled('div')(({theme}) => ({
  cursor: 'pointer',
  '.menuButton': {
    width: `${theme.spacing(100)}`,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.textList': {

  }
}))
