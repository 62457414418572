import {Button} from '@mui/material'
import {DatePicker, Form, Input, TimePicker} from 'antd'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CalendarEvent} from '../Calendar/Calendar'
import locale from 'antd/es/date-picker/locale/it_IT'
import {useState} from 'react'
import Icon from '@mdi/react'
import {mdiClose, mdiMagnify} from '@mdi/js'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import {VirtualMeetingOrganizeDialogStyled} from './VirtualMeetingOrganizeDialogStyled'
import moment from 'moment'
import DoctorsLookupDialog from '../DoctorsLookupDialog/DoctorsLookupDialog'

type VirtualMeetingOrganizeDialogProps = {
  participantUserGroup: string,
  onClose: () => void,
  onSave: (values: CalendarEvent) => void;
};

const VirtualMeetingOrganizeDialog: React.FC<VirtualMeetingOrganizeDialogProps> = ({
  participantUserGroup,
  onClose,
  onSave
}) => {
  const [form] = Form.useForm()
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [isDoctorsModal, setIsDoctorsModal] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<Record<string, any>>()

  const onFinish = (values : any) => {
    const startDay = values.startDay
    delete values.startDay

    onSave(
      {
        ...values,
        startDate: values.startDate.set({year: startDay.year(), month: startDay.month(), date: startDay.date()}).toISOString(),
        endDate: values.endDate.set({year: startDay.year(), month: startDay.month(), date: startDay.date()}).toISOString(),
        user: currentUser,
        participantUserGroup
      }
    )
  }

  const body = (
    <VirtualMeetingOrganizeDialogStyled>
      <Form
        form={form}
        id='organize-form'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          className='title'
          label='Titolo'
          name='title'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='startDay'
          label='Data evento'
          name='startDay'
          rules={[
            {required: true, message: 'Campo obbligatorio'},
            () => ({
              validator (_, value) {
                if (value?.isBefore(moment())) {
                  return Promise.reject(new Error("L'appuntamento non può essere nel passato"))
                }
                return Promise.resolve()
              }
            })
          ]}
        >
          <DatePicker
            format='DD MMM YYYY'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='startDate'
          label='Dalle'
          name='startDate'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <TimePicker
            format='HH:mm'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='endDate'
          label='Alle'
          name='endDate'
          rules={[
            {required: true, message: 'Campo obbligatorio'},
            () => ({
              validator (_, value) {
                if (
                  value.set('date', form.getFieldValue('startDate').date()).isBefore(form.getFieldValue('startDate')) ||
                  value.set('date', form.getFieldValue('startDate').date()).isSame(form.getFieldValue('startDate'))
                ) {
                  return Promise.reject(new Error('L\'orario di inizio deve essere inferiore all\'orario di fine'))
                }
                return Promise.resolve()
              }
            })
          ]}
        >
          <TimePicker
            format='HH:mm'
            getPopupContainer={(trigger) => trigger}
            locale={locale}
            placeholder=''
          />
        </Form.Item>
        <Form.Item
          className='patient'
          label={participantUserGroup === 'PATIENT' ? 'Paziente' : 'Medico'}
        >
          <div className='patient-content'>
            <Form.Item
              name='user'
              rules={[{required: true, message: 'Campo obbligatorio'}]}
            >
              <Input disabled />
            </Form.Item>
            <div className='patient-buttons'>
              <Button
                className='patient-button'
                onClick={() => participantUserGroup === 'PATIENT' ? setIsPatientsModal(true) : setIsDoctorsModal(true)}
              >
                <Icon path={mdiMagnify} size={1} />
              </Button>
              <Button
                className='patient-button'
                disabled={currentUser === undefined}
                onClick={() => form.setFieldValue('user', undefined)}
              >
                <Icon path={mdiClose} size={1} />
              </Button>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          className='email'
          label={participantUserGroup === 'PATIENT' ? 'Email paziente' : 'Email medico'}
          name='email'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input />
        </Form.Item>
      </Form>
    </VirtualMeetingOrganizeDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton
        form='organize-form'
        key='submit'
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <>
      <DialogCustom
        body={body}
        footer={footer}
        maxWidth='xl'
        onClose={onClose}
        title={{text: participantUserGroup === 'PATIENT' ? 'patientEvent' : 'appointment', format: true}}
      />
      {isPatientsModal &&
        <PatientLookupDialog
          onClose={() => setIsPatientsModal(false)}
          onRowClick={(row) => {
            setCurrentUser(row)
            form.setFieldValue('user', `${row.firstName} ${row.lastName}`)
            form.setFieldValue('email', row.email)
            setIsPatientsModal(false)
          }}
        />}
      {isDoctorsModal &&
        <DoctorsLookupDialog
          onClose={() => setIsDoctorsModal(false)}
          onRowClick={(row) => {
            setCurrentUser(row)
            form.setFieldValue('user', `${row.firstName} ${row.lastName}`)
            form.setFieldValue('email', row.email)
            setIsDoctorsModal(false)
          }}
        />}
    </>
  )
}

export default VirtualMeetingOrganizeDialog
