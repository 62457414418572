import {STATUS} from 'src/constants/constants'
import Loader from '../Loader/Loader'
import {FileViewerModalStyled} from './FileViewerModalStyled'
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'

const FileViewerModal: React.FC<any> = ({filepath, MIMETypeValue, open, handleClose, status, fileName}) => {
  const downloadFile = async () => {
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <FileViewerModalStyled
        onClose={() => { handleClose() }}
        open={open}
      >
        <div className='modal-container'>
          {status === STATUS.PENDING &&
            <Loader />}
          {status === STATUS.FULFILLED &&
            <>
              <div style={{flexGrow: 1, display: 'flex', width: 'inherit', justifyContent: 'center'}}>
                {['image/jpeg', 'image/png', 'image/svg+xml'].includes(MIMETypeValue) &&
                  <img alt='document data' src={filepath} style={{maxHeight: 'calc(100vh - 200px)', maxWidth: 'calc(100vw - 150px)', alignSelf: 'center'}} title='document data' />}
                {['application/pdf', 'audio/mpeg', 'video/x-msvideo', 'video/mp4', 'video/mpeg'].includes(MIMETypeValue) &&
                  <iframe height='100%' src={`${filepath}#toolbar=0`} title='document data' width='100%' />}
              </div>
              <div style={{display: 'flex', justifyContent: 'end', width: '100%', paddingTop: '16px'}}>
                <PrimaryButton onClick={() => downloadFile()}>{'Scarica'}</PrimaryButton>
              </div>
            </>}
        </div>
      </FileViewerModalStyled>
    </>
  )
}

export default FileViewerModal
