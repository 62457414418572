import {mdiAccount, mdiContentCopy, mdiVideoPlus} from '@mdi/js'
import Icon from '@mdi/react'
import {Button, Slide, Snackbar, Typography} from '@mui/material'
import {Checkbox, DatePicker, Form, Input, TimePicker} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory, useLocation} from 'react-router-dom'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {PATHS, STATUS} from 'src/constants/constants'
import {VirtualMeetingDetailStyled} from './VirtualMeetingDetailStyled'
import TableHeader from 'src/components/templates/TableHeader/TableHeader'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {useAppSelector} from 'src/hooks'
import TextArea from 'antd/lib/input/TextArea'

const parseMedicalSupportService = (form: any) => {
  let medicalSupportService = false
  let qualificationIdsArray

  if (form.getFieldValue('qualificationIds')) {
    qualificationIdsArray = [...form.getFieldValue('qualificationIds')]
    const index = qualificationIdsArray.indexOf('medicalSupportService')
    if (index !== -1) {
      qualificationIdsArray.splice(index, 1)
      medicalSupportService = true
    }
  }
  return {
    medicalSupportService,
    qualificationIdsArray
  }
}

const VirtualMeetingDetail = () => {
  const {state} = useLocation<Record<string, any>>()
  const history = useHistory<any>()
  const [status, setStatus] = useState<any>(undefined)
  const [form] = Form.useForm()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const userInfo = useAppSelector(({userReducer}) => userReducer.data)
  const {virtualMeetingsConfigurations: {qualifications}} = useAppSelector(app => app.pspConfigReducer.data)

  const isReadOnlyMode = state.creatorId !== userInfo.authUserId || state.startDate < moment().toISOString()

  const [readOnly, setReadOnly] = useState<any>(isReadOnlyMode)

  const readOnlyFunc = async () => {
    if (userInfo?.authUserId) {
      setReadOnly(isReadOnlyMode)
    }
  }

  useEffect(() => {
    readOnlyFunc()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.authUserId])

  useEffect(() => {
    if (status === STATUS.FULFILLED) {
      let newState
      if (!state?.reqConfirm) {
        const startDate = form.getFieldValue('startDate')
        const endDate = form.getFieldValue('endDate')
        const startDay = form.getFieldValue('startDay')

        newState = {
          ...state,
          title: form.getFieldValue('title'),
          startDate: startDate.set({year: startDay.year(), month: startDay.month(), date: startDay.date()}).toISOString(),
          endDate: endDate.set({year: startDay.year(), month: startDay.month(), date: startDay.date()}).toISOString()
        }
      } else {
        const qualificationsData = parseMedicalSupportService(form)
        newState = {
          ...state,
          title: form.getFieldValue('title'),
          medicalSupportService: qualificationsData.medicalSupportService,
          qualificationIds: qualificationsData.qualificationIdsArray,
          notes: form.getFieldValue('notes')
        }
      }

      history.replace({pathname: PATHS.VIRTUAL_MEETING_DETAIL, state: newState})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const columns : TableColumn[] = [
    {
      id: 'firstName',
      label: 'NOME'
    },
    {
      id: 'lastName',
      label: 'COGNOME'
    },
    {
      id: 'email',
      label: 'EMAIL'
    },
    {
      id: 'qualification',
      label: 'QUALIFICA'
    }
  ]

  const editVirtualMeeting = async (values : any) => {
    if (!state?.reqConfirm) {
      await apiClient.patchVirtualMeetings(state._id, {
        title: values.title,
        startDate: values.startDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString(),
        endDate: values.endDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString()
      }, setStatus)
    } else {
      const qualificationsData = parseMedicalSupportService(form)
      await apiClient.patchVirtualMeetings(state._id, {
        title: values.title,
        medicalSupportService: qualificationsData.medicalSupportService,
        qualifications: qualificationsData.qualificationIdsArray,
        notes: values.notes || ''
      }, setStatus)
    }
  }

  useEffect(() => {
    if (!state?.reqConfirm) {
      form.setFieldValue('title', state?.title)
      form.setFieldValue('code', state?._id)
      form.setFieldValue('startDay', state?.startDate ? moment(state.startDate) : undefined)
      form.setFieldValue('startDate', state?.startDate ? moment(state.startDate) : undefined)
      form.setFieldValue('endDate', state?.endDate ? moment(state.endDate) : undefined)
      form.setFieldValue('linkTeleconsultation', state?.linkTeleconsultation)
      form.setFieldValue('organizer', state?.organizer ? `${state.organizer.firstName} ${state.organizer.lastName}` : undefined)
    } else {
      form.setFieldValue('title', state?.title)
      form.setFieldValue('code', state?._id)
      form.setFieldValue('organizer', state?.organizer ? `${state.organizer.firstName} ${state.organizer.lastName}` : undefined)
      form.setFieldValue('createdAt', state?.createdAt ? moment(state.createdAt) : undefined)
      if (state?.medicalSupportService && state?.qualificationIds) {
        form.setFieldValue('qualificationIds', ['medicalSupportService'].concat(state.qualificationIds))
      } else {
        form.setFieldValue('qualificationIds', state?.qualificationIds)
      }
      form.setFieldValue('medicalSupportService', state?.medicalSupportService)
      form.setFieldValue('notes', state?.notes)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, state])

  const getContentInfo = () => {
    if (!state?.reqConfirm) {
      return (
        <>
          <Form.Item
            className='startDay'
            label='Data evento'
            name='startDay'
            rules={[
              {required: true, message: 'Campo obbligatorio'},
              () => ({
                validator (_, value) {
                  if (value?.isBefore(moment())) {
                    return Promise.reject(new Error("L'appuntamento non può essere nel passato"))
                  }
                  return Promise.resolve()
                }
              })
            ]}
          >
            <DatePicker
              disabled={readOnly}
              format='DD MMM YYYY'
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          <Form.Item
            className='startDate'
            label='Ora inizio'
            name='startDate'
          >
            <TimePicker
              disabled={readOnly}
              format='HH:mm'
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          <Form.Item
            className='endDate'
            label='Ora fine'
            name='endDate'
          >
            <TimePicker
              disabled={readOnly}
              format='HH:mm'
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          <Form.Item
            className='link'
            label='Link per il collegamento'
          >
            <div className='field-content'>
              <Form.Item
                name='linkTeleconsultation'
              >
                <Input disabled />
              </Form.Item>
              <div className='field-buttons'>
                <Button
                  className='video-button'
                  disabled={state?.linkTeleconsultation === undefined}
                  onClick={() => window.open(state.linkTeleconsultation, '_blank')}
                >
                  <Icon path={mdiVideoPlus} size={1} />
                </Button>
                <Button
                  className='field-button'
                  disabled={state?.linkTeleconsultation === undefined}
                  onClick={() => navigator.clipboard.writeText(state.linkTeleconsultation)}
                >
                  <Icon path={mdiContentCopy} size={1} />
                </Button>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            className='organizer'
            label='Organizzatore'
          >
            <div className='field-content'>
              <Form.Item
                name='organizer'
              >
                <Input disabled />
              </Form.Item>
            </div>
          </Form.Item>
        </>
      )
    } else {
      return (
        <>
          <Form.Item
            className='organizer-second'
            label='Organizzatore'
          >
            <div className='field-content'>
              <Form.Item
                name='organizer'
              >
                <Input disabled />
              </Form.Item>
              <div className='field-buttons'>
                <Button
                  className='field-button'
                >
                  <Icon path={mdiAccount} size={1} />
                </Button>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            className='date'
            label='Data/ora richiesta'
            name='createdAt'
          >
            <TimePicker
              disabled
              format='ddd DD MMM YYYY HH:mm'
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          <Form.Item
            className='participationRequest'
            label='Richiesta la partecipazione di'
            name='qualificationIds'
          >
            <Checkbox.Group>
              <Checkbox disabled={readOnly} key={'medicalSupportService'} value={'medicalSupportService'}>
                <Typography variant='body2'>{'Medico del Servizio'}</Typography>
              </Checkbox>
              {qualifications.map((qualification: any) => {
                return (
                  <Checkbox disabled={readOnly} key={qualification.id} value={qualification.id}>
                    <Typography variant='body2'>{qualification.name}</Typography>
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            className='note'
            label='Date e fasce orarie di disponibilità ed eventuali Note'
            name='notes'
          >
            <TextArea
              disabled={readOnly} rows={4} style={{height: 120, resize: 'none'}}
            />
          </Form.Item>
        </>
      )
    }
  }

  return (
    <LayoutContainer
      breadcrumbs={[
        {label: 'Lista incontri virtuali', href: PATHS.VIRTUAL_MEETINGS},
        {label: 'Incontro virtuale'}
      ]}
      buttons={[
        <PrimaryButton
          disabled={readOnly}
          key='save'
          onClick={() => editVirtualMeeting(form.getFieldsValue())}
        >
          <FormattedMessage id='save' />
        </PrimaryButton>
      ]}
    >
      <Slide
        direction='up'
        in
        mountOnEnter
        timeout={1000}
        unmountOnExit
      >
        <VirtualMeetingDetailStyled>
          <CardCustom title={
            <div className='custom-card-header' style={{margin: '10px 0px'}}>
              <Typography fontWeight={700} variant='h4'><FormattedMessage id='mainData' />
              </Typography>
            </div>
          }
          >
            <Form
              form={form}
              id='main-data-form'
              layout='vertical'
            >
              <Form.Item
                className='title'
                label='Titolo'
                name='title'
                rules={[
                  {required: true, message: 'Campo obbligatorio'}
                ]}
              >
                <Input disabled={readOnly} />
              </Form.Item>
              <Form.Item
                className='code'
                label='Codice'
                name='code'
              >
                <Input disabled />
              </Form.Item>
              {getContentInfo()}
            </Form>
          </CardCustom>
          <div className='participants'>
            <TableHeader>
              <div className='table-header'>
                <FormattedMessage id={'participants'} />
              </div>
            </TableHeader>
            <TableCustom
              columns={columns}
              onPaginationChange={setPagination}
              pagination={pagination}
              rows={state.participants ?? []}
              showPagination={false}
              totalElements={state.participants.length}
            />
          </div>
        </VirtualMeetingDetailStyled>
      </Slide>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel modificare i dati della pagina.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.FULFILLED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='success' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Modificati i dati con successo'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default VirtualMeetingDetail
