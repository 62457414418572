import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {DatePicker, Form, Input, Radio, Select} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import locale from 'antd/es/date-picker/locale/it_IT'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import Icon from '@mdi/react'
import {mdiAccount, mdiFormatListBulleted, mdiUpload} from '@mdi/js'
import {ActivityDialogStyled} from './ActivityDialogStyled'
import {useEffect} from 'react'
import moment from 'moment'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import ActivityDocuments from 'src/components/templates/ActivityDocuments/ActivityDocuments'

type ActivityDialogProps = {
  activity: Record<string, any>;
  showDepartment?: boolean,
  withDocument?: boolean,
  onClose: () => void;
};

const activityState = [
  {
    label: 'Da pianificare',
    value: 'TO_BE_PLANNED'
  },
  {
    label: 'Da confermare',
    value: 'TO_BE_CONFIRMED'
  },
  {
    label: 'Avvenuta da completare',
    value: 'TO_BE_CONCLUDED '
  },
  {
    label: 'Avvenuta da verificare',
    value: 'TO_BE_VERIFIED'
  },
  {
    label: 'Avvenuta e completata',
    value: 'COMPLETED'
  },
  {
    label: 'Annullata',
    value: 'CANCELLED'
  }
]

const ActivityDialog: React.FC<ActivityDialogProps> = ({
  activity,
  onClose,
  showDepartment = true,
  withDocument = false
}) => {
  const [form] = Form.useForm()

  const TABS = [
    {
      id: 'data',
      icon: <Icon path={mdiFormatListBulleted} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Anagrafica'}</Typography>
    },

    {
      id: 'documents',
      icon: <Icon path={mdiUpload} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Documenti'}</Typography>
    }
  ]
  useEffect(() => {
    form.setFieldValue('name', activity.title)
    form.setFieldValue('code', activity._id)
    form.setFieldValue('beneficiary', activity.beneficiary ? `${activity.beneficiary.firstName} ${activity.beneficiary.lastName}` : undefined)
    form.setFieldValue('state', activityState.find((state: {label: string; value: string}) => state.value === activity.state)?.label)
    form.setFieldValue('verify', activity.isVerificationRequired)
    form.setFieldValue('note', activity.note)
    form.setFieldValue('date', activity.plannedDate ? moment(activity.plannedDate) : undefined)
    if (activity.type !== 'VIRTUAL_MEETING') {
      form.setFieldValue('duration', activity.activityReport?.activityDurationMinutes ?? undefined)
      form.setFieldValue('country', activity.place?.country)
      form.setFieldValue('region', activity.place?.region)
      form.setFieldValue('province', activity.place?.province)
      form.setFieldValue('city', activity.place?.city)
      form.setFieldValue('address', activity.place?.address)
      form.setFieldValue('civicNumber', activity.place?.streetNumber)
      form.setFieldValue('CAP', activity.place?.postalCode)
      form.setFieldValue('locality', activity.place?.locality)
    }
  }, [form, activity])

  const activityDetail = (
    <ActivityDialogStyled>
      <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='mainData' /></Typography>}>
        <Form
          disabled
          form={form}
          id='main-data-form'
          layout='vertical'
        >
          <Form.Item
            className='name'
            label='Nome'
            name='name'
          >
            <Input />
          </Form.Item>
          <Form.Item
            className='code'
            label='Codice attività'
            name='code'
          >
            <Input />
          </Form.Item>
          <Form.Item
            className='user'
            label='Beneficiario'
          >
            <Form.Item name='beneficiary' style={{marginBottom: '0px'}}>
              <Input />
            </Form.Item>
            <Icon path={mdiAccount} size={1} />
          </Form.Item>
          <Form.Item
            className='state'
            label='Stato'
            name='state'
          >
            <Select
              getPopupContainer={(trigger) => trigger}
            />
          </Form.Item>
          <Form.Item
            className='verify'
            label='Verifica'
            name='verify'
          >
            <Radio.Group>
              <Radio value>{'Sì'}</Radio>
              <Radio value={false}>{'No'}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className='description'
            label='Note'
            name='note'
          >
            <TextArea disabled rows={4} />
          </Form.Item>
          <Form.Item
            className='date'
            label='Data/ora attività'
            name='date'
          >
            <DatePicker
              format='ddd DD MMM YYYY HH:MM'
              getPopupContainer={(trigger) => trigger}
              locale={locale}
              placeholder=''
            />
          </Form.Item>
          {activity.type !== 'VIRTUAL_MEETING' &&
            <Form.Item
              className='duration'
              label='Durata (minuti)'
              name='duration'
            >
              <Input />
            </Form.Item>}
        </Form>
      </CardCustom>
      {activity.type !== 'VIRTUAL_MEETING' && activity.place &&
        <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='meetingPlace' /></Typography>}>
          <Form
            disabled
            form={form}
            id='site-form'
            layout='vertical'
          >
            <Form.Item
              className='state'
              label='Stato'
              name='country'
            >
              <Select
                getPopupContainer={(trigger) => trigger}
              />
            </Form.Item>
            <Form.Item
              className='region'
              label='Regione'
              name='region'
            >
              <Select
                getPopupContainer={(trigger) => trigger}
              />
            </Form.Item>
            <Form.Item
              className='province'
              label='Provincia'
              name='province'
            >
              <Select
                getPopupContainer={(trigger) => trigger}
              />
            </Form.Item>
            <Form.Item
              className='city'
              label='Città'
              name='city'
            >
              <Select
                getPopupContainer={(trigger) => trigger}
              />
            </Form.Item>
            <Form.Item
              className='address'
              label='Indirizzo'
              name='address'
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='civicNumber'
              label='Civico'
              name='civicNumber'
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='CAP'
              label='Codice Postale'
              name='CAP'
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='locality'
              label='Località'
              name='locality'
            >
              <Input />
            </Form.Item>
          </Form>
        </CardCustom>}
    </ActivityDialogStyled>
  )

  const document = (
    <ActivityDocuments activityId={activity._id} beneficiaryId={activity.beneficiaryId} />
  )

  const CONTENT = [
    activityDetail,
    document
  ]

  const body = (
    <TabsCustom
      contents={CONTENT}
      tabs={TABS}
    />
  )

  return (
    <DialogCustom
      body={withDocument ? body : activityDetail}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: `${activity.title}`, format: false}}
    />
  )
}

export default ActivityDialog
