import {styled} from '@mui/system'

export const DBSDialogStyled = styled('div')(({theme}) => ({

  '.ant-form-item': {
    width: '100%'
  },

  '.ant-input': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  '.ant-input[disabled]': {
    backgroundColor: '#eee',
    borderColor: '#ccc',
    color: '#555'
  },

  '.ant-picker': {
    width: '100%',
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#eee'
  },

  '.patient-content': {
    display: 'flex',
    width: '100%',

    '.patient-buttons': {
      display: 'flex',

      '.patient-button': {
        color: theme.palette.neutral.main,
        borderRadius: theme.spacing(21),
        minWidth: theme.spacing(17),
        height: theme.spacing(21),
        '&:hover': {
          backgroundColor: theme.palette.neutral[200]
        }
      }
    }
  }
}))
