import {styled} from '@mui/system'
import {STYLE} from 'src/constants/constants'

export const HeaderStyled = styled('div')(({theme}) => ({
  height: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
  gap: theme.spacing(4),
  width: `calc(100vw - ${theme.spacing(STYLE.SIDEBAR_WIDTH)})`,
  position: 'fixed',
  zIndex: '15',
  backgroundColor: theme.palette.background.default,

  '.logo': {
    img: {
      height: '35px'
    }
  }
}))
