import {styled} from '@mui/system'

export const ButtonStyled = styled('div')(({theme}) => ({
  '.MuiButtonBase-root': {
    color: 'black',
    fontSize: 18,
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    }
  }
}))
