import {styled} from '@mui/system'

export const CustomerUserDialogStyled = styled('div')(({theme}) => ({
  '.ant-form': {
    paddingBottom: theme.spacing(18),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(11)
  },
  '.container': {
    border: `1px solid ${theme.palette.neutral[100]}`,
    borderRadius: theme.spacing(1),
    '&-title': {
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`
    },
    '.MuiDivider-root': {
      borderColor: theme.palette.neutral[100]
    },
    '&-body': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(7.5),
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`,
      '&-row': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: theme.spacing(15),
        '&-flex': {
          display: 'flex',
          gap: theme.spacing(15)
        },
        '&-column': {
          gridTemplateColumns: '1fr 1fr'
        },
        '&-long': {
          gridTemplateColumns: '2fr 1fr 1fr 2fr'
        }
      },
      '&-element': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5)
      },
      '&-value': {
        cursor: 'no-drop',
        minHeight: theme.spacing(18),
        padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
        backgroundColor: theme.palette.neutral[100]
      }
    },
    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-form-item': {
      marginBottom: 0
    },
    '.ant-select': {
      width: '100%',
      minWidth: '130px',
      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-overflow': {
          position: 'absolute',
          '.ant-select-selection-item': {
            borderRadius: '16px'
          }
        }
      }
    },
    '.ant-radio-row': {
      '.ant-radio-inner': {
        borderRadius: 0,
        '&:after': {
          borderRadius: 0
        }
      },
      '.ant-form-item-row': {
        width: 'fit-content',
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        alignItems: 'center',
        '.ant-form-item-label': {
          padding: 0
        }
      }
    }
  }
}))
