import React, {useEffect, useState} from 'react'
import {mdiClipboardText} from '@mdi/js'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import apiClient from 'src/services/apiClient'
import {STATUS, PREVIEW_MIME_TYPES} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {Snackbar, Typography} from '@mui/material'
import ExportFilesModal from './ExportFilesModal'
import {Spin} from 'antd'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'

export type ExportFileType = {
    fileId: string,
    fileName: string,
    templateId: string,
    templateName: string
}

export interface PharmacovigilanceEvent {
  _id: string,
  patientId: string,
  eventDate: string,
  code:string,
  eventType: string,
  state: string,
  signallingDate: string,
  notificationDate: string,
  reportType: string,
  patientFirstName?: string,
  patientLastName?: string,
  exportFiles?: ExportFileType[]
}

const COLUMNS = [
  {id: 'eventDate', label: 'Data evento', type: 'date', format: 'DD MMMM YYYY'},
  {id: 'code', label: 'Codice evento'},
  {id: 'patient', label: 'Paziente'},
  {id: 'eventType', label: 'Tipo'},
  {id: 'notificationDate', label: 'Data invio', type: 'date', format: 'DD MMMM YYYY'},
  {id: 'reportType', label: 'Tipo segnalazione'}
]

export default function Pharmacovigilance ({patientId}: {patientId: string}) {
  const filterConfig: FilterItem[] = [
    {
      id: 'eventDate',
      label: 'Data evento',
      type: 'rangePicker'
    },
    {
      id: 'eventType',
      label: 'Tipo evento',
      type: 'select',
      options: [
        {
          value: 'segnalazioneIniziale',
          label: 'Segnalazione iniziale'
        },
        {
          value: 'segnalazioneFollowUp',
          label: 'Segnalazione follow up'
        }
      ]
    },
    {
      id: 'signallingDate',
      label: 'Data raccolta segnalazione',
      type: 'rangePicker'
    },
    {
      id: 'state',
      label: 'Stato evento',
      type: 'select',
      options: [
        {
          value: 'Effettiva',
          label: 'Effettiva'
        },
        {
          value: 'Annullata',
          label: 'Annullata'
        }
      ]
    },
    {
      id: 'notificationDate',
      label: 'Data notifica al Cliente',
      type: 'rangePicker'
    },
    {
      id: 'code',
      label: 'Codice',
      type: 'inputText'
    },
    {
      id: 'reportType',
      label: 'Tipo segnalazione',
      type: 'select',
      options: [
        {
          label: 'ICRS',
          value: 'icrs'
        },
        {
          label: 'MI',
          value: 'mi'
        },
        {
          label: 'TPC',
          value: 'tpc'
        },
        {
          label: 'PC',
          value: 'pc'
        },
        {
          label: 'Deviazione dalla schedula',
          value: 'deviazioneDallaSchedula'
        }
      ]
    }
  ]

  const [isGenerateFormDialogOpen, setIsGenerateFormDialogOpen] = useState(false)
  const [isGenerateMultipleFormDialogOpen, setIsGenerateMultipleFormDialogOpen] = useState(false)
  const [selectedPharmacovigilance, setSelectedPharmacovigilance] = useState<PharmacovigilanceEvent>()
  const [selectedExportIndex, setSelectedExportIndex] = useState<number>(0)
  const [file, setFile] = useState<any>()
  const [fileName, setFileName] = useState<any>()
  const [MIMETypeValue, setMIMETypeValue] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)

  const [data, setData] = useState<PharmacovigilanceEvent[]>([])
  const [status, setStatus] = useState<string>()
  const [downloadStatus, setDownloadStatus] = useState<string>()

  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()

  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getPharmacovigilanceEvents(patientId, filters, setStatus, pagination) ?? []
      setData(fetchedData)
      const fetchedDataCount = await apiClient.getPharmacovigilanceEventsCount(patientId, filters, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }

    fetchData()
  }, [filters, pagination, patientId])

  const cleanData = (rawData: PharmacovigilanceEvent[]) => {
    return rawData.map((row) => ({
      ...row,
      patient: `${row.patientFirstName} ${row.patientLastName}`
    }))
  }

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const exportPharmacovigilance = async () => {
    const exportFiles = selectedPharmacovigilance?.exportFiles || []
    const fileResponse = await apiClient.downloadPharmacovigilance(exportFiles[selectedExportIndex].fileId, setDownloadStatus)
    setIsGenerateFormDialogOpen(false)
    setIsGenerateMultipleFormDialogOpen(false)
    const blob = new Blob([new Uint8Array(fileResponse?.data)], {type: fileResponse?.headers['content-type']})
    const filepath = (window.URL || window.webkitURL).createObjectURL(blob)
    setFile(filepath)
    setFileName(exportFiles[selectedExportIndex]?.fileName)
    setMIMETypeValue(fileResponse?.headers['content-type'])
    if (PREVIEW_MIME_TYPES.includes(fileResponse?.headers['content-type'])) {
      setOpenViewModal(true)
    } else {
      const link = document.createElement('a')
      link.setAttribute('href', filepath)
      link.setAttribute('download', exportFiles[selectedExportIndex]?.fileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'end'}}>
      <Filters config={filterConfig} onSubmit={onSubmit} />
      <TableCustom
        actions={[
          {
            id: 'form',
            label: 'Visualizza documento',
            icon: mdiClipboardText,
            action: (row: any) => {
              if (row?.exportFiles?.length === 1) { setIsGenerateFormDialogOpen(true); setSelectedExportIndex(0) } else setIsGenerateMultipleFormDialogOpen(true)
              setSelectedPharmacovigilance(row)
            },
            condition: (row: any) => { return row.exportFiles && row.exportFiles.length > 0 }
          }
        ]}
        columns={COLUMNS}
        onPaginationChange={setPagination}
        pagination={pagination}
        rows={cleanData(data)}
        totalElements={count}
      />
      <DialogCustom
        body={
          <div>
            <p>{'Si desidera generare il Form di Farmaco Vigilanza selezionato?'}</p>
            {downloadStatus === 'PENDING' &&
              <div style={{display: 'flex', gap: '8px'}}>
                <Spin size='small' />
                {'Generazione form in corso'}
              </div>}
          </div>
        }
        disableShadow={isGenerateMultipleFormDialogOpen}
        footer={
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
            <SecondaryButton onClick={() => exportPharmacovigilance()}>{'Ok'}</SecondaryButton>
            <SecondaryButton onClick={() => {
              if (!isGenerateMultipleFormDialogOpen) {
                setIsGenerateFormDialogOpen(false)
                setSelectedPharmacovigilance(undefined)
              } else {
                setIsGenerateFormDialogOpen(false)
              }
            }}
            >{'Annulla'}
            </SecondaryButton>
          </div>
        }
        onClose={() => {
          if (!isGenerateMultipleFormDialogOpen) {
            setIsGenerateFormDialogOpen(false)
            setSelectedPharmacovigilance(undefined)
          } else {
            setIsGenerateFormDialogOpen(false)
          }
        }}
        open={isGenerateFormDialogOpen}
        title={{text: 'Conferma generazione Form', format: false}}
      />
      <ExportFilesModal
        exportFiles={selectedPharmacovigilance?.exportFiles || []}
        handleClose={() => { setIsGenerateMultipleFormDialogOpen(false); setSelectedPharmacovigilance(undefined) }}
        handleOpen={(index: number) => { setIsGenerateFormDialogOpen(true); setSelectedExportIndex(index) }}
        open={isGenerateMultipleFormDialogOpen}
      />
      <FileViewerModal
        MIMETypeValue={MIMETypeValue}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setMIMETypeValue(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={downloadStatus}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setDownloadStatus(undefined) }}
        open={downloadStatus === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setDownloadStatus(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nello scaricare documento'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )
}
