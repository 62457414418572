import {Paper} from '@mui/material'
import {styled} from '@mui/system'

export const EnrollmentPatientFormStyled = styled('div')(({theme}) => ({

  '.custom-card-header': {
    margin: `${theme.spacing(5)} 0px`
  },

  '#enrollment-form': {

    '.patient-details': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: theme.spacing(12)
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        }
      }
    }
  },

  '.enrollment-upload': {
    img: {
      maxHeight: theme.spacing(75),
      margin: theme.spacing(16)
    }
  },

  '.footer-div': {
    display: 'flex',
    justifyContent: 'flex-end',

    button: {
      margin: theme.spacing(2)
    }
  },

  '.upload-items-header': {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(8)
  },

  '.upload-item': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(8),
    border: `1px solid ${theme.palette.neutral[200]}`
  }
}))

export const CardStyled = styled(Paper)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(24),

  '.title': {
    fontSize: theme.spacing(12),
    fontWeight: 600,
    margin: theme.spacing(8)
  },

  '.MuiCircularProgress-root': {
    margin: theme.spacing(10)
  },

  svg: {
    marginBottom: theme.spacing(8)
  },

  '.subtitle': {
    fontSize: theme.spacing(7),
    fontStyle: 'italic'
  }

}))
