import {styled} from '@mui/system'

export const ActivityDialogStyled = styled('div')(({theme}) => ({

  padding: theme.spacing(8),

  '.ant-form-item-label > label': {
    fontSize: theme.spacing(6),
    color: '#222'
  },

  '.ant-input': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  '.ant-input[disabled]': {
    backgroundColor: '#eee',
    borderColor: '#ccc',
    color: '#555'
  },

  '.ant-picker': {
    width: '100%',
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#eee'
  },

  '.ant-picker-input > input[disabled]': {
    backgroundColor: '#eee',
    borderColor: '#ccc',
    color: '#555'
  },

  '.ant-select': {
    width: '100%',

    '.ant-select-selector': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(21),

      '.ant-select-selection-placeholder': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-item': {
        lineHeight: 'unset'
      }
    }
  },

  '.ant-select-disabled': {

    '.ant-select-selector': {
      backgroundColor: '#eee !important',
      borderColor: '#ccc',
      color: '#555 !important'
    }
  },

  '.ant-radio-disabled + span': {
    color: '#555'
  },

  '.ant-form-item-control-input-content': {
    display: 'flex',
    alignItems: 'flex-start'
  },

  '.ant-row': {
    width: '100%'
  },

  '.ant-form-item': {
    width: '100%'
  },

  svg: {
    margin: theme.spacing(4),
    color: theme.palette.neutral.main
  },

  '#main-data-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: '10px',
    color: 'red',

    '.name': {
      gridColumn: '1 / 4',
      gridRow: 1
    },

    '.code': {
      gridColumn: '4 / 7',
      gridRow: 1
    },

    '.user': {
      gridColumn: '1 / 4',
      gridRow: 2
    },

    '.state': {
      gridColumn: '4 / 6',
      gridRow: 2
    },

    '.verify': {
      gridColumn: 6,
      gridRow: 2
    },

    '.description': {
      gridColumn: '1 / 7',
      gridRow: 3,
      textarea: {
        height: theme.spacing(40)
      }
    },

    '.date': {
      gridColumn: '4 / 7',
      gridRow: 4
    },

    '.duration': {
      gridColumn: '1 / 4',
      gridRow: 4
    }
  },

  '#site-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    columnGap: '10px',

    '.state': {
      gridColumn: '1 / 4',
      gridRow: 1,

      '.ant-select-disabled': {
        '.ant-select-selector': {
          backgroundColor: 'white !important'
        },

        '.ant-select-selection-item': {
          color: '#8F99A0',
          fontWeight: 600
        }
      }
    },

    '.region': {
      gridColumn: '4 / 7',
      gridRow: 1,

      '.ant-select-disabled': {
        '.ant-select-selector': {
          backgroundColor: 'white !important'
        },

        '.ant-select-selection-item': {
          color: '#8F99A0',
          fontWeight: 600
        }
      }
    },

    '.province': {
      gridColumn: '1 / 4',
      gridRow: 2,

      '.ant-select-disabled': {
        '.ant-select-selector': {
          backgroundColor: 'white !important'
        },

        '.ant-select-selection-item': {
          color: '#8F99A0',
          fontWeight: 600
        }
      }
    },

    '.city': {
      gridColumn: '4 / 7',
      gridRow: 2,

      '.ant-select-disabled': {
        '.ant-select-selector': {
          backgroundColor: 'white !important'
        },

        '.ant-select-selection-item': {
          color: '#8F99A0',
          fontWeight: 600
        }
      }
    },

    '.address': {
      gridColumn: '1 /  3',
      gridRow: 3
    },

    '.civicNumber': {
      gridColumn: 3,
      gridRow: 3
    },

    '.CAP': {
      gridColumn: 4,
      gridRow: 3
    },

    '.locality': {
      gridColumn: '5 / 7',
      gridRow: 3
    }
  }
}))
