import {styled} from '@mui/system'

export const UserAvatarDialogStyled = styled('div')(({theme}) => ({
  '.ant-input': {
    width: '30vw'
  },
  '.avatar-container': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1vw'
  }
}))
