import React from 'react'
import HeaderMenu from 'src/components/elements/Menus/HeaderMenu/HeaderMenu'
import {FirstLoginHeaderStyled} from './FirstLoginHeaderStyled'
import {useAppSelector} from 'src/hooks'

const FirstLoginHeader: React.FC<{showMenu?: boolean}> = ({showMenu = true}) => {
  const {logoImagePSP} = useAppSelector(state => state.pspConfigReducer.data)

  return (
    <FirstLoginHeaderStyled className='header'>
      <div className='logo'>
        <img alt='IA logo' src={`data:image/png;base64,${logoImagePSP}`} />
      </div>
      {showMenu && <HeaderMenu firstLogin />}
    </FirstLoginHeaderStyled>
  )
}

export default FirstLoginHeader
