import {styled} from '@mui/system'

export const NewEnrollmentDialogStyled = styled('div')(({theme}) => ({
  padding: theme.spacing(8),

  '.ant-form-item-label > label': {
    fontSize: theme.spacing(6),
    color: '#222'
  },

  '.ant-input': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  '.ant-input-number': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '.ant-input-number-input': {
      height: '100%'
    }
  },

  '.ant-input[disabled]': {
    backgroundColor: '#eee',
    borderColor: '#ccc',
    color: '#555'
  },

  '.ant-input-number-group-wrapper': {
    width: '100%'
  },

  '.ant-picker': {
    width: '100%',
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: '#eee'
  },

  '.ant-picker-input > input[disabled]': {
    backgroundColor: '#eee',
    borderColor: '#ccc',
    color: '#555'
  },

  '.ant-select': {
    width: '100%',

    '.ant-select-selector': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(21),

      '.ant-select-selection-placeholder': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-item': {
        lineHeight: 'unset'
      }
    }
  },

  '.ant-select-disabled': {

    '.ant-select-selector': {
      backgroundColor: '#eee !important',
      borderColor: '#ccc',
      color: '#555 !important'
    }
  },

  '.ant-radio-disabled + span': {
    color: '#555'
  },

  '.ant-form-item-control-input-content': {
    display: 'flex',
    alignItems: 'flex-start'
  },

  '.ant-row': {
    width: '100%'
  },

  '.ant-form-item': {
    width: '100%'
  },

  '#digital-enrollment-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: '10px',

    '.firstName': {
      gridColumn: '1 /  3',
      gridRow: 1
    },

    '.lastName': {
      gridColumn: '1 /  3',
      gridRow: 2
    },

    '.serviceType': {
      gridColumn: '1 /  3',
      gridRow: 4
    },

    '.therapyTypeId': {
      gridColumn: '1 /  3',
      gridRow: 5
    }
  }
}))
