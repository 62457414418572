import {mdiDownload, mdiPlus} from '@mdi/js'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import {STATUS, PATHS} from 'src/constants/constants'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import ExportDialog from 'src/components/templates/ExportDialog/ExportDialog'
import Filters from 'src/components/templates/Filters/Filters'
import TableHeader from 'src/components/templates/TableHeader/TableHeader'
import {ExportStyled} from './ExportStyled'
import apiClient from 'src/services/apiClient'
import {AlertColor, Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {useAppSelector} from 'src/hooks'

const MESSAGES = {
  AWAIT_REPORT: "L'Export richiesto è in generazione, a breve sarà disponibile",
  ERROR_DOWNLOAD: 'Download non riuscito',
  EXPORT_ERROR: 'Generazione  xls non riuscita',
  EXPORT_START_ERROR: 'Avvio export non riuscito'
}

export const EXPORT_TYPES = {
  TUTOR_CUSTOMER_DASHBOARD: 'tutor-customer-dashboard',
  TUTOR_CUSTOMER_APP_PORTALI: 'tutor-customer-app-portali',
  TUTOR_CUSTOMER_REPORT_PESI: 'tutor-customer-report-pesi',
  TUTOR_CUSTOMER_QUESTIONNAIRE: 'tutor-customer-questionnaire',
  TUTOR_CUSTOMER_ADHERENCE: 'tutor-customer-adherences',
  TUTOR_DOCTOR_REPORT: 'tutor-doctor-report',
  TUTOR_DOCTOR_REPORT_PESI: 'tutor-doctor-report-pesi',
  QUIPERTE_CUSTOMER_DASHBOARD: 'quiperte-customer-dashboard',
  QUIPERTE_CUSTOMER_QUESTIONNAIRE: 'quiperte-customer-questionnaire',
  QUIPERTE_CUSTOMER_APP_PORTALI: 'quiperte-customer-app-portali'
}

export const getStatusLabel = (state: string) => {
  switch (state) {
    case 'created':
      return 'Creato'
    case 'processing':
      return 'In elaborazione'
    case 'error':
      return 'Errore'
    default:
      return state
  }
}

const Export = () => {
  const {exportConfigurations} = useAppSelector(state => state.pspConfigReducer.data)
  const columns : TableColumn[] = [
    {
      id: 'filename',
      label: 'FILE'
    },
    {
      id: 'createdAt',
      label: 'DATA CREAZIONE',
      type: 'date',
      format: 'ddd DD MMM YYYY HH:mm'
    },
    {
      id: 'status',
      label: 'STATO'
    }
  ]

  const rowActions : Action[] = [
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: (row) => downloadData(row),
      condition: (row: any) => { return row.status === 'Creato' }
    }
  ]

  const headerActions : Action[] = [
    {
      id: EXPORT_TYPES.TUTOR_DOCTOR_REPORT,
      label: 'Report Medico',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_DOCTOR_REPORT)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_DOCTOR_REPORT_PESI,
      label: 'Report peso Paziente – Medico',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_DOCTOR_REPORT_PESI)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_CUSTOMER_DASHBOARD,
      label: 'Report mensile attività - Dashboard',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_CUSTOMER_DASHBOARD)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_CUSTOMER_ADHERENCE,
      label: 'Report file aderenze',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_CUSTOMER_ADHERENCE)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_CUSTOMER_REPORT_PESI,
      label: 'Report peso Paziente',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_CUSTOMER_REPORT_PESI)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_CUSTOMER_QUESTIONNAIRE,
      label: 'Report questionari di soddisfazione',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_CUSTOMER_QUESTIONNAIRE)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.TUTOR_CUSTOMER_APP_PORTALI,
      label: 'Report uso app e portali',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.TUTOR_CUSTOMER_APP_PORTALI)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.QUIPERTE_CUSTOMER_DASHBOARD,
      label: 'Genera report dashboard',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.QUIPERTE_CUSTOMER_DASHBOARD)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.QUIPERTE_CUSTOMER_QUESTIONNAIRE,
      label: 'Genera report questionari',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.QUIPERTE_CUSTOMER_QUESTIONNAIRE)
        setIsExportModal(true)
      }
    },
    {
      id: EXPORT_TYPES.QUIPERTE_CUSTOMER_APP_PORTALI,
      label: 'Genera report uso APP/Portali',
      icon: mdiPlus,
      action: () => {
        setExportId(EXPORT_TYPES.QUIPERTE_CUSTOMER_APP_PORTALI)
        setIsExportModal(true)
      }
    }
  ].filter((action) => exportConfigurations.exportTypes.includes(action.id))

  const [filters, setFilters] = useState([])
  const [fetchedData, setFetchedData] = useState<{data: Record<string, any>[], count: number}>({
    data: [],
    count: 0
  })
  const [exportId, setExportId] = useState<string>('medicalReport')
  const [isExportModal, setIsExportModal] = useState<boolean>(false)
  const [status, setStatus] = useState<any>(undefined)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [type, setType] = useState('')
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-updatedAt'
  })

  useEffect(() => {
    fetchData()

    const interval = setInterval(async () => {
      const prevType = type
      setType('get')
      await fetchData()
      setType(prevType)
    }, 60000)

    return () => clearInterval(interval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    if (type === 'post') {
      if (status === STATUS.REJECTED) {
        setSeverity('error')
        setMessage(MESSAGES.EXPORT_ERROR)
        setOpenSnackbar(true)
        setType('')
      } else if (status === STATUS.FULFILLED) {
        setSeverity('success')
        setMessage(MESSAGES.AWAIT_REPORT)
        setOpenSnackbar(true)
        setType('')
      }
    }
    if (type === 'download') {
      if (status === STATUS.REJECTED) {
        setSeverity('error')
        setMessage(MESSAGES.ERROR_DOWNLOAD)
        setOpenSnackbar(true)
        setType('')
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const fetchData = async () => {
    Promise.all([apiClient.getExports(filters, undefined, pagination), apiClient.getExportCount(filters, undefined)])
      .then(([exports, count]) => {
        setFetchedData({
          data: exports,
          count
        })
      })
  }

  const onSubmitReport = async (values: any) => {
    setIsExportModal(false)
    setType('post')
    const timer = setTimeout(async () => {
      await fetchData()
    }, 1000)
    await apiClient.postExport(values, setStatus)
    clearTimeout(timer)
    fetchData()
  }

  const downloadData = async (row?: Record<string, any>) => {
    setType('download')

    const data = await apiClient.downloadExport(row?.fileServiceName, setStatus)

    const blob = new Blob([data], {type: 'application/octet-stream'})
    const elem = document.createElement('a')
    elem.href = window.URL.createObjectURL(blob)
    elem.download = row?.filename
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  }

  const onSubmit = (values: any) => {
    setFilters(values)
    setPagination(prev => ({...prev, page: 0}))
  }

  const cleanData = (rawData?: Record<string, any>[]) => {
    return (rawData || []).map((row) => ({
      ...row,
      status: getStatusLabel(row?.status)
    }))
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Export', href: PATHS.EXPORT}]}
      buttons={[
        <Filters config={exportConfigurations.filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setOpenSnackbar(false)} severity={severity} sx={{width: '100%'}}>
            <Typography variant='h5'>
              {message}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <ExportStyled>
        <TableHeader actions={headerActions}>
          <div className='table-header'>
            <FormattedMessage id={'export'} />
          </div>
        </TableHeader>
        <TableCustom
          actions={rowActions}
          columns={columns}
          onPaginationChange={setPagination}
          pagination={pagination}
          rows={cleanData(fetchedData.data)}
          totalElements={fetchedData.count}
        />
      </ExportStyled>
      {isExportModal &&
        <ExportDialog
          exportId={exportId}
          onClose={() => setIsExportModal(false)}
          onSubmit={onSubmitReport}
          setStatus={setStatus}
        />}
    </LayoutContainer>
  )
}

export default Export
