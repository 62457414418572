import {styled} from '@mui/system'

export const WeightDialogStyled = styled('div')(({theme}) => ({
  border: `1px solid ${theme.palette.neutral[100]}`,
  borderRadius: theme.spacing(1),
  '.title': {
    padding: '12px 18px',
    borderBottom: `1px solid ${theme.palette.neutral[100]}`
  },
  '.ant-form': {
    padding: '18px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(21)
  },
  '.ant-picker': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: '100%'
  },
  '.ant-input': {
    height: theme.spacing(19),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))
