import {styled} from '@mui/system'

export const CalendarDialogStyled = styled('div')(({theme}) => ({

  padding: theme.spacing(8),

  '#event-form': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '10px',

    '.ant-row': {
      width: '100%'
    },

    '.reason': {
      gridColumn: '1 /  3',
      gridRow: 1
    },

    '.site': {
      gridColumn: 3,
      gridRow: 1
    },

    '.startDay': {
      gridColumn: 1,
      gridRow: 2
    },

    '.startDate': {
      gridColumn: 2,
      gridRow: 2
    },

    '.endDate': {
      gridColumn: 3,
      gridRow: 2
    },

    '.warning': {
      gridColumn: '1 /  3',
      gridRow: 3,

      '.ant-form-item-control-input-content': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      },

      '.warning-unit': {
        width: '100%'
      },

      '.ant-input-number': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginRight: theme.spacing(8),

        '.ant-input-number-input': {
          height: 'unset'
        }
      }
    },

    '.channels': {
      gridColumn: 3,
      gridRow: 3,
      whiteSpace: 'nowrap'
    },

    '.patient': {
      gridColumn: '1 /  3',
      gridRow: 4,
      display: 'flex',

      '.patient-content': {
        display: 'flex',

        '.ant-form-item': {
          width: '100%'
        },

        '.patient-buttons': {
          display: 'flex',

          '.patient-button': {
            color: theme.palette.neutral.main,
            borderRadius: theme.spacing(21),
            minWidth: theme.spacing(17),
            height: theme.spacing(21),
            '&:hover': {
              backgroundColor: theme.palette.neutral[200]
            }
          }
        },

        '.ant-input[disabled]': {
          backgroundColor: '#eee',
          borderColor: '#ccc',
          color: '#555'
        }
      }
    },

    '.shareWithPatient': {
      gridColumn: 3,
      gridRow: 4
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-select': {
      width: '100%',

      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        }
      }
    }
  }
}))
