import React, {PropsWithChildren} from 'react'
import {LayoutContainerStyled} from './LayoutContainerStyled'
import {Breadcrumbs} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {Link} from 'react-router-dom'
import {useAppSelector} from 'src/hooks'

type Breadcrumb = {
  label: string,
  href?: string
}

type LayoutContainerProps = {
  breadcrumbs: Breadcrumb[],
  buttons?: React.ReactNode[]
};

const LayoutContainer: React.FC<PropsWithChildren<LayoutContainerProps>> = ({
  breadcrumbs,
  buttons = [],
  children
}) => {
  const {footerConfiguration} = useAppSelector(state => state.pspConfigReducer.data)

  return (
    <LayoutContainerStyled>
      <div className='layout-container-header'>
        <div className='container-header'>
          <Breadcrumbs
            aria-label='breadcrumb'
            separator={<NavigateNextIcon fontSize='small' />}
          >
            {breadcrumbs.map((breadcrumb, index) => {
              return breadcrumb.href ?
                (
                  <Link
                    key={index}
                    to={breadcrumb.href}
                  >
                    <h3>{breadcrumb.label}</h3>
                  </Link>
                ) :
                (<h3>{breadcrumb.label}</h3>)
            })}
          </Breadcrumbs>
          <div className='buttons'>
            {buttons.map((button, index) => (
              <div className='button' key={index}>
                {button}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='layout-container-body'>
        {children}
      </div>
      {footerConfiguration.enable &&
        <div className='layout-container-footer'>
          {footerConfiguration.text}
        </div>}
    </LayoutContainerStyled>
  )
}

export default LayoutContainer
