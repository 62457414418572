import React, {useEffect, useState} from 'react'
import {Program} from './ChangePortalPage'
import {styled} from '@mui/system'
import apiClient from 'src/services/apiClient'
import {STATUS} from 'src/constants/constants'
import Loader from 'src/components/elements/Loader/Loader'

interface PortalCardProps {
  portal: Program
}

export default function PortalCard ({
  portal
}: PortalCardProps) {
  const [logoBase64, setLogoBase64] = useState('')
  const [status, setStatus] = useState<string>()

  const fetchProgramImage = async () => {
    if (portal?.logoFileService?.file) {
      const logo = await apiClient.getProgramImage(portal.logoFileService.file, setStatus)
      setLogoBase64(logo)
    }
  }

  useEffect(() => {
    fetchProgramImage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portal?.logoFileService?.file])

  return (
    <PortalCardStyled>
      <div className='img-container'>
        {!status || status === STATUS.PENDING ?
          <Loader /> :
          <img
            alt={portal?.scope ?? ''}
            height={150}
            src={`data:image/png;base64,${logoBase64}`}
          />}
      </div>
      <div className='text-container'>
        <h2>{`Portale programma ${portal?.name ?? ''}`}</h2>
        <p>{'Portale di programma supporto paziente'}</p>
      </div>
    </PortalCardStyled>
  )
}

export const PortalCardStyled = styled('div')(({theme}) => ({
  border: '1px solid #dee5e7',
  backgroundColor: 'white',
  width: '500px',

  '.img-container': {
    padding: '15px',
    textAlign: 'center'
  },

  '.text-container': {
    padding: '15px',
    textAlign: 'center',
    borderTop: '1px solid #dee5e7',

    h2: {fontSize: '32px', marginBottom: 0},
    p: {color: '#98a6ad'}
  }
}))
