import {Paper} from '@mui/material'
import {styled} from '@mui/system'

export const TableCustomStyled = styled(Paper)(({theme}) => ({
  width: '100%',
  overflow: 'hidden',
  borderRadius: 0,

  '& .MuiTableCell-head': {
    height: theme.spacing(10),
    padding: '10px 16px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    textTransform: 'UPPERCASE',
    WebkitPrintColorAdjust: 'exact',

    '& .MuiTableSortLabel-icon': {
      height: theme.spacing(12),
      width: theme.spacing(12)
    }
  },

  '& .MuiTableBody-root': {
    border: 'solid 1px #dee5e7',

    '& .MuiTableCell-body': {
      height: 20,
      padding: '13px',
      color: theme.palette.neutral.main
    },

    '& .MuiTableRow-root:hover': {
      backgroundColor: theme.palette.primary[100]
    }
  },

  '& .MuiTablePagination-toolbar': {
    display: 'flex',
    justifyContent: 'center',

    '& .MuiInputBase-root': {
      order: 4,
      position: 'absolute',
      right: '0',

      '& .MuiTablePagination-select': {
        border: 'solid 1px',
        borderColor: theme.palette.neutral[200],
        borderRadius: theme.spacing(21),
        fontWeight: 600,
        color: theme.palette.neutral.main,
        height: theme.spacing(20),
        width: theme.spacing(22),
        padding: `0 ${theme.spacing(15)} !important`,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },

    '& .MuiTablePagination-displayedRows': {
      display: 'flex',
      order: 1,
      marginTop: '1em',

      '.page-info': {
        display: 'flex',
        alignItems: 'center',

        '.page-counter': {
          margin: theme.spacing(4),
          padding: theme.spacing(6),
          border: 'solid 1px',
          borderColor: theme.palette.neutral[200],
          borderRadius: theme.spacing(21),
          color: theme.palette.neutral.main,
          height: theme.spacing(21),
          fontSize: 14,
          fontWeight: 500
        },

        '.total-elements': {
          margin: theme.spacing(4),
          color: theme.palette.neutral.main,
          fontSize: 14,
          fontWeight: 500
        }
      }
    },

    '& .MuiPagination-root': {
      order: 0,

      '& .MuiPagination-ul': {
        li: {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 600,
          color: theme.palette.neutral.main,
          border: 'solid 1px',
          margin: theme.spacing(2),
          borderColor: theme.palette.neutral[200],
          borderRadius: theme.spacing(21),
          height: theme.spacing(21),
          width: theme.spacing(30),

          '& .Mui-selected': {
            height: theme.spacing(21),
            width: theme.spacing(30),
            borderRadius: theme.spacing(21),
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            padding: 0,
            margin: 0
          }
        }
      }
    },

    '& .MuiTablePagination-spacer': {
      display: 'none'
    },

    '& .MuiTablePagination-selectLabel': {
      display: 'none'
    }
  }

}))
