import {Snackbar, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {ITALIAN_REGIONS} from 'src/constants/mock/county'
import {ITALIAN_PROVINCE} from 'src/constants/mock/province'
import {ITALIAN_CITY} from 'src/constants/mock/city'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import MedicalCenterDialog from 'src/components/templates/MedicalCenterDialog/MedicalCenterDialog'
import {PATHS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'

const parseType = (type: string) => {
  switch (type) {
    case 'OTHER':
      return 'Altro'
    case 'HOSPITAL':
      return 'Ospedale'
    case 'ASL':
      return 'ASL'
    default:
      return '-'
  }
}

const MedicalCenters = () => {
  const columns: TableColumn[] = [
    {
      id: 'type',
      label: 'TIPO'
    },
    {
      id: 'name',
      label: 'NOME'
    },
    {
      id: 'phoneNumber',
      label: 'TELEFONO'
    },
    {
      id: 'faxNumber',
      label: 'FAX'
    },
    {
      id: 'email',
      label: 'EMAIL'
    },
    {
      id: 'activeDoctorsCount',
      label: 'MEDICI ATTIVI'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'name',
      label: 'Nome centro medico',
      type: 'string'
    },
    {
      id: 'type',
      label: 'Tipi centro medico',
      type: 'multiSelect',
      options: [
        {
          label: 'Ospedale',
          value: 'HOSPITAL'
        },
        {
          label: 'ASL',
          value: 'ASL'
        },
        {
          label: 'Altro',
          value: 'OTHER'
        }
      ]
    },
    {
      id: 'region',
      label: 'Regione',
      type: 'select',
      options: ITALIAN_REGIONS
    },
    {
      id: 'province',
      label: 'Provincia',
      type: 'select',
      options: ITALIAN_PROVINCE
    },
    {
      id: 'city',
      label: 'Città',
      type: 'select',
      options: ITALIAN_CITY
    },
    {
      id: 'doctor',
      label: 'Medico',
      type: 'doctorLookup'
    }
  ]

  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [data, setData] = useState<Record<string, any>[]>([])
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [status, setStatus] = useState<string>()
  const [count, setCount] = useState<number>(0)
  const [open, setOpen] = useState(false)
  const [selectedMedicalCenter, setSelectedMedicalCenter] = useState<Record<string, any>>()

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getMedicalCenters(filters, setStatus, pagination) ?? []
      const parsedData = fetchedData.map((document: any) => {
        return {
          ...document,
          type: parseType(document.type)
        }
      })
      setData(parsedData)
      const fetchedDataCount = await apiClient.getMedicalCentersCount(filters, setStatus) ?? []
      setCount(fetchedDataCount)
    }

    fetchData()
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const onRowClicked = async (row: Record<string, any>) => {
    setSelectedMedicalCenter(row)
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    setSelectedMedicalCenter(undefined)
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Lista centri medici', href: PATHS.MEDICAL_CENTERS}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClicked}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </div>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} autoHideDuration={2000} onClose={() => setStatus(undefined)} open={status === 'REJECTED'}>
        <div>
          <SnackbarAlertCustom onClose={() => setStatus(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i centri medici.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      {(open && selectedMedicalCenter) &&
        <MedicalCenterDialog
          medicalCenter={selectedMedicalCenter}
          onClose={handleOnClose}
        />}
    </LayoutContainer>
  )
}

export default MedicalCenters
