import {BoxAderenzaStyle} from './BoxAderenzaStyle'

type BoxAderenzaProps = {
    title: string,
    subtitle: string
}
const BoxAderenza: React.FC<BoxAderenzaProps> = ({title, subtitle}) => {
  return (
    <BoxAderenzaStyle>
      <div className='box-aderenza-container'>
        <span className='box-aderenza-primary'>
          {title}
        </span>
        <span className='box-aderenza-secondary'>
          {subtitle}
        </span>
      </div>
    </BoxAderenzaStyle>
  )
}

export default BoxAderenza
