import Icon from '@mdi/react'
import {WarningPatientEmptyDataStyled} from './WarningPatientEmptyDataStyled'
import {mdiAlert} from '@mdi/js'

const WarningPatientEmptyData: React.FC<{subject: string}> = ({subject}) => {
  return (
    <WarningPatientEmptyDataStyled>
      <Icon path={mdiAlert} size={1} style={{color: '#ffc53d'}} />
      <div>{`Questo paziente non ha nessun ${subject} associato`}</div>
    </WarningPatientEmptyDataStyled>
  )
}

export default WarningPatientEmptyData
