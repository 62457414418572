/* eslint-disable react-hooks/exhaustive-deps */
import {Form, Input, Select} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {FileInfoDialogStyled} from './FileInfoDialogStyled'

type FileInfoDialogDialogProps = {
  data: any,
  onClose: () => void,
  categoryOptions: any
};

const FileInfoDialog: React.FC<FileInfoDialogDialogProps> = ({
  data,
  onClose,
  categoryOptions
}) => {
  const [form] = Form.useForm()

  const parseCategoryToValue = (categoryLabel: string) => {
    return categoryOptions.find((category: any) => category.label === categoryLabel)?.value
  }

  const parseCategoriesToArray = (category: any) => {
    const splittedCategory = category.split(', ')
    return splittedCategory.map((categ: any) => {
      return parseCategoryToValue(categ)
    })
  }

  useEffect(() => {
    form.setFieldValue('name', data?.name)
    form.setFieldValue('fileName', data?.fileName)
    form.setFieldValue('description', data?.description)
    form.setFieldValue('type', data?.type)
    form.setFieldValue('size', data?.size ? `${Math.round(data?.size / (1024 ** 2) * 100) / 100} MB` : '-')
    form.setFieldValue('category', data?.category ? parseCategoriesToArray(data?.category) : [])
  }, [form, data])

  const body = (
    <FileInfoDialogStyled>
      <Form
        disabled
        form={form}
        id='file-info-form'
        layout='vertical'
      >
        <Form.Item
          className='name'
          label='Nome'
          name='name'
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='description'
          label='Descrizione'
          name='description'
        >
          <TextArea disabled rows={4} />
        </Form.Item>
        <Form.Item
          className='type'
          label='Tipo Documento'
          name='type'
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='category'
          label='Categoria'
          name='category'
        >
          <Select
            mode='multiple'
            options={categoryOptions}
          />
        </Form.Item>
        <Form.Item
          className='filename'
          label='Nome file'
          name='fileName'
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='size'
          label='Dimensione'
          name='size'
        >
          <Input />
        </Form.Item>
      </Form>
    </FileInfoDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton disabled style={{marginRight: '8px'}}>
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      maxWidth='md'
      onClose={onClose}
      title={{text: (data.name || data.fileName), format: false}}
    />
  )
}

export default FileInfoDialog
