import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {TimeslotProps} from 'src/types/types'
import TimeslotsSelector from 'src/components/elements/TimeslotsSelector/TimeslotsSelector'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import {FormattedMessage} from 'react-intl'
import {useState, useEffect} from 'react'

type TimeslotsDialogProps = {
  title: string;
  onClose: () => void;
  timeslots?: TimeslotProps[];
  onEdit: (value: TimeslotProps[]) => void;
};

const TimeslotsDetailDialog: React.FC<TimeslotsDialogProps> = ({title, timeslots = [], onClose, onEdit}) => {
  const [values, setValues] = useState<TimeslotProps[]>(timeslots)
  const [isModified, setIsModified] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(true)

  useEffect(() => {
    setIsModified(JSON.stringify(timeslots) !== JSON.stringify(values))
  }, [timeslots, values])

  useEffect(() => {
    const checkValidity = () => {
      return values.every((timeslot) => timeslot.day && timeslot.from && timeslot.to)
    }
    setIsValid(checkValidity())
  }, [values])

  const handleSave = () => {
    if (isValid) {
      onEdit(values)
      onClose()
    }
  }

  const body = <div style={{height: '400px'}}><TimeslotsSelector onChange={setValues} value={values} /></div>

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton disabled={!isModified || !isValid} onClick={handleSave}>
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      maxWidth='lg'
      onClose={onClose}
      title={{text: title, format: true}}
    />
  )
}

export default TimeslotsDetailDialog
