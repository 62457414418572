/* eslint-disable react-hooks/exhaustive-deps */
import {mdiDownload} from '@mdi/js'
import {Snackbar, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import apiClient from 'src/services/apiClient'
import moment from 'moment'
import {PREVIEW_MIME_TYPES, STATUS} from 'src/constants/constants'
import FileViewerModal from 'src/components/elements/FileViewerModal/FileViewerModal'

type DossierProps = {
  patient: Record<string, any>
}

export default function Dossier ({patient}: DossierProps) {
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-createdAt'
  })
  const [count, setCount] = useState<number>(0)
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [statusDownload, setStatusDownload] = useState<any>(undefined)
  const [file, setFile] = useState<any>()
  const [MIMETypeValue, setMIMETypeValue] = useState<any>()
  const [openViewModal, setOpenViewModal] = useState<boolean>(false)
  const [fileName, setFileName] = useState<any>()

  async function exportDossier ({uploadedFile, createdAt}: any) {
    const fileResponse = await apiClient.downloadPatientsDossierExport(patient?._id, uploadedFile, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse?.data)], {type: fileResponse?.headers['content-type']})
    const filepath = (window.URL || window.webkitURL).createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', filepath)
    link.setAttribute('download', `Dossier - ${patient?.firstName} ${patient?.lastName} - ${moment(createdAt).format('DD/MM/YYYY')}`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function handleModalView ({uploadedFile, createdAt}: any) {
    const fileResponse = await apiClient.downloadPatientsDossierExport(patient?._id, uploadedFile, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse?.data)], {type: fileResponse?.headers['content-type']})
    const filepath = (window.URL || window.webkitURL).createObjectURL(blob)
    setFile(filepath)
    setMIMETypeValue(fileResponse?.headers['content-type'])
    setFileName(`Dossier - ${patient?.firstName} ${patient?.lastName} - ${moment(createdAt).format('DD/MM/YYYY')}`)
    if (PREVIEW_MIME_TYPES.includes(fileResponse?.headers['content-type'])) {
      setOpenViewModal(true)
    } else {
      const link = document.createElement('a')
      link.setAttribute('href', filepath)
      link.setAttribute('download', `Dossier - ${patient?.firstName} ${patient?.lastName} - ${moment(createdAt).format('DD/MM/YYYY')}`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const rowActions : Action[] = [
    {
      id: 'download',
      label: 'Scarica',
      icon: mdiDownload,
      action: exportDossier
    }
  ]

  const columns : TableColumn[] = [
    {
      id: 'createdAt',
      label: 'GENERATO IL',
      type: 'date',
      format: 'DD MMMM YYYY, HH:mm'
    }
  ] || []

  const [data, setData] = useState<any>([])

  const fetchData = async () => {
    const fetchedData = await apiClient.getPatientsDossiersExports(patient._id, setStatus, pagination) ?? []
    setData(fetchedData)
    const fetchedDataCount = await apiClient.getPatientsDossiersExportsCount(patient._id, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  useEffect(() => {
    fetchData()
  }, [patient, pagination])

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'end'}}>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined); setStatusCount(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i files.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <TableCustom
        actions={rowActions}
        columns={columns}
        onPaginationChange={setPagination}
        onRowClick={(row: any) => handleModalView(row)}
        pagination={pagination}
        rows={data}
        totalElements={count}
      />
      <FileViewerModal
        MIMETypeValue={MIMETypeValue}
        fileName={fileName}
        filepath={file}
        handleClose={() => { setOpenViewModal(false); setFile(undefined); setMIMETypeValue(undefined); setFileName(undefined) }}
        open={openViewModal}
        status={statusDownload}
      />
    </div>
  )
}
