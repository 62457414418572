import React, {useEffect, useState} from 'react'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import Filters from '../Filters/Filters'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import apiClient from 'src/services/apiClient'
import {Snackbar, Typography} from '@mui/material'
import {STATUS, statesOptions} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'

type DoctorsLookupDialogProps = {
  onClose: () => void;
  onRowClick: (row: Record<string, any>) => void
};

const columns = [
  {
    id: 'lastName',
    label: 'COGNOME'
  },
  {
    id: 'firstName',
    label: 'NOME'
  },
  {
    id: 'email',
    label: 'EMAIL'
  },
  {
    id: 'state',
    label: 'STATO'
  }
]

const filterConfig = [
  {
    id: 'lastName',
    label: 'Cognome',
    type: 'inputText'
  },
  {
    id: 'firstName',
    label: 'Nome',
    type: 'inputText'
  },
  {
    id: 'state',
    label: 'Stato',
    type: 'select',
    options: statesOptions
  }
]

const DoctorsLookupDialog: React.FC<DoctorsLookupDialogProps> = ({onClose, onRowClick}) => {
  const [filters, setFilters] = useState<Record<string, string | string[]>[]>([])
  const [data, setData] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getDoctorsSearch(filters, setStatus, pagination) ?? []
      const parsedData = fetchedData.map((doctor: any) => {
        return {
          ...doctor,
          state: statesOptions.find((option) => option.value === doctor.state)?.label
        }
      })
      setData(parsedData)
      const fetchedDataCount = await apiClient.getDoctorsCount(filters, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }

    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  const onDoctorsFiltersSubmit = (values: any) => {
    setFilters(values)
  }

  const body = (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      margin: '8px'
    }}
    >
      <Filters config={filterConfig} formId='doctor-filters-form' key='filters' onSubmit={onDoctorsFiltersSubmit} />
      <div style={{marginTop: '16px', width: '100%'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClick}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000} onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: 'Dottori', format: false}}
    />
  )
}

export default DoctorsLookupDialog
