import {Snackbar, Typography} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import CalendarCustom, {CalendarEvent} from 'src/components/templates/Calendar/Calendar'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'

const Agenda = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [eventsTypes, setEventsTypes] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [start, setStart] = useState<any>(moment().startOf('month').startOf('week'))
  const [end, setEnd] = useState<any>(moment().endOf('month').endOf('week'))

  useEffect(() => {
    const fetchEvents = async () => {
      const fetchedData = await apiClient.getUserEvents(start, end, setStatus) ?? []
      setEvents(fetchedData)
    }
    const fetchEventReasons = async () => {
      const fetchedData = await apiClient.getUserEventsTypes(setStatus) ?? []
      setEventsTypes(fetchedData)
    }
    fetchEvents()
    fetchEventReasons()
  }, [start, end])

  const onSave = async (values: any) => {
    const {warning} = values
    delete values.warning

    const event = {
      ...values,
      user: values.user?._id ?? values.user,
      channels: values.channels ?? [],
      reminderMilliseconds: warning.value * warning.unit
    }
    await apiClient.postAppointment(event, setStatus)
    const fetchedData = await apiClient.getUserEvents(start, end, setStatus) ?? []
    setEvents(fetchedData)
  }

  const onUpdate = async (values: any, id: string | undefined) => {
    const event = {
      ...values,
      user: values.user?._id ?? values.user,
      channels: values.channels ?? [],
      reminderMilliseconds: values.warning.value * values.warning.unit
    }

    await apiClient.patchAppointment({event, id}, setStatus)
    const fetchedData = await apiClient.getUserEvents(start, end, setStatus) ?? []
    setEvents(fetchedData)
  }

  return (
    <LayoutContainer breadcrumbs={[{label: 'Agenda', href: PATHS.AGENDA}]}>
      <CalendarCustom endDate={end} events={events} eventsTypes={eventsTypes} onSave={onSave} onUpdate={onUpdate} setEndDate={setEnd} setStartDate={setStart} startDate={start} />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              <FormattedMessage id='agendaWarning' />
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default Agenda
