/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {Switch, Route} from 'react-router-dom'
import NavBar from '../../components/templates/NavBar/NavBar'
import {PATHS, STATUS} from 'src/constants/constants'
import {FirstLoginStyled, MainRoutingStyled} from './MainRoutingStyled'
import Home from '../home/Home'
import Header from 'src/components/templates/Header/Header'
import apiClient from 'src/services/apiClient'
import Patients from '../patients/Patients'
import Patient from '../patient/Patient'
import PatientEnrollment from '../patientEnrollment/PatientEnrollment'
import DigitalEnrollment from '../digitalEnrollment/DigitalEnrollment'
import Agenda from '../agenda/Agenda'
import PatientActivities from '../patientActivities/PatientActivities'
import Export from '../export/Export'
import TestsDBS from '../testsDBS/TestsDBS'
import VirtualMeetings from '../virtualMeetings/VirtualMeetings'
import VirtualMeetingDetail from '../virtualMeetings/VirtualMeetingDetail'
import {useAppSelector} from 'src/hooks'
import WarningDialog from 'src/components/templates/WarningDialog/WarningDialog'
import Communications from '../communications/Communications'
import InfoMaterials from '../InfoMaterials/InfoMaterials'
import PatientSummary from '../patientSummary/PatientSummary'
import Loader from 'src/components/elements/Loader/Loader'
import Documents from '../documents/Documents'
import MedicalCenters from '../medicalCenters/MedicalCenters'
import CustomerUsers from '../customerUsers/CustomerUsers'
import Profile from '../profile/Profile'
import TherapyDiary from '../therapyDiary/TherapyDiary'
import Surveys from '../surveys/Surveys'
import DocumentsAndInfoMaterials from '../DocumentsAndInfoMaterials/DocumentsAndInfoMaterials'
import {Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {useCookies} from 'react-cookie'
import {useDispatch} from 'react-redux'
import {setUnreadNotificationsCount} from 'src/slices/userSlice'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import FirstLoginHeader from 'src/components/templates/FirstLoginHeader/FirstLoginHeader'
import ReactHtmlParser from 'react-html-parser'
import {Form, Radio} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import Performances from '../performances/Performances'
import InsuranceUserSummary from '../insuranceUserSummary/InsuranceUserSummary'

function MainRoutingPages () {
  const [contactStatus, setContactStatus] = useState<string | undefined>(undefined)
  const [step, setStep] = useState<number>(0)
  const [cookies, setCookies] = useCookies(['sid', 'tokens', 'warningDialog'])
  const statusUserInfo = useAppSelector(({userReducer}) => userReducer.status)
  const userInfo = useAppSelector(state => state.userReducer.data)
  const pspConfig = useAppSelector(state => state.pspConfigReducer.data)
  const statusConfig = useAppSelector(({pspConfigReducer}) => pspConfigReducer.status)
  const queryCookies = new URL(window.location.href).searchParams.get('cookies')
  const queryScope = new URL(window.location.href).searchParams.get('scope')
  const scope = pspConfig.scope !== '' ? pspConfig.scope : queryScope
  const dispatch = useDispatch()
  const [form] = useForm()

  const consentOptions = [
    {value: 'CONSENT', label: 'Esprimo il consenso'},
    {value: 'NOT_CONSENT', label: 'Nego il consenso'}
  ]

  useEffect(() => {
    let tokens = []
    if (queryCookies) {
      setCookies('tokens', queryCookies, {maxAge: 86400, secure: true, path: '/'})
      tokens = JSON.parse(Buffer.from(queryCookies, 'base64').toString())
    } else if (cookies.tokens) {
      tokens = JSON.parse(Buffer.from(cookies.tokens, 'base64').toString())
    }

    const scopeCookie = tokens.find((cookie : {scope: string, cookie: string}) => cookie.scope === scope)?.cookie

    if (scopeCookie) {
      setCookies('sid', scopeCookie, {maxAge: 86400, secure: true, path: '/'})
    }

    apiClient.getProfile()
    apiClient.pspConfig()
    apiClient.getNotificationsCount({hasBeenRead: false})
      .then(count => dispatch(setUnreadNotificationsCount(count)))
  }, [dispatch])

  const updateConsents = async (values: Record<string, any>) => {
    const privacyConsents: string[] = []
    pspConfig.firstLoginConfiguration.consentCheckboxOptions.forEach((checkbox: any) => {
      if (values[checkbox.value] === 'CONSENT') {
        privacyConsents.push(checkbox.value)
      }
      delete values[checkbox.value]
    })

    const body = {
      privacyConsents: [...userInfo.privacyConsents, ...privacyConsents]
    }

    await apiClient.patchUserDataHttp(body)
    await apiClient.getProfile()
  }

  const renderConsentCheckbox = () => {
    return pspConfig.firstLoginConfiguration.consentCheckboxOptions.map((checkbox:any) => (
      <Form.Item
        className='consent'
        key={checkbox.value}
        label={checkbox.label}
        name={checkbox.value}
        rules={[
          {required: true, message: 'Questo campo è obbligatorio'},
          () => ({
            validator (_, value) {
              if ((checkbox.required && value === 'CONSENT') || !checkbox.required) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Questo consenso è obbligatorio'))
            }
          })
        ]}
      >
        <Radio.Group options={consentOptions} />
      </Form.Item>
    ))
  }

  const renderFirstLoginStep = () => {
    switch (step) {
      case 0:
        return (
          <div style={{margin: '64px'}}>
            <Typography className='warning' variant='body1'><FormattedMessage id='warningFirstLogin' /></Typography>
          </div>
        )
      case 1:
        return (
          <div style={{margin: '64px 256px'}}>
            {ReactHtmlParser(pspConfig.firstLoginConfiguration.termsAndConditions)}
            <div className='cookie-card'>
              <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='cookiePolicy' /></Typography>}>
                <p>{"Utilizziamo cookie tecnici per garantire il corretto funzionamento del sito. Cliccando su 'Accetto', acconsenti all'uso di tali cookie. Per ulteriori informazioni consulta il documento di "}
                  <a href={pspConfig.firstLoginConfiguration.cookiePolicyLink} rel='noreferrer' target='_blank'>{'cookie policy.'}</a>
                </p>
              </CardCustom>
            </div>
          </div>
        )
      case 2:
        return (
          <div className='step2'>
            {ReactHtmlParser(pspConfig.firstLoginConfiguration.dataProcessingInfo)}
            <p><b>{'CONSENSO AL TRATTAMENTO DEI DATI PERSONALI'}</b></p>
            <p>{pspConfig.firstLoginConfiguration.consentCheckboxPreText}</p>
            <Form
              form={form}
              id='patient-digital-enrollment-form'
              layout='vertical'
              onFinish={async (values) => { await updateConsents(values) }}
            >
              {renderConsentCheckbox()}
            </Form>
            <p>{pspConfig.firstLoginConfiguration.consentCheckboxPostText}</p>
          </div>
        )
    }
  }

  const renderContent = () => {
    if (userInfo.privacyConsents && !userInfo.privacyConsents.includes('PRIVACY_ENROLLMENT_APP_WEB') && !userInfo.userGroup.toUpperCase().includes('CLIENT')) {
      return (
        <FirstLoginStyled>
          <div>
            <FirstLoginHeader showMenu />
          </div>
          <div>
            {renderFirstLoginStep()}
            <div className='stepButton'>
              {
                step === 2 ?
                  <PrimaryButton
                    onClick={() => form.submit()}
                  >
                    {'Accetto'}
                  </PrimaryButton> :
                  <PrimaryButton
                    onClick={() => { setStep(step + 1); window.scrollTo(0, 0) }}
                  >
                    {'Accetto'}
                  </PrimaryButton>
              }
            </div>
          </div>
        </FirstLoginStyled>
      )
    }

    return (
      <MainRoutingStyled>
        {!cookies.warningDialog && <WarningDialog onClose={() => { setCookies('warningDialog', true) }} />}
        <div className='parent'>
          <div className='childHeader'>
            <Header showMenu />
          </div>
          <div className='childNavbar'>
            <NavBar setContactStatus={setContactStatus} />
          </div>
          <div className='childLogo' style={{zIndex: 15}}>
            <img alt='logo' height={191} src={`data:image/png;base64,${pspConfig.logoPSP}`} width={190} />
          </div>
          <Switch>
            {pspConfig.sidebarItems.summary.show && <Route component={Home} exact path={PATHS.SUMMARY} />}
            {pspConfig.sidebarItems.summaryInsurance?.show && <Route component={InsuranceUserSummary} exact path={PATHS.SUMMARY} />}
            <Route component={Profile} path={PATHS.PROFILE} />
            {pspConfig.sidebarItems.summaryPatient.show && <Route component={PatientSummary} exact path={PATHS.SUMMARY} />}
            {pspConfig.sidebarItems.patients.show && <Route component={Patients} exact path={PATHS.PATIENTS} />}
            {pspConfig.sidebarItems.patients.show && <Route component={Patient} path={PATHS.PATIENT} />}
            {pspConfig.sidebarItems.patientEnrollment.show && <Route component={PatientEnrollment} path={PATHS.PATIENT_ENROLLMENT} />}
            {pspConfig.sidebarItems.digitalEnrollment?.show && <Route component={DigitalEnrollment} path={PATHS.DIGITAL_ENROLLMENT} />}
            {pspConfig.sidebarItems.therapyDiary.show && <Route component={TherapyDiary} path={PATHS.DIARY} />}
            {pspConfig.sidebarItems.agenda.show && <Route component={Agenda} path={PATHS.AGENDA} />}
            {pspConfig.sidebarItems.patientActivities.show && <Route component={PatientActivities} path={PATHS.PATIENT_ACTIVITIES} />}
            {pspConfig.sidebarItems.export.show && <Route component={Export} path={PATHS.EXPORT} />}
            {pspConfig.sidebarItems.testDBS.show && <Route component={TestsDBS} path={PATHS.TEST_DBS} />}
            {pspConfig.sidebarItems.virtualMeetings.show && <Route component={VirtualMeetings} path={PATHS.VIRTUAL_MEETINGS} />}
            {pspConfig.sidebarItems.virtualMeetings.show && <Route component={VirtualMeetingDetail} path={PATHS.VIRTUAL_MEETING_DETAIL} />}
            {pspConfig.sidebarItems.documents.show && <Route component={Documents} path={PATHS.DOCUMENTS} />}
            {pspConfig.sidebarItems.infoMaterials.show && <Route component={InfoMaterials} path={PATHS.INFO_MATERIALS} />}
            {pspConfig.sidebarItems.communications.show && <Route component={Communications} path={PATHS.COMMUNICATIONS} />}
            {pspConfig.sidebarItems.medicalCenters.show && <Route component={MedicalCenters} path={PATHS.MEDICAL_CENTERS} />}
            {pspConfig.sidebarItems.customerUtilities.show && <Route component={CustomerUsers} path={PATHS.CUSTOMER_USERS} />}
            {pspConfig.sidebarItems.survey.show && <Route component={Surveys} path={PATHS.QUESTIONNAIRE} />}
            {pspConfig.sidebarItems.documentsAndInfoMaterials.show && <Route component={DocumentsAndInfoMaterials} path={PATHS.DOCUMENTS_AND_MATERIALS} />}
            {pspConfig.sidebarItems.performances?.show && <Route component={Performances} path={PATHS.PERFORMANCES} />}
          </Switch>
          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={() => setContactStatus(undefined)}
            open={contactStatus === STATUS.REJECTED}
          >
            <div>
              <SnackbarAlertCustom onClose={() => setContactStatus(undefined)} severity='error' sx={{width: '100%'}}>
                <Typography variant='h5'>
                  {'Errore nel invio della richiesta.'}
                </Typography>
              </SnackbarAlertCustom>
            </div>
          </Snackbar>
          <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={() => { setContactStatus(undefined) }}
            open={contactStatus === STATUS.FULFILLED}
          >
            <div>
              <SnackbarAlertCustom onClose={() => { setContactStatus(undefined) }} severity='success' sx={{width: '100%'}}>
                <Typography variant='h5'>
                  {'Richiesta inviata con successo'}
                </Typography>
              </SnackbarAlertCustom>
            </div>
          </Snackbar>
        </div>
      </MainRoutingStyled>
    )
  }

  return (
    <>
      {(statusConfig === STATUS.PENDING || statusUserInfo === STATUS.PENDING) && <Loader />}
      {(statusConfig === STATUS.FULFILLED && statusUserInfo === STATUS.FULFILLED) && renderContent()}
    </>
  )
}

export default MainRoutingPages
