import React, {useEffect, useState} from 'react'
import {Snackbar, Typography} from '@mui/material'
import Icon from '@mdi/react'
import Avatar from 'src/components/elements/Avatar/Avatar'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import apiClient from 'src/services/apiClient'
import {PatientStyled} from './PatientStyled'
import {useParams} from 'react-router-dom'
import {
  mdiAccountMultiple, mdiAlert, mdiBookOpenOutline, mdiChartBoxOutline, mdiFormatListBulleted,
  mdiHuman, mdiIvBag, mdiMortarPestlePlus, mdiPill, mdiRun, mdiTooltipQuestion, mdiUpload
} from '@mdi/js'
import UserInfo from 'src/components/templates/PatientDetailsComponent/UserInfo/UserInfo'
import {useForm} from 'antd/lib/form/Form'
import {setFormPatient} from 'src/components/utils/formUtils'
import Caregivers from 'src/components/templates/PatientDetailsComponent/Caregivers/Caregivers'
import Diary from 'src/components/templates/PatientDetailsComponent/Diary/Diary'
import Weight from 'src/components/templates/PatientDetailsComponent/Weight/Weight'
import Pharmacies from '../../components/templates/PatientDetailsComponent/Pharmacies/Pharmacies'
import Pharmacovigilance from '../../components/templates/PatientDetailsComponent/Pharmacovigilance/Pharmacovigilance'
import TherapyCard from '../../components/templates/PatientDetailsComponent/TherapyCard/TherapyCard'
import Activities from '../../components/templates/PatientDetailsComponent/Activities/Activities'
import {useAppSelector} from 'src/hooks'
import Dossier from '../../components/templates/PatientDetailsComponent/Dossier/Dossier'
import Surveys from '../../components/templates/PatientDetailsComponent/Surveys/Surveys'
import Documents from '../../components/templates/PatientDetailsComponent/Documents/Documents'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import FeedbacksParametersDiary from 'src/components/templates/PatientDetailsComponent/Diary/FeedbacksParametersDiary'
import Analytics from 'src/components/templates/PatientDetailsComponent/Analytics/Analytics'

interface PathParams { id: string }

const Patient = () => {
  const {id} = useParams<PathParams>()
  const [data, setData] = useState<Record<string, any>>([])
  const {patientDetailConfiguration, patientDetailConfiguration: {diaryConfigurations: {diaryType}}, scope} = useAppSelector(state => state.pspConfigReducer.data)
  const [form] = useForm()
  const [status, setStatus] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const patient = await apiClient.getPatientsUserData(id, setStatus) ?? []
      setData(patient)
      setFormPatient(form, patient)
    }

    if (id) {
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const TABS = [
    {
      id: 'patientData',
      icon: <Icon path={mdiFormatListBulleted} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.patientData.label}</Typography>
    },
    {
      id: 'caregivers',
      icon: <Icon path={mdiAccountMultiple} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.caregivers.label}</Typography>
    },
    {
      id: 'therapy',
      icon: <Icon path={scope === 'tutor' ? mdiIvBag : mdiPill} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.therapy.label}</Typography>
    },
    {
      id: 'dossier',
      icon: <Icon path={mdiFormatListBulleted} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.dossier.label}</Typography>
    },
    {
      id: 'diary',
      icon: <Icon path={mdiBookOpenOutline} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.diary.label}</Typography>
    },
    {
      id: 'analytics',
      icon: <Icon path={mdiChartBoxOutline} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.analytics.label}</Typography>
    },
    {
      id: 'weight',
      icon: <Icon path={mdiHuman} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.weight.label}</Typography>
    },
    {
      id: 'activities',
      icon: <Icon path={mdiRun} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.activities.label}</Typography>
    },
    {
      id: 'pharmacovigilance',
      icon: <Icon path={mdiAlert} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.pharmacovigilance.label}</Typography>
    },
    {
      id: 'documents',
      icon: <Icon path={mdiUpload} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.documents.label}</Typography>
    },
    {
      id: 'pharmacies',
      icon: <Icon path={mdiMortarPestlePlus} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.pharmacies.label}</Typography>
    },
    {
      id: 'surveys',
      icon: <Icon path={mdiTooltipQuestion} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{patientDetailConfiguration.tabs.surveys.label}</Typography>
    }
  ].filter((tab) => patientDetailConfiguration.tabs[tab.id]?.show)

  const CONTENT = [
    <UserInfo form={form} key='patientData' patientDiseases={data.diseases} patientId={data._id} />,
    <Caregivers key='caregivers' patientId={data._id} />,
    <TherapyCard key='therapy' patientId={data._id} />,
    <Dossier key='dossier' patient={data} />,
    (diaryType === 'DIARY_FEEDBACKS_PARAMETERS') ? <FeedbacksParametersDiary key='diary' patientId={data._id} /> : <Diary key='diary' patientId={data._id} serviceConf={data.serviceConf} userFirstName={data?.firstName} userLastName={data?.lastName} />,
    <Analytics key='analytics' patientId={data._id} />,
    <Weight key='weight' patientId={data._id} patientInfo={{firstName: data.firstName, lastName: data.lastName}} />,
    <Activities key='activities' patientId={data._id} />,
    <Pharmacovigilance key='pharmacovigilance' patientId={data._id} />,
    <Documents key='documents' patientId={data._id} />,
    <Pharmacies key='pharmacies' patientId={data._id} />,
    <Surveys key='surveys' patientId={data._id} />
  ].filter((content) => patientDetailConfiguration.tabs[String(content.key)]?.show)

  const parseStates = (state: any) => {
    switch (state) {
      case 'TO_ACTIVE':
        return 'Da attivare'
      case 'ACTIVATING':
        return 'In attivazione'
      case 'ACTIVE':
        return 'Attivo'
      case 'ACTIVE_DELIVERY':
        return 'Attivo delivery'
      case 'SUSPENDED':
        return 'Sospeso'
      case 'WAITING_TO_EXIT':
        return 'In attesa di uscita'
      case 'EXITED':
        return 'Drop-out'
      default:
        return '-'
    }
  }

  return (
    <PatientStyled>
      <div className='layout-container-header'>
        <Avatar disabled imageSrc={''} size='120px' />
        <div>
          <Typography variant='h4'>{`${data?.firstName} ${data?.lastName}`}</Typography>
          <div className='label'>{`Stato: ${parseStates(data.state)}`}</div>
        </div>
      </div>
      <div className='tabs-container'>
        <TabsCustom
          contents={CONTENT}
          tabs={TABS}
        />
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare nei dati del paziente'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </PatientStyled>
  )
}

export default Patient
