import React from 'react'
import {EmptyPlaceholderSummaryCardStyle} from './EmptyPlaceholderSummaryCardStyle'
import Icon from '@mdi/react'

export type EmptyPlaceholderSummaryCardProps = {
  title: string,
  icon: any,
  subtitle: string
}

const EmptyPlaceholderSummaryCard: React.FC<EmptyPlaceholderSummaryCardProps> = ({icon, title, subtitle}) => {
  return (
    <EmptyPlaceholderSummaryCardStyle>
      <Icon path={icon} size={2} />
      <div style={{fontWeight: 600}}>{title}</div>
      <div>{subtitle}</div>
    </EmptyPlaceholderSummaryCardStyle>
  )
}

export default EmptyPlaceholderSummaryCard
