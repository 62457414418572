import {PropsWithChildren} from 'react'
import {Alert, AlertProps} from '@mui/material'
import {SnackbarAlertCustomStyled} from './SnackbarAlertCustomStyled'
import {mdiShieldAlert, mdiCheckBold} from '@mdi/js'
import Icon from '@mdi/react'

const SnackbarAlertCustom: React.FC<PropsWithChildren<AlertProps>> = ({
  children,
  severity,
  ...other
}) => {
  return (
    <SnackbarAlertCustomStyled>
      <Alert
        icon={<Icon path={severity === 'success' ? mdiCheckBold : mdiShieldAlert} size={'30px'} />}
        severity={severity}
        {...other}
      >
        {children}
      </Alert>
    </SnackbarAlertCustomStyled>
  )
}

export default SnackbarAlertCustom
