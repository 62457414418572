import React, {useEffect, useState} from 'react'
import Header from 'src/components/templates/Header/Header'
import apiClient from 'src/services/apiClient'
import PortalCard from './PortalCard'
import {ChangePortalPageStyled} from './ChangePortalPageStyled'
import {Snackbar, Typography} from '@mui/material'
import {STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {useCookies} from 'react-cookie'

export interface Program {
  name: string,
  scope: string,
  logoFileService?: {
    _id: string,
    name: string,
    file: string,
    location: string,
    size: number
  },
  url: string
}

export default function ChangePortalPage () {
  const [data, setData] = useState<Program[]>([])
  const [status, setStatus] = useState<any>(undefined)
  const [cookies] = useCookies(['tokens'])

  const fetchPrograms = async () => {
    const programs = await apiClient.getPrograms(setStatus) ?? []
    setData(programs)
  }

  useEffect(() => {
    fetchPrograms()
  }, [])

  return (
    <ChangePortalPageStyled>
      <Header showMenu={false} />
      <ul>
        {data.map(program => (
          <li key={program.name}>
            <a href={cookies.tokens ? `${program.url}?cookies=${cookies.tokens}&scope=${program.scope}` : program.url}>
              <PortalCard portal={program} />
            </a>
          </li>
        ))}
      </ul>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatus(undefined)}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatus(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recupero delle informazioni sui portali.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </ChangePortalPageStyled>
  )
}
