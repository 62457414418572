import {mdiEmoticonCryOutline, mdiEmoticonHappyOutline, mdiEmoticonNeutralOutline, mdiEmoticonSadOutline, mdiEmoticonWinkOutline} from '@mdi/js'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import Icon from '@mdi/react'
import moment from 'moment'
import React from 'react'
import {statesOptions} from 'src/constants/constants'

type TablePDFSummaryProps ={
    rows: any
}

const formatAdherence = (adherence: any) => {
  if (adherence > 99) {
    return (
      <Icon path={mdiEmoticonWinkOutline} size={1} style={{color: 'green'}} />
    )
  }
  if (adherence <= 99 && adherence >= 97) {
    return (
      <Icon path={mdiEmoticonHappyOutline} size={1} style={{color: 'lightgreen'}} />
    )
  }
  if (adherence <= 96 && adherence >= 90) {
    return (
      <Icon path={mdiEmoticonNeutralOutline} size={1} style={{color: 'yellow'}} />
    )
  }
  if (adherence <= 89 && adherence >= 80) {
    return (
      <Icon path={mdiEmoticonSadOutline} size={1} style={{color: 'orange'}} />
    )
  }
  if (adherence <= 79) {
    return (
      <Icon path={mdiEmoticonCryOutline} size={1} style={{color: 'red'}} />
    )
  }
  return 'n.a.'
}

const formatAdherenceAverage = (adherenceAverage: any) => {
  if (adherenceAverage > 99) {
    return (
      <div style={{color: 'green'}}>
        {`${adherenceAverage}%`}
      </div>
    )
  }
  if (adherenceAverage <= 99 && adherenceAverage >= 97) {
    return (
      <div style={{color: 'lightgreen'}}>
        {`${adherenceAverage}%`}
      </div>
    )
  }
  if (adherenceAverage <= 96 && adherenceAverage >= 90) {
    return (
      <div style={{color: 'yellow'}}>
        {`${adherenceAverage}%`}
      </div>
    )
  }
  if (adherenceAverage <= 89 && adherenceAverage >= 80) {
    return (
      <div style={{color: 'orange'}}>
        {`${adherenceAverage}%`}
      </div>
    )
  }
  if (adherenceAverage <= 79) {
    return (
      <div style={{color: 'red'}}>
        {`${adherenceAverage}%`}
      </div>
    )
  }
  return 'n.a.'
}

const TablePDFSummary: React.FC<TablePDFSummaryProps> = ({rows}) => {
  return (
    <div className='dashboard-table'>
      <TableContainer component={Paper}>
        <Table sx={{pageBreakInside: 'auto', tableLayout: 'fixed', width: '100%'}}>
          <TableHead sx={{'-webkit-print-color-adjust': 'exact', background: '#F0F3F4'}}>
            <TableRow sx={{pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
              <TableCell>{'Cognome'}</TableCell>
              <TableCell align='center'>{'Nome'}</TableCell>
              <TableCell align='center'>{'Data adesione'}</TableCell>
              <TableCell align='center'>{'Data prima rilevazione'}</TableCell>
              <TableCell align='center'>{'Stato'}</TableCell>
              <TableCell align='center'>{'Terapia'}</TableCell>
              <TableCell align='center'>{'Servizio'}</TableCell>
              <TableCell align='center'>{'Compilazione Diario'}</TableCell>
              <TableCell align='center'>{'Aderenza(*)'}</TableCell>
              <TableCell align='center'>{'Aderenza % Media(*)'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row._id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component='th' scope='row'>{row.lastName || 'n.a.'}</TableCell>
                <TableCell align='center'>{row.firstName || 'n.a.'}</TableCell>
                <TableCell align='center'>{row.enrollmentDate ? moment(row.enrollmentDate).format('DD/MM/YYYY') : 'n.a.'}</TableCell>
                <TableCell align='center'>{row.firstDetection ? moment(row.firstDetection).format('DD/MM/YYYY') : 'n.a.'}</TableCell>
                <TableCell align='center'>{row.state ? statesOptions.find((option) => option.value === row.state)?.label : 'n.a.'}</TableCell>
                <TableCell align='center'>{row.therapyWithDrugName || 'n.a.'}</TableCell>
                <TableCell align='center'>{row.serviceName || 'n.a.'}</TableCell>
                <TableCell align='center'>{row.questionaresScoresAverage || 0}</TableCell>
                <TableCell align='center'>{row.adherence ? formatAdherence(row.adherence) : 'n.a.'}</TableCell>
                <TableCell align='center'>{row.averageAdherence ? formatAdherenceAverage(row.averageAdherence) : 'n.a.'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='legend'>
        {'(*) rilevata da device PluggyMed (by PhutureMed) - FASCE DI ADERENZA'}
        <div className='color-box' style={{background: 'green'}} />
        {'100%'}
        <div className='color-box' style={{background: 'lightgreen'}} />
        {'99 - 97%'}
        <div className='color-box' style={{background: 'yellow'}} />
        {'96 - 90%'}
        <div className='color-box' style={{background: 'orange'}} />
        {'89 - 80%'}
        <div className='color-box' style={{background: 'red'}} />
        {'sotto 80%'}
      </div>
    </div>
  )
}

export default TablePDFSummary
