import {styled} from '@mui/system'

export const ChangePortalPageStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  paddingTop: '100px',

  '.header': {
    width: '100%',
    top: 0
  },

  ul: {
    padding: '0 80px',
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    justifyContent: 'center'
  }
}))
