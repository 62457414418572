import {useState} from 'react'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import ConfirmationDialog from 'src/components/templates/ConfirmationDialog/ConfirmationDialog'
import EnrollmentPatientForm from 'src/components/templates/Filters/EnrollmentPatientForm/EnrollmentPatientForm'
import {PATHS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'

const PatientEnrollment = () => {
  const [formValues, setFormValues] = useState<any>({})
  const [status, setStatus] = useState<string | undefined>()
  const [isConfirmationDialog, setIsConfirmationDialog] = useState<boolean>(false)

  const onSubmit = (values: any) => {
    setFormValues(values)
    setIsConfirmationDialog(true)
  }

  const submitForm = () => {
    const {firstName, lastName, birthDate, file: fileList} = formValues
    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('birthDate', birthDate.toISOString())
    fileList.forEach(({originFileObj}: any) => {
      formData.append('file', originFileObj, originFileObj.name)
    })
    apiClient.postPatientEnrollment(formData, setStatus)
  }

  return (
    <LayoutContainer breadcrumbs={[{label: 'Iscrizione nuovo paziente', href: PATHS.PATIENT_ENROLLMENT}]}>
      <EnrollmentPatientForm onSubmit={onSubmit} status={status} />
      {isConfirmationDialog &&
        <ConfirmationDialog
          messageId={'enrollmentConfirmMessage'}
          onClose={() => setIsConfirmationDialog(false)}
          onConfirm={() => { submitForm(); setIsConfirmationDialog(false) }}
          titleId={'enrollmentConfirmTitle'}
        />}
    </LayoutContainer>
  )
}

export default PatientEnrollment
