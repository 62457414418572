import {mdiBug} from '@mdi/js'
import React, {useEffect, useState} from 'react'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import PharmacyDetailModal from './PharmacyDetailModal'
import {Snackbar, Typography} from '@mui/material'
import {STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import apiClient from 'src/services/apiClient'

export interface Pharmacy {
  _id: string,
  address?: Record<string, string>,
  code?: string,
  openingTimes?: string,
  createdAt: string,
  email: string,
  fax?: string,
  name: string,
  phoneNumber?: string,
  status: string,
  type: string
}

const COLUMNS = [
  {id: 'isPrimary', label: 'Riferimento', type: 'boolean'},
  {id: 'code', label: 'Codice'},
  {id: 'name', label: 'Nome'},
  {id: 'typeReadable', label: 'Tipo'},
  {id: 'phoneNumber', label: 'Telefono'},
  {id: 'status', label: 'Stato attivazione', defaultValue: 'Attiva'}
]

export default function Pharmacies ({patientId}: {patientId: string}) {
  const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [data, setData] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()

  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getPatientsPharmacies(patientId, setStatus, pagination) ?? []
      const parsedFetchData = fetchedData.map((pharmacy: any) => {
        return {
          ...pharmacy,
          typeReadable: [{value: 'EXTERNAL', label: 'Esterna'}, {value: 'HOSPITAL', label: 'Ospedaliera'}]
            .find((option) => option.value === pharmacy.type)?.label
        }
      })
      setData(parsedFetchData)
      const fetchedDataCount = await apiClient.getPatientsPharmaciesCount(patientId, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }

    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return (
    <>
      <TableCustom
        actions={[
          {
            id: 'detail',
            label: 'Dettaglio',
            icon: mdiBug,
            action: (row: any) => setSelectedPharmacy(row)
          }
        ]}
        columns={COLUMNS}
        onPaginationChange={setPagination}
        pagination={pagination}
        rows={data}
        totalElements={count}
      />
      <PharmacyDetailModal
        onClose={() => setSelectedPharmacy(undefined)}
        open={Boolean(selectedPharmacy)}
        pharmacy={selectedPharmacy}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </>
  )
}
