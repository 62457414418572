import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import {updateScope} from 'src/slices/pspConfigSlice'
import {dispatch} from 'src/redux-store'
import FirstStep from './steps/FirstStep/FirstStep'
import SecondStep from './steps/SecondStep/SecondStep'
import {SelfRegistrationStyled} from './SelfRegistrationStyled'
import ThirdStep from './steps/ThirdStep/ThirdStep'

const SelfRegistration = () => {
  const [config, setConfig] = useState<any>()
  const [step, setStep] = useState<number>(0)
  const [registration, setRegistration] = useState({email: '', code: ''})

  useEffect(() => {
    async function getLoginConfig () {
      const response = await apiClient.loginConfiguration()
      setConfig(response)
    }
    getLoginConfig()
  }, [])

  useEffect(() => {
    if (config?.scope) {
      dispatch(updateScope(config.scope))
    }
    if (config?.PSPTitleAndIcon) {
      const link = document.querySelector('link[rel="shortcut icon"]')
      const title = document.querySelector('title')
      if (title) {
        document.title = config.PSPTitleAndIcon.title
      }
      if (link) {
        link.setAttribute('href', `data:image/png;base64,${config.PSPTitleAndIcon.icon}`)
      }
    }
  }, [config])

  return (
    config ?
      <SelfRegistrationStyled>
        {step === 0 &&
          <FirstStep
            config={config}
            registration={registration}
            setRegistration={setRegistration}
            setStep={setStep}
          />}
        {step === 1 &&
          <SecondStep
            config={config}
            email={registration.email}
            setStepPage={setStep}
          />}
        {step === 2 &&
          <ThirdStep
            config={config}
            email={registration.email}
          />}
      </SelfRegistrationStyled> :
      <></>
  )
}

export default SelfRegistration
