import {CircularProgress} from '@mui/material'
import {LoaderStyled} from './LoaderStyled'

const Loader: React.FC = () => {
  return (
    <LoaderStyled>
      <CircularProgress />
    </LoaderStyled>
  )
}

export default Loader
