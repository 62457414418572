import {TextField} from '@mui/material'
import {PropsWithChildren} from 'react'
import {LoginTextFieldStyled} from './LoginTextFieldStyled'

const LoginTextField: React.FC<PropsWithChildren<any>> = ({
  children,
  loginConfiguration,
  ...other
}) => {
  return (
    <LoginTextFieldStyled
    // @ts-ignore
      loginConfiguration={loginConfiguration}
    >
      <TextField
        InputProps={{
          className: 'login-text-field',
          style: {height: '42px'}
        }}
        {...other}
      >
        {children}
      </TextField>
    </LoginTextFieldStyled>

  )
}

export default LoginTextField
