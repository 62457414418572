import {PropsWithChildren} from 'react'
import {ButtonStyled} from './ButtonTextStyled'
import {Button, ButtonProps} from '@mui/material'

const ButtonText: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...other
}) => {
  return (
    <ButtonStyled>
      <Button {...other} variant='text'>
        {children}
      </Button>
    </ButtonStyled>
  )
}

export default ButtonText
