import {styled} from '@mui/system'

export const NavbarItemStyled = styled('div')(({theme}) => ({

  width: '90%',
  borderRadius: '0 80px 80px 0',

  '&.selectedIconListItem': {
    background: 'rgba(255,255,255,.2)'
  },
  ':hover': {
    background: 'rgba(255,255,255,.2)'
  },
  '.menuLink': {
    display: 'flex',
    height: '100%',
    padding: `${theme.spacing(6)} ${theme.spacing(10)}`,
    margin: '4px 0 4px 0'
  },
  '.MuiListItemIcon-root': {
    alignItems: 'center',
    width: '100%'
  }
}))
