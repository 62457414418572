import {Paper} from '@mui/material'
import {styled} from '@mui/system'

export const CalendarPaperStyled = styled(Paper)(({theme}) => ({
  minWidth: theme.spacing(150),
  padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
  color: '#58666e !important',
  '.popover-title': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.spacing(9)
  },
  '.popover-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    fontSize: theme.spacing(6)
  },
  '.icon-popover': {
    alignSelf: 'center',
    marginTop: theme.spacing(1)
  },
  '.hr-popover': {
    backgroundColor: '#edf1f2',
    height: '1px',
    border: 'none'
  },

  '.popover-row': {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2.5)
  },

  '.personalData-popover': {
    gap: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'row'
  }
}))
