import {styled} from '@mui/system'

export const FlexRowStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row'
}))

export const FlexRowEndStyled = styled('div')(({theme}) => ({
  ...FlexRowStyled,
  alignItems: 'flex-end'
}))

export const FlexColumnStyled = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '64px'
}))

export const SingleSelector = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
}))

export const SlotSelector = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '32px'
}))

export const SlotSelectorContainer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '48px',
  '.ant-select': {
    width: '100%',
    minWidth: '130px',
    '.ant-select-selector': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(21),

      '.ant-select-selection-placeholder': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-item': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-overflow': {
        position: 'absolute',
        '.ant-select-selection-item': {
          borderRadius: '16px'
        }
      }
    }
  }
}))

export const FormStyle = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px'
}))
