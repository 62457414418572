/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import React, {useState} from 'react'
import AgendaToolbar from 'src/components/elements/AgendaToolbar/AgendaToolbar'
import {Typography} from '@mui/material'
import {ActivitiesAgendaStyled} from './ActivitiesAgendaStyled'
import ActivityDialog from '../ActivityDialog/ActivityDialog'

const localizer = momentLocalizer(moment)

type ActivitiesAgendaProps = {
  events: Record<string, any>[],
  startDate: Date,
  setStartDate: any,
  endDate: Date,
  setEndDate: any
}

const EventBox = (activity: Record<string, any>) => {
  return (
    <div className='event-box'>
      <Typography className='event-reason' variant='body2'>
        <span className='time-label'>{moment(activity?.startDate).format('HH:mm')}</span>
        <span className='event-dot' />
        <span className='title-label'>{`${activity.beneficiary.firstName} ${activity.beneficiary.lastName} - ${activity.title}`}</span>
      </Typography>
    </div>
  )
}

const DateBox = (day: Date) => {
  return (
    <div className='date-box'>
      <Typography className='event-reason' variant='body2'>
        <span>{moment(day).format('dddd')}</span>
      </Typography>
      <Typography className='event-reason' variant='body2'>
        <span>{moment(day).format('DD MMMM YYYY')}</span>
      </Typography>
    </div>
  )
}

const ActivitiesAgenda: React.FC<ActivitiesAgendaProps> = ({events, startDate, setStartDate, endDate, setEndDate}) => {
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const [range, setRange] = useState<number>(0)
  const [selectedEvent, setSelectedEvent] = useState<Record<string, any>>({})
  const [isEventModal, setIsEventModal] = useState<boolean>(false)

  const handleRangeChange = (newRange: number) => {
    setRange(newRange)
  }

  const handleDateChange = (date: Date) => {
    if (!(moment(startDate).toISOString() <= moment(date).toISOString() && moment(date).toISOString() <= moment(endDate).toISOString())) {
      setStartDate(moment(date).startOf('month'))
      setEndDate(moment(date).endOf('month'))
    }
    setCalendarDate(new Date(date))
  }

  const calendarComponents = {
    toolbar: () => AgendaToolbar(
      {
        date: calendarDate,
        range: range,
        handleDateChange: handleDateChange,
        handleRangeChange: handleRangeChange
      }),
    agenda: {
      event: ({event} : any) => EventBox(event),
      date: ({day}: any) => DateBox(day)
    }
  }

  return (
    <>
      <ActivitiesAgendaStyled
        components={calendarComponents}
        culture='it'
        date={calendarDate}
        defaultView='agenda'
        endAccessor={(event: any) => new Date(event.endDate)}
        events={events}
        length={range}
        localizer={localizer}
        onNavigate={handleDateChange}
        onSelectEvent={(event) => { setSelectedEvent(event); setIsEventModal(true) }}
        startAccessor={(event: any) => new Date(event.startDate)}
        style={{height: 'calc(100vh - 180px)'}}
      />
      {isEventModal &&
        <ActivityDialog
          activity={selectedEvent}
          onClose={() => setIsEventModal(false)}
        />}
    </>
  )
}

export default ActivitiesAgenda
