import CloseIcon from '@mui/icons-material/Close'
import {DialogCustomStyled, DialogPaperStyled} from './DialogCustomStyled'
import {Breakpoint, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'

type DialogCustomProps = {
  open?: boolean,
  title?: {text: string, format: boolean},
  body: React.ReactNode,
  footer?: React.ReactNode,
  maxWidth?: Breakpoint,
  onClose?: () => void,
  disableShadow?: any
}

const DialogCustom: React.FC<DialogCustomProps> = ({open = true, title, body, footer = <></>, maxWidth, onClose, disableShadow}) => {
  return (
    // @ts-ignore
    <DialogCustomStyled disableShadow={disableShadow} fullWidth maxWidth={maxWidth} onClose={onClose} open={open}>
      <DialogPaperStyled>
        <DialogTitle>
          <div className='dialog-header'>
            <Typography className='dialog-title' variant='h1'>{title?.format ? <FormattedMessage id={title.text} /> : <>{title?.text}</>}</Typography>
            <IconButton aria-label='close' edge='end' onClick={onClose} style={{backgroundColor: 'transparent'}}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='dialog-body'>
            {body}
          </div>
        </DialogContent>
        <DialogActions>
          <div className='dialog-footer'>
            {footer}
          </div>
        </DialogActions>
      </DialogPaperStyled>
    </DialogCustomStyled>
  )
}

export default DialogCustom
