import {Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import apiClient from 'src/services/apiClient'

type ContactMeDialogProps = {
  setStatus: React.Dispatch<React.SetStateAction<string | undefined>>;
  onClose: () => void;
};

const ContactMeDialog: React.FC<ContactMeDialogProps> = ({setStatus, onClose}) => {
  const sendRequest = async () => {
    await apiClient.postContactRequest({}, setStatus)
    onClose()
  }
  const body = <Typography variant='body1'><FormattedMessage id='contactMeDialogBody' /></Typography>

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <SecondaryButton onClick={() => sendRequest()} style={{marginRight: '8px'}}>
        <FormattedMessage id='ok' />
      </SecondaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      onClose={onClose}
      title={{text: 'contactMeDialogTitle', format: true}}
    />
  )
}

export default ContactMeDialog
