import {styled} from '@mui/system'

export const PatientStyled = styled('div')(({theme}) => ({
  gridArea: 'main',
  backgroundColor: 'white',
  overflowX: 'hidden',

  '.layout-container-header': {
    backgroundColor: 'white',
    borderRadius: `${theme.spacing(20)} ${theme.spacing(20)} ${theme.spacing(0)} ${theme.spacing(0)}`,
    padding: `${theme.spacing(15)} ${theme.spacing(10)} ${theme.spacing(10)} ${theme.spacing(10)}`,
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    '.avatar': {
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary[100],
      img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '50%'
      }
    },

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    },

    '.label': {
      textShadow: 'none',
      width: 'fit-content',
      color: '#fff',
      fontSize: '13px',
      padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main,
      fontWeight: '600'
    }
  },

  '.tabs-container': {
    padding: '12px',

    '.MuiTabPanel-root': {
      padding: '24px',
      border: '1px solid #DDD'
    },

    '.MuiTabs-flexContainer': {
      justifyContent: 'flex-start'
    }
  }

}))
