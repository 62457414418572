import {Grid, Typography, useTheme} from '@mui/material'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {PDFSummaryStyled} from './PDFSummaryStyled'
import {useEffect, useState} from 'react'
import Chart from 'src/components/elements/Chart/Chart'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'
import {useLocation} from 'react-router-dom'
import moment from 'moment'
import TablePDFSummary from './TablePDFSummary'

const PDFSummary = () => {
  const theme = useTheme()
  const {summaryConfiguration, logoImagePSP} = useAppSelector(state => state.pspConfigReducer.data)
  const {medicalCenter, firstName, lastName} = useAppSelector(state => state.userReducer.data)
  const [, setStatus] = useState<any>(undefined)

  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const filters = searchParams.get('filters') || ''

  const [patientSex, setPatientSex] = useState([])
  const [patientCurrentAge, setPatientCurrentAge] = useState([])
  const [patientStatus, setPatientStatus] = useState([])
  const [patientVialDosages, setPatientVialDosages] = useState([])
  const [patientDrug, setPatientDrug] = useState([])
  const [patientMonthlyAdherence, setPatientMonthlyAdherence] = useState({})
  const [patientRegionResidence, setPatientRegionResidence] = useState({})
  const [doctorRegionResidence, setDoctorRegionResidence] = useState({})
  const [patientServices, setPatientServices] = useState([])
  const [mdsActive, setMdsActive] = useState([])
  const [skippedAssumption, setSkippedAssumption] = useState([])
  const [patientAdherenceActivations, setPatientAdherenceActivations] = useState({})
  const [monthlyDrugAdherence, setMonthlyDrugAdherence] = useState({})
  const [assumptionsRegion, setAssumptionsRegion] = useState({})
  const [regionAdherence, setRegionAdherence] = useState({})

  const defaultColors = ['#A5C842', '#495CA2', '#028B96', '#E87D1E', '#003DA5', '#085AA6', '#731472', '#3599B8']
  const {scope, theme: themeColors} = useAppSelector(state => state.pspConfigReducer.data)
  const [colors, setColors] = useState<string[]>((scope === 'quiperte' && themeColors) ? [themeColors?.primaryColor, themeColors?.secondaryColor].concat(defaultColors) : defaultColors)
  const [patientsData, setPatientsData] = useState([])

  useEffect(() => {
    fetchData()
    fetchProgram()
    fetchPatientsData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const fetchData = async () => {
    const fetchedData = await apiClient.getGraphs(JSON.parse(filters), setStatus) ?? []
    setPatientSex(fetchedData?.patientSex || [])
    setPatientCurrentAge(fetchedData?.patientCurrentAge || [])
    setPatientStatus(fetchedData?.patientStatus || [])
    setPatientRegionResidence(fetchedData?.patientRegionResidence || {})
    setDoctorRegionResidence(fetchedData?.doctorRegionResidence || {})
    setPatientMonthlyAdherence(fetchedData?.patientMonthlyAdherence || {})
    setPatientDrug(fetchedData?.patientDrug || [])
    setPatientServices(fetchedData?.patientServices || [])
    setMdsActive(fetchedData?.mdsActive || [])
    setSkippedAssumption(fetchedData?.skippedAssumption || [])
    setPatientAdherenceActivations(fetchedData?.patientAdherenceActivations || {})
    setMonthlyDrugAdherence(fetchedData?.monthlyDrugAdherence || {})
    setAssumptionsRegion(fetchedData?.assumptionsRegion || {})
    setRegionAdherence(fetchedData?.regionAdherence || {})
    setPatientVialDosages(fetchedData?.patientVialDosages || [])
  }

  const fetchPatientsData = async () => {
    const fetchedData = await apiClient.getPatientsData(() => {}) ?? []
    setPatientsData(fetchedData)
  }

  const fetchProgram = async () => {
    const program = await apiClient.getProgram()
    if (program) {
      setColors((scope === 'quiperte' && themeColors) ? [themeColors?.primaryColor, themeColors?.secondaryColor].concat(program.programPalette.colors) : program.programPalette.colors)
    }
  }

  return (
    <>
      <PDFSummaryStyled>
        <table className='print-container'>
          <thead className='print-header' style={{height: '50px', paddingTop: '32px'}}>
            <tr>
              <th className='report-header-cell'>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <img alt='IA logo' src={'/assets/images/logo_IA.png'} />
                  </div>
                  <div>
                    <img alt='logo PSP' src={`data:image/png;base64,${logoImagePSP}`} />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='print-content'>
            <tr>
              <td className='print-content-cell'>
                <div style={{textAlign: 'left'}}>
                  <div style={{fontSize: '18px', color: '#495055', fontWeight: '600', margin: '24px 0'}}>
                    {'STATISTICHE PAZIENTE'}
                  </div>
                  <div style={{display: 'flex', gap: '40px', marginBottom: '16px'}}>
                    <div style={{fontWeight: '600'}}>
                      <div>
                        {'Data estrazione:'}
                      </div>
                      <div>
                        {'Centro:'}
                      </div>
                      <div>
                        {'Medico:'}
                      </div>
                    </div>
                    <div>
                      <div>
                        {moment(new Date()).format('DD/MM/YYYY')}
                      </div>
                      <div>
                        {medicalCenter}
                      </div>
                      <div>
                        {`${firstName} ${lastName}`}
                      </div>
                    </div>
                  </div>
                </div>
                <Grid className='chart-grid-container' columnSpacing={theme.spacing(8)} columns={{xs: 4, sm: 8, md: 12}} container rowSpacing={theme.spacing(4)}>
                  {summaryConfiguration?.kpiIdentifiers.patientStatus &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per stato'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientStatus}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientServices &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Distribuzione dei pazienti per tipologia di servizio'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientServices}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientSex &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per genere'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientSex}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientCurrentAge &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per fasce d\'età'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientCurrentAge}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.mdsActive &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Attivazioni MDS'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={mdsActive}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.skippedAssumption &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Distribuzione delle infusioni saltate'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={skippedAssumption}
                            legend={false}
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientDrug &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Pazienti per farmaco'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientDrug}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientVialDosages &&
                    <Grid item md={6} sm={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Fiala in uso'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientVialDosages}
                            legend
                            type='pie'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientRegionResidence &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero pazienti per regione'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientRegionResidence}
                            type='column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.assumptionsRegion &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero di infusioni per regione del paziente'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={assumptionsRegion}
                            type='column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientAdherenceActivations &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero di adesioni/attivazioni per mese'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientAdherenceActivations}
                            type='column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.doctorRegionResidence &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Numero dottori per regione'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={doctorRegionResidence}
                            type='column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.patientMonthlyAdherence &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza per mese'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={patientMonthlyAdherence}
                            percentage
                            type='stacking-column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.regionAdherence &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza per regione'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={regionAdherence}
                            percentage
                            type='stacking-column'

                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                  {summaryConfiguration?.kpiIdentifiers.monthlyDrugAdherence &&
                    <Grid item md={12} sx={{pageBreakInside: 'avoid'}}>
                      <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Dati di aderenza tutor per mese per farmaco'}</Typography>}>
                        <div className='chart-container'>
                          <Chart
                            colors={colors}
                            data={monthlyDrugAdherence}
                            percentage
                            type='column'
                          />
                        </div>
                      </CardCustom>
                    </Grid>}
                </Grid>
                {(scope === 'quiperte') &&
                  <div style={{pageBreakBefore: 'always'}}>
                    <div style={{textAlign: 'left'}}>
                      <div style={{fontSize: '18px', color: '#495055', fontWeight: '600', margin: '24px 0'}}>
                        {'DASHBOARD ADERENZA PAZIENTI'}
                      </div>
                      <div style={{display: 'flex', gap: '40px', marginBottom: '16px'}}>
                        <div style={{fontWeight: '600'}}>
                          <div>
                            {'Data estrazione:'}
                          </div>
                          <div>
                            {'Centro:'}
                          </div>
                          <div>
                            {'Medico:'}
                          </div>
                        </div>
                        <div>
                          <div>
                            {moment(new Date()).format('DD/MM/YYYY')}
                          </div>
                          <div>
                            {medicalCenter}
                          </div>
                          <div>
                            {`${firstName} ${lastName}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    <TablePDFSummary
                      rows={patientsData || []}
                    />
                  </div>}
              </td>
            </tr>
          </tbody>
        </table>
      </PDFSummaryStyled>
    </>
  )
}

export default PDFSummary
