import {mdiAccount, mdiClose, mdiMagnify} from '@mdi/js'
import Icon from '@mdi/react'
import {Button, Typography} from '@mui/material'
import {Form, Input} from 'antd'
import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import PatientLookupDialog from '../PatientLookupDialog/PatientLookupDialog'
import {DBSDialogStyled} from './DBSDialogStyled'

type DBSDIalogProps = {
  onClose: () => void,
  onConfirm: (patient: Record<string, any> | undefined) => void;
};

const DBSDIalog: React.FC<DBSDIalogProps> = ({
  onClose,
  onConfirm
}) => {
  const [isPatientsModal, setIsPatientsModal] = useState<boolean>(false)
  const [patient, setPatient] = useState<Record<string, any>>()
  const [form] = Form.useForm()

  const body = (
    <DBSDialogStyled>
      <Form
        form={form}
      >
        <Form.Item>
          <div className='patient-content'>
            <Form.Item
              name={'patient'}
              rules={[{required: true, message: 'Campo obbligatorio'}]}
            >
              <Input disabled />
            </Form.Item>
            <div className='patient-buttons'>
              <Button
                className='patient-button'
                onClick={() => setIsPatientsModal(true)}
              >
                <Icon path={mdiMagnify} size={1} />
              </Button>
              <Button
                className='patient-button'
                disabled={patient === undefined}
              >
                <Icon path={mdiAccount} size={1} />
              </Button>
              <Button
                className='patient-button'
                disabled={patient === undefined}
                onClick={() => {
                  setPatient(undefined)
                  form.setFieldValue('patient', undefined)
                }}
              >
                <Icon path={mdiClose} size={1} />
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
      <Typography>
        <FormattedMessage id='DBSDialogMessage' />
      </Typography>
    </DBSDialogStyled>

  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <SecondaryButton
        disabled={patient === undefined}
        onClick={() => onConfirm(patient)}
        style={{marginRight: '8px'}}
      >
        <FormattedMessage id='continue' />
      </SecondaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <>
      <DialogCustom
        body={body}
        footer={footer}
        onClose={onClose}
        title={{text: 'requestDBS', format: true}}
      />
      {isPatientsModal &&
        <PatientLookupDialog
          onClose={() => setIsPatientsModal(false)}
          onRowClick={(row) => {
            setPatient(row)
            setIsPatientsModal(false)
            form.setFieldValue('patient', `${row.firstName} ${row.lastName}`)
          }}
        />}
    </>
  )
}

export default DBSDIalog
