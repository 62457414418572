import {styled} from '@mui/system'

export const CaregiverDetailDialogStyled = styled('div')(({theme}) => ({
  paddingBottom: theme.spacing(18),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(11),
  '.container': {
    border: `1px solid ${theme.palette.neutral[100]}`,
    borderRadius: theme.spacing(1),
    '&-title': {
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`
    },
    '.MuiDivider-root': {
      borderColor: theme.palette.neutral[100]
    },
    '&-body': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(7.5),
      padding: `${theme.spacing(5)} ${theme.spacing(6.5)}`,
      '&-row': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: theme.spacing(15),
        '&-first': {
          display: 'grid',
          gridTemplateColumns: '1fr 2fr 2fr',
          gap: theme.spacing(15)
        },
        '&-grid': {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: theme.spacing(15),
          rowGap: theme.spacing(7.5)
        },
        '&-long': {
          gridTemplateColumns: '2fr 1fr 1fr 2fr'
        }
      },
      '&-center': {
        display: 'flex',
        justifyContent: 'center'
      },
      '&-element': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5)
      }
    },
    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    '.ant-form-item': {
      marginBottom: 0,
      width: '100%'
    },
    '.ant-select': {
      width: '100%',
      minWidth: '130px',
      '.ant-select-selector': {
        height: theme.spacing(19),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.spacing(21),

        '.ant-select-selection-placeholder': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-item': {
          lineHeight: 'unset'
        },

        '.ant-select-selection-overflow': {
          position: 'absolute',
          '.ant-select-selection-item': {
            borderRadius: '16px'
          }
        }
      }
    }
  }
}))
