import {Snackbar, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import CustomerUserDialog from 'src/components/templates/CustomerUserDialog/CustomerUserDialog'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import {PATHS, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'

const buildBodyWithAddressAndPreferredContacts = (body: any) => {
  const {
    address: addressBody, postalCode,
    streetNumber, country, locality, SMS, EMAIL, PUSH
  } = body
  const parseBody = {...body}
  delete parseBody.city
  delete parseBody.province
  delete parseBody.region
  delete parseBody.postalCode
  delete parseBody.streetNumber
  delete parseBody.country
  delete parseBody.locality
  delete parseBody.EMAIL
  delete parseBody.SMS
  delete parseBody.PUSH
  const preferredContacts: any = []
  if (EMAIL === 'true') {
    preferredContacts.push('EMAIL')
  }
  if (SMS === 'true') {
    preferredContacts.push('SMS')
  }
  if (PUSH === 'true') {
    preferredContacts.push('PUSH')
  }
  return {
    ...parseBody,
    sex: (Object.keys(parseBody).includes('sex') ? (parseBody.sex || '') : undefined),
    preferredContacts: preferredContacts.length > 0 ? preferredContacts : undefined,
    address: {
      address: addressBody,
      city: Object.keys(body).includes('city') ? (body.city || '') : undefined,
      province: Object.keys(body).includes('province') ? (body.province || '') : undefined,
      region: Object.keys(body).includes('region') ? (body.region || '') : undefined,
      postalCode,
      streetNumber,
      country,
      locality
    }
  }
}

const CustomerUsers = () => {
  const columns: TableColumn[] = [
    {
      id: 'title',
      label: 'TITOLO'
    },
    {
      id: 'lastName',
      label: 'COGNOME'
    },
    {
      id: 'firstName',
      label: 'NOME'
    }
  ]

  const filterConfig: FilterItem[] = [
    {
      id: 'firstName',
      label: 'Nome',
      type: 'string'
    },
    {
      id: 'lastName',
      label: 'Cognome',
      type: 'string'
    }
  ]

  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [data, setData] = useState<Record<string, any>[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [customerUser, setCustomerUser] = useState<Record<string, any>>()
  const [status, setStatus] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()
  const [statusPatch, setStatusPatch] = useState<string>()

  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)

  const fetchData = async () => {
    const fetchedData = await apiClient.getClients(filters, setStatus, pagination) ?? []
    setData(fetchedData)
    const fetchedDataCount = await apiClient.getClientsCount(filters, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const onRowClicked = (row: Record<string, any>) => {
    setCustomerUser(row)
    setOpen(true)
  }

  const onCloseDialog = () => {
    setOpen(false)
    setCustomerUser(undefined)
  }

  const onSaveHandler = async (user: any) => {
    await apiClient.patchClients(customerUser?._id, buildBodyWithAddressAndPreferredContacts(user), setStatusPatch)
    await fetchData()
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Lista utenze cliente', href: PATHS.CUSTOMER_USERS}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClicked}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </div>
      {(open && customerUser) &&
        <CustomerUserDialog
          onClose={onCloseDialog}
          onSave={onSaveHandler}
          readOnly={customerUser?.authUserId !== userInfoData.authUserId}
          user={customerUser}
        />}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatusPatch(undefined) }}
        open={statusPatch === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatusPatch(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel modificare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default CustomerUsers
