import {Icon} from '@mdi/react'
import {AvatarStyled} from './AvatarStyled'
import {mdiTooltipAccount} from '@mdi/js'

export type AvatarType = {
  imageSrc?: string,
  onClick?: () => void,
  size: string,
  selected?: boolean,
  disabled?: boolean
}

const Avatar: React.FC<AvatarType> = ({
  imageSrc,
  onClick,
  size,
  selected = false,
  disabled = false
}) => {
  return (
    <AvatarStyled backgroundColor={!imageSrc ? '#F0F3F4' : undefined} disabled={disabled} onClick={onClick} selected={selected} width={size}>
      <div className='avatar'>
        {imageSrc ?
          <img
            alt='avatar'
            src={`data:image/png;base64,${imageSrc}`}
          /> :
          <Icon color={'black'} path={mdiTooltipAccount} size={2} />}
      </div>
    </AvatarStyled>
  )
}

export default Avatar
