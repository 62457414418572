import {Typography} from '@mui/material'
import {DatePicker, Divider, Form, Radio} from 'antd'
import {FormattedMessage} from 'react-intl'
import {ProgramMembershipDataStyled} from './ProgramMembershipDataStyled'
import locale from 'antd/es/date-picker/locale/it_IT'

const radioOptions = [
  {label: 'Sì', value: 'true'},
  {label: 'No', value: 'false'}
]

type ProgramMembershipDataType = {
  profileConfiguration: Record<string, any>,
  readOnly?: boolean
}

const ProgramMembershipData: React.FC<ProgramMembershipDataType> = ({profileConfiguration, readOnly = false}) => {
  if (profileConfiguration?.enrollmentDate?.hidden && profileConfiguration?.receiveModuleDate?.hidden &&
    profileConfiguration?.privacyConsents?.ENROLLMENT?.hidden && profileConfiguration?.privacyConsents?.PRIVACY_PHONE_SURVEY?.hidden &&
    profileConfiguration?.privacyConsents?.ENROLLMENT?.hidden) {
    return null
  }

  return (
    <ProgramMembershipDataStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            <FormattedMessage id='programMembershipData' />
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <div className='container-body-row'>
            {!profileConfiguration?.enrollmentDate?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Data di Adesione'
                  name='enrollmentDate'
                >
                  <DatePicker
                    disabled={profileConfiguration?.enrollmentDate?.readOnly || readOnly}
                    format='DD MMM YYYY'
                    locale={locale}
                    placeholder=''
                  />
                </Form.Item>
              </div>}
            {!profileConfiguration?.receiveModuleDate?.hidden &&
              <div className='container-body-element'>
                <Form.Item
                  label='Data ricezione modulo'
                  name='receiveModuleDate'
                >
                  <DatePicker
                    disabled={profileConfiguration?.receiveModuleDate?.readOnly || readOnly}
                    format='DD MMM YYYY'
                    locale={locale}
                    placeholder=''
                  />
                </Form.Item>
              </div>}
          </div>
          <Form.Item
            label='Consenso al trattamento dei dati personali'
            required
          >
            <div style={{paddingLeft: '20px'}}>
              {!profileConfiguration?.privacyConsents?.ENROLLMENT?.hidden &&
                <Form.Item
                  className='ant-radio-row'
                  label="Per l'adesione al Programma"
                  name='ENROLLMENT'
                >
                  <Radio.Group disabled={profileConfiguration?.privacyConsents?.ENROLLMENT?.readOnly || readOnly}>
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>}
              {!profileConfiguration?.privacyConsents?.PRIVACY_PHONE_SURVEY?.hidden &&
                <Form.Item
                  className='ant-radio-row'
                  label='Per rilevazione telefonica finalizzata alla valutazione del gradimento dei servizi'
                  name='PRIVACY_PHONE_SURVEY'
                >
                  <Radio.Group disabled={profileConfiguration?.privacyConsents?.PRIVACY_PHONE_SURVEY?.readOnly || readOnly}>
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>}
              {!profileConfiguration?.privacyConsents?.PRIVACY_ENROLLMENT_APP_WEB?.hidden &&
                <Form.Item
                  className='ant-radio-row'
                  label='Per la registrazione al portale/app'
                  name='PRIVACY_ENROLLMENT_APP_WEB'
                >
                  <Radio.Group disabled={profileConfiguration?.privacyConsents?.PRIVACY_ENROLLMENT_APP_WEB?.readOnly || readOnly}>
                    <Radio value>{'Sì'}</Radio>
                    <Radio value={false}>{'No'}</Radio>
                  </Radio.Group>
                </Form.Item>}
            </div>
          </Form.Item>
          {!profileConfiguration?.mds?.hidden &&
            <Form.Item
              className='ant-radio-row'
              label='Medico del Servizio'
              name='mds'
            >
              <Radio.Group disabled={profileConfiguration?.mds?.readOnly || readOnly} options={radioOptions} />
            </Form.Item>}
          {!profileConfiguration?.testDBSService?.hidden &&
            <Form.Item
              className='ant-radio-row'
              label='Servizio test DBS'
              name='testDBSService'
            >
              <Radio.Group disabled={profileConfiguration?.testDBSService?.readOnly || readOnly} options={radioOptions} />
            </Form.Item>}
        </div>
      </div>
    </ProgramMembershipDataStyled>
  )
}

export default ProgramMembershipData
