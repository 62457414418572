import {styled} from '@mui/material'

export const DiaryStyle = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),

  '.ant-select': {
    width: '100%',

    '.ant-select-selector': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: theme.spacing(21),

      '.ant-select-selection-placeholder': {
        lineHeight: 'unset'
      },

      '.ant-select-selection-item': {
        lineHeight: 'unset'
      }
    }
  },

  '.card': {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    border: '1px solid #edf1f2'
  },

  '.header': {
    padding: theme.spacing(7.5)
  }
}))
