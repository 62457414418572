import {Paper} from '@mui/material'
import {styled} from '@mui/system'
import {Modal} from 'antd'

export const UploaderStyled = styled(Paper)(({theme}) => ({

  width: theme.spacing(600),
  padding: theme.spacing(8),

  '.MuiTooltip-popper': {
    zIndex: '9999999 !important'
  },

  'button[disabled]': {
    pointerEvents: 'auto'
  },

  '.document-upload': {
    img: {
      maxHeight: theme.spacing(75),
      margin: theme.spacing(16)
    }
  },

  '.upload-items-header': {
    padding: theme.spacing(5),
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(8),
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr'
  },

  '.upload-item': {
    padding: theme.spacing(8),
    border: `1px solid ${theme.palette.neutral[200]}`,
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr'
  },

  '.upload-item-cell': {
    paddingRight: '20px',
    '.ant-select': {
      width: '100%'
    }
  }

}))

export const ModalSubmitFileStyled = styled(Modal)(() => ({
  '.ant-modal-content': {
    width: '600px',
    padding: '10px',
    borderRadius: '20px'
  }

}))
