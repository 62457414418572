export default function getFileMIMEtype (fileName?: string) {
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'JPEG' || fileName.split('.')?.pop()?.toUpperCase() === 'JPG')) {
    return 'image/jpeg'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'AVI')) {
    return 'video/x-msvideo'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'MP3')) {
    return 'audio/mpeg'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'MP4')) {
    return 'video/mp4'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'MPEG')) {
    return 'video/mpeg'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'PNG')) {
    return 'image/png'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'PDF')) {
    return 'application/pdf'
  }
  if (fileName && (fileName.split('.')?.pop()?.toUpperCase() === 'SVG')) {
    return 'image/svg+xml'
  }
}
