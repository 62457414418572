import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {NewEnrollmentDialogStyled} from './NewEnrollmentDialogStyled'
import {FormattedMessage} from 'react-intl'
import {Form, Input, Select} from 'antd'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {useForm} from 'antd/lib/form/Form'
import UnitSelector from 'src/components/elements/UnitSelector'
import {THERAPY_DAILY_DOSAGE_UNIT_TYPE_OPTIONS, THERAPY_DOSAGE_UNIT_TYPE_OPTIONS, THERAPY_SPEED_OF_ASSUMPTION_UNIT_OPTIONS, THERAPY_SPEED_OF_DEVICE_UNIT_OPTIONS} from 'src/constants/constants'
import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'

type NewEnrollmentDialogProps = {
  therapyTypeOptions: {value: string, label: string}[];
  serviceTypesOptions: {value: string, label: string}[];
  request?: Record<string, any>;
  onSubmit: (values: any) => void;
  onClose: () => void;
};

const NewEnrollmentDialog: React.FC<NewEnrollmentDialogProps> = ({therapyTypeOptions, serviceTypesOptions, request, onSubmit, onClose}) => {
  const [form] = useForm()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [status, setStatus] = useState<any>(undefined)

  useEffect(() => {
    form.resetFields()
    if (request) {
      form.setFieldsValue(request)
      form.setFieldValue('therapyTypeId', request.therapyType?._id)
    }
  }, [form, request])

  const onFinish = (values: any) => {
    const body = {
      ...values,
      drugDosage: values.drugDosage?.value ? {value: values.drugDosage.value, unitType: values.drugDosage.unitType ?? THERAPY_DOSAGE_UNIT_TYPE_OPTIONS[0].value} : undefined,
      dailyDosage: values.dailyDosage?.value ? {value: values.dailyDosage.value, unitType: values.dailyDosage.unitType ?? THERAPY_DAILY_DOSAGE_UNIT_TYPE_OPTIONS[0].value} : undefined,
      speedOfAssumption: values.speedOfAssumption?.value ? {value: values.speedOfAssumption.value, unitType: values.speedOfAssumption.unitType ?? THERAPY_SPEED_OF_ASSUMPTION_UNIT_OPTIONS[0].value} : undefined,
      speedOfDevice: values.speedOfDevice?.value ? {value: values.speedOfDevice.value, unitType: values.speedOfDevice.unitType ?? THERAPY_SPEED_OF_DEVICE_UNIT_OPTIONS[0].value} : undefined
    }
    onSubmit(body)
  }

  const body = (
    <NewEnrollmentDialogStyled>
      <Form
        form={form}
        id='digital-enrollment-form'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          className='firstName'
          label='Nome Paziente'
          name='firstName'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input disabled={Boolean(request)} />
        </Form.Item>
        <Form.Item
          className='lastName'
          label='Cognome Paziente'
          name='lastName'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Input disabled={Boolean(request)} />
        </Form.Item>
        <Form.Item
          className='email'
          label='Email'
          name='email'
          rules={[
            {type: 'email', message: 'Formato email non valido'},
            () => ({
              async validator (_, value) {
                const isEmailValid = await apiClient.checkEmail(value, setStatus)
                if (isEmailValid) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Email non disponibile'))
              }
            }),
            ({getFieldValue}) => ({
              validator (_, value) {
                if (value || getFieldValue('cellPhoneNumber')) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Un input tra Email e Numero cellulare deve essere compilato'))
              }
            })
          ]}
        >
          <Input disabled={Boolean(request)} onChange={() => form.validateFields()} />
        </Form.Item>
        <Form.Item
          className='cellPhoneNumber'
          label='Numero cellulare'
          name='cellPhoneNumber'
          rules={[
            ({getFieldValue}) => ({
              validator (_, value) {
                if (value || getFieldValue('email')) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Un input tra Email e Numero cellulare deve essere compilato'))
              }
            })
          ]}
        >
          <Input disabled={Boolean(request)} onChange={() => form.validateFields()} />
        </Form.Item>
        <Form.Item
          className='serviceType'
          label='Tipologia servizio'
          name='serviceType'
          rules={[{required: true, message: 'Campo obbligatorio'}]}
        >
          <Select
            disabled={Boolean(request)}
            getPopupContainer={(trigger) => trigger}
            options={serviceTypesOptions}
          />
        </Form.Item>
        <Form.Item
          className='therapyTypeId'
          label='Terapia'
          name='therapyTypeId'
        >
          <Select
            disabled={Boolean(request)}
            getPopupContainer={(trigger) => trigger}
            options={therapyTypeOptions}
          />
        </Form.Item>
        <Form.Item
          className='drugDosage'
          label='Dosaggio'
          name='drugDosage'
        >
          <UnitSelector
            disabled={Boolean(request)}
            onChange={(values) => {}}
            unitTypeOptions={THERAPY_DOSAGE_UNIT_TYPE_OPTIONS}
            value={request?.drugDosage}
          />
        </Form.Item>
        <Form.Item
          className='dailyDosage'
          label='Dosaggio giornaliero'
          name='dailyDosage'
        >
          <UnitSelector
            disabled={Boolean(request)}
            onChange={(values) => {}}
            unitTypeOptions={THERAPY_DAILY_DOSAGE_UNIT_TYPE_OPTIONS}
            value={request?.dailyDosage}
          />
        </Form.Item>
        <Form.Item
          className='speedOfAssumption'
          label='Velocità di somministrazione'
          name='speedOfAssumption'
        >
          <UnitSelector
            disabled={Boolean(request)}
            onChange={(values) => {}}
            unitTypeOptions={THERAPY_SPEED_OF_ASSUMPTION_UNIT_OPTIONS}
            value={request?.speedOfAssumption}
          />
        </Form.Item>
        <Form.Item
          className='speedOfDevice'
          label='Velocità device'
          name='speedOfDevice'
        >
          <UnitSelector
            disabled={Boolean(request)}
            onChange={(values) => {}}
            unitTypeOptions={THERAPY_SPEED_OF_DEVICE_UNIT_OPTIONS}
            value={request?.speedOfDevice}
          />
        </Form.Item>
      </Form>
    </NewEnrollmentDialogStyled>
  )

  const footer = (
    <div style={{display: 'flex', width: '100%'}}>
      <PrimaryButton
        form='digital-enrollment-form'
        key='submit'
        type='submit'
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={onClose}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      maxWidth='lg'
      onClose={onClose}
      title={{text: 'Nuova iscrizione paziente', format: false}}
    />
  )
}

export default NewEnrollmentDialog
