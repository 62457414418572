import {mdiAccount, mdiClose, mdiContentCopy, mdiVideoPlus} from '@mdi/js'
import Icon from '@mdi/react'
import {Button, IconButton, Typography, Snackbar} from '@mui/material'
import {DatePicker, Form, Input, TimePicker} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import TableHeader from 'src/components/templates/TableHeader/TableHeader'
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton'
import CardCustom from '../CardCustom/CardCustom'
import TableCustom, {PaginationType, TableColumn} from '../TableCustom/TableCustom'
import {VirtualMeetingDetailModalStyled} from './VirtualMeetingDetailStyled'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'

const VirtualMeetingDetailModal = ({event = {}, handleClose}: any) => {
  const [form] = Form.useForm()
  const [status, setStatus] = useState<any>(undefined)

  const userInfo = useAppSelector(({userReducer}) => userReducer.data)

  const isReadOnlyMode = event.organizer._id !== userInfo._id || event.startDate < moment().toISOString()
  const [readOnly, setReadOnly] = useState<any>(isReadOnlyMode)
  const readOnlyFunc = async () => {
    if (userInfo?.authUserId) {
      setReadOnly(isReadOnlyMode)
    }
  }

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const columns : TableColumn[] = [
    {
      id: 'firstName',
      label: 'NOME'
    },
    {
      id: 'lastName',
      label: 'COGNOME'
    },
    {
      id: 'email',
      label: 'EMAIL'
    },
    {
      id: 'qualification',
      label: 'QUALIFICA'
    }
  ]

  const editVirtualMeeting = async () => {
    const values = form.getFieldsValue()
    await apiClient.patchVirtualMeetings(event._id, {
      title: values.title,
      startDate: values.startDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString(),
      endDate: values.endDate.set({year: values.startDay.year(), month: values.startDay.month(), date: values.startDay.date()}).toISOString()
    }, setStatus)
  }

  useEffect(() => {
    form.setFieldValue('title', event?.title)
    form.setFieldValue('code', event?._id)
    form.setFieldValue('startDay', moment(event?.startDate))
    form.setFieldValue('startDate', moment(event?.startDate))
    form.setFieldValue('endDate', moment(event?.endDate))
    form.setFieldValue('linkTeleconsultation', event?.linkTeleconsultation)
    form.setFieldValue('organizer', event?.organizer ? `${event.organizer.firstName} ${event.organizer.lastName}` : undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, event])

  useEffect(() => {
    readOnlyFunc()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.authUserId])

  return (
    <VirtualMeetingDetailModalStyled>
      <div className='title-container'>
        {'Incontro virtuale'}
        <IconButton
          onClick={handleClose}
        >
          <Icon path={mdiClose} size={'25px'} />
        </IconButton>
      </div>
      <div className='container-virtual-meeting'>
        <CardCustom title={<Typography fontWeight={700} variant='h4'><FormattedMessage id='mainData' /></Typography>}>
          <Form
            form={form}
            id='main-data-form'
            layout='vertical'
          >
            <Form.Item
              className='title'
              label='Titolo'
              name='title'
            >
              <Input disabled={readOnly} />
            </Form.Item>
            <Form.Item
              className='code'
              label='Codice'
              name='code'
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className='startDay'
              label='Data evento'
              name='startDay'
              rules={[
                {required: true, message: 'Campo obbligatorio'},
                () => ({
                  validator (_, value) {
                    if (value?.isBefore(moment())) {
                      return Promise.reject(new Error("L'appuntamento non può essere nel passato"))
                    }
                    return Promise.resolve()
                  }
                })
              ]}
            >
              <DatePicker
                disabled={readOnly}
                format='DD MMM YYYY'
                getPopupContainer={(trigger) => trigger}
                locale={locale}
                placeholder=''
              />
            </Form.Item>
            <Form.Item
              className='startDate'
              label='Ora inizio'
              name='startDate'
            >
              <TimePicker
                disabled={readOnly}
                format='HH:mm'
                getPopupContainer={(trigger) => trigger}
                locale={locale}
                placeholder=''
              />
            </Form.Item>
            <Form.Item
              className='endDate'
              label='Ora fine'
              name='endDate'
            >
              <TimePicker
                disabled={readOnly}
                format='HH:mm'
                getPopupContainer={(trigger) => trigger}
                locale={locale}
                placeholder=''
              />
            </Form.Item>
            <Form.Item
              className='link'
              label='Link per il collegamento'
            >
              <div className='field-content'>
                <Form.Item
                  name='linkTeleconsultation'
                >
                  <Input disabled />
                </Form.Item>
                <div className='field-buttons'>
                  <Button
                    className='video-button'
                    onClick={() => window.open(event.linkTeleconsultation, '_blank')}
                  >
                    <Icon path={mdiVideoPlus} size={1} />
                  </Button>
                  <Button
                    className='field-button'
                    onClick={() => navigator.clipboard.writeText(event.linkTeleconsultation)}
                  >
                    <Icon path={mdiContentCopy} size={1} />
                  </Button>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              className='organizer'
              label='Organizzatore'
            >
              <div className='field-content'>
                <Form.Item
                  name='organizer'
                >
                  <Input disabled />
                </Form.Item>
                <div className='field-buttons'>
                  <Button
                    className='field-button'
                  >
                    <Icon path={mdiAccount} size={1} />
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </CardCustom>
        <div className='participants'>
          <TableHeader>
            <FormattedMessage id={'participants'} />
          </TableHeader>
          <TableCustom
            columns={columns}
            onPaginationChange={setPagination}
            pagination={pagination}
            rows={event.participants ?? []}
            totalElements={20}
          />
        </div>
      </div>
      <div className='save-button-container'>
        <PrimaryButton
          disabled={readOnly}
          key='save'
          onClick={() => editVirtualMeeting()}
        >
          <FormattedMessage id='save' />
        </PrimaryButton>
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel modificare i dati della pagina.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined) }}
        open={status === STATUS.FULFILLED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='success' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Modificati i dati con successo'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </VirtualMeetingDetailModalStyled>
  )
}

export default VirtualMeetingDetailModal
