import {Switch, Redirect, Route} from 'react-router-dom'
import {MainRoutingStyled} from './MainRoutingStyled'
import LoginPage from '../login/LoginPage'
import MainRoutingPages from './MainRoutingPages'
import {PATHS, STATUS} from 'src/constants/constants'
import PasswordRecoveryPage from '../passwordRecovery/PasswordRecoveryPage'
import ChangePortalPage from '../changePortal/ChangePortalPage'
import {useAppSelector} from 'src/hooks'
import PDFSummary from 'src/pdf/Summary/PDFSummary'
import {useEffect} from 'react'
import PatientDigitalEnrollment from '../patientDigitalEnrollment/PatientDigitalEnrollment'
import PDFDiary from 'src/pdf/PDFDiary/PDFDiary'
import SelfRegistration from '../selfRegistration/SelfRegistration'

function MainRouting () {
  const pspConfig = useAppSelector(state => state.pspConfigReducer.data)
  const statusConfig = useAppSelector(({pspConfigReducer}) => pspConfigReducer.status)
  const statusUserInfo = useAppSelector(({userReducer}) => userReducer.status)
  const {summaryConfiguration, therapyDiaryConfigurations} = useAppSelector(state => state.pspConfigReducer.data)

  useEffect(() => {
    const link = document.querySelector('link[rel="shortcut icon"]')
    const title = document.querySelector('title')
    if (title && pspConfig.PSPTitleAndIcon.title) {
      document.title = pspConfig.PSPTitleAndIcon?.title
    }
    if (link && pspConfig.PSPTitleAndIcon.icon) {
      link.setAttribute('href', `data:image/png;base64,${pspConfig.PSPTitleAndIcon.icon}`)
    }
  }, [pspConfig])

  return (
    <MainRoutingStyled>
      <Switch>
        <Route component={LoginPage} path={PATHS.LOGIN} />
        <Route component={PasswordRecoveryPage} path={PATHS.PASSWORD_RECOVERY} />
        <Route component={ChangePortalPage} path={PATHS.PORTALS} />
        <Route component={PatientDigitalEnrollment} path={PATHS.PATIENT_DIGITAL_ENROLLMENT} />
        <Route component={SelfRegistration} path={PATHS.AUTO_REGISTRATION} />
        {(statusConfig === STATUS.FULFILLED && statusUserInfo === STATUS.FULFILLED) && pspConfig.sidebarItems.summary?.show && summaryConfiguration.enableExport &&
          <Route component={PDFSummary} path={`/${PATHS.PDF_SUMMARY}`} />}
        {(statusConfig === STATUS.FULFILLED && statusUserInfo === STATUS.FULFILLED) && (pspConfig.sidebarItems.therapyDiary?.show || pspConfig.patientDetailConfiguration.tabs.diary?.show) && therapyDiaryConfigurations?.showExport &&
          <Route component={PDFDiary} path={`/${PATHS.PDF_DIARY}`} />}
        <Switch>
          <MainRoutingPages />
        </Switch>
        <Redirect to={PATHS.SUMMARY} />
      </Switch>
    </MainRoutingStyled>
  )
}

export default MainRouting
