import React, {useEffect, useState} from 'react'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import {STATUS, PATHS} from 'src/constants/constants'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import apiClient from 'src/services/apiClient'
import {AlertColor, Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {DigitalEnrollmentStyled} from './DigitalEnrollmentStyled'
import NewEnrollmentDialog from 'src/components/templates/NewEnrollmentDialog/NewEnrollmentDialog'
import {FormattedMessage} from 'react-intl'
import TableHeader from 'src/components/templates/TableHeader/TableHeader'
import {Action} from 'src/components/elements/Menus/ActionMenu/ActionsMenu'
import {mdiPlus} from '@mdi/js'
import {useAppSelector} from 'src/hooks'

const DIGITAL_ENROLLMENT_STATE_OPTIONS = [
  {value: 'COMPLETED', label: 'Completata'},
  {value: 'CONFIRMED', label: 'Conferma'},
  {value: 'DOCTOR_COMPILED', label: 'Compilata dal dottore'},
  {value: 'PATIENT_COMPILED', label: 'Compilata dal paziente'}
]

const DigitalEnrollment = () => {
  const {services} = useAppSelector(state => state.pspConfigReducer.data.digitalEnrollmentConfiguration)
  const [filters, setFilters] = useState([])
  const [fetchedData, setFetchedData] = useState<{data: Record<string, any>[], count: number}>({
    data: [],
    count: 0
  })
  const [isNewEnrollmentModal, setIsNewEnrollmentModal] = useState<boolean>(false)
  const [therapyTypeOptions, setTherapyTypeOptions] = useState([])
  const [serviceTypeOptions, setServiceTypeOptions] = useState([])
  const [selectedRequest, setSelectedRequest] = useState<Record<string, any>>()
  const [status, setStatus] = useState<any>(undefined)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<AlertColor>('success')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-createdAt'
  })

  const columns : TableColumn[] = [
    {
      id: 'lastName',
      label: 'Cognome Paziente'
    },
    {
      id: 'firstName',
      label: 'Nome Paziente'
    },
    {
      id: 'therapyName',
      label: 'Terapia'
    },
    {
      id: 'createdAt',
      label: 'DATA CREAZIONE',
      type: 'date',
      format: 'ddd DD MMM YYYY HH:mm'
    },
    {
      id: 'state',
      label: 'Stato'
    }
  ]

  const filterConfig : FilterItem[] = [
    {
      id: 'lastName',
      label: 'Cognome Paziente',
      type: 'string'
    },
    {
      id: 'firstName',
      label: 'Nome Paziente',
      type: 'string'
    },
    {
      id: 'therapyTypeId',
      label: 'Terapia',
      type: 'select',
      options: therapyTypeOptions
    },
    {
      id: 'state',
      label: 'Stato',
      type: 'select',
      options: DIGITAL_ENROLLMENT_STATE_OPTIONS
    }
  ]

  const headerActions : Action[] = [
    {
      id: 'new',
      label: 'Nuova',
      icon: mdiPlus,
      action: () => {
        setSelectedRequest(undefined)
        setIsNewEnrollmentModal(true)
      }
    }
  ]
  const fetchData = async () => {
    Promise.all([apiClient.getDigitalEnrollment(filters, setStatus, pagination), apiClient.getDigitalEnrollmentCount(filters, setStatus)])
      .then(([exports, count]) => {
        setFetchedData({
          data: exports,
          count
        })
      })
  }

  const fetchTherapyTypes = async () => {
    const fetchedTherapyTypes = await apiClient.getTherapiesTypes(setStatus) ?? []
    setTherapyTypeOptions(fetchedTherapyTypes.map((therapy: any) => ({label: therapy.name, value: therapy._id})))
  }

  const fetchServiceTypes = async () => {
    const fetchedServiceTypes = await apiClient.getPackages(setStatus) ?? []
    setServiceTypeOptions(
      fetchedServiceTypes
        .map((service: any) => ({label: service.name, value: service._id}))
        .reduce((acc: {label: string, value: string}[], option: {label: string, value: string}) => {
          const service = services.find((el: {name: string, label: string}) => el.name === option.label)
          if (service) {
            acc.push({...option, label: service.label})
          }
          return acc
        }, []))
  }

  const cleanData = (rawData?: Record<string, any>[]) => {
    return (rawData || []).map((row) => ({
      ...row,
      therapyName: row.therapyType?.name,
      state: DIGITAL_ENROLLMENT_STATE_OPTIONS.find(state => state.value === row?.state)?.label ?? row?.state
    }))
  }

  const onRowClick = (row: Record<string, any>) => {
    setSelectedRequest(row)
    setIsNewEnrollmentModal(true)
  }

  const onSubmitReport = async (values: any) => {
    setIsNewEnrollmentModal(false)
    await apiClient.postDigitalEnrollment(values, setStatus)
    fetchData()
  }

  const onSubmit = (values: any) => {
    setFilters(values)
    setPagination(prev => ({...prev, page: 0}))
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    fetchTherapyTypes()
    fetchServiceTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (status === STATUS.REJECTED) {
      setSeverity('error')
      setMessage('Si è verificato un errore')
      setOpenSnackbar(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Adesione digitale paziente', href: PATHS.DIGITAL_ENROLLMENT}]}
      buttons={[
        <Filters config={filterConfig} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setOpenSnackbar(false)} severity={severity} sx={{width: '100%'}}>
            <Typography variant='h5'>
              {message}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <DigitalEnrollmentStyled>
        <TableHeader actions={headerActions}>
          <div className='table-header'>
            <FormattedMessage id={'digitalEnrollment'} />
          </div>
        </TableHeader>
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClick}
          pagination={pagination}
          rows={cleanData(fetchedData.data)}
          totalElements={fetchedData.count}
        />
      </DigitalEnrollmentStyled>
      {isNewEnrollmentModal &&
        <NewEnrollmentDialog
          onClose={() => setIsNewEnrollmentModal(false)}
          onSubmit={(values) => onSubmitReport(values)}
          request={selectedRequest}
          serviceTypesOptions={serviceTypeOptions}
          therapyTypeOptions={therapyTypeOptions}
        />}
    </LayoutContainer>
  )
}

export default DigitalEnrollment
