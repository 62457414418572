import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {UserAvatarDialogStyled} from './UserAvatarDialogStyled'
import {Form, Input} from 'antd'
import Avatar from 'src/components/elements/Avatar/Avatar'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {useState} from 'react'
import {AvatarType} from 'src/pages/profile/Profile'

type UserAvatarDialogProps = {
  avatarNickName: string;
  avatar?: AvatarType;
  onClose: () => void;
  onSave: (username: string, avatar?: AvatarType) => void;
  avatarImages: AvatarType[]
};

const UserAvatarDialog: React.FC<UserAvatarDialogProps> = ({avatarNickName, avatar, onSave, onClose, avatarImages}) => {
  const [newAvatarNickName, setNewAvatarNickName] = useState(avatarNickName)
  const [selectedAvatar, setSelectedAvatar] = useState<AvatarType | undefined>(avatar)

  const body = (
    <UserAvatarDialogStyled>
      <Form layout='vertical'>
        <Form.Item label='Nickname'>
          <Input onChange={(event) => setNewAvatarNickName(event.target.value)} value={newAvatarNickName} />
        </Form.Item>
      </Form>
      <div className='avatar-container'>
        {avatarImages.map((el: AvatarType, index: number) => (
          <Avatar imageSrc={el.imageBase64} key={index} onClick={() => setSelectedAvatar(el)} selected={selectedAvatar?._id === el._id} size='5.625vw' />
        ))}
      </div>
    </UserAvatarDialogStyled>
  )

  const footer = (
    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '13px'}}>
      <PrimaryButton
        key='submit'
        onClick={() => onSave(newAvatarNickName, selectedAvatar)}
      >
        <FormattedMessage id='save' />
      </PrimaryButton>
      <SecondaryButton onClick={() => onClose()}>
        <FormattedMessage id='undo' />
      </SecondaryButton>
    </div>
  )

  return (
    <DialogCustom
      body={body}
      footer={footer}
      maxWidth='lg'
      onClose={onClose}
      title={{text: 'chooseAvatarUsername', format: true}}
    />
  )
}

export default UserAvatarDialog
