
import React, {
  useEffect,
  useState
} from 'react'
// import apiClient from 'src/services/apiClient'
import apiClient from 'src/services/apiClient'
import {Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import Chart from 'src/components/elements/Chart/Chart'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import WarningPatientEmptyData from 'src/components/elements/WarningEmptyCard/WarningPatientEmptyData'

const VITAL_SIGNS_COLLECTION = 'vital-signs-collection'
const THERAPEUTICAL_PARAMS_COLLECTION = 'therapeutic-parameters-collection'

const Analytics: React.FC<{patientId: string}> = ({patientId}) => {
  const [vitalParamsChartData, setVitalParamsChartData] = useState<Record<string, any>[]>([])
  const [therapeuticalParamsChartData, setTherapeuticalParamsChartData] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<any>(undefined)
  const [colors, setColors] = useState<string[]>(['#A5C842', '#495CA2', '#028B96', '#E87D1E', '#003DA5', '#085AA6', '#731472', '#3599B8'])

  useEffect(() => {
    fetchData()
    fetchProgram()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId])

  const fetchProgram = async () => {
    const program = await apiClient.getProgram()
    if (program) {
      setColors(program.programPalette.colors)
    }
  }

  const fetchData = async () => {
    Promise.all([apiClient.getPatientsFeedbacksChartValues(VITAL_SIGNS_COLLECTION, patientId, setStatus), apiClient.getPatientsFeedbacksChartValues(THERAPEUTICAL_PARAMS_COLLECTION, patientId, setStatus)])
      .then(([vitalParamsData, therapeuticalParamsData]) => {
        setVitalParamsChartData(vitalParamsData)
        setTherapeuticalParamsChartData(therapeuticalParamsData)
      })
  }

  const VitalParamsChart = (
    vitalParamsChartData.length ?
      vitalParamsChartData.map((data) => {
        const name = data.unitMeasure ? `${data.name} [${data.unitMeasure}]` : data.name

        return (
          <div key={data.id} style={{marginTop: '8px'}}>
            <CardCustom title={<Typography fontWeight={700} variant='body2'>{name}</Typography>}>
              <Chart
                colors={colors}
                data={[{...data, name}]}
                type='spline'
              />
            </CardCustom>
          </div>
        )
      }) :
      <WarningPatientEmptyData subject='parametro vitale' />
  )

  const TherapeuticalParamsChart = (
    therapeuticalParamsChartData.length ?
      therapeuticalParamsChartData.map((data) => {
        const name = data.unitMeasure ? `${data.name} [${data.unitMeasure}]` : data.name

        return (
          <div key={data.id} style={{marginTop: '8px'}}>
            <CardCustom title={<Typography fontWeight={700} variant='body2'>{name}</Typography>}>
              <Chart
                colors={colors}
                data={[{...data, name}]}
                type='spline'
              />
            </CardCustom>
          </div>
        )
      }) :
      <WarningPatientEmptyData subject='parametro terapeutico' />
  )

  return (
    <div>
      <TabsCustom
        contents={[
          VitalParamsChart,
          TherapeuticalParamsChart
        ]}
        tabs={[
          {
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Parametri vitali'}</Typography>
          },
          {
            label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Parametri terapeutici'}</Typography>
          }
        ]}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => setStatus(undefined)}
        open={status === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => setStatus(undefined)}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare caregivers'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )
}

export default Analytics
