
import React, {
  useEffect,
  // useEffect,
  useState
} from 'react'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
// import apiClient from 'src/services/apiClient'
import CaregiverDetailDialog from '../../CaregiverDetailDialog/CaregiverDetailDialog'
import apiClient from 'src/services/apiClient'
import {Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'

const Caregivers: React.FC<{patientId: string}> = ({patientId}) => {
  const [fetchedData, setFetchedData] = useState<{data: Record<string, any>[], count: number}>({data: [], count: 0})
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [caregiverDetail, setCaregiverDetail] = useState<Record<string, any>>()
  const [openDetailDialog, setOpenDetailDialog] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const columns: TableColumn[] = [
    {
      id: 'title',
      label: 'Titolo'
    },
    {
      id: 'lastName',
      label: 'Cognome'
    },
    {
      id: 'firstName',
      label: 'Nome'
    },
    {
      id: 'phoneNumber',
      label: 'Telefono'
    },
    {
      id: 'cellPhoneNumber',
      label: 'Cellulare'
    }
  ]

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, pagination])

  const onRowClick = (row: Record<string, any>) => {
    setCaregiverDetail(row)
    setOpenDetailDialog(true)
  }

  const fetchData = async () => {
    Promise.all([apiClient.getPatientsCaregivers(patientId, setStatus, pagination), apiClient.getPatientsCaregiversCount(patientId, setStatusCount)])
      .then(([caregivers, count]) => {
        setFetchedData({
          data: caregivers,
          count
        })
      })
  }

  return (
    <div>
      <TableCustom
        columns={columns}
        onPaginationChange={setPagination}
        onRowClick={onRowClick}
        pagination={pagination}
        rows={fetchedData.data}
        totalElements={fetchedData.count}
      />
      {openDetailDialog &&
        <CaregiverDetailDialog
          detail={caregiverDetail}
          onClose={() => setOpenDetailDialog(false)}
        />}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare caregivers'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )
}

export default Caregivers
