import React, {PropsWithChildren} from 'react'
import {createTheme, ThemeProvider} from '@mui/material'
import PropTypes from 'prop-types'
import {useAppSelector} from 'src/hooks'

const ThemeAppProvider: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const pspCofigTheme = useAppSelector(state => state.pspConfigReducer.data.theme)

  let theme = createTheme()

  theme = createTheme({
    palette: {
      primary: {
        main: pspCofigTheme.primaryColor ?? '#A4C842',
        500: pspCofigTheme.secondaryColor ?? '#8FC530',
        100: pspCofigTheme?.hoverbutton ?? 'rgba(164,200,66, .5)',
        700: pspCofigTheme?.darkerColor ?? '#8FC530'
      },
      background: {
        default: '#F0F3F4'
      },
      neutral: {
        main: '#58666e',
        100: '#EEEEEE',
        200: '#DEE5E7'
      }
    },
    typography: {
      fontFamily: [
        '"Roboto"', 'sans-serif'
      ].join(','),
      fontWeightMedium: 400,
      fontWeightBold: 600,
      h1: {
        fontSize: 25,
        fontWeight: 600
      },
      h4: {
        fontSize: 18,
        fontWeight: 600
      },
      h5: {
        fontSize: 14,
        fontWeight: 600
      },
      body1: {
        fontSize: 16,
        fontWeight: 400
      },
      body2: {
        fontSize: 14,
        fontWeight: 400
      }
    },
    spacing: 2
  })

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

ThemeAppProvider.propTypes = {
  children: PropTypes.any
}

export default ThemeAppProvider
