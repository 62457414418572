import React, {useEffect, useState} from 'react'
import {Fade, ListItemText, MenuItem, MenuList, Paper, Slide, Snackbar, Typography} from '@mui/material'
import {Link, useHistory} from 'react-router-dom'
import Icon from '@mdi/react'
import {mdiChevronDown} from '@mdi/js'
import {HeaderMenuStyled, MenuStyled} from './HeaderMenuStyled'
import {FormattedMessage} from 'react-intl'
import {TransitionProps} from '@mui/material/transitions'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'
import SnackbarAlertCustom from '../../SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import {useCookies} from 'react-cookie'
import {dispatch} from 'src/redux-store'
import {clearPspConfig} from 'src/slices/pspConfigSlice'
import {clearUserInfo} from 'src/slices/userSlice'

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Fade in ref={ref} {...props}>
      <div>
        <Slide direction='left' ref={ref} {...props} timeout={{enter: 800, exit: 0}} />
      </div>
    </Fade>
  )
})

const HeaderMenu: React.FC<{firstLogin?: boolean}> = ({firstLogin}) => {
  const {headerConfiguration} = useAppSelector(state => state.pspConfigReducer.data)
  const userInfoData = useAppSelector(({userReducer}) => userReducer.data)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [logoutStatus, setLogoutStatus] = useState<string>()
  const history = useHistory()
  const [, , removeCookie] = useCookies(['sid', 'tokens'])

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const onLogout = () => {
    apiClient.logout(setLogoutStatus)
  }

  useEffect(() => {
    if (logoutStatus === STATUS.FULFILLED) {
      removeCookie('sid')
      removeCookie('tokens')
      dispatch(clearPspConfig())
      dispatch(clearUserInfo())
      history.push('/login')
    }
  }, [history, logoutStatus, removeCookie])

  return (
    <HeaderMenuStyled>
      <div className='menuButton' onClick={handleClick}>
        <span style={firstLogin ? {color: 'white'} : {}}>{userInfoData.title ? `${userInfoData.title} ${userInfoData.firstName} ${userInfoData.lastName}` : `${userInfoData.firstName} ${userInfoData.lastName}`}</span>
        <Icon className='icon' path={mdiChevronDown} size={'20px'} style={firstLogin ? {color: 'white'} : {}} />
      </div>
      <MenuStyled
        MenuListProps={{sx: {padding: 0}}}
        TransitionComponent={Transition}
        TransitionProps={{timeout: 2000}}
        anchorEl={anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        open={open}
      >
        <Paper sx={{width: 200, maxWidth: '100%', boxShadow: 0}}>
          <MenuList>
            {headerConfiguration?.profile && !firstLogin &&
              <MenuItem component={Link} onClick={handleClose} to={'/profile'}>
                <ListItemText className='textList' disableTypography>
                  <Typography variant='body2'> <FormattedMessage id='profile' /></Typography>
                </ListItemText>
              </MenuItem>}
            {headerConfiguration?.changePortal && !firstLogin &&
              <MenuItem component={Link} onClick={handleClose} to={'/portals'}>
                <ListItemText className='textList' disableTypography>
                  <Typography variant='body2'> <FormattedMessage id='cambiaPortale' /></Typography>
                </ListItemText>
              </MenuItem>}
            {headerConfiguration?.logout &&
              <MenuItem onClick={onLogout}>
                <ListItemText className='textList' disableTypography>
                  <Typography variant='body2'><FormattedMessage id='logout' /></Typography>
                </ListItemText>
              </MenuItem>}
          </MenuList>
        </Paper>
      </MenuStyled>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setLogoutStatus(undefined)}
        open={logoutStatus === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setLogoutStatus(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore durante la procedura di logout.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </HeaderMenuStyled>
  )
}

export default HeaderMenu
