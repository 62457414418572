import {
  HttpStatusCode,
  isAxiosError
} from 'axios'

const onResponseRejected = (error: Error) => {
  if (
    isAxiosError(error) &&
      error.response?.status === HttpStatusCode.Unauthorized && window.location.pathname !== '/login'
  ) {
    window.location.href = `${window.location.protocol}//${window.location.host}/login`
  }
  return Promise.reject(error)
}

export default {
  response: {
    onRejected: onResponseRejected
  }
}
