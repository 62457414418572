import {PropsWithChildren} from 'react'
import {Button, ButtonProps} from '@mui/material'
import {SecondaryButtonStyled} from './SecondaryButtonStyled'

const SecondaryButton: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...other
}) => {
  return (
    <SecondaryButtonStyled>
      <Button {...other} variant='outlined'>
        {children}
      </Button>
    </SecondaryButtonStyled>
  )
}

export default SecondaryButton
