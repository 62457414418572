import {CircularProgress, Typography} from '@mui/material'
import {DatePicker, Form, Input, Upload, UploadFile} from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import {FormattedMessage} from 'react-intl'

import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'

import {mdiDelete, mdiThumbUp} from '@mdi/js'
import Icon from '@mdi/react'
import {EnrollmentPatientFormStyled, CardStyled} from './EnrollmentPatientFormStyled'
import moment from 'moment'
import {STATUS} from 'src/constants/constants'
import {useState} from 'react'

type EnrollmentPatientFormProps = {
  status: string | undefined,
  onSubmit: (values: any) => void;
}

type UploadListItemProps = {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile<any>;
  fileList: UploadFile<any>[];
  actions: {
    download: () => void,
    preview: () => void,
    remove: () => void
  }
}

const EnrollmentPatientForm : React.FC<EnrollmentPatientFormProps> = ({
  status,
  onSubmit
}) => {
  const [form] = Form.useForm()
  const {Dragger} = Upload
  const [fileListEl, setFileList] = useState<UploadFile<any>[]>([])

  const UploadListItem = ({originNode, file, fileList, actions}: UploadListItemProps) => {
    return (
      <>
        {
          fileList[0] === file &&
            <div className='upload-items-header'>
              <Typography style={{fontSize: '13px'}} variant='h5'>
                <span>{'FILE'}</span>
              </Typography>
            </div>
        }
        <div className='upload-item'>
          <Typography variant='h5'>
            <span>{file.name}</span>
          </Typography>
          <SecondaryButton onClick={() => actions.remove()} style={{padding: 'unset'}}>
            <Icon path={mdiDelete} size={1} />
          </SecondaryButton>
        </div>
      </>
    )
  }

  const EnrollmentForm = () => {
    return (
      <Form
        form={form}
        id='enrollment-form'
        layout='vertical'
        onFinish={(values) => { onSubmit(values) }}
      >
        <div className='patient-details'>
          <Form.Item
            label={'Cognome Paziente'}
            name={'lastName'}
            rules={[{required: true, message: 'Campo obbligatorio'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Nome Paziente'}
            name={'firstName'}
            rules={[{required: true, message: 'Campo obbligatorio'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Data di nascita'}
            name={'birthDate'}
            rules={[
              {required: true, message: 'Campo obbligatorio'},
              () => ({
                validator (_, value) {
                  if (value?.isAfter(moment())) {
                    return Promise.reject(new Error('La data di nascita non può essere nel futuro'))
                  }
                  return Promise.resolve()
                }
              })
            ]}
          >
            <DatePicker
              format='DD MMM YYYY'
              getPopupContainer={(trigger) => trigger}
              locale={locale}
              placeholder=''
            />
          </Form.Item>
        </div>
        <div className='enrollment-upload'>
          <Form.Item
            name={'file'}
            rules={[{required: true, message: 'Campo obbligatorio'}]}
            valuePropName={''}
          >
            <Dragger
              beforeUpload={() => false}
              fileList={fileListEl}
              itemRender={(originNode, file, fileList, actions) => (
                <UploadListItem actions={actions} file={file} fileList={fileList} originNode={originNode} />
              )}
              multiple
              onChange={({fileList: newFileList}) => {
                setFileList(newFileList)
                form.setFieldValue('file', newFileList)
              }}
              showUploadList
            >
              <img alt='uploader' src={'/assets/images/upload.png'} />
              <Typography>
                <span>{'Clicca o trascina qui per caricare il file con il modulo di adesione'}</span>
              </Typography>
            </Dragger>
          </Form.Item>
        </div>
        <div className='footer-div'>
          <PrimaryButton
            form='enrollment-form'
            key='submit'
            type='submit'
          >
            <FormattedMessage id='save' />
          </PrimaryButton>
          <SecondaryButton onClick={() => form.resetFields()}>
            <FormattedMessage id='undo' />
          </SecondaryButton>
        </div>
      </Form>
    )
  }

  const LoadingCard = () => {
    return (
      <CardStyled>
        <Typography id='title'>
          <FormattedMessage id='enrollmentLoadingTitle' />
        </Typography>
        <CircularProgress />
        <Typography id='subtitle'>
          <FormattedMessage id='enrollmentLoadingMessage' />
        </Typography>
      </CardStyled>
    )
  }

  const SuccessCard = () => {
    return (
      <CardStyled>
        <Typography className='title'>
          <FormattedMessage id='enrollmentSuccessTitle' />
        </Typography>
        <Icon path={mdiThumbUp} size={2} />
        <Typography className='subtitle'>
          <FormattedMessage id='enrollmentSuccessMessage' />
        </Typography>
        <Typography>
          <span>{'Nome e Cognome Paziente:'} <b>{`${form.getFieldValue('lastName')} ${form.getFieldValue('firstName')} `}</b></span>
        </Typography>
        <Typography>
          <span>{'Data di Nascita: '} <b>{`${form.getFieldValue('birthDate')?.format('DD MMM YYYY')} `}</b></span>
        </Typography>
      </CardStyled>
    )
  }

  return (
    <EnrollmentPatientFormStyled>
      {
        status === STATUS.PENDING ?
          <LoadingCard /> :
          status === STATUS.FULFILLED ?
            <SuccessCard /> :
            <CardCustom title={
              <div className='custom-card-header'>
                <Typography fontWeight={700} variant='body2'><FormattedMessage id='patientData' /></Typography>
              </div>
            }
            >
              <EnrollmentForm />
            </CardCustom>
      }

    </EnrollmentPatientFormStyled>
  )
}

export default EnrollmentPatientForm
