import {Collapse, Divider} from 'antd'
import {PricingCardStyled} from './PricingCardStyled'
import {Typography} from '@mui/material'
import {Product} from 'src/types/types'
import PricingTable from './PricingTable'

type PricingCardProps = {
  products: Product[];
  }

const PricingCard: React.FC<PricingCardProps> = (
  {
    products
  }
) => {
  const {Panel} = Collapse

  return (
    <PricingCardStyled>
      <div className='container'>
        <div className='container-title'>
          <Typography variant='h4'>
            {'Prodotti attivi'}
          </Typography>
        </div>
        <Divider />
        <div className='container-body'>
          <Collapse defaultActiveKey={['1']}>
            {products.map((product, index) => {
              return (
                <Panel header={product.name} key={product.name + index}>
                  <div className='container-body-list'>
                    {product.packages.map((erodedPackage) => <PricingTable erodedPackage={erodedPackage} key={erodedPackage._id + index} />)}
                  </div>
                </Panel>
              )
            })}
          </Collapse>
        </div>
      </div>
    </PricingCardStyled>
  )
}

export default PricingCard
