import {mdiCalendarBlank, mdiDownload, mdiFolderOutline, mdiPhone} from '@mdi/js'
import Icon from '@mdi/react'
import {InsuranceUserSummaryStyled} from './InsuranceUserSummaryStyled'
import {useAppSelector} from 'src/hooks'
import {PATHS, STATUS} from 'src/constants/constants'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {useHistory} from 'react-router-dom'
import HeaderSummaryCard from 'src/components/templates/HeaderSummaryCard/HeaderSummaryCard'
import EmptyPlaceholderSummaryCard from 'src/components/templates/EmptyPlaceholderSummaryCard copy/EmptyPlaceholderSummaryCard'
import apiClient from 'src/services/apiClient'
import {useEffect, useState} from 'react'
import {Button, Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import moment from 'moment'

function InsuranceUserSummary () {
  const [statusDocuments, setStatusDocuments] = useState<any>(undefined)
  const [statusDownload, setStatusDownload] = useState<any>(undefined)
  const [documents, setDocuments] = useState<any>([])
  const userInfo = useAppSelector(({userReducer}) => userReducer.data)

  const history = useHistory()

  const fetchData = async () => {
    const fetchedData = await apiClient.getDocuments([], setStatusDocuments, {
      page: 0,
      rowsPerPage: 2,
      order: '-createdAt'
    }) ?? []
    setDocuments(fetchedData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const dowloadFile = async (documentData: any) => {
    const fileResponse = await apiClient.download(documentData?.fileId, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse?.data)], {type: fileResponse?.headers['content-type']})
    const filepath = (window.URL || window.webkitURL).createObjectURL(blob)
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', documentData?.fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (

    <LayoutContainer breadcrumbs={[{label: '', href: PATHS.SUMMARY}]}>
      <InsuranceUserSummaryStyled>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{fontWeight: 600, fontSize: '32px'}}>{`Ciao ${userInfo?.firstName} 👋`}</div>
          <div style={{color: '#afbb00', border: '4px dotted #afbb00', borderRadius: '16px', textAlign: 'center', padding: '18px', fontSize: '18px'}}>
            <div style={{display: 'flex', gap: '8px', justifyContent: 'center', fontSize: '32px', fontWeight: 600}}>
              <Icon path={mdiPhone} size={2} />
              <div>{'800 408 999'}</div>
            </div>
            <div>{"Contatta il Numero Verde dedicato a questo programma o scrivi all'email caringforcarers@italiassistenza.it"}</div>
            <div>{'(attivo dal lunedì al venerdì dalle 9.00 alle 18.00)'}</div>
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{width: '40%', alignSelf: 'center'}}>
            <img alt='Homepage doctor' src={'/assets/images/InsuranceOnlineDoctor.svg'} />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', gap: '16px', margin: '32px 0px 0px 64px'}}>
            <div style={{fontSize: '24px', fontWeight: 600}}>
              <div>{'Benvenuto nel programma di Assistenza Domiciliare di Generali Welion che ti permette di prenderti cura di te e dei tuoi cari attraverso servizi socio-assistenziali e sanitari.'}</div>
            </div>
            <div>
              <ol style={{paddingLeft: 0, listStyle: 'inside decimal', fontSize: '18px'}}>
                <li>{'Contatta direttamente il Numero Verde dedicato per conoscere il programma oppure attendi di essere contattato telefonicamente da un nostro operatore per una Welcome Call'}</li>
                <li>{'Accedi alla sezione "Informazioni sul servizio" che trovi nel menù a sinistra per conoscere i servizi gratuiti previsti dal programma e i servizi aggiuntivi a tariffa agevolata'}</li>
                <li>{'Utilizza i servizi pensati per te e i tuoi familiari!'}</li>
              </ol>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', gap: '32px'}}>
          <div style={{width: '100%'}}>
            <HeaderSummaryCard
              onSeeOthers={() => history.push(PATHS.AGENDA)}
              title={'Prossimi appuntamenti'}
            />
            <EmptyPlaceholderSummaryCard
              icon={mdiCalendarBlank}
              subtitle={'Verrai contattato dalla tua struttura per programmare un appuntamento'}
              title={'Nessun appuntamento in programma'}
            />
          </div>
          <div style={{width: '100%'}}>
            <HeaderSummaryCard
              onSeeOthers={() => history.push(PATHS.DOCUMENTS)}
              title={'Ultimi documenti'}
            />
            {(documents && documents.length) ?
              <div style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
                {documents.map((document: any, index: number) =>
                  (
                    <div
                      key={index} style={{
                        border: '1px solid lightgray',
                        borderLeft: '3px solid #afbb00',
                        borderRadius: '8px',
                        width: '100%',
                        padding: '24px',
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        <div><b>{document?.fileName}</b></div>
                        <div>{moment(document?.createdAt).format('DD/MM/YYYY')}</div>
                      </div>
                      <Button
                        onClick={() => { dowloadFile(document) }}
                        variant='text'
                      >
                        <div style={{display: 'flex'}}>
                          <Icon path={mdiDownload} size={1} style={{alignSelf: 'center'}} />
                          <b>{'SCARICA'}</b>
                        </div>
                      </Button>
                    </div>
                  ))}
              </div> :
              <EmptyPlaceholderSummaryCard
                icon={mdiFolderOutline}
                subtitle={'Non è stato caricato ancora nessun documento'}
                title={'Nessun documento caricato'}
              />}
          </div>
        </div>
      </InsuranceUserSummaryStyled>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatusDocuments(undefined) }}
        open={statusDocuments === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatusDocuments(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>

  )
}

export default InsuranceUserSummary
