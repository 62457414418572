
import React from 'react'
import {Button, Typography} from '@mui/material'
import Icon from '@mdi/react'
import {mdiCloseCircleOutline, mdiFilterOutline} from '@mdi/js'
import {FormattedMessage} from 'react-intl'
import {FilterButtonStyled} from './FilterButtonStyled'

type FilterButtonProps = {
  open: boolean,
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

const FilterButton: React.FC<FilterButtonProps> = (
  {
    open,
    onClick
  }
) => {
  return (
    <FilterButtonStyled>
      {open ?
        <Button
          className='close-button'
          onClick={onClick}
          startIcon={<Icon path={mdiCloseCircleOutline} size={1.2} />}
          variant='contained'
        >
          <div className='close-text'>
            <Typography variant='body1'>
              <FormattedMessage id={'close'} />
            </Typography>
          </div>
        </Button> :
        <Button
          className='filter-button'
          onClick={onClick}
          startIcon={<Icon path={mdiFilterOutline} size={1} />}
          variant='contained'
        >
          <div className='filter-text'>
            <Typography variant='body1'>
              <FormattedMessage id={'filter'} />
            </Typography>
          </div>
        </Button>}
    </FilterButtonStyled>
  )
}

export default FilterButton
