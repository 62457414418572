import {Snackbar, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Chart from 'src/components/elements/Chart/Chart'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {PaginationType, TableColumn} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import SurveyDialog from 'src/components/templates/SurveyDialog/SurveyDialog'
import {PATHS, STATUS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import apiClient from 'src/services/apiClient'

const Surveys: React.FC = () => {
  const {graphs} = useAppSelector(state => state.pspConfigReducer.data.surveysConfiguration ?? {})
  const [open, setOpen] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState<Record<string, any>>()
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)
  const [rows, setRows] = useState<{data: Record<string, any>[], count: number}>({data: [], count: 0})
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [graphData, setGraphData] = useState<any>({
    moriskyScores: [],
    compilationDiaryScores: [],
    satisfactionScores: []
  })

  const columns: TableColumn[] = [
    {
      id: 'name',
      label: 'NOME'
    },
    {
      id: 'translatedState',
      label: 'STATO'
    },
    {
      id: 'createdAt',
      label: 'DATA CREAZIONE',
      type: 'date',
      format: 'ddd DD MMM YYYY'
    },
    {
      id: 'requestDate',
      label: 'DATA SOTTOMISSIONE',
      type: 'date',
      format: 'ddd DD MMM YYYY'
    }
  ]

  const STATE_TYPES: Record<string, string> = {
    CONFIRMED: 'Confermato',
    COMPILED: 'Compilato',
    TO_COMPILE: 'Da compilare'
  }

  useEffect(() => {
    if (graphs) {
      fetchGraphsData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  const fetchGraphsData = async () => {
    const fetchedData = await apiClient.getSurveyGraphData(setStatus) ?? []
    setGraphData(fetchedData)
  }

  const onRowClicked = (element: Record<string, any>) => {
    setSelectedSurvey(element)
    setOpen(true)
  }

  const fetchData = async () => {
    Promise.all([apiClient.getSurveys(setStatus, pagination), apiClient.getSurveysCount(setStatus)])
      .then(([surveys, count]) => {
        setRows({
          data: surveys.map((el: any) => ({...el, translatedState: STATE_TYPES[el.state] ?? el.state})),
          count
        })
      })
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Questionari', href: PATHS.QUESTIONNAIRE}]}
    >
      <div style={{marginTop: '16px'}}>
        {graphs &&
          <div>
            <Chart
              colors={['#4472C4']}
              data={graphData.moriskyScores}
              title='Questionario Morisky'
              type='morisky'
            />
            <Chart
              colors={['#4472C4']}
              data={graphData.satisfactionScores}
              title='Questionario di rilevazione soddisfazione per il programma'
              type='morisky'
            />
            <Chart
              colors={['#4472C4']}
              data={graphData.compilationDiaryScores}
              title='Questionario Monitoraggio Diario'
              type='morisky'
            />
          </div>}
        <TableCustom
          columns={columns}
          onPaginationChange={setPagination}
          onRowClick={onRowClicked}
          pagination={pagination}
          rows={rows?.data}
          totalElements={rows?.count}
        />
      </div>
      {(open && selectedSurvey) &&
        <SurveyDialog
          onClose={() => { setOpen(false); fetchData() }}
          surveyData={selectedSurvey}
        />}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare i questionari'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default Surveys
