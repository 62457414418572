import {styled} from '@mui/system'

export const TherapyCardStyled = styled('div')(({theme}) => ({
  border: '1px solid #edf1f2',

  '.header': {
    padding: '20px 14px',
    borderBottom: '1px solid #edf1f2',
    display: 'flex',

    '.header-element': {
      display: 'flex',
      alignItems: 'center'
    }
  },

  '#therapy-form': {
    display: 'grid',
    padding: '16px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '10px',

    '.ant-row': {
      width: '100%'
    },

    '.ant-form-item-label > label': {
      fontSize: theme.spacing(6),
      color: '#222'
    },

    '.ant-input': {
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },

    '.ant-input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    },

    '.ant-picker': {
      width: '100%',
      height: theme.spacing(19),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#eee'
    },

    '.ant-picker-input > input[disabled]': {
      backgroundColor: '#eee',
      borderColor: '#ccc',
      color: '#555'
    }
  }
}))
