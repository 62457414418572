
import {styled} from '@mui/system'
import {Calendar} from 'react-big-calendar'

export const ActivitiesAgendaStyled = styled(Calendar)(({theme}) => ({

  '.rbc-agenda-table': {
    thead: {
      display: 'none'
    },

    '.rbc-agenda-time-cell': {
      display: 'none'
    },

    tr: {
      display: 'flex',
      flexDirection: 'column'
    },

    '.rbc-agenda-date-cell': {
      background: '#eee',
      padding: `${theme.spacing(4)} ${theme.spacing(7)}`,

      '.date-box': {
        display: 'flex',
        justifyContent: 'space-between',
        textTransform: 'capitalize'
      }
    },

    '.rbc-agenda-event-cell': {
      padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
      borderLeft: 'solid #27c24c',
      background: '#f5f5f5',
      cursor: 'pointer !important',

      '.time-label': {
        paddingRight: theme.spacing(7)
      },

      '.event-dot': {
        display: 'inline-block',
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: theme.spacing(2.5),
        background: theme.palette.primary[100],
        marginLeft: theme.spacing(7)
      },

      '.title-label': {
        paddingLeft: theme.spacing(7)
      }
    }

  }
}))
